import { Component } from "@angular/core";
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationParameters } from "../../models/navigationParameters";
import { Router } from "@angular/router";

@Component({
    selector:'change-password',
    templateUrl:'./change-password.component.html',
    styleUrls:['./change-password.component.scss']
})

export class ChangePasswordComponent {
    user:User = new User();
    resetStatus:string = "";
    currentPassword:string = "";
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    showPassword:boolean = false;   
    showConfirmPassword:boolean = false;   
    showCurrentPassword:boolean = false;   

    constructor (
        private userService:UserService,
        private router:Router,
        private spinnerService: NgxSpinnerService
    ){
    }

    ngOnInit(){
        this.spinnerService.show();
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.user.email = this.navigationParams.loginEmail;
        this.spinnerService.hide();
    }

    toggleShowPassword(){
        this.showPassword = !this.showPassword
    }

    toggleShowConfirmPassword(){
        this.showConfirmPassword = !this.showConfirmPassword
    }

    toggleShowCurrentPassword(){
        this.showCurrentPassword = !this.showCurrentPassword
    }


    changePassword(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {

            this.spinnerService.show();

            //password property is used for current password
            //confirmPassword property is used for new password
            this.user.password = this.currentPassword;
            this.userService.changePassword(this.user).subscribe(data=>{
                if (data)
                    this.resetStatus = data.confirmPassword;
                else
                    this.resetStatus = "failed";
            });

            this.user.confirmPassword = "";
            this.user.password = "";
            this.currentPassword = "";
            
            this.spinnerService.hide();
        }
    }
}