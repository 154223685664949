import {GeneralInfo} from "./generalInfo";
import {FamilyMember} from "./familyMember";
import {IncomeRent} from "./incomeRent";
import {VeteranStatus} from "./veteranStatus";
import {HomelessStatus} from "./homelessStatus";
import {DisplacementStatus} from "./displacementStatus";
import {LeaseInPlaceStatus} from "./leaseInPlaceStatus";
import {DisabilityAccommodation} from "./disabilityAccommodation";
import { RaceEthnicity } from "./raceEthnicity";

export class Applicant{
    generalInfo:GeneralInfo;
    familyMembers:FamilyMember[];
    raceEthnicity:RaceEthnicity;
    incomeRent:IncomeRent;
    veteranStatus:VeteranStatus;
    homelessStatus:HomelessStatus;
    displacementStatus:DisplacementStatus;
    leaseInPlaceStatus:LeaseInPlaceStatus;
    disabilityAccommodation:DisabilityAccommodation;
}