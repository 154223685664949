    <div class="modal-content">
        <div class="modal-header bg-shradarkgreen">
            <div class="page-subtitle1 text-white text-center">Frequently Asked Questions</div>
            <button type="button" class="close" aria-label="Close" (click)="closeClick()">
            <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="text-center mt-5 align-items-center">
                <div class="page-text"><input type="text" id="searchText" class="searchBox" #searchText placeholder="search here">
                    <span class="text-left page-text text-search ml-2">
                    <a href="javascript:void(0)" (click)="search(searchText.value)">
                            <img src="{{imgFolder}}search button-01.svg" width="40px" height="40px" class="img img-responsive">
                        </a>
                    </span>
                </div>
            </div>
            <div *ngIf="!isMobile" class="row">
                <div class="column1 mt-5 ml-5">
                    <div class="mt-5"></div>
                    <div *ngFor="let fr of faqForms | orderby:'displayOrder':false">
                        <div *ngIf="!fr.itemType.includes('Sub With Content')" id="faqGroup" class="mb-3">
                            <div *ngIf = "!fr.itemType.includes('Main Without Content')">
                                <a [ngClass]="isSelectedRow(fr.faqFormID) ? 'page-subtitle1 text-search':'page-subtitle1 lnk-text'" href="javascript:void(0)" (click)="itemClick(fr.faqFormID)"><i class="bi bi-search mr-2"></i>{{fr.description}}</a>
                            </div>
                            <div *ngIf = "fr.itemType.includes('Main Without Content')">
                                <span class="page-subtitle1"><i class="bi bi-search mr-2"></i>{{fr.description}}</span>
                            </div>
                        </div>
                        <div *ngFor="let frs of faqForms">
                            <div *ngIf="frs.itemType.includes('Sub With Content') && frs.itemID == fr.faqFormID" id="faqGroup" class="mb-3 ml-5"><a [ngClass]="isSelectedRow(frs.faqFormID) ? 'page-text text-search':'page-text lnk-text'" href="javascript:void(0)" (click)="itemClick(frs.faqFormID)"><i class="bi bi-search mr-2"></i>{{frs.description}}</a></div>
                        </div>
                    </div>
                </div>
                <div class="vr p-0 text-green mt-5"></div>
                <div class="column2">
                    <div *ngIf="faqForm && questions && faqs">
                        <div *ngIf="searchText.value == ''" class="page-subtitle1 text-center mb-3 mt-5">{{faqForm.description}}</div>
                        <div *ngIf="searchText.value != ''" class="page-subtitle1 text-center mb-3 mt-5">Search Results for - {{searchText.value}}</div>
                        <div id="qs" *ngFor="let q of questions">
                            <div class="text-left ml-2">
                                <button (click)="toggleAccordian($event)" class="accordion" [innerHTML] = "q"></button>
                                <div class="panel bg-transparent">
                                    <div class="content-box mb-3">
                                        <span style="float: right;"><a class="text-green mr-2" href="javascript:void(0)" (click)="contentClose($event)"><i class="bi bi-x-circle-fill"></i></a></span>
                                        <div class="page-subtitle1 text-center mb-3 mt-2">{{q}}</div>
                                        <div *ngFor="let f of faqs">
                                            <div *ngIf="f.question==q">
                                                <span class="content-text ml-5" *ngIf="f.answerType == 'collapse'">
                                                    <button (click)="toggleContentAccordian($event)" class="content-accordion"></button>
                                                    <div class="content-panel">
                                                        <div class="content-text ml-1 mb-2"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                    </div>
                                                </span>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'text'"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'list'"><div class="p1" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'listoflist'"><div class="p3" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'img'"><div class="text-center"><img class="img-fluid" src="{{imgFolder}}{{f.answer}}" alt=""></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'gotolink'"><div class="p"><a href="javascript:void(0)" (click)="goToItem(f.answer)">{{f.answer}}</a></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'pagelink'"><div class="p">Click <a (click)="gotoPage('paperApplicationRequest')" href="javascript:void(0)">here</a> to request a paper application.</div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="questions.length == 0">
                            <div class="page-subtitle1 ml-3 mb-3 mt-5 text-search"><i>No results found</i></div>
                        </div>            
                    </div>            
                </div>
            </div>

            <div *ngIf="isMobile" class="row">
                <div class="column1 mt-5 ml-3 mr-3">
                    <div *ngIf="searchText.value != ''">
                        <div class="page-subtitle1 text-center mb-3">Search Results for - {{searchText.value}}</div>

                        <div id="qs" *ngFor="let q of questions">
                            <div class="text-left ml-2">
                                <button (click)="toggleAccordian($event)" class="accordion" [innerHTML] = "q"></button>
                                <div class="panel bg-transparent">
                                    <div class="content-box mb-3">
                                        <span style="float: right;"><a class="text-green mr-2" href="javascript:void(0)" (click)="contentClose($event)"><i class="bi bi-x-circle-fill"></i></a></span>
                                        <div class="page-subtitle1 text-center mb-3 mt-2">{{q}}</div>
                                        <div *ngFor="let f of faqs">
                                            <div *ngIf="f.question==q">
                                                <span class="content-text ml-5" *ngIf="f.answerType == 'collapse'">
                                                    <button (click)="toggleContentAccordian($event)" class="content-accordion"></button>
                                                    <div class="content-panel">
                                                        <div class="content-text ml-1 mb-2"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                    </div>
                                                </span>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'text'"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'list'"><div class="p1" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'listoflist'"><div class="p3" [innerHTML] = "f.answer"></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'img'"><div class="text-center"><img class="img-fluid" src="{{imgFolder}}{{f.answer}}" alt=""></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'gotolink'"><div class="p"><a href="javascript:void(0)" (click)="goToItem(f.answer)">{{f.answer}}</a></div></div>
                                                <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'pagelink'"><div class="p">Click <a (click)="gotoPage('paperApplicationRequest')" href="javascript:void(0)">here</a> to request a paper application.</div></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="questions.length == 0">
                            <div class="page-subtitle1 ml-3 mb-3 mt-5 text-search"><i>No results found</i></div>
                        </div>            

                        <div *ngFor="let fr of faqForms | orderby:'displayOrder':false">
                            <div *ngIf="!fr.itemType.includes('Sub With Content')" id="faqGroup" class="mb-3">
                                <div *ngIf = "!fr.itemType.includes('Main Without Content')">
                                    <a [ngClass]="isSelectedRow(fr.faqFormID) ? 'page-subtitle1 text-search':'page-subtitle1 lnk-text'" href="javascript:void(0)" (click)="itemClick(fr.faqFormID)"><i class="bi bi-search mr-2"></i>{{fr.description}}</a>
                                </div>
                                <div *ngIf = "fr.itemType.includes('Main Without Content')">
                                    <span class="page-subtitle1"><i class="bi bi-search mr-2"></i>{{fr.description}}</span>
                                </div>
                            </div>
                            <div *ngFor="let frs of faqForms">
                                <div *ngIf="frs.itemType.includes('Sub With Content') && frs.itemID == fr.faqFormID" id="faqGroup" class="mb-3 ml-5"><a [ngClass]="isSelectedRow(frs.faqFormID) ? 'page-text text-search':'page-text lnk-text'" href="javascript:void(0)" (click)="itemClick(frs.faqFormID)"><i class="bi bi-search mr-2"></i>{{frs.description}}</a></div>
                            </div>
                        </div>
                    </div> 

                    <div *ngIf="searchText.value == ''">
                        <div *ngFor="let fr of faqForms | orderby:'displayOrder':false">
                            <div *ngIf="!fr.itemType.includes('Sub With Content')" id="faqGroup" class="mb-3">
                                <div *ngIf = "!fr.itemType.includes('Main Without Content')">
                                    <a [ngClass]="isSelectedRow(fr.faqFormID) ? 'page-subtitle1 text-search':'page-subtitle1 lnk-text'" href="javascript:void(0)" (click)="itemClick(fr.faqFormID)"><i class="bi bi-search mr-2"></i>{{fr.description}}</a>
                                </div>
                                <div *ngIf = "fr.itemType.includes('Main Without Content')">
                                    <span class="page-subtitle1"><i class="bi bi-search mr-2"></i>{{fr.description}}</span>
                                </div>
                            </div>
                            <div *ngFor="let frs of faqForms">
                                <div *ngIf="frs.itemType.includes('Sub With Content') && frs.itemID == fr.faqFormID" id="faqGroup" class="mb-3 ml-5"><a [ngClass]="isSelectedRow(frs.faqFormID) ? 'page-text text-search':'page-text lnk-text'" href="javascript:void(0)" (click)="itemClick(frs.faqFormID)"><i class="bi bi-search mr-2"></i>{{frs.description}}</a></div>
                            </div>

                            <div *ngIf="faqForm && questions && faqs && params.faqFormID == fr.faqFormID">
                                <div id="qs" *ngFor="let q of questions">
                                    <div class="text-left ml-2">
                                        <button (click)="toggleAccordian($event)" class="accordion" [innerHTML] = "q"></button>
                                        <div class="panel bg-transparent">
                                            <div class="content-box mb-3">
                                                <span style="float: right;"><a class="text-green mr-2" href="javascript:void(0)" (click)="contentClose($event)"><i class="bi bi-x-circle-fill"></i></a></span>
                                                <div class="page-subtitle1 text-center mb-3 mt-2">{{q}}</div>
                                                <div *ngFor="let f of faqs">
                                                    <div *ngIf="f.question==q">
                                                        <span class="content-text ml-5" *ngIf="f.answerType == 'collapse'">
                                                            <button (click)="toggleContentAccordian($event)" class="content-accordion"></button>
                                                            <div class="content-panel">
                                                                <div class="content-text ml-1 mb-2"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                            </div>
                                                        </span>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'text'"><div class="p" [innerHTML] = "f.answer"></div></div>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'list'"><div class="p1" [innerHTML] = "f.answer"></div></div>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'listoflist'"><div class="p3" [innerHTML] = "f.answer"></div></div>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'img'"><div class="text-center"><img class="img-fluid" src="{{imgFolder}}{{f.answer}}" alt=""></div></div>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'gotolink'"><div class="p"><a href="javascript:void(0)" (click)="goToItem(f.answer)">{{f.answer}}</a></div></div>
                                                        <div class="content-text ml-1 mb-2" *ngIf="f.answerType == 'pagelink'"><div class="p">Click <a (click)="gotoPage('paperApplicationRequest')" href="javascript:void(0)">here</a> to request a paper application.</div></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="questions.length == 0">
                                    <div class="page-subtitle1 ml-3 mb-3 mt-5 text-search"><i>No results found</i></div>
                                </div>            
                            </div>            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
