import { Component, EventEmitter, Output} from "@angular/core";
import { Parameters } from '../../models/parameters';
import { Applicant } from '../../models/applicant';
import { User } from '../../models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';

@Component ({
    selector : 'registration-new',
    templateUrl : './registration-new.component.html',
    styleUrls: ['./registration-new.component.scss']
})

export class RegistrationNewComponent {
    @Output() showContent:EventEmitter<string> = new EventEmitter(); 
    applicant:Applicant = new Applicant();
    params: Parameters = new Parameters();
    config:any;
    title:string = "Registration";
    user:User = new User();
    userstatus:string = "";
    formInvalid:boolean = false;
    showPassword:boolean = false;   
    showConfirmPassword:boolean = false;   

    constructor (
        private spinnerService: NgxSpinnerService,
        private authService:AuthService,
    ){
        this.config = {
            monthFormat: 'MMM, YYYY',
            showNearMonthDays: true,
            enableMonthSelector: true,
            format: "MM/DD/YYYY",
            hideOnOutsideClick:true
          };
    }

    ngOnInit(){
        this.userstatus = JSON.parse(localStorage.getItem('userstatus'));
        this.userstatus = this.userstatus == "not found"? this.userstatus : "" ;
        localStorage.removeItem('userstatus');
    }

    setContent(content:string){
        this.showContent.emit(content);
    }

    toggleShowPassword(){
        this.showPassword = !this.showPassword
    }

    toggleShowConfirmPassword(){
        this.showConfirmPassword = !this.showConfirmPassword
    }

    register(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            this.params.email = this.user.email;
            this.userstatus = "";

            //check if user has alredy signed up
            this.authService.getUser(this.user)
            .then(s=> {
                if (s && s.email)
                {
                    this.user.id = s.id;                
                    this.userstatus = s.emailConfirmed == false? "registration not confirmed" : "already registered";
                }
                else
                {
                    this.user.role = "RegisteredUser";
                    this.authService.register(this.user).then(s=> {
                            this.userstatus = s? "success" : "failure";
                    });                                
                }
            });

            this.spinnerService.hide();
        }
    }

    resendConfirmationLink(){
        this.spinnerService.show();
        this.authService.resendConfirmationLink(this.user)
        .then(s=> {
            if (s)
                this.userstatus = s.email == "not found"? "resent error" : "resent";
            else
                this.userstatus = "resent error";
        });
        this.spinnerService.hide();
    }

}