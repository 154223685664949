import { UntypedFormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function InvalidDate(controlName: string) {
    return (formGroup: UntypedFormGroup) => {
        const control = formGroup.parent.controls[controlName];

        // return null if controls haven't initialised yet
        if (!control) {
          return null;
        }

        // set error on matchingControl if validation fails
        if (!isNaN(control.value) || !isDate(control.value)) {
            setTimeout(() => {
                control.setErrors({ invalidDate: true });
              });
        } else {
            control.setErrors(null);
        }

        function isDate(value:string) {
            //var re = /^(?=\d)(?:(?:31(?!.(?:0?[2469]|11))|(?:30|29)(?!.0?2)|29(?=.0?2.(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(?:\x20|$))|(?:2[0-8]|1\d|0?[1-9]))([-.\/])(?:1[012]|0?[1-9])\1(?:1[6-9]|[2-9]\d)?\d\d(?:(?=\x20\d)\x20|$))?(((0?[1-9]|1[012])(:[0-5]\d){0,2}(\x20[AP]M))|([01]\d|2[0-3])(:[0-5]\d){1,2})?$/;
            var re = /^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|([0-2][1-9])|(3[0-1]))\/((\d{2})|(\d{4}))$/;
            var flag = re.test(value);
            return flag;
          }
    }
}