export class Parameters{
    hmy:number;
    applicantCode:string;
    ssn:string;
    dob:string;
    firstName:string;
    middleInitial:string;
    lastName:string;    
    hUser:string;
    uniqueCode:string;
    waitList:string;
    submitDate:string;
    category:string;
    email:string;
    waitlistStatus:string;
    bedroomSize: number;
    chronoText:string;
    lastUpdatedDate:string;
}