import {Component, Input, OnChanges} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { MessageService } from '../services/message.service';
import { WaitlistService } from '../services/waitlist.service';

@Component({
    selector: 'waitlist-preference',
    templateUrl: './waitlist-preference.component.html' ,
    styleUrls: ['./waitlist-preference.component.scss']
})

export class WaitlistPreferenceComponent implements OnChanges{
    @Input() waitlist:string;
    @Input() title:string;
    prefs: Preferences[] = [];
    params: PortalParameters = new PortalParameters();

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService
        ){}


    ngOnChanges()
    {
        if (!this.title || this.title == "") this.title = "Preferences";

        this.spinnerService.show();

        this.params.waitlist = this.waitlist;
        this.params.itemType = this.title == "SHRA Requirements"? this.title : "Preference";
        this.waitlistService.getWaitlistPreferences(this.params)
        .subscribe(d => {
            this.prefs = d;
        }, (err) => {
            this.msg.showError(err, "waiting lists error");
        });  
        
        this.spinnerService.hide();
    }

    toggleAccordian(event) {
        var element = event.target;
        element.classList.toggle("active");
        var panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

    
}