import { Routes } from '@angular/router';
import { DisabilityAccomComponent } from './disability-accom/disability-accom.component';
import { DisplacementStatusComponent } from './displacement-status/displacement-status.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { HomelessStatusComponent } from './homeless-status/homeless-status.component';
import { IncomeRentComponent } from './income-rent/income-rent.component';
import { LIPStatusComponent } from './lease-in-place-status/lease-in-place-status.component';
import { VeteranStatusComponent } from './veteran-status/veteran-status.component';
import { ResultComponent } from './result/result.component';
//import { DashboardComponent } from './dashboard/dashboard.component';
import { FamilyMembersComponent } from './family-members/family-members.component';
import { RegistrationComponent } from './login/registration/registration.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';
import { AuthGuard } from './helpers/auth.guard';
import { WaitlistProgramComponent } from './waitlist-program/waitlist-program.component';
import { WaitlistTypeComponent } from './waitlist-type/waitlist-type.component';
import { WaitlistsComponent } from './waitlists/waitlists.component';
import { RegistrationConfirmationComponent } from './login/registration-confirmation/registration-confirmation.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { PasswordResetRequestComponent } from './login/password-reset-request/password-reset-request.component';
import { RaceEthnicityComponent } from './race-ethnicity/race-ethnicity.component';
import { RegistrationNewComponent } from './login/registration-new/registration-new.component';
import { BasicInfoComponent } from './basic-info/basic-info.component';
import { AnnouncementComponent } from './announcement/announcement.component';
// import { ContactFormComponent } from './contact-form_TBD/contact-form.component';
import { PaperApplicationRequestComponent } from './paper-application-request/paper-application-request.component';
// import { PaperApplicationFaqComponent } from './paper-application-faq_TBD/paper-application-faq.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
import { PropertyComponent } from './property/property.component';
import { WaitingListComponent } from './waiting-list/waiting-list.component';
import { ReferralProgramComponent } from './referral-program/referral-program.component';
// import { AdditionalResourcesComponent } from './additional-resources_TBD/additional-resources.component';
import { FormComponent } from './form/form.component';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { LoginMainComponent } from './login/login-main.component';
import { pendingChangesGuard } from './helpers/pendingChanges.guard';


export const appRoutes:Routes = [
    {path: 'dashboard', component:HomeComponent},
    {path: 'home', component:HomeComponent},    
    {path: 'landing', redirectTo:'/home', pathMatch:'full'},    
    {path: 'loginMain', component: LoginMainComponent},
    {path: 'userlogin', component: LoginMainComponent},
    {path: 'basicInfo', component:BasicInfoComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'generalInfo', component:GeneralInfoComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'familyMembers', component:FamilyMembersComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'raceEthnicity', component:RaceEthnicityComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},    
    {path: 'incomeRent', component:IncomeRentComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'disabilityAccommodation', component:DisabilityAccomComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'veteranStatus', component:VeteranStatusComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'homelessStatus', component:HomelessStatusComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'displacementStatus', component:DisplacementStatusComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'leaseInPlaceStatus', component:LIPStatusComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: 'result', component:ResultComponent},
    // {path: 'waitlist', component:WaitlistsComponent},
    // {path: 'waitlistProgram', component:WaitlistProgramComponent},
    // {path: 'waitlistType', component:WaitlistTypeComponent},
    {path: 'registration', component:RegistrationComponent},
    {path: 'registrationNew', component:RegistrationNewComponent},
    {path: 'confirmRegistration', component:RegistrationConfirmationComponent},
    {path: 'resetPassword', component:PasswordResetComponent},
    {path: 'passwordResetRequest', component:PasswordResetRequestComponent},
    {path: 'changePassword', component:ChangePasswordComponent, canActivate:[AuthGuard]},  
    {path: 'announcement', component:AnnouncementComponent},  
    // {path: 'contactForm', component:ContactFormComponent},  
    {path: 'contact', component:ContactComponent},      
    {path: 'paperApplicationRequest', component:PaperApplicationRequestComponent},      
    // {path: 'paperApplicationFaq', component:PaperApplicationFaqComponent},      
    {path: 'learnMore', component:LearnMoreComponent},      
    {path: 'applicantDashboard', component:ApplicantDashboardComponent, canActivate:[AuthGuard]},  
    {path: 'property', component:PropertyComponent},      
    {path: 'waitingList', component:WaitingListComponent},          
    {path: 'referralProgram', component:ReferralProgramComponent},          
    // {path: 'additionalResources', component:AdditionalResourcesComponent},          
    {path: 'form', component:FormComponent, canActivate:[AuthGuard], canDeactivate:[pendingChangesGuard]},
    {path: '', redirectTo:'/home', pathMatch:'full'}
]