<body>
   <banner [showHCVMsg]="false"></banner>
   <div id="top" class="d-flex justify-content-center align-items-center">
      <div class="container-fluid mb-5">
         <div class="container text-center">
            <!-- <div class="page-title mt-5">{{title | titlecase}}</div> -->
            <div class="page-title mt-5">REFERRAL PROGRAMS</div>
            <div class="container mt-5 mb-5 text-center"> 
               <!-- <div class="mt-5 text-center page-text font-italic">
                  <div *ngIf="!isMobile">
                     Welcome to the Referral Programs and Homelessness Resource page. Referral programs are administered<br/>
                     through community organizations that work with persons experiencing homelessness.<br/>
                     The full list of resources and the contact information for the programs are listed below.
                  </div>
                  <div class="mb-5" *ngIf="isMobile">
                     Welcome to the Referral Programs and Homelessness Resource page. Referral programs are administered through community organizations that work with persons experiencing homelessness. The full list of resources and the contact information for the programs are listed below.
                  </div>
               </div> -->
               <div *ngIf="!isMobile" class="text-center">
                   <img class="img-expand" [src]="getImagePath('housing resources.png')" alt="" width="50%" height="50%" (click)="expand($event)">
               </div>
               <div *ngIf="isMobile" class="text-center">
                  <img class="img-expand" [src]="getImagePath('housing resources.png')" alt="" width="100%" height="100%" (click)="expand($event)">
              </div>
               <!-- <div class="page-text text-center text-danger">
                  SHRA does not provide immediate emergency housing or hotel vouchers. For emergency housing, call 211.  
               </div> -->
            </div>
         </div>
         <!-- <div class="container text-left"> 
            <span style="float:left;" class="page-subtitle mr-5">Filter By:</span> 
            <span style="float:left;">
               <select class="page-text" #wlFilter (change)="updateWLFilter(wlFilter.value)"  name="wlFilter" >
                  <option class="page-text" *ngFor="let wlf of waitlistFilterList" [value]="wlf.description">{{wlf.description}}</option>
               </select>
            </span>
            <div style="float:left;" class="col-md-10 mt-2 mb-5">               
               <span class="page-text mr-5" *ngFor="let pf of preferenceFilterList">
                  <span class="whitespacenowrap">
                     <label class="mr-2">{{pf.description}}</label>
                     <input class="mr-2" type="checkbox" (change)="updatePreferenceFilter(pf.description, $event)">
                  </span>
               </span>
            </div>
         </div> -->

         <div class="container text-left" *ngIf="waitlistsExtendedFilter">
            <div *ngFor="let t of wltypes">
               <div *ngIf="getWaitlistsCount(t) > 0">
                  <div class="page-subtitle1 mb-2">{{t.description}}</div>            
                  <div class="" *ngIf="!isMobile">
                     <table class="table table-bordered table-striped table-responsive-stack mb-5" id="tableOne">
                        <thead class="bg-light text-center">
                           <tr>
                              <th class="bg-table-header">Program</th>
                              <th class="bg-table-header">Who to Contact</th>                        
                              <th *ngIf="t.showLocation" class="bg-table-header">Location</th>              
                              <!-- <th *ngIf="t.showSHRARequirements" class="bg-table-header">SHRA Requirements</th>
                              <th *ngIf="t.showSiteRequirements" class="bg-table-header">Site Requirements</th>  -->
                              <th *ngIf="t.showSHRARequirements || t.showSiteRequirements" class="bg-table-header">Requirements</th>                           
                           </tr>
                        </thead>
                        <tbody>
                           <tr *ngFor="let w of getWaitlistsExtendedFilterByWLType(t.waitlistTypeID) | orderby:'waitlistExtendedName':false">                  
                              <ng-container>
                                 <td class="table-content col-2">
                                    <a href="javascript:void(0)" (click)="goToPropertyProfile(w)">{{w.waitlist.name.replace('/', ' / ')}}</a>
                                 </td>
                                 <td class="table-content col-3 lnbr"><div [innerHTML] = "w.waitlist.notes"></div></td>
                                 <td *ngIf="t.showLocation" class="table-content col-2">{{w.waitlist.location}}</td>              
                                 <!-- <td *ngIf="t.showSHRARequirements" class="table-content col-2">{{w.shraRequirements | titlecase}}<br/></td>                             
                                 <td *ngIf="t.showSiteRequirements" class="table-content col-2">{{w.preferences | titlecase}}<br/></td> -->
                                 <td *ngIf="t.showSHRARequirements || t.showSiteRequirements" class="table-content col-2">
                                    <div *ngIf="t.showSHRARequirements && w.shraRequirements && w.shraRequirements.length > 0">
                                       <div class="table-content-bold">SHRA Requirements:</div>
                                       {{w.shraRequirements | titlecase}}
                                       <span *ngIf="t.showSHRARequirements && w.shraRequirements && w.shraRequirements.length > 0" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('Preferences')"></i></span>
                                    </div>
                                    <div *ngIf="t.showSiteRequirements && w.preferences && w.preferences.length > 0" class="mt-3">
                                       <div class="table-content-bold">Site Requirements:</div>
                                       {{w.preferences | titlecase}}
                                       <span *ngIf="t.showSiteRequirements && w.preferences && w.preferences.length > 0" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('site requirements')"></i></span>
                                    </div>
                                 </td>                             
                              </ng-container>
                           </tr>
                        </tbody>
                     </table>
                  </div>
                  <div class="mt-5" *ngIf="isMobile">
                     <div class="container justify-content-center mt-5">
                        <div *ngFor="let w1 of getWaitlistsExtendedFilterByWLType(t.waitlistTypeID) | orderby:'waitlistExtendedName':false" class="mb-5">
                           <div class="container align-items-center text-center bdr-rounded p-0">
                              <table class="table table-striped table-responsive-stack" id="tableOne">
                                 <th class="bg-table-header bdr-th">
                                    <div class="page-text text-white">
                                       {{w1.waitlist.name.replace('/', ' / ') | uppercase}}
                                       <span class="ml-2"><i class="text-white bi bi-box-arrow-up-right" (click)="goToPropertyProfile(w1)"></i></span>
                                    </div>
                                 </th>                  
                                 <tbody>
                                    <tr><div class="table-content"><p>Who to Contact</p><div [innerHTML] = "w1.waitlist.notes"></div></div></tr>
                                    <tr *ngIf="w1.waitlist.location && w1.waitlist.location.length > 0">
                                       <div class="table-content">
                                          <p>Location</p>{{w1.waitlist.location}}
                                       </div>
                                    </tr>
                                    <tr>
                                       <div class="table-content" *ngIf="t.showSHRARequirements || t.showSiteRequirements">
                                          <p>Requirements</p>
                                          <div *ngIf="t.showSHRARequirements && w1.shraRequirements && w1.shraRequirements.length > 0">
                                             <div class="table-content">SHRA Requirements:</div>
                                             {{w1.shraRequirements | titlecase}}
                                             <span *ngIf="t.showSHRARequirements && w1.shraRequirements && w1.shraRequirements.length > 0" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('SHRA requirements')"></i></span>
                                          </div>
                                          <div *ngIf="t.showSiteRequirements && w1.preferences && w1.preferences.length > 0" class="mt-3">
                                             <div class="table-content">Site Requirements:</div>
                                             {{w1.preferences | titlecase}}
                                             <span *ngIf="t.showSiteRequirements && w1.preferences && w1.preferences.length > 0" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('site requirements')"></i></span>
                                          </div>                                          
                                       </div>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                     </div>
                  </div> 
               </div>
               <div class="page-text text-right mb-3"><a href="javascript:void(0)" (click)="scrollToAnchor('top')">Back to Top</a></div> 
            </div>
         </div>
         <div class="mt-5 mb-5">
            <a routerLink="/contact">Additional Resources</a>
         </div>
         <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
         </ngx-spinner>      
      </div>
   </div>
 </body>
 