<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="container fst-italic text-left">
            <div *ngIf="resetStatus == 'success'" class="page-text">
                An email is sent with the password reset instructions. Please follow to reset your password.
            </div>
            <div *ngIf="resetStatus != 'success'">
                <div class="form-group">
                    <div *ngIf="email.invalid && (email.dirty || formInvalid) && resetStatus != 'failure'" class="errormsg">
                        <div *ngIf="email.errors.required">Email address is required</div>
                        <div *ngIf="email.errors.pattern">Email address is not valid</div>
                    </div>
                    <div *ngIf="resetStatus == 'failure'" class="alert alert-danger">
                        Error resetting password. Your account does not exist or your registration has not been confirmed yet. If you have registered already, please check your inbox for an email with a confirmation link and use the link to confirm your registration.
                    </div>                    
                    <input type="email" [(ngModel)]="user.email" name="email" id="email" #email="ngModel" placeholder="Email Address" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"/>
                </div>
                <div class="form-group text-right">
                    <button (click)="resetPasswordRequest(f.form)" class="btn btn-general">Email Reset Password Link</button>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>