import { Component} from "@angular/core";
import { Parameters } from '../../models/parameters';
import { User } from '../../models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationParameters } from "src/app/models/navigationParameters";

@Component({
    selector : 'registration-confirmation',
    templateUrl:'./registration-confirmation.component.html',
    styleUrls:['./registration-confirmation.component.scss']
})

export class RegistrationConfirmationComponent {
    params: Parameters = new Parameters();
    title:string = "Confirm Registration";
    user:User = new User();
    userstatus:string = "";
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;

    constructor (
        private spinnerService: NgxSpinnerService,
        private authService:AuthService,
        private router:ActivatedRoute,
        private rter1:Router
    ){
        this.isMobile = window.innerWidth < 768;
    }

    
    ngOnInit(){

        this.spinnerService.show();
        //confirm registration
        this.user.id = this.router.snapshot.queryParamMap.get('userId');
        this.user.token = this.router.snapshot.queryParamMap.get('code');
        this.authService.confirmRegistration(this.user)
        .then(s=> {
            if (s)
                this.userstatus = s.emailConfirmed? "success" : "expired";
            else
                this.userstatus = "error";
         
        });

        this.spinnerService.hide();
    }


    resendConfirmationLink(){
        this.spinnerService.show();
        this.authService.resendConfirmationLink(this.user)
        .then(s=> {
            if (s)
                this.userstatus = s.email == "not found"? "resent error" : "resent";
            else
                this.userstatus = "resent error";
         
        });
        this.spinnerService.hide();
    }

    login(){
        localStorage.setItem('returnLocation', JSON.stringify('userlogin'));
        this.rter1.navigate(['/loginMain']);
    }
   
}