export class RaceEthnicity{
    hmy:number;
    gender:string;
    raceWhite?: number;
    raceBlack?: number;
    raceAmericanIndian?: number;    
    raceAsian?: number;
    racePacificIslander?: number;
    ethnicity:string;
    englishUnderstanding?: number;
    primaryLanguage:string;
}