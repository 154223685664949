import { ViewportScroller } from '@angular/common';
import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from '../helpers/pendingChanges.guard';
import { Applicant } from '../models/applicant';
import { HomelessStatus } from '../models/homelessStatus';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from '../services/application-pending-changes.service';
import { ConfirmationMessageService } from '../services/confirm-message.service';
import { MessageService } from '../services/message.service';


@Component ({
    selector: 'homeless-status',
    templateUrl: './homeless-status.component.html',
    styleUrls: ['./homeless-status.component.scss']
})

export class HomelessStatusComponent implements OnInit, routingAway{
    applicant: Applicant;
    params:Parameters = new Parameters();
    homelessStatus:HomelessStatus = new HomelessStatus();
    readonly:boolean = false;
    homelessOptionsValid:boolean = true;
    navigationParams:NavigationParameters = new NavigationParameters();
    property:string = "";
    item:string = "";
    itemType:string = "";
    itemTitle:string = "";
    isMobile:boolean = false;
    contentTitle:string;
    contentText:string;
    @ViewChild("content",{static:true}) content:ElementRef;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private viewportScroller: ViewportScroller,
        private confirmationService: ConfirmationMessageService,
        private modalService: NgbModal,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;        
    }

    ngOnInit(){
        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.homelessStatus = this.applicant.homelessStatus;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        if ((!this.homelessStatus && this.applicant.generalInfo.pCode) || (this.homelessStatus && this.homelessStatus.hmy != this.applicant.generalInfo.hmy))
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getHomelessStatusCNV(this.params)
                .subscribe(data  => { 
                        this.homelessStatus = data;
                        if (!this.homelessStatus){
                            this.homelessStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                homelessPref : null,
                                homelessPrefA : null,
                                homelessPrefB : null,
                                homelessPrefC : null,
                                homelessPrefD : null,
                                homelessPrefE : null,
                                homelessPrefF : null,
                                homelessPrefG : null,
                                homelessPref2 : null,
                                homelessPref3 : null,
                                homelessPref4 : null,
                                homelessSvcs : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getHomelessStatusHCV(this.params)
                .subscribe(data  => { 
                        this.homelessStatus = data;
                        if (!this.homelessStatus){
                            this.homelessStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                homelessPref : null,
                                homelessPrefA : null,
                                homelessPrefB : null,
                                homelessPrefC : null,
                                homelessPrefD : null,
                                homelessPrefE : null,
                                homelessPrefF : null,
                                homelessPrefG : null,
                                homelessPref2 : null,
                                homelessPref3 : null,
                                homelessPref4 : null,
                                homelessSvcs : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getHomelessStatusAff(this.params)
                .subscribe(data  => { 
                        this.homelessStatus = data;
                        if (!this.homelessStatus){
                            this.homelessStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                homelessPref : null,
                                homelessPrefA : null,
                                homelessPrefB : null,
                                homelessPrefC : null,
                                homelessPrefD : null,
                                homelessPrefE : null,
                                homelessPrefF : null,
                                homelessPrefG : null,
                                homelessPref2 : null,
                                homelessPref3 : null,
                                homelessPref4 : null,
                                homelessSvcs : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.homelessStatus) this.homelessStatus = new HomelessStatus();
        this.spinnerService.hide();

    }


    itemClick(item:string){

        this.item = item;
        this.itemType = "HomelessQuestion"

        if (this.item == "Currently Homeless")
            this.itemTitle = "Currently Homeless"

        if (this.item == "At Risk of Homelessness")
            this.itemTitle = "At Risk of Homelessness"

        if (this.item == "Recently Homeless")
            this.itemTitle = "Recently Homeless";

        if (this.item == "In a Dangerous Situation")
            this.itemTitle = "In a Dangerous Situation";

        // this.modalService.open(this.content, { size: 'lg', centered: true});
        setTimeout(() => this.viewportScroller.scrollToAnchor('details'), 100);
    }

    infoClick(infoText:string){

        this.contentTitle = infoText;
        if (infoText == 'Currently Homeless') 
            this.contentText = "";

        this.modalService.open(this.content, { size: 'md', centered: true});

    }


    acknowledgeSvcsRequirement() {
        if (this.homelessStatus.homelessSvcs == 1)
        {
            // let msg = "<b>Warning: Homeless with Services</b><br><br>Selecting this preference requires you to obtain a letter from a community service provider that verifies you receive wrap around services. You are required to send this letter in with your application. If you do not receive wrap around services from a community service provider, do not select this preference. It may delay the time it takes to receive housing by returning you to the waiting list. However, you may qualify for the Homeless preference without a letter from a community service provider.";
            let msg = "Selecting this preference requires you to obtain a letter from a community service provider that verifies you receive services. You will be required to send in the letter when you are pulled from the waitlist. If you do not receive services from a community service provider, do not select this preference. It may delay the time it takes to receive housing by returning you to the waiting list. However, you may qualify for the Homeless preference without a letter from a community service provider.";

            this.confirmationService.confirm('Homeless with Services', msg, 'I Understand', true, "", "lg")
            .then(result => { 
                if (!result)
                this.homelessStatus.homelessSvcs = 0;
            });
        }
    }

    // homelessPrefCheck(pref:string){
        
    //     if (pref!="PrefA") this.homelessStatus.homelessPrefA = 0;
    //     if (pref!="PrefB") this.homelessStatus.homelessPrefB = 0;
    //     if (pref!="PrefC") this.homelessStatus.homelessPrefC = 0;
    //     if (pref!="PrefD") this.homelessStatus.homelessPrefD = 0;
    //     if (pref!="PrefE") this.homelessStatus.homelessPrefE = 0;
    //     if (pref!="PrefF") this.homelessStatus.homelessPrefF = 0;
    //     if (pref!="PrefG") this.homelessStatus.homelessPrefG = 0;

    // }

    // homelessPrefNoCheck()
    // {
    //     this.homelessStatus.homelessPrefA = null;
    //     this.homelessStatus.homelessPrefB = null;
    //     this.homelessStatus.homelessPrefC = null;
    //     this.homelessStatus.homelessPrefD = null;
    //     this.homelessStatus.homelessPrefE = null;
    //     this.homelessStatus.homelessPrefF = null;
    //     this.homelessStatus.homelessPrefG = null;
    // }

    // isHomelessOptionsValid():boolean{

    //     if (this.homelessStatus.homelessPref == 1){
    //         if (
    //             this.homelessStatus.homelessPrefA == 0 &&
    //             this.homelessStatus.homelessPrefB == 0 &&
    //             this.homelessStatus.homelessPrefC == 0 &&
    //             this.homelessStatus.homelessPrefD == 0 &&
    //             this.homelessStatus.homelessPrefE == 0 &&
    //             this.homelessStatus.homelessPrefF == 0 &&
    //             this.homelessStatus.homelessPrefG == 0 )            
    //             return false;
    //     }
    //     return true;
    // }

    back(f:any)
    {
        //this.homelessOptionsValid = this.isHomelessOptionsValid();

        // if (this.readonly || (f.valid && !this.readonly && this.homelessOptionsValid))
        // {
            //this.setFieldValues();
            this.applicant.homelessStatus = this.homelessStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/veteranStatus']);
        // }
    }

    next(f:any)
    {
        //this.homelessOptionsValid = this.isHomelessOptionsValid();

        if (this.readonly || (f.valid && !this.readonly && this.homelessOptionsValid))
        {
            //this.setFieldValues();
            this.applicant.homelessStatus = this.homelessStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/displacementStatus']);
        }
    }

    // setFieldValues(){
    //     this.homelessStatus.homelessPref = + this.homelessStatus.homelessPref;
    //     this.homelessStatus.homelessPrefA = + this.homelessStatus.homelessPrefA;
    //     this.homelessStatus.homelessPrefB = + this.homelessStatus.homelessPrefB;
    //     this.homelessStatus.homelessPrefC = + this.homelessStatus.homelessPrefC;
    //     this.homelessStatus.homelessPrefD = + this.homelessStatus.homelessPrefD;
    //     this.homelessStatus.homelessPrefE = + this.homelessStatus.homelessPrefE;
    //     this.homelessStatus.homelessPrefF = + this.homelessStatus.homelessPrefF;
    //     this.homelessStatus.homelessPrefG = + this.homelessStatus.homelessPrefG;
    //     this.homelessStatus.homelessSvcs = + this.homelessStatus.homelessSvcs;
    // }
}