<body>
   <banner [showHCVMsg]="true"></banner>
   <div class="page-title mt-5">{{title | titlecase}}</div>
   <div class="d-flex justify-content-center align-items-center">
      <div class="container-fluid mb-5">
         <!-- <div class="container mb-5">
            <div class="page-title mt-5">{{title}}</div>
            <div class="page-text mt-2 mb-3">New to Sacwaitlist.com? Start here to learn more.</div>
            <div class="container iframe-container">
                <div class="video">
                  <iframe class="responsive-iframe"
                    src="https://www.youtube.com/embed/LYdpu_Q6lNA"
                    allow="autoplay"
                    width="640"
                    height="360"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                  >
                  </iframe>
                </div>               
            </div>
         </div> -->
         <div class="container">
            <div class="text-left mt-2">
               <a class="link-offset-2 link-underline link-underline-opacity-0 page-subtitle" data-toggle="collapse" href="#whoweare" aria-expanded="true" aria-controls="whoweare">
                  <i class="bi bi-caret-right-fill text-success"></i>
                  <i class="bi bi-caret-down-fill text-success"></i>
                  Who we Are and What We Do
               </a>            
            </div>
            <div id="whoweare" class="collapse show mt-2 ml-5 mb-5 text-left"> 
               <div class="page-subtitle1">What is SHRA?</div>
               <div class="mb-3">The Sacramento Housing and Redevelopment Agency (SHRA) is a government agency that operates and administers subsidized housing programs in the City and County of Sacramento. Learn more at <a href="https://www.shra.org" target="_blank">SHRA.org</a></div>

               <div class="page-subtitle1">What is subsidized housing?</div>
               <div class="mb-3">Subsidized housing refers to residential units where the government provides financial assistance to reduce the cost of rent for eligible low-income individuals or families.</div>

               <div class="page-subtitle1">What is Sacwaitlist.com?</div>
               <div class="">Sacwaitlist.com is an SHRA website where you can view and apply for subsidized housing waiting lists in Sacramento County and find more housing resources.</div>

               <!-- <div class="page-subtitle1">Where in Sacramento are our programs?</div>
               <div class="">Unless otherwise stated, SHRA Waiting Lists are for the entire County of Sacramento, including the City of Sacramento. For a map of County boundaries click <a href="https://generalmap.gis.saccounty.gov/JSViewer/county_portal.html" target="_blank">here</a>.</div> -->
            </div>
            

            <div class="text-left mt-2">
               <a class="link-offset-2 link-underline link-underline-opacity-0 page-subtitle" data-toggle="collapse" href="#housingprograms" aria-expanded="true" aria-controls="housingprograms">
                  <i class="bi bi-caret-right-fill text-success"></i>
                  <i class="bi bi-caret-down-fill text-success"></i>
                  Our Housing Programs
               </a>            
            </div>

            <div id="housingprograms" class="collapse show mt-2 ml-5 mb-5 text-left"> 

               <div class="page-subtitle1">Public Housing</div>
               <div class="mb-3">Public housing apartments are owned and operated by SHRA. This includes one to five bedroom rental units located throughout the City and County of Sacramento.</div>
   
               <div class="page-subtitle1">Housing Choice Voucher (HCV) Program</div>
               <div class="mb-3">Formerly known as the Section 8 program, HCV is a rental assistance program that helps eligible individuals and families pay for housing by providing a housing subsidy paid directly to the landlord.
               Applicants can register to be considered for two types of rental assistance under the HCV program: Tenant-Based Vouchers or Project-Based Vouchers. </div>
               <hcv-programs-compare></hcv-programs-compare>
            </div>

            <div class="text-left mt-2">
               <a class="link-offset-2 link-underline link-underline-opacity-0 page-subtitle" data-toggle="collapse" href="#keyterms" aria-expanded="true" aria-controls="keyterms">
                  <i class="bi bi-caret-right-fill text-success"></i>
                  <i class="bi bi-caret-down-fill text-success"></i>
                  <!-- Key Terms -->
                  Income Requirements
               </a>            
            </div>
            <div id="keyterms" class="collapse show mt-2 ml-5 mb-3 text-left"> 
               <!-- <div class="page-subtitle1">Income Requirements</div> -->
               <div class="mb-3">To be eligible for SHRA's assisted housing programs, families must have an income below certain thresholds based on the family size. Income limits may vary by program.</div>
               <div *ngIf="!isMobile" class="text-center page-subtitle2">
                  <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked"/>Housing Choice Voucher</span>
                  <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />Public Housing</span>
                  <!-- <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />Affordable Housing</span> -->
               </div>
               <div *ngIf="isMobile" class="text-center page-subtitle2">
                  <div class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked"/>Housing Choice Voucher</div>
                  <div class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />Public Housing</div>
                  <!-- <div class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />Affordable Housing</div> -->
               </div>
               <waitlist-income-limit [item]='yardiIncomeLimitsCode' [itemType]='yardiIncomeLimitsDB'></waitlist-income-limit>
   
               <!-- <div class="page-subtitle1 mt-3">Referral Programs</div>
               <div class="">In order to receive assistance for a referral program, individuals must work with a community organization that is partnered with SHRA. "click here" to connect with a partnered community organization.</div> -->

            </div>
         </div>  
      </div> 
   </div>
</body>