<body>
   <banner [showHCVMsg]="true"></banner>
   <div class="page-title mt-5">{{title | titlecase}}</div>
   <div id="top" class="d-flex justify-content-center align-items-center">
      <div class="container-fluid mb-5">
         <div class="container-fluid">
            <div class="text-center mt-5">
               <div *ngIf="!isMobile"><img class="img-expand" [src]="getImagePath('my application no title.png')" alt="" width="75%" height="75%" (click)="expand($event)"></div>
               <div *ngIf="isMobile"><img class="img-expand" [src]="getImagePath('my application mobile no title.png')" alt="" width="100%" height="100%" (click)="expand($event)"></div>
            </div>
         </div>
         <!-- <div class="container">
            <div class="text-left mt-2">
               <a class="link-offset-2 link-underline link-underline-opacity-0 page-subtitle" data-toggle="collapse" href="#incomeLimit" aria-expanded="true" aria-controls="incomeLimit">
                  <i class="bi bi-caret-right-fill text-success"></i>
                  <i class="bi bi-caret-down-fill text-success"></i>
                  Income Limit By Program
               </a>            
            </div>
            <div id="incomeLimit" class="collapse show mt-2"> 
               <div class="page-subtitle2">
                  <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked" />Housing Choice Voucher</span>
                  <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />Public Housing</span>
                  <span class="ml-5"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />Affordable Housing</span>
               </div>
               <waitlist-income-limit [item]='yardiIncomeLimitsCode' [itemType]='yardiIncomeLimitsDB'></waitlist-income-limit>
            </div>
         </div> -->
         <!-- <div class="container text-left"> 
               <span style="float:left;" class="page-subtitle mr-5">Filter By:</span> 

               <span class="page-text ml-2 mr-5 mb-2" style="float:left; min-width: 200px;">
                  <ng-multiselect-dropdown
                     [settings]="ddlWLStatusFilterSettings"
                     [data]="ddlWLStatusFilter"
                     [placeholder]="'Waiting list status'"
                     [(ngModel)]="selectedWLFilter"
                     (onSelect)="updateWLFilterSelect($event, 'wlstatus')"
                     (onDeSelect)="updateWLFilterDeselect($event, 'wlstatus')">
                  </ng-multiselect-dropdown>
               </span>               
               <span class="page-text ml-2 mr-5 mb-2" style="float:left; min-width: 200px;">
                  <ng-multiselect-dropdown
                     [settings]="ddlWLProgramFilterSettings"
                     [data]="ddlWLProgramFilter"
                     [placeholder]="'Waiting list program'"
                     (onSelect)="updateWLFilterSelect($event, 'wlprogram')"
                     (onDeSelect)="updateWLFilterDeselect($event, 'wlprogram')">
                  </ng-multiselect-dropdown>
               </span>               
               <span class="page-text ml-2 mr-5 mb-2" style="float:left; min-width: 200px;">
                  <ng-multiselect-dropdown
                     [settings]="ddlPreferenceFilterSettings"
                     [data]="ddlPreferenceFilter"
                     [placeholder]="'Preferences'"
                     (onSelect)="updateWLFilterSelect($event, 'preference')"
                     (onDeSelect)="updateWLFilterDeselect($event, 'preference')">
                  </ng-multiselect-dropdown>
               </span>               
         </div> -->
         <div class="container text-left" *ngIf="waitlistsExtendedFilter">
            <!-- <div class="page-subtitle mb-5">
               <input class="checkbox" type="checkbox" [checked]="wlStatusFilter == 'Open'" (click)="updateWLStatusFilter($event)"/>
               <span class="ml-2">Open waiting lists only</span>
            </div> -->
            <div *ngIf="!isMobile" class="mt-3 mb-5">
               <span class="page-subtitle1">Filter By:</span>
               <span class="page-subtitle2 ml-5"><input type="radio" class="mr-2" (click)="updateWLStatusFilter($event)" [checked]="wlStatusFilter == 'Open'" value="Open" name="wlStatus" />Open Waiting Lists</span>
               <span class="page-subtitle2 ml-5"><input type="radio" class="mr-2" (click)="updateWLStatusFilter($event)" [checked]="wlStatusFilter == 'All'" value="All" name="wlStatus" />All Waiting Lists</span>
            </div>
            <div *ngIf="isMobile" class="mt-3 mb-5">
               <div class="page-subtitle1">Filter By:</div>
               <span class="page-subtitle2 ml-5"><input type="radio" class="mr-2" (click)="updateWLStatusFilter($event)" [checked]="wlStatusFilter == 'Open'" value="Open" name="wlStatus" />Open Waiting Lists</span>
               <span class="page-subtitle2 ml-5"><input type="radio" class="mr-2" (click)="updateWLStatusFilter($event)" [checked]="wlStatusFilter == 'All'" value="All" name="wlStatus" />All Waiting Lists</span>
            </div>            
            <div *ngFor="let p of wlprograms | orderby:'name':true; let i = index" class="mb-5">
               <div>
                  <div class="page-subtitle mb-3">{{p.name}}
                     <span *ngIf="!p.name.includes('Affordable Housing')" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick(p.name, 'faq')"></i></span>
                  </div> 
                  <div  class="row mb-3 justify-content-between">
                     <span *ngIf="p.name.includes('Housing Choice Voucher')" class="page-text text-left">What is the difference between Tenant-Based and Project-Based? <i class="bi bi-question-circle-fill imgbutton text-search" (click)="infoClick(p.name, 'prTypes')"></i></span> 
                     <span class="page-text text-right">Income Limits <i class="bi bi-question-circle-fill imgbutton text-search" (click)="infoClick(p.name, 'il')"></i></span> 
                  </div>
                  <!-- <div class="page-text mb-3">{{p.descriptionText}}</div>  -->
                 <div>
                     <div class="" *ngIf="!isMobile">
                        <table class="table table-bordered table-striped table-responsive-stack mb-5" id="tableOne">
                           <thead class="bg-light text-center">
                              <tr>
                                 <th class="bg-table-header"></th>
                                 <th class="bg-table-header" *ngIf="loggedIn && role == 'Applicant'">Your Status</th>                           
                                 <!-- <th class="bg-table-header">Program</th> -->
                                 <th class="bg-table-header">Waiting List</th>
                                 <th class="bg-table-header">Bedroom Open <span><i class="bi bi-question-circle-fill imgbutton text-search" (click)="infoClick(p.name, 'brText')"></i></span></th>
                                 <th class="bg-table-header">Preferences</th>
                                 <th class="bg-table-header">Location</th>              
                              </tr>
                           </thead>
                           <tbody>
                              <tr *ngIf="getWaitlistsCount(p) == 0">
                                 <td [attr.colspan]="loggedIn && role == 'Applicant' ? 6 : 5">
                                    <div *ngIf="wlStatusFilter == 'Open'" class="table-content">Currently, there are no open waiting lists.</div> 
                                    <div *ngIf="wlStatusFilter != 'Open'" class="table-content">Currently, there are no waiting lists.</div> 
                                 </td>
                              </tr>
                              <tr *ngFor="let w of getWaitlistsExtendedFilterByProgram(p.name) | orderby:'waitlistExtendedName':false">                  
                                 <ng-container>
                                    <td class="table-content col-2">
                                       <div class="font-italic" *ngIf="w.wlStatus =='Open'">
                                          <div *ngIf="w.action =='apply' && !w.waitlist.name.includes('Referral')">
                                             <div class="table-content p3" *ngIf="!w.applicantBREligibility">Your household does not meet the bedroom size requirement for available bedrooms<br/></div>
                                             <div class="table-content p3" *ngIf="w.waitlist.name == 'Elderly Only'">
                                                <div *ngIf="!w.applicantBREligibility && !w.applicantAgeEligibility">or the age requirement for this property<br/></div>
                                                <div *ngIf="w.applicantBREligibility && !w.applicantAgeEligibility">Your household does not meet the age requirement for this property<br/></div>
                                             </div>
                                             <div class="table-content p3 mb-2" *ngIf="!w.accessCodeValid">Invalid access code</div>                  
                                             <div class="table-content mb-2" *ngIf="w.applicantBREligibility && w.applicantAgeEligibility && w.waitlist.accessCodeRequired == 1"><input type="text" [(ngModel)]="w.accessCode" name="accessCode" placeholder="Access Code" required /></div>                  
                                             <div class="mb-2" *ngIf="w.applicantBREligibility && w.applicantAgeEligibility"><button type="button" (click)="applyToWaitlist(w)" class="btn btn-sm btn-general">APPLY NOW</button></div>
                                             <div class="table-content" *ngIf="w.applicantBREligibility && w.applicantAgeEligibility && w.waitlist.accessCodeRequired == 1">{{w.waitlist.accessCodeText}}</div>
                                          </div>
                                       </div>
                                       <div *ngIf="w.wlStatus !='Open'"><button type="button" disabled="true" class="btn btn-sm btn-secondary">Closed</button></div>
                                    </td>
                                    <td class="table-content col-1" *ngIf="loggedIn && role == 'Applicant'">{{w.applicantStatus | titlecase}}</td>                                                           
                                    <!-- <td class="table-content col-2">
                                       {{w.waitlistProgram}}<span class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick(w.waitlistProgram)"></i></span>
                                       <br/>{{w.waitlistType}}
                                    </td> -->
                                    <td class="table-content col-1"><a href="javascript:void(0)" (click)="goToPropertyProfile(w)">{{w.waitlist.name.replace('/', ' / ')}}</a>
                                       <div *ngIf="p.name=='Housing Choice Voucher'">{{w.waitlistType}}</div>
                                    </td>
                                    <td class="table-content col-1">
                                       <div *ngIf="w.wlStatus == 'Open'">{{w.waitlist.bedroomsAvailable}}</div>
                                    </td>
                                    <td class="table-content col-2">
                                       <div *ngIf="w.waitlist.name.includes('Referral')" [innerHTML]="w.waitlist.shraRequirements"></div>
                                       <div *ngIf="!w.waitlist.name.includes('Referral')" [innerHTML]="w.preferences"></div>
                                       <span *ngIf="w.preferences && w.preferences.length > 0 && !w.waitlist.name.includes('Referral')" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('Preferences', 'faq')"></i></span>
                                    </td>                             
                                    <td class="table-content col-2">{{w.waitlist.location}}</td>              
                                 </ng-container>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div class="mt-5" *ngIf="isMobile">
                        <div class="container justify-content-center mt-5">
                           <div *ngFor="let w1 of getWaitlistsExtendedFilterByProgram(p.name) | orderby:'waitlistExtendedName':false" class="mb-5">
                              <div class="container align-items-center text-center bdr-rounded p-0">
                                 <table class="table table-striped table-responsive-stack" id="tableOne">
                                    <th class="bg-table-header bdr-th">
                                       <!-- <a class="page-text lnk-text" href="javascript:void(0)" (click)="goToPropertyProfile(w1)">{{w1.waitlist.name.replace('/', ' / ') | uppercase}}</a> -->
                                       <div class="page-subtitle1 text-white">
                                          <p class="bg-table-header">{{w1.waitlist.name.replace('/', ' / ') | uppercase}}
                                             <span class="ml-2"><i class="text-white bi bi-box-arrow-up-right" (click)="goToPropertyProfile(w1)"></i></span>
                                             <span *ngIf="p.name=='Housing Choice Voucher'"><br/>{{w1.waitlistType}}</span>
                                          </p>
                                       </div>
                                    </th>                  
                                    <tbody>
                                       <!-- <tr><div class=""><p>Program</p>{{w1.waitlistProgram}}  -  {{w1.waitlistType}}</div></tr> -->
                                       <tr *ngIf="w1.wlStatus == 'Open'"><div class="table-content"><p>Bedroom(s) Open <span><i class="bi bi-question-circle-fill imgbutton text-search" (click)="infoClick(p.name, 'brText')"></i></span></p>{{w1.waitlist.bedroomsAvailable}}</div></tr>
                                       <tr>
                                          <div class="table-content">
                                             <p>Preferences</p>
                                             <div *ngIf="w1.waitlist.name.includes('Referral')" [innerHTML]="w1.waitlist.shraRequirements"></div>
                                             <div *ngIf="!w1.waitlist.name.includes('Referral')" [innerHTML]="w1.preferences"></div>
                                             <span *ngIf="w1.preferences && w1.preferences.length > 0 && !w1.waitlist.name.includes('Referral')" class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('Preferences', 'faq')"></i></span>
                                          </div>
                                       </tr>
                                       <tr><div class="table-content"><p>Location</p>{{w1.waitlist.location}}</div></tr>
                                       <tr *ngIf="loggedIn && role == 'Applicant'"><div class=""><p>Your Status</p>{{w1.applicantStatus | titlecase}}</div></tr>
                                       <tr>
                                          <div *ngIf="w1.wlStatus =='Open'" class="table-content">
                                             <div *ngIf="w1.action =='apply' && !w1.waitlist.name.includes('Referral')">
                                                <div class="p3" *ngIf="!w1.applicantBREligibility">Your household does not meet the bedroom size requirement for available bedrooms<br/></div>
                                                <div class="p3 mb-2" *ngIf="w1.waitlist.name == 'Elderly Only'">
                                                   <div *ngIf="!w1.applicantBREligibility && !w1.applicantAgeEligibility">or the age requirement for this property<br/></div>
                                                   <div *ngIf="w1.applicantBREligibility && !w1.applicantAgeEligibility">Your household does not meet the age requirement for this property<br/></div>
                                                </div>
                                                <div *ngIf="w1.applicantBREligibility && w1.applicantAgeEligibility && w1.waitlist.accessCodeRequired == 1"><p>Important!</p>{{w1.waitlist.accessCodeText}}</div>
                                                <div class="mb-2 mt-2" *ngIf="w1.applicantBREligibility && w1.applicantAgeEligibility && w1.waitlist.accessCodeRequired == 1"><input type="text" [(ngModel)]="w1.accessCode" name="accessCode" placeholder="Access Code" required /></div>                  
                                                <div class="p3 mb-2" *ngIf="!w1.accessCodeValid">Invalid access code</div>                                                         
                                                <div *ngIf="w1.applicantBREligibility && w1.applicantAgeEligibility"><button type="button" (click)="applyToWaitlist(w1)" class="btn btn-general">Apply Now</button></div>
                                             </div>
                                          </div>
                                          <div *ngIf="w1.wlStatus !='Open'"><button type="button" disabled="true" class="btn btn-secondary">Closed</button></div>
                                       </tr>
                                    </tbody>
                                 </table>
                              </div>
                           </div>
                        </div>
                     </div> 
                  </div>  
               </div>           
               <div class="page-text text-right mb-3"><a href="javascript:void(0)" (click)="scrollToAnchor('top')">Back to Top</a></div> 
            </div>
            <div class="mt-5 text-center">
               <div class="page-text mt-2">Not seeing what you're looking for? Explore referral programs in the Sacramento area <a routerLink="/referralProgram">here</a>.</div>
               <div class="page-text mt-2">Already applied to a waitlist or are not sure if you're on one?</div>
               <div class="mt-2 mb-5">
                  <a type="button" class="btn btn-general" routerLink="/applicantDashboard" role="button">Check Application Status</a>
               </div>
            </div>

            <div *ngIf="mapurl != '' && !isMobile" class="container text-center mt-5">
               <div class="container iframe-container">
                   <iframe class="responsive-iframe" [src]="mapurl | safe: 'resourceUrl'" frameborder="0" style="border:0" width="200" height="200" allowfullscreen>iFrames are not supported on this page.</iframe>
               </div>
            </div>
            <div *ngIf="mapurl != '' && isMobile" class="container row mt-5">
               <a [href]="mapurl" target="_blank">SHRA Waiting Lists Map</a>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
               <p style="color: white;">Please wait.</p>
            </ngx-spinner>      
         </div>
      </div>
   </div>
   <ng-template #brTextHCV let-modal>
      <div class="modal-header bg-shradarkgreen">
         <div class="page-subtitle2 text-white mb-2">Bedroom Size Calculation</div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <div class="container page-text text-center p-3">SHRA does not determine who shares a bedroom/sleeping room, but only calculates the number of bedrooms the family is eligible to rent.  One bedroom will be assigned to the head of household and spouse/partner and one bedroom will be assigned for each additional two persons.</div>
      </div>
   </ng-template>

   <ng-template #brTextCNV let-modal>
      <div class="modal-header bg-shradarkgreen">
         <div class="page-subtitle2 text-white mb-2">Bedroom Size Calculation</div>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <div class="container page-text text-center p-3">SHRA does not determine who shares a bedroom/sleeping room, but only calculates the number of bedrooms the family is eligible to rent.  The calculation is based on the age and gender of family members.  For more information, <a href="{{acopLink}}" target="_blank">click here</a></div>
      </div>
   </ng-template>

   <ng-template #ilHCV let-modal>
      <div class="modal-header bg-shradarkgreen">
         <div class="page-subtitle2 text-white mb-2">Income Limits</div> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <waitlist-income-limit [item]='getYardiIncomeLimitsCode("Housing Choice Voucher")' [itemType]='getYardiIncomeLimitsDB("Housing Choice Voucher")'></waitlist-income-limit>
      </div>
   </ng-template>    

   <ng-template #ilCNV let-modal>
      <div class="modal-header bg-shradarkgreen">
         <div class="page-subtitle2 text-white mb-2">Income Limits</div> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <waitlist-income-limit [item]='getYardiIncomeLimitsCode("Public Housing")' [itemType]='getYardiIncomeLimitsDB("Public Housing")'></waitlist-income-limit>
      </div>
   </ng-template>    

   <ng-template #prTypes let-modal>
      <div class="modal-header bg-shradarkgreen">
         <div class="page-subtitle2 text-white mb-2">HCV Programs</div> 
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
         <hcv-programs-compare></hcv-programs-compare>
      </div>
   </ng-template>    

 </body>
