import { UntypedFormControl } from '@angular/forms';

// custom validator to check that two fields match
export function MinNumber(minNumber:number) {
    return (control: UntypedFormControl) => {
        // return null if controls haven't initialised yet
        if (!control) {
          return null;
        }

        // set error on matchingControl if validation fails
        //if (!isNaN(control.value) || control.value < minNumber) {
        if (control.value < minNumber) {            
            setTimeout(() => {
                control.setErrors({ minNumber: true });
              });
        } else {
            control.setErrors(null);
        }
    }
}