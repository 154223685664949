import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { NavigationParameters } from '../models/navigationParameters';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { WaitlistPrograms } from '../models/waitlistPrograms';
import { WaitlistTypes } from '../models/waitlistTypes';
import { MessageService } from '../services/message.service';
import { WaitlistService } from '../services/waitlist.service';

@Component({
    selector: 'waitlist-program',
    templateUrl: './waitlist-program.component.html' ,
    styleUrls: ['./waitlist-program.component.scss']
})

export class WaitlistProgramComponent implements OnInit{
    wlPrograms:WaitlistPrograms[] = [];
    wlTypes: WaitlistTypes[] = [];
    prefs: Preferences[] = [];
    params: PortalParameters = new PortalParameters();
    //loggedIn:boolean = false;
    //role:string = "";
    waitlistTypeCategories: string[];
    navigationParams:NavigationParameters = new NavigationParameters();
    imgFolder: string = environment.imgFolder;

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService,
        private router:Router
        ){}


    ngOnInit()
    {
        this.spinnerService.show();

        //this.loggedIn =  !!JSON.parse(localStorage.getItem('loggedIn'));
        //this.role =  JSON.parse(localStorage.getItem('role'));

        this.navigationParams =  JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();

        this.waitlistService.getWaitlistPrograms()
            .subscribe(data  => { 
                this.wlPrograms = data;
                this.waitlistService.getWaitlistTypes()
                .subscribe(d => {
                    this.wlTypes = d;
                    this.waitlistTypeCategories = this.getwaitlistTypeCategories(this.wlTypes);
                });
            }, (error) => {
                this.msg.showError(error, "Loading Error");
            });

        this.spinnerService.hide();            
    }


    checkIfWLTypeExistsForProgramCategory(programID:number, category:string):boolean{
        if (this.wlTypes.filter(t => t.waitlistProgramID == programID && t.waitlistTypeCategory == category).length > 0)
            return true;
        return false;
    }

    getwaitlistTypeCategories(wlt:WaitlistTypes[]):string[]{
        let categories:string[] = [];
        let i:number = 0;
        wlt.forEach(t => {
            i = categories.findIndex( x => x == t.waitlistTypeCategory);
            if (i < 0)
                categories.push(t.waitlistTypeCategory);
        });
        return categories;
    }

    programClick(programName:string, waitlistType:string, category:string){
        this.spinnerService.show();
        this.navigationParams.waitlistProgram = programName;
        this.navigationParams.waitlistType = waitlistType;
        this.navigationParams.waitlistCategory = category;
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));

        this.spinnerService.hide();
        this.router.navigate(['/waitlistType']);

        // this.loggedIn =  !!JSON.parse(localStorage.getItem('loggedIn'));

        // if (this.loggedIn)
        // {
        //     if (this.role == "Applicant")
        //         this.router.navigate(['/waitlistType']);
        //     else
        //         this.router.navigate(['/waitlistTypeNew']);
        // }
        // else
        //     this.router.navigate(['/waitlistTypeGeneral']);

    }
    
}