import {Component, HostListener, OnInit} from "@angular/core";
import { ApplicantService } from '../services/applicant.service';
import { Applicant } from '../models/applicant';
import { GeneralInfo } from '../models/generalInfo';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { PortalParameters } from '../models/portalParameters';
import { Parameters } from '../models/parameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from "../models/user";
import { WaitlistPortalService } from "../services/waitlist-portal.service";
import { UserService } from "../services/user.service";
import { NavigationParameters } from "../models/navigationParameters";
import { PortalUtilitiesService } from "../services/portal-utilities.service";
import { routingAway } from "../helpers/pendingChanges.guard";
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";

@Component ({
    selector : 'basic-info',
    templateUrl : './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss']
})

export class BasicInfoComponent implements OnInit, routingAway{
    applicant: Applicant = new Applicant();
    generalInfo:GeneralInfo = new GeneralInfo();
    portalParams:PortalParameters = new PortalParameters;
    tcode:string;
    params: Parameters = new Parameters();
    formInvalid:boolean = false;
    user:User = new User();
    maxDate:Date = new Date();
    minDate:Date = new Date();
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private waitlistPortalService:WaitlistPortalService,
        private portalUtilityService: PortalUtilitiesService,
        private userService:UserService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService
    ){
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){

        this.maxDate = this.portalUtilityService.dateAdd(this.maxDate, "Y", -14);
        this.minDate = this.portalUtilityService.dateAdd(this.minDate, "Y", -110);

        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (this.navigationParams.userRole != "RegisteredUser")
            this.router.navigate(['/generalInfo'])
    }

    back()
    {
        if (this.navigationParams.returnLocation == "waitlistType")
            this.router.navigate(['/waitlistType']);
        else
            this.router.navigate(['/home']);
    }

    next(f:any)
    {
        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            this.params.ssn = this.generalInfo.ssn;
            this.params.dob = this.generalInfo.dob.toString();
            this.generalInfo.dob = new Date(this.generalInfo.dob);
            this.applicant.generalInfo = this.generalInfo;
            this.generalInfo.email = this.user.email = this.params.email = this.navigationParams.loginEmail;

            let routeTo:string = "";
            let resultMsg:string = "";

            //check if applicant already exists
            this.applicantService.getAllGeneralInfoBySSNDOBHCV(this.params)
            .subscribe((h)=>{
                if (h && h[0]) this.generalInfo = h[0];
                this.user.hcvTcode  = h && h[0]? h[0].pCode : ""; 
                this.applicantService.getAllGeneralInfoBySSNDOBCNV(this.params)
                .subscribe((c)=>{
                    if (c && c[0]) this.generalInfo = c[0];
                    this.user.cnvTcode  = c && c[0]? c[0].pCode : ""; 
                    this.applicantService.getAllGeneralInfoBySSNDOBAff(this.params)
                    .subscribe((a)=>{
                        if (a && a[0]) this.generalInfo = a[0];
                        this.generalInfo.tenantStatus = h && h[0]? h[0].tenantStatus : c && c[0]? c[0].tenantStatus : a && a[0] ? a[0].tenantStatus : "";
        
                        this.user.affPcode  = a && a[0]? a[0].pCode : ""; 
                        if (this.user.hcvTcode.length  || this.user.cnvTcode.length || this.user.affPcode.length)
                        {
                            this.userService.updateCNVTCode(this.user).subscribe(u=> {
                                if (u.email == this.user.email)
                                {
                                    this.userService.updateHCVTCode(this.user).subscribe(s=> {
                                        if (s.email == this.user.email)
                                        {
                                            this.userService.updateAffPCode(this.user).subscribe(t=> {
                                                if (t.email == this.user.email)
                                                {
                                                    resultMsg = "Your application has been located and updated.";
                                                    this.user.role = "Applicant";
                                                    this.userService.updateUserRole(this.user)
                                                    .subscribe((r)=>{

                                                        this.waitlistPortalService.setupCurrentUser(r, this.params);

                                                        //update Yardi email
                                                        if (r.cnvTcode.length)
                                                        {
                                                            this.params.applicantCode = r.cnvTcode;
                                                            this.waitlistPortalService.updateCNVYardiEmail(this.params).then(() =>{});
                                                        }
                                                        if (r.hcvTcode.length)
                                                        {
                                                            this.params.applicantCode = r.hcvTcode;
                                                            this.waitlistPortalService.updateHCVYardiEmail(this.params).then(() =>{});
                                                        }
                                                        if (r.affPcode.length)
                                                        {
                                                            this.params.applicantCode = r.affPcode;
                                                            this.waitlistPortalService.updateAffYardiEmail(this.params).then(() =>{});
                                                        }

                                                        if (this.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                                                        {
                                                            resultMsg = 'Hello! Your application has been located. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                                                            routeTo = "result";
                                                        }
                                                    });
                                                }
                                                else
                                                {
                                                    this.generalInfo = new GeneralInfo();
                                                    resultMsg = 'This applicant has already signed up with another login. Please use that login to apply.';
                                                    this.spinnerService.hide();
                                                    routeTo = "result";
                                                }
                                            });
                                        }
                                        else
                                        {
                                            this.generalInfo = new GeneralInfo();
                                            resultMsg = 'This applicant has already signed up with another login. Please use that login to apply.';
                                            this.spinnerService.hide();
                                            routeTo = "result";
                                        }
                                    });  
                                }
                                else
                                {
                                    this.generalInfo = new GeneralInfo();
                                    resultMsg = 'This applicant has already signed up with another login. Please use that login to apply.';
                                    this.spinnerService.hide();
                                    routeTo = "result";
                                }
                            });                              
                        }
                        else
                        {
                            //let returnLocation:string = JSON.parse(localStorage.getItem('returnLocation'));
                            if (this.navigationParams.returnLocation == "dashboard" || this.navigationParams.returnLocation == "home")
                            {
                                resultMsg = 'The application could not be located with the information provided.';
                                this.spinnerService.hide();
                                routeTo = "result";
                            }
                        }

                        setTimeout(() => {

                            this.generalInfo.email = this.user.email;
                            this.applicant.generalInfo = this.generalInfo;

                            this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
                            if (resultMsg != "" && (this.navigationParams.returnLocation == "dashboard" || this.navigationParams.returnLocation == "home" || !this.generalInfo?.pCode)) 
                                this.navigationParams.resultMessage = resultMsg;

                            //set tenant status for tenant in current/notice status
                            if (this.navigationParams.tenantStatusHCV && 
                                    (this.navigationParams.tenantStatusHCV?.toUpperCase() == "CURRENT" || this.navigationParams.tenantStatusHCV?.toUpperCase() == "NOTICE"))
                                this.applicant.generalInfo.tenantStatus = this.navigationParams.tenantStatusHCV;
                            if (this.navigationParams.tenantStatusCNV && 
                                    (this.navigationParams.tenantStatusCNV?.toUpperCase() == "CURRENT" || this.navigationParams.tenantStatusCNV?.toUpperCase() == "NOTICE"))
                                this.applicant.generalInfo.tenantStatus = this.navigationParams.tenantStatusCNV;

                            if (this.applicant.generalInfo.tenantStatus && 
                                    (this.applicant.generalInfo.tenantStatus?.toUpperCase() == "CURRENT" || this.applicant.generalInfo.tenantStatus?.toUpperCase() == "NOTICE"))
                                if (this.navigationParams.returnLocation == "waitingList")
                                    this.navigationParams.resultMessage = this.navigationParams.resultMessage 
                                        + 'Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';

                            localStorage.setItem('applicant', JSON.stringify(this.applicant));
                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            this.spinnerService.hide();

                            if (routeTo == "result")
                                this.router.navigate(['/result']);
                            else
                                this.router.navigate(['/generalInfo']);

                        }, 1000);

                    });
                });
            });
        }
    }

}