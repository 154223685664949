<!--Note: classes field-group-component, control-label, and form-control are used in the PDF builder-->
<div *ngFor='let field of myFields; let i = index;' class="col-md-12 field-group-component">
    <div *ngIf="!field.isHidden && !field.fieldResponses[resIndex]?.isFieldHidden" class="row mb-2 d-flex align-items-center">
      <label *ngIf="field.fieldTypeName != 'BulletList' && field.fieldTypeName != 'Label'" [class]="field.fieldTypeName == 'Label' ? 'text-left col-md-12' : 'col-md-4 control-label text-right xs-left'">
        {{field.fieldLabel}}
      </label>
  
      
      <div *ngIf="field.fieldTypeName == 'Label'" [class]="'text-left col-md-12'" [innerHTML] = "field.fieldLabel"></div>
      <div *ngIf="field.fieldTypeName === 'LookupLabel'">
        <label *ngFor="let l of field.lookups" class="control-label col-md-12">{{l.name}}</label>
      </div>  
      <div *ngIf="field.fieldTypeName === 'LookupLabel3Column'" class="col-md-12">
        <ul>
          <li *ngFor="let l of field.lookups">{{l.name}}</li>
        </ul>
      </div>  
  
      <div *ngIf="field.fieldTypeName === 'BulletList'" class="col-11">
        <!-- <img src="../../assets/images/bullet icon.png" width="10px" height="10px"/><span class="control-label ml-3">{{field.fieldLabel}}</span> -->
        <img src="../../assets/images/bullet icon.png" width="10px" height="10px"/><span class="control-label ml-3" [innerHTML] = "field.fieldLabel"></span>
      </div>  
  
      <div *ngIf="field.fieldTypeName === 'Signature'" class="col-11">
        <div style="margin-left: 39%;">
          <!-- <span style="font-family: monospace;">Write the signature and click on Save</span> -->
          <canvas #signPadCanvas (touchstart)="startSignPadDrawing($event)" (touchmove)="movedFinger($event)" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)"></canvas>
          <div class="mt-2">
            <button class="btn btn-sm btn-general mr-2" color="secondary" (click)="saveSignPad(field.fieldID, resIndex)">Save</button>
            <button class="btn btn-sm btn-secondary mr-2" (click)="clearSignPad(field.fieldID, resIndex)">Clear</button>
            <!-- <button class="btn btn-warning" (click)="undoSign()">Undo</button> -->
          </div>
          <!-- <div>
              <img src='{{ signImage }}' />
          </div> -->
        </div>
      </div>  
  
  
      <div class="col-11 col-md-5">    
        <div *ngIf="field.fieldTypeName === 'Text'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)"/>
        </div>  
        <div *ngIf="field.fieldTypeName === 'TextArea'">
          <textarea [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)"></textarea>
        </div>  
        <div *ngIf="field.fieldTypeName === 'DropdownList'">
          <select [disabled]='isDisabled' class="form-control" [value]="field.fieldResponses[resIndex]?.response"  (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)">
            <option class="control-label" [value]="dropDownListDefaultValue">{{dropDownListDefaultValue}}</option>
            <option class="control-label" *ngFor="let l of field.lookups" [value]="l.name" [selected]="field.fieldResponses[resIndex]?.response == l.name">{{l.name}}</option>
          </select>
        </div>  
        <div *ngIf="field.fieldTypeName === 'LookupResponseSignatureLabel'">
          <label *ngIf="!field.lookups[resIndex]" class="control-label">{{resIndex + 1}}.</label>
          <label *ngIf="field.lookups[resIndex]" class="control-label">{{field.lookups[resIndex]?.name}}</label>
        </div>  
        <div *ngIf="field.fieldTypeName === 'Radio'">
          <input [disabled]='isDisabled' class="checkbox" type="radio" name="field.fieldID" [value]="field.fieldResponses[resIndex]?.response" (change)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)">
          <label class="control-label ml-5 mr-2" for="field.fieldID">{{field.fieldLabel}}</label>
        </div>  
        <div *ngIf="field.fieldTypeName === 'Checkbox'">
          <div class="row" *ngFor="let l of field.lookups">
            <input [disabled]='isDisabled' class="checkbox" type="checkbox" name="field.fieldID" [value]="l.name" [checked]="field.fieldResponses[resIndex]?.response.includes(l.name)" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)">
            <label class="control-label ml-5 mr-2"  for="field.fieldID">{{l.name}}</label>
          </div>
        </div>  
        <div *ngIf="field.fieldTypeName === 'Calendar'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)" [maxDate]="maxDate|date" [minDate]="minDate|date" pattern="^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|(0[1-9])|([1-2][0-9])|(3[0-1]))\/((\d{2})|(\d{4}))$"/>
        </div>  
        <div *ngIf="field.fieldTypeName === 'SSN'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)" pattern="\d{9}" />
        </div>  
        <div *ngIf="field.fieldTypeName === 'Phone'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)" pattern="\d{10}"/>
        </div>  
        <div *ngIf="field.fieldTypeName === 'Email'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)" pattern="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"/>
        </div>  
        <div *ngIf="field.fieldTypeName === 'ZipCode'">
          <input [disabled]='isDisabled' type="text" class="form-control" [value]="field.fieldResponses[resIndex]?.response" (blur)="catchFieldResponse($event.target.value, field.fieldID, resIndex, $event)" pattern="^[0-9]{5}(?:-[0-9]{4})?$" />
        </div>  
        <div *ngIf="field.fieldTypeName === 'FileUpload'">
          <div *ngIf="field.fieldResponses[resIndex]?.response?.length == 0">
            <input [disabled]="isDisabled"  type="file" class="form-condivol-file"
              id="fieldID" name="fieldName" (change)="selectFile($event, field, resIndex)"
              placeholder="Upload file" accept=".pdf,.doc,.docx">
              <button class="btn btn-success btn-sm" [disabled]="!selectedFiles" (click)="upload()">Upload</button>
          </div>
          <div class="row d-flex align-items-center" *ngIf="field.fieldResponses[resIndex]?.response?.length > 0 && field.fieldResponses[resIndex]?.response">
            <div class="col-sm-8">
              <div class="link file-response" (click)="viewFile(field.fieldResponses[resIndex]?.response)" attr.data-filepath="{{field.fieldResponses[resIndex]?.response}}">
                <i class="fas fa-file"></i> {{field.fieldResponses[resIndex]?.response | filename}}</div>
            </div>
            <div class="col-xs-12 col-md-4 upload-remove" *ngIf="!isDisabled">
              <button [disabled]="isDisabled" class="btn btn-secondary" (click)="removeFile(field.fieldResponses[resIndex].response)">
                Remove
                <i class="fas fa-trash pl-1"></i>
              </button>
            </div>
            
          </div>
        <div *ngIf="field.fieldTypeName === 'FileLink'">
  
        </div>  
        <div *ngIf="field.fieldTypeName === 'Currency'">
  
        </div>  
        <div *ngIf="field.fieldTypeName === 'Percentage'">
  
        </div>  
        <div *ngIf="field.fieldTypeName === 'Image'">
          <img src="field.fieldResponses[resIndex].response" width="100px" height="100px"/>
        </div>  
        <span class="col-1">
          <i *ngIf="showAlert(field, resIndex)" 
            class="fas fa-exclamation red fade-in"></i>
        </span>
      </div>
    </div>
  </div>