import {Component, HostListener, OnInit} from "@angular/core";
import { ApplicantService } from '../services/applicant.service';
import { Applicant } from '../models/applicant';
import { GeneralInfo } from '../models/generalInfo';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { PortalLookups } from '../models/portalLookups';
import { WaitlistService } from '../services/waitlist.service';
import { PortalParameters } from '../models/portalParameters';
import { Parameters } from '../models/parameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationParameters } from "../models/navigationParameters";
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";
import { routingAway } from "../helpers/pendingChanges.guard";


@Component ({
    selector : 'general-info',
    templateUrl : './general-info.component.html',
    styleUrls: ['./general-info.component.scss']
})

export class GeneralInfoComponent implements OnInit, routingAway{
    applicant: Applicant = new Applicant();
    generalInfo:GeneralInfo = new GeneralInfo();
    stateList:PortalLookups[] = [];
    cityList:PortalLookups[] = [];
    portalParams:PortalParameters = new PortalParameters;
    tcode:string;
    params: Parameters = new Parameters();
    readonly:boolean = false;
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    ssnMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService
    ){
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){

        this.spinnerService.show();
        //add code to check applicant status
        //If applicant is in current/noice status show a message and navigate them through read only fields
        this.readonly = false;
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));

        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant)
            {
                this.generalInfo = this.applicant.generalInfo;
                if (this.generalInfo && this.generalInfo.tenantStatus)
                    if (this.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                        if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                            this.readonly = false;
                        else
                            this.readonly = true;
            }
        }


        this.portalParams.category = "SacCity";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
           this.cityList = data;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "states";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
           this.stateList = data;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        if (!this.generalInfo || this.navigationParams.formMode.length > 0)
        {
            //this.params.applicantCode = JSON.parse(localStorage.getItem('tcode')); 
            if (this.navigationParams.formMode.toUpperCase() == "AFFUPDATE")
                this.params.applicantCode = this.navigationParams.affPCode;
            else
            {
                if (this.navigationParams.formMode.toUpperCase() == "HCVVIEW")
                    this.params.applicantCode = this.navigationParams.hcvTCode == null ? this.navigationParams.cnvTCode : this.navigationParams.hcvTCode;
                else
                    this.params.applicantCode = this.navigationParams.hcvTCode == null ? this.navigationParams.cnvTCode == null ? this.navigationParams.affPCode == null ? '' : this.navigationParams.affPCode : this.navigationParams.cnvTCode : this.navigationParams.hcvTCode;
            }


            if (this.params.applicantCode.substring(0,2) == 't5')
            {
                this.applicantService.getGeneralInfoCNV(this.params)
                .subscribe(data  => { 
                    if (data) 
                    {
                        this.generalInfo = data;
                        this.generalInfo.tenantStatus = this.applicant.generalInfo.tenantStatus;
                        this.applicant.generalInfo = this.generalInfo;
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.params.applicantCode.substring(0,2) == 't0')
            {
                this.applicantService.getGeneralInfoHCV(this.params)
                .subscribe(data  => { 
                    if (data) 
                    {
                        this.generalInfo = data;
                        this.generalInfo.tenantStatus = this.applicant.generalInfo.tenantStatus;
                        this.applicant.generalInfo = this.generalInfo;                        
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.params.applicantCode.substring(0,1).toUpperCase() == 'P')
            {
                this.params.applicantCode = this.navigationParams.affPCode; 
                this.applicantService.getGeneralInfoAff(this.params)
                .subscribe(data  => { 
                    if (data) 
                    {
                        this.generalInfo = data;
                        this.generalInfo.tenantStatus = this.applicant.generalInfo.tenantStatus;
                        this.applicant.generalInfo = this.generalInfo;                        
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        this.spinnerService.hide();
    }


    back()
    {
        this.setFieldValues();
        this.applicant.generalInfo = this.generalInfo;
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.navigationParams.formMode = '';
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));

        if (this.navigationParams.returnLocation == "waitlistType")
            this.router.navigate(['/waitlistType']);
        else if (this.navigationParams.returnLocation == "applicantDashboard")
            this.router.navigate(['/applicantDashboard']);
        else
        {
            if (this.navigationParams.userRole.toUpperCase() == "REGISTEREDUSER")
                this.router.navigate(['/basicInfo']);
            else
                this.router.navigate(['/home']);
        }
    }

    next(f:any)
    {
        this.spinnerService.show();
        if (f.invalid && !this.readonly)
            this.formInvalid = true;
        else
        {
            this.setFieldValues();
            if (!this.generalInfo.otherCity) this.generalInfo.otherCity = "";
            this.applicant.generalInfo = this.generalInfo;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/raceEthnicity']);
        }
        this.spinnerService.hide();
    }

    setFieldValues(){
        this.generalInfo.disability = + this.generalInfo.disability;
        this.generalInfo.locatedSacramento = + this.generalInfo.locatedSacramento;
    }
}