import { Component } from '@angular/core';

@Component({
    selector:'applicant-app',
    template: `
    <portalheader></portalheader>
    <router-outlet></router-outlet>
    <portalfooter></portalfooter>
    <faq-widget></faq-widget>
    `
})

export class ApplicantAppComponent{


}