import { UntypedFormControl } from '@angular/forms';

// custom validator to check that two fields match
export function MaxDate(maxDate:Date) {
    return (control: UntypedFormControl) => {

        // return null if controls haven't initialised yet
        if (!control) {
          return null;
        }

        //format date for dashes
        let controlValue = control.value;
        if (controlValue)
            controlValue = new Date(controlValue);
          //controlValue = controlValue.replace("-", "/").replace("-", "/");
          
        // set error on matchingControl if validation fails
        //if (!isNaN(controlValue) || !isDate(controlValue) || exceedsMax(controlValue)) {
        if (exceedsMax(controlValue)) {          
            setTimeout(() => {
                control.setErrors({ maxDate: true });
              });
        } else {
              control.setErrors(null);
        }

        function exceedsMax(val:any){

          let dateValue = new Date(val);
          let maxValue = new Date(maxDate);

          if (dateValue > maxValue) 
            return true;
          return false;

        }

        function isDate(value:string) {
            var re = /^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|(0[1-9])|([1-2][0-9])|(3[0-1]))\/((\d{2})|(\d{4}))$/;
            var flag = re.test(value);
            return flag;
          }
    }
}