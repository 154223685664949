<body> 
  <div class="form d-flex justify-content-center align-items-center">
    <div class="container mt-5">

      <div class="mt5 text-center">
        <hr><h2 class="text-center mb-5">Family Members</h2><hr>
      </div>

      <!-- <label class="control-label">List all family members who will be living with you (DO NOT include yourself). If you are the only person in your household,
      this table should remain blank. Please Note: The largest rental units we have can accommodate a family up to 14 people:
      the Head of Household and 13 members. We cannot accommodate families with more than 14 family members. Please list one member per card.</label> -->
      <div class="row mt-5 mb-5 ml-2 mr-2">
        <div class="formgroup col-lg-12 justify-content-between">
          <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
            <label class="control-label">List all family members who will be living with you (DO NOT include yourself). If you are the only person in your household, this table should remain blank.  Please list one member per card.
            <br/><br/>Please Note: If applying for a project-based voucher waiting list, the largest rental units we have can accommodate a family up to 14 people: the Head of Household and 13 members. We cannot accommodate families in project-based voucher units with more than 14 family members. </label>
          </div>
          <div class="row">
            <div *ngIf='!readonly' class="mt-2">
              <button type="button" (click)="addMember()" class="btn btn-edit btn-sm mr-5" style="float:right;" [disabled]="familyMembers?.length > 12 || disableAdd">Add Member</button>
            </div>
          </div>

          <div class="card-deck mb-5">
            <div class="row" *ngFor='let fm of familyMembers; let i = index'>
              <div class="md-padding" layout="row" layout-xs="column" layout-wrap>
                <family-member [familyMember]="fm" [index]="i" [readonly]='readonly' (cancelFM)="onCancel($event)" (deleteFM)="onDelete($event)" (updateFM)="onUpdate($event)"></family-member>
              </div>
            </div>
          </div>
          <div id="newfm"></div>
          <div *ngIf="formInvalid" class="errormsg mb-5">Please save family member data</div>

          <div class="mt-3 mb-3">
            <button type="button" (click)="back()" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
          </div>

          <div class="mb-5">
            <button type="button" (click)="next()" class="btn btn-general btn-sm float-left">Next</button>
          </div>
          <div *ngIf='!readonly && familyMembers?.length > 1' [ngClass]="!isMobile ? 'mb-3 mr-5' : 'mb-3'">
              <button type="button" (click)="addMember()"  [ngClass]="!isMobile ? 'btn btn-edit btn-sm' : 'mt-5 btn btn-edit-sm btn-sm'" [disabled]="familyMembers?.length > 12 || disableAdd">Add Member</button>
          </div>
          </div>
        </div>
        <div style="height: 5rem;"></div>
    </div>
    <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
      <p style="color: white;">Please wait.</p>
    </ngx-spinner>
  </div>

</body>
