import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { Waitlists } from '../models/waitlists';
import { WaitlistsExtended } from '../models/waitlistsExtended';
import { WaitlistTypes } from '../models/waitlistTypes';
import { AuthService } from '../services/auth.service';
import { MessageService } from '../services/message.service';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { WaitlistService } from '../services/waitlist.service';


@Component({
    selector: 'waitlist-type',
    templateUrl: './waitlist-type.component.html' ,
    styleUrls: ['./waitlist-type.component.scss']
})

export class WaitlistTypeComponent implements OnInit{
    wltypes:WaitlistTypes[] = [];
    waitlistSelected:Waitlists = new Waitlists();
    waitlists: Waitlists[] = [];
    waitlistTypeSelected: string;
    //wlt:WaitlistTypes = new WaitlistTypes();
    property:string = "";
    params:PortalParameters = new PortalParameters();
    title:string = "";
    wlPreferences:Preferences[] = [];
    applicantParams:Parameters = new Parameters();
    waitlistsExtended: WaitlistsExtended[] = [];
    item:string;
    itemType:string;
    itemTitle:string = "";

    role:string = "";
    loggedIn:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();


    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private authService: AuthService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService,
        private router:Router,
        private viewportScroller: ViewportScroller

    ) {}

    
    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams =  JSON.parse(localStorage.getItem('navigationParams'));
        this.navigationParams.resultMessage = "";
        this.navigationParams.returnLocation = "";
        this.navigationParams.waitlist = "";
        this.navigationParams.updateAction = "";
        this.navigationParams.formMode = "";

        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.role = this.navigationParams.userRole;

        //if (this.role == "RegisteredUser") this.router.navigate(['/waitlistTypeNew']);
        this.loggedIn =  this.navigationParams.loggedIn = this.authService.isUserAuthenticated();

        this.params.waitlistProgram = this.navigationParams.waitlistProgram;
        this.waitlistTypeSelected = this.navigationParams.waitlistType;
        this.params.waitlistType = this.waitlistTypeSelected;

        this.params.waitlistTypeCategory = this.navigationParams.waitlistCategory;
 
        this.title = this.params.waitlistProgram; // + ' Program';

        if (!this.params.waitlistProgram || this.params.waitlistProgram == "") this.router.navigate(['/waitlistProgram']);

        this.waitlistService.getWaitlistTypesByProgram(this.params)
            .subscribe(data  => { 
                this.wltypes = data;

                 //this.wlt = this.wltypes.find(w=>w.name == this.waitlistTypeSelected);
                if (this.waitlistTypeSelected != "All")
                    this.wltypes = this.wltypes.filter(t=>t.name == this.waitlistTypeSelected);

                this.waitlistService.getWaitlistsByType(this.params)
                .subscribe(d=>{
                    this.waitlists = d;

                    // sort by name
                    this.waitlists.sort((a, b) => {
                        const nameA = a.name.toUpperCase(); 
                        const nameB = b.name.toUpperCase(); 
                        if (nameA < nameB) {
                        return -1;
                        }
                        if (nameA > nameB) {
                        return 1;
                        }
                        // same names 
                        return 0;
                    });
                    this.setupWaitlists(this.waitlists)
                }, (err) => {
                    this.spinnerService.hide();            
                    this.msg.showError(err, "Loading Error");
                })

            }, (error) => {
                this.spinnerService.hide();            
                this.msg.showError(error, "Loading Error");
            });

        this.spinnerService.hide();            

    }

    setupWaitlists(wls:Waitlists[]){

        if (this.loggedIn && this.role.toUpperCase() == 'REGISTEREDUSER')
            this.setupWaitlistsRegisteredUser(wls);
        else 
        {
            if (this.loggedIn && this.role.toUpperCase() == "APPLICANT")
                this.setupWaitlistsApplicant(wls);
            else
                this.setupWaitlistsGeneral(wls);
        }
    }

    applyToWaitlist(wlt:WaitlistsExtended){

        if (this.loggedIn && this.role.toUpperCase() == 'REGISTEREDUSER')
            this.applyToWaitlistRegisteredUser(wlt);

        if (this.loggedIn && this.role.toUpperCase() == "APPLICANT")
                this.applyToWaitlistApplicant(wlt);
    }

   
    propertyClick(waitlist:string, property:string){
        this.property = property;
        this.waitlistSelected = this.waitlists.find(w=>w.name==waitlist);

        if (this.property == "faqProgram")
        {
            this.itemTitle = "FAQs"
            this.itemType = "WaitlistProgram";
            this.item = this.params.waitlistProgram;
        }
        if (this.property == "faq")
        {
            this.itemTitle = "FAQs"
            this.itemType = "Waitlist";
            this.item = this.waitlistSelected.name;
        }
        if (this.property == "instructions")
        {
            this.itemTitle = "Waitlist Application Instructions";
            this.itemType = "WaitlistProgram";
            this.item = this.params.waitlistProgram;
        }
        setTimeout(() => this.scrollToAnchor(), 100);
    }


    scrollToAnchor(){
        //setup scrolling
        if (this.property == "waitlist")
            this.viewportScroller.scrollToAnchor('wlsec');
        if (this.property == "faq" || this.property == 'instructions')
            this.viewportScroller.scrollToAnchor('faq');            
        if (this.property == "Preferences" || this.property=="Site Requirements" || this.property == 'SHRA Requirements')
            this.viewportScroller.scrollToAnchor('preferences');
        if (this.property == "faqProgram")
            this.viewportScroller.scrollToAnchor('faqProgram');            
    }

    
    paperApplicationRequest(){
        //this.router.navigate(['/paperApplicationRequest']);
        this.router.navigate(['/paperApplicationFaq']);
    }


    setupPreferences(prefs:Preferences[]):string{

        let preferences = "";
 
        prefs.forEach(p => {
            if (p && p.name.length && p.name.length > 0)
                preferences += p.name + ", ";
        });
 
        //remove last ,
        preferences = preferences.substring(0, preferences.length - 2);

        return preferences;

    }

  
    //General User (All public users)
    setupWaitlistsGeneral(wls:Waitlists[]){

        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name.replace("FYI", "<span class='text-danger'>FYI");
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID
            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                    wle.preferences = this.setupPreferences(p);
                    if (this.wltypes[0].showSHRARequirements == 1)
                    {
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                this.waitlistsExtended.push(wle);                    
                        });
                    }
                    else
                        this.waitlistsExtended.push(wle);                    
                });
            });                
        }); 
    }


    //Registered User
    setupWaitlistsRegisteredUser(wls:Waitlists[]){

        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name.replace("FYI", "<span class='text-danger'>FYI");
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID
            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;

                if (wle.wlStatus.toUpperCase() == "OPEN") wle.action = "apply";
                wle.applicantAgeEligibility = true;
                wle.applicantBREligibility = true;

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                wle.preferences = this.setupPreferences(p);
                    if (this.wltypes[0].showSHRARequirements == 1)
                    {
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                this.waitlistsExtended.push(wle);                    
                        });
                    }
                    else
                        this.waitlistsExtended.push(wle);                    
                });
            });                
        }); 
    }


    //Registered User
    applyToWaitlistRegisteredUser(wlt:WaitlistsExtended){
    
        let wl = wlt.waitlist;
        //check if waitlist needs an access code and supplied access code is valid
        if (wl.accessCodeRequired == 1 && wl.accessCode != wlt.accessCode)
            this.waitlistsExtended.find(f=>f.waitlist.name==wl.name).accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = wlt.waitlist.name;
            this.navigationParams.waitlistTypesModel = this.wltypes[0];
            this.navigationParams.returnLocation = "waitlistType";
            this.navigationParams.availableBR = wlt.waitlist.bedroomsAvailable.toString();
            this.navigationParams.updateAction = "apply";

            if (wl.name == "Housing Choice Voucher")
                this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
            else
               this.navigationParams.resultMessage = "Hello. You have been added to " + wl.name + " waiting list with your application update.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/basicInfo']);
       }
    }


    //Applicant
    setupWaitlistsApplicant(wls:Waitlists[]){

        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name.replace("FYI", "<span class='text-danger'>FYI");
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID

            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;
                wle.action = "update";

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                    wle.preferences = this.setupPreferences(p);
                    this.waitlistPortalService.getApplicantPreferences().then (pr => {
                        if (pr)
                        {
                            let index:number = -1;
                            index = pr.findIndex(p => p.name.toLowerCase().includes('elderly') || p.name.toLowerCase().includes('neareld'));
                            wle.applicantAgeEligibility = index != -1? true : false;
                            if (wle.waitlist.name != 'Elderly Only' && wle.waitlist.name != 'Project Based Elderly') wle.applicantAgeEligibility = true;
                        }

                    
                        //SHRA requirements
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                if (this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID && x.waitlistTypeCategory.toUpperCase().includes('WAITING LIST')))
                                {
                                    //get applicant's status for this waitlist if exists
                                    this.params.waitlist = wl.name;
                                    this.waitlistPortalService.getApplicantWaitlistStatus(this.params).then(h =>{
                                        this.applicantParams = h;
                                        wle.applicantStatus = h.waitlistStatus;
                                        wle.applicantBedroom = h.bedroomSize;

                                        let availableBR:string = wl.bedroomsAvailable + ",";
                                        if (availableBR == "0," || wle.applicantBedroom == 999 || availableBR.includes(wle.applicantBedroom + ","))
                                            wle.applicantBREligibility = true;

                                        if (availableBR.includes('SRO') && wle.applicantBedroom < 2)
                                            wle.applicantBREligibility = true;

                                        if (wle.wlStatus.toUpperCase() == "OPEN")
                                            if (wle.applicantStatus.toUpperCase() == "REMOVED" || wle.applicantStatus.toUpperCase() == "LEASED" || wle.applicantStatus.toUpperCase() == "NOT ON LIST")
                                                    wle.action = "apply";
                                                                                            
                                        this.waitlistsExtended.push(wle);                                                
                                    });
                                }
                                else
                                    this.waitlistsExtended.push(wle);                    
                        });
                    });
                });                
            }); 
        });                                
    }


    //Applicant
    applyToWaitlistApplicant(wlt:WaitlistsExtended){
    
        let wl = wlt.waitlist;
        //check if waitlist needs an access code and supplied access code is valid
        if (wl.accessCodeRequired == 1 && wl.accessCode != wlt.accessCode)
            this.waitlistsExtended.find(f=>f.waitlist.name==wl.name).accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = wlt.waitlist.name;
            this.navigationParams.waitlistTypesModel = this.wltypes[0];
            this.navigationParams.returnLocation = "waitlistType";
            this.navigationParams.availableBR = wlt.waitlist.bedroomsAvailable.toString();

            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));

            if (this.wltypes[0].yardiDB != 'AFF' && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
            {
                if (wl.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. '
                        + 'Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                else
                    this.navigationParams.resultMessage = 'Hello! You have been added to ' + wl.name + ' waiting list. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                // this.waitlistPortalService.applyToWaitlist();   
                this.applyWithoutUpdate(applicant);             
                this.router.navigate(['/result']);
            }
            else
            {
                this.navigationParams.updateAction = "apply";
                if (wl.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
                else
                    this.navigationParams.resultMessage = "Hello! You have been added to " + wl.name + " waiting list with your application update.";
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                this.router.navigate(['/generalInfo']);
            }
        }
    }


    applyWithoutUpdate(applicant:Applicant){
        let resultMsg = "";        
        if (this.navigationParams.waitlist.toLowerCase().includes('elderly'))
        {
            var ageEligible = this.waitlistPortalService.checkForElderlyMember(applicant);
            if (!ageEligible) resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the age requirement for this Elderly Only property";
        }

        //available BR size for the selected waitlist
        let availableBR:string = this.navigationParams.availableBR + ",";
        let roomCount:number;
        if (this.navigationParams.waitlistProgram == 'Housing Choice Voucher')
            roomCount = this.waitlistPortalService.calculateBRSizeHCV(applicant);
        
        if (this.navigationParams.waitlistProgram == 'Public Housing' || this.navigationParams.waitlistProgram == 'Affordable Housing')                        
            roomCount = this.waitlistPortalService.calculateBRSizeCNV(applicant);

        let brEligible:boolean = false;
        if (availableBR == "0," || availableBR.includes(roomCount.toString() + ","))                        
                brEligible = true;

        if (availableBR.includes('SRO') && roomCount < 2)
                brEligible = true;

        if (!brEligible) 
            resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the bedroom size requirement for available bedrooms";                                        
        
        if (resultMsg == "") 
            this.waitlistPortalService.applyToWaitlist(true);
        else
        {
            this.navigationParams.resultMessage = resultMsg;
            localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
            this.waitlistPortalService.applyToWaitlist(false);                    
        }

        localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));          
    }


    //Applicant
    updateApplication(){
        let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams.waitlistTypesModel = this.wltypes[0];
        this.navigationParams.returnLocation = "waitlistType";

        if (this.wltypes[0].yardiDB != 'AFF' && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
        {
            //let msg:string = "Hello.  Your update cannot be completed online because you’re a participant in current status or in a move process. Please submit a written request to your caseworker to update your household information.";
            this.navigationParams.resultMessage = 'Hello!  Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/result']);
        }
        else
        {
            if (this.wltypes[0].yardiDB == "AFF" && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
                this.navigationParams.resultMessage = 'Hello! Your Affordable Housing application has been updated. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
            else
                this.navigationParams.resultMessage = "Hello!  Your application has been updated.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/generalInfo']);
        }
    }

    backtoWaitlistPrograms(){
        this.router.navigate(['/waitlistProgram']);
    }
    
}