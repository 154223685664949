import { Directive, ElementRef, HostListener, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';


@Directive({
    selector: '[appTooltipTemplate]'
})

export class MyTooltipTemplateDirective {

    constructor (
        private elRef:ElementRef,
        private renderer: Renderer2,
        private viewRefContainer: ViewContainerRef
    ) {}
    @Input('myTemplate') myTemplate: TemplateRef<any>;


    createTooltip(): HTMLElement {
        const tooltip = this.renderer.createElement('div');
        this.renderer.addClass(tooltip, 'tooltipMy');
        this.renderer.setStyle(tooltip, 'position', 'absolute');
        const viewRef = this.viewRefContainer.createEmbeddedView(this.myTemplate)
        viewRef.detectChanges();
        const tooltipContent = viewRef.rootNodes[0];
        this.renderer.appendChild(tooltip, tooltipContent);
        this.renderer.appendChild(this.elRef.nativeElement, tooltip);
        return tooltip;
    }
    

    @HostListener('mouseenter')
    onMouseEnter() {
        const myTooltip = this.createTooltip();
        this.renderer.appendChild(this.elRef.nativeElement, myTooltip);
    }


    @HostListener('mouseleave')
    onMouseLeave() {
        const tooltip = this.elRef.nativeElement.querySelector('.tooltipMy');
        setTimeout(() => {
            this.renderer.removeChild(this.elRef.nativeElement, tooltip)
        }, 300);
    }


    @HostListener('mouseout')
    onMouseOut() {
        const tooltip = this.elRef.nativeElement.querySelector('.tooltipMy');
        setTimeout(() => {
            this.renderer.removeChild(this.elRef.nativeElement, tooltip)
        }, 300);
    }

}