export class Waitlists{
     waitlistID: number;
     name: string;
     description: string;
     waitlistTypeID: number;
     units: number;
     numberOfUnitsText: string;
     bedroomMinimum: number;
     bedroomMaximum: number;
     bedroomsAvailable: number;
     city: string;
     location: string;
     locationText: string;
     statusID: number;
     notes: string;
     accessCodeRequired:number;
     accessCode:string;
     accessCodeText:string;
     shraRequirements:string;
     active: number;
     waitlistSubTypeLookupID: number;
     waitlistSubType: string;
     yardiDB: string;
     schoolDistrict: string;
     schoolDistrictLink: string;
     showNeighborhoodScores: number;
     showWaitlistType: number;
     showImage: number;
     showGoogleMap: number;
}