import {Component, HostListener, OnInit} from "@angular/core";
import { ApplicantService } from '../services/applicant.service';
import { Applicant } from '../models/applicant';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { Parameters } from '../models/parameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { RaceEthnicity } from "../models/raceEthnicity";
import { Lookup } from "../models/lookup";
import { NavigationParameters } from "../models/navigationParameters";
import { routingAway } from "../helpers/pendingChanges.guard";
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";

@Component ({
    selector : 'race-ethnicity',
    templateUrl : './race-ethnicity.component.html',
    styleUrls: ['./race-ethnicity.component.scss']
})

export class RaceEthnicityComponent implements OnInit, routingAway {
    applicant: Applicant = new Applicant;
    raceEthnicity:RaceEthnicity = new RaceEthnicity();
    params:Parameters = new Parameters();
    readonly:boolean = false;
    LEPLanguages:Lookup[] = [];
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService
    ){
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){

        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));        
        this.raceEthnicity = this.applicant.raceEthnicity;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';
        if (this.applicant.generalInfo.tenantStatus == null) this.applicant.generalInfo.tenantStatus = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        this.params.category = "LEPLanguage";
        this.applicantService.getLookupsByCategoryHCV(this.params).subscribe((data: any) => {
            this.LEPLanguages = data;
        });            

        if ((!this.raceEthnicity && this.applicant.generalInfo.pCode) || (this.raceEthnicity && this.raceEthnicity.hmy != this.applicant.generalInfo.hmy))
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getRaceEthnicityCNV(this.params)
                .subscribe(data  => { 
                    if (data) this.raceEthnicity = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getRaceEthnicityHCV(this.params)
                .subscribe(data  => { 
                    if (data) this.raceEthnicity = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getRaceEthnicityAff(this.params)
                .subscribe(data  => { 
                    if (data) this.raceEthnicity = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.raceEthnicity) this.raceEthnicity = new RaceEthnicity();
        this.spinnerService.hide();
    }


    back(f:any)
    {
        // if (f.invalid && this.readonly == false)
        //     this.formInvalid = true;
        // else
        // {
            this.setFieldValues();
            this.applicant.raceEthnicity = this.raceEthnicity;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/generalInfo']);
        // }
    }

    next(f:any){

        if (f.invalid && this.readonly == false)
            this.formInvalid = true;
        else
        {
            this.setFieldValues();
            this.applicant.raceEthnicity = this.raceEthnicity;        
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/incomeRent']);
        }
    }

    setFieldValues(){
        this.raceEthnicity.englishUnderstanding = + this.raceEthnicity.englishUnderstanding;
        this.raceEthnicity.raceAmericanIndian = + this.raceEthnicity.raceAmericanIndian;
        this.raceEthnicity.raceAsian = + this.raceEthnicity.raceAsian;
        this.raceEthnicity.raceBlack = + this.raceEthnicity.raceBlack;
        this.raceEthnicity.racePacificIslander = + this.raceEthnicity.racePacificIslander;
        this.raceEthnicity.raceWhite = + this.raceEthnicity.raceWhite;

        if (this.raceEthnicity.englishUnderstanding == 1) this.raceEthnicity.primaryLanguage = "";
    }
}