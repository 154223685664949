<!--Note: classes field-group-component, control-label, and form-control are used in the PDF builder-->
<div *ngFor='let field of myFields; let i = index;' class="col-md-12 field-group-component">
    <div *ngIf="!field.isHidden && !field.fieldResponses[resIndex]?.isFieldHidden" class="row mb-2 d-flex align-items-center">
  
        <div *ngIf="field.fieldTypeName === 'LookupResponseSignatureLabel'" class="col-md-12">
            <div *ngFor="let l of field.lookups">
                <div *ngFor='let fld of myFields; let i = index;'>
                    <span *ngIf="fld.fieldTypeName === 'LookupResponseSignatureLabel'">{{l.name}}</span>
    
                    <div *ngIf="fld.fieldTypeName === 'Signature'" class="col-11">
                        <div style="margin-left: 39%;">
                            <!-- <span style="font-family: monospace;">Write the signature and click on Save</span> -->
                            <canvas #signPadCanvas (touchstart)="startSignPadDrawing($event)" (touchmove)="movedFinger($event)" (blur)="catchFieldResponse($event.target.value, field.fieldID, i, $event)"></canvas>
                            <div class="mt-2">
                                <button class="btn btn-sm btn-general mr-2" color="secondary" (click)="saveSignPad(field.fieldID, i)">Save</button>
                                <button class="btn btn-sm btn-secondary mr-2" (click)="clearSignPad(field.fieldID, i)">Clear</button>
                            </div>
                        </div>
                    </div>  
        
                    <!-- <span class="col-1">
                    <i *ngIf="showAlert(fld, i)" 
                        class="fas fa-exclamation red fade-in"></i>
                    </span> -->
                </div>
            </div> 
        </div>
    </div>
  </div>