import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { tr } from "date-fns/locale";
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from "src/environments/environment";
import { Applicant } from "../models/applicant";
import { FamilyMember } from '../models/familyMember';
import { Lookup } from '../models/lookup';
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ConfirmationMessageService } from "../services/confirm-message.service";
import { MessageService } from '../services/message.service';
import { PortalUtilitiesService } from "../services/portal-utilities.service";

@Component ({
    selector : 'family-member',
    templateUrl : './family-member.component.html',
    styleUrls: ['./family-member.component.scss']
})

export class FamilyMemberComponent implements OnInit{
    @Output() updateFM:EventEmitter<FamilyMember> = new EventEmitter(); 
    @Output() deleteFM:EventEmitter<FamilyMember> = new EventEmitter();
    @Output() cancelFM:EventEmitter<FamilyMember> = new EventEmitter();
    @Input() familyMember:FamilyMember = new FamilyMember;
    @Input() index:number;
    @Input() readonly:boolean;
    applicant: Applicant = new Applicant();
    edit:boolean = false;
    params:Parameters = new Parameters();
    relationshipList:Lookup[]=[];
    fMBackup:FamilyMember = new FamilyMember();
    formInvalid:boolean = false;
    fmedit:string = "";
    LEPLanguages:Lookup[] = [];
    navigationParams:NavigationParameters = new NavigationParameters();
    duplicateSSN:boolean = false;
    spouseNotAdult:boolean = false;
    maxDate:Date = new Date();
    minDate:Date = new Date();
    imgFolder: string = environment.imgFolder;
    isMobile:boolean = false;

    constructor (
        private applicantService:ApplicantService,
        private spinnerService: NgxSpinnerService,
        private portalUtilityService: PortalUtilitiesService,
        private msg:MessageService,
        private confirmationService: ConfirmationMessageService
        ){  
            this.isMobile = window.innerWidth < 768;      
    }

    ngOnInit(){

        this.spinnerService.show();
        this.maxDate = this.portalUtilityService.dateAdd(this.maxDate, "M", 10);
        this.minDate = this.portalUtilityService.dateAdd(this.minDate, "Y", -110);
        
        this.applicant = JSON.parse(localStorage.getItem('applicant'));

        this.params.category = "LEPLanguage";
        this.applicantService.getLookupsByCategoryHCV(this.params).subscribe((data) => {
            this.LEPLanguages = data;
        }); 

        this.params.category = "relationship";
        this.applicantService.getLookupsByCategoryHCV(this.params).subscribe((data) => {
           this.relationshipList = data;
           //remove spouse if already added
           if (this.applicant.familyMembers && this.applicant.familyMembers.find(f => f.relationship.toUpperCase().includes("SPOUSE") && f.memberHMy != this.familyMember.memberHMy))
           {
               let i:number = 0;
               i = this.relationshipList.findIndex(r => r.lkuStrItem.toUpperCase().includes("SPOUSE"))
               if (i >= 0)
                    this.relationshipList.splice(i,1);
           }
        }, (error) => {
          this.msg.showError(error, "Family Member");
        });

        if(this.familyMember.memberHMy==0)
            this.edit = true;
        
        this.spinnerService.hide();
    }

    editMember(){
        localStorage.setItem('fmedit', JSON.stringify("edit"));
        this.edit = true;
        this.setFieldValues();
    }

    
    // checkSSNExists():boolean {
    //     if (this.applicant.generalInfo.ssn == this.familyMember.ssn && this.familyMember.ssn != "999999999")
    //         return true;
    //     if (this.applicant.familyMembers.find(f => f.ssn == this.familyMember.ssn && f.memberHMy != this.familyMember.memberHMy && this.familyMember.ssn != "999999999")) 
    //         return true;
    //     return false;        
    // }

    // checkIfSpouseIsAdult():boolean{
    //     if (this.familyMember.relationship.toUpperCase().includes("SPOUSE") && 
    //         this.portalUtilityService.calculateAge(new Date(this.familyMember.dob)) < 18) 
    //             return true;
    //     return false;        
    // }

    save(f:any)
    {
        this.spinnerService.show();
        this.duplicateSSN = false;
        this.spouseNotAdult = false;

        if (f.invalid && this.readonly == false)
            this.formInvalid = true;
        else if (this.applicant.familyMembers && this.applicant.familyMembers.find(f => f.ssn == this.familyMember.ssn && f.memberHMy != this.familyMember.memberHMy && this.familyMember.ssn != "999999999")
            || this.applicant.generalInfo.ssn == this.familyMember.ssn && this.familyMember.ssn != "999999999")
                this.duplicateSSN = true;
        else if (this.familyMember.relationship.toUpperCase().includes("SPOUSE") && 
            this.portalUtilityService.calculateAge(new Date(this.familyMember.dob)) < 18)
                this.spouseNotAdult = true;
        else
        {
            localStorage.setItem('fmedit', JSON.stringify(""));
            this.edit = false;
            this.setFieldValues();
            this.updateFM.emit(this.familyMember);
        }
        this.spinnerService.hide();

    }

    cancel(){
        this.confirmationService.confirm('Confirm Cancel','Are you sure you want to discard changes?', 'Discard Changes')
        .then(result => {
            if (result) {
                localStorage.setItem('fmedit', JSON.stringify(""));
                this.edit = false;
                this.setFieldValues();
                if(this.familyMember.memberHMy == 0)
                    this.cancelFM.emit(this.familyMember);
            } 
        });
    }

    delete(){
        this.confirmationService.confirm('Confirm Delete','This action cannot be undone. Are you sure you want to delete the family member?', 'Delete')
        .then(result => {
            if (result) {
                localStorage.setItem('fmedit', JSON.stringify(""));
                this.edit = false;
                this.setFieldValues();
                this.deleteFM.emit(this.familyMember);
            } 
        });
    }

    setFieldValues(){
        this.familyMember.disability = +this.familyMember.disability;
    }
}