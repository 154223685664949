import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { PortalParameters } from '../models/portalParameters';
import { WaitlistAmenities } from '../models/waitlistAmenities';
import { Waitlists } from '../models/waitlists';
import { MessageService } from '../services/message.service';
import { WaitlistService } from '../services/waitlist.service';

@Component({
    selector: 'waitlists',
    templateUrl: './waitlists.component.html' ,
    styleUrls: ['./waitlists.component.scss']
})


export class WaitlistsComponent implements OnChanges{
    @Input() waitlist:Waitlists;
    @Input() waitlistType:string;
    amenities: WaitlistAmenities[] = [];
    params: PortalParameters = new PortalParameters();
    imgFolder: string = environment.imgFolder;


    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService
    ) {}


    ngOnChanges(){
        this.spinnerService.show();

        this.params.waitlist = this.waitlist.name;
        this.waitlistService.getWaitlistAmenities(this.params)
        .subscribe(d => {
            this.amenities = d;
        }, (err) => {
            this.spinnerService.hide();            
            this.msg.showError(err, "waitlists error");
        });  
        
        this.spinnerService.hide();
    }

}