import {Component, HostListener, OnInit} from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from "../helpers/pendingChanges.guard";
import { Applicant } from '../models/applicant';
import { DisabilityAccommodation } from "../models/disabilityAccommodation";
import { HomelessStatus } from "../models/homelessStatus";
import { LeaseInPlaceStatus } from '../models/leaseInPlaceStatus';
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";
import { MessageService } from '../services/message.service';
import { WaitlistPortalService } from '../services/waitlist-portal.service';

@Component ({
    selector : 'lip-status',
    templateUrl : './lease-in-place-status.component.html',
    styleUrls: ['./lease-in-place-status.component.scss']
})

export class LIPStatusComponent implements OnInit, routingAway{
    applicant: Applicant;
    params:Parameters = new Parameters();
    leaseInPlaceStatus:LeaseInPlaceStatus = new LeaseInPlaceStatus();
    readonly:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }    

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;        
    }

    ngOnInit(){
        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.leaseInPlaceStatus = this.applicant.leaseInPlaceStatus;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.applicant.generalInfo.tenantStatus == 'Current' || this.applicant.generalInfo.tenantStatus == 'Notice')
            this.readonly = true;

        if (!this.leaseInPlaceStatus && this.applicant.generalInfo.pCode)
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getLeaseInPlaceStatusCNV(this.params)
                .subscribe(data  => { 
                        this.leaseInPlaceStatus = data;
                        if (!this.leaseInPlaceStatus){
                            this.leaseInPlaceStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                curRes3MonthORMore:null,
                                landlordAcceptVoucher:null,
                                landlordSignANew1Year:null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            else
            {
                this.applicantService.getLeaseInPlaceStatusHCV(this.params)
                .subscribe(data  => { 
                        this.leaseInPlaceStatus = data;
                        if (!this.leaseInPlaceStatus){
                            this.leaseInPlaceStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                curRes3MonthORMore:null,
                                landlordAcceptVoucher:null,
                                landlordSignANew1Year:null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.leaseInPlaceStatus) this.leaseInPlaceStatus = new LeaseInPlaceStatus();
        this.spinnerService.hide();
    }

    back(f:any)
    {
        // if (this.readonly || (f.valid && !this.readonly))
        // {
            //this.setFieldValues();
            this.applicant.leaseInPlaceStatus = this.leaseInPlaceStatus
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/displacementStatus']);
        // }
    }


    goToDashboard(){
        this.applicant.leaseInPlaceStatus = this.leaseInPlaceStatus
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.navigationParams.formMode = '';
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/home']);
    }

    goToWaitlists(){
        this.applicant.leaseInPlaceStatus = this.leaseInPlaceStatus
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.navigationParams.formMode = '';
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/waitlistType']);
    }

    submit(f:any){
        if (this.readonly || (f.valid && !this.readonly))
        {
            this.spinnerService.show();
            //this.setFieldValues();
            if (!this.applicant.disabilityAccommodation) 
                this.applicant.disabilityAccommodation = new DisabilityAccommodation();

            if (!this.applicant.homelessStatus) 
                this.applicant.homelessStatus = new HomelessStatus();
                
            this.applicant.leaseInPlaceStatus = this.leaseInPlaceStatus
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            let resultMsg = "";

            if (this.navigationParams.updateAction && this.navigationParams.updateAction.toUpperCase() == "APPLY")
            {
                //check if the applicant qualifies - age and BRSize
                if (!this.readonly)
                {
                    if (this.navigationParams.waitlist.toLowerCase().includes('elderly'))
                    {
                        var ageEligible = this.waitlistPortalService.checkForElderlyMember(this.applicant);
                        if (!ageEligible) resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the age requirement for this Elderly Only property";
                    }

                    //available BR size for the selected waitlist
                    let availableBR:string = this.navigationParams.availableBR + ",";
                    let roomCount:number;
                    if (this.navigationParams.waitlistProgram == 'Housing Choice Voucher')
                        roomCount = this.waitlistPortalService.calculateBRSizeHCV(this.applicant);
                    else
                        roomCount = this.waitlistPortalService.calculateBRSizeCNV(this.applicant);

                    let brEligible:boolean = false;
                    if (availableBR == "0," || availableBR.includes(roomCount.toString() + ",") || this.navigationParams.waitlistTypesModel.name.toUpperCase() == 'TENANT-BASED')                        
                            brEligible = true;
                    
                    if (availableBR.includes('SRO') && roomCount < 2)
                            brEligible = true;


                    if (!brEligible) 
                        resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the bedroom size requirement for available bedrooms";                                        

                }

                localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                //apply if eligible
                if (resultMsg == "") 
                    this.waitlistPortalService.applyToWaitlist(true);
                else
                {
                    this.navigationParams.resultMessage = resultMsg;
                    localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                    this.waitlistPortalService.applyToWaitlist(false);                    
                }
    

                setTimeout(() =>{
                    if (!this.readonly)
                        this.waitlistPortalService.updateAllApplicants(this.applicant);
                }, 10000);
                                    
                this.navigationParams.updateAction = "";
                localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                
            }
            else
            {
                if (!this.readonly)
                    this.waitlistPortalService.updateAllApplicants(this.applicant);
            }

            setTimeout(() =>{
                this.spinnerService.hide();
                this.router.navigate(['/result']);
            }, 3000);
        }
    }


    // setFieldValues(){
    //     this.leaseInPlaceStatus.curRes3MonthORMore = + this.leaseInPlaceStatus.curRes3MonthORMore;
    //     this.leaseInPlaceStatus.landlordAcceptVoucher = + this.leaseInPlaceStatus.landlordAcceptVoucher;
    //     this.leaseInPlaceStatus.landlordSignANew1Year = + this.leaseInPlaceStatus.landlordSignANew1Year;
    // }
 

}