import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import SignaturePad from 'signature_pad';
import { Field } from '../models/field';
import { FieldResponse } from '../models/fieldResponse';
import { ApplicantProcessesService } from '../services/applicant-processes.service';
import { AuthService } from '../services/auth.service';
import { FormService } from '../services/form.service';
import { PortalUtilitiesService } from '../services/portal-utilities.service';

@Component({
  selector: 'signature-field-group',
  templateUrl: './signature-field-group.component.html',
  styleUrls: ['./signature-field-group.component.scss'],
})
export class SingatureFieldGroupComponent implements OnChanges {
  @Input() fieldGroups: any;
  @Input() resIndex: any;
  @Input() editMode: any;
  @Input() myRouteParams: any;
  @Input() visited: boolean;
  @Input() isFAQ = false;
  @Output() eventBlur = new EventEmitter();
  isHidden: boolean;
  myFields: Field[];
  fieldGroupName: string;
  applicantFormID: number;
  applicantCode: string;
  dropDownListDefaultValue: string = '*** Choose ***';
  maxDate:Date = new Date();
  minDate:Date = new Date();
  signImage:any;
  signPad: any;
  selectedFiles?: FileList;
  progress = 0;
  message = '';
  fileInfos?: Observable<any>;
  @ViewChild('signPadCanvas', {static: false}) signaturePadElement:any;  

  constructor(
    private formService: FormService,
    private portalUtilityService: PortalUtilitiesService,
    private applicantProcessService: ApplicantProcessesService,
    private authService: AuthService
  ) {
  }

  ngOnChanges(): void {
    this.applicantCode =  JSON.parse(localStorage.getItem('tcode'));
    this.applicantFormID = +JSON.parse(localStorage.getItem('applicantFormID'));
    this.isHidden = this.fieldGroups.isHidden;
    this.fieldGroupName = this.fieldGroups.groupName;
    this.myFields = this.fieldGroups.fields;
    //console.log(this.myFields);

    this.fileInfos = this.applicantProcessService.getFiles();

    this.maxDate = this.portalUtilityService.dateAdd(this.maxDate, "M", 10);
    this.minDate = this.portalUtilityService.dateAdd(this.minDate, "Y", -110);
  }


  ngAfterViewInit() {
    if(this.signaturePadElement)
    {
      this.signPad = new SignaturePad(this.signaturePadElement.nativeElement);
    }
  }


  /*It's work in devices*/
  startSignPadDrawing(event: Event) {
    console.log(event);
  }

  /*It's work in devices*/
  movedFinger(event: Event) {
  }

  /*Undo last step from the signature*/
  undoSign() {
    const data = this.signPad.toData();
    if (data) {
      data.pop(); // remove the last step
      this.signPad.fromData(data);
    }
  }

  /*Clean whole the signature*/
  clearSignPad(fieldID: number, resIndex: number) {
    this.signPad.clear();
    this.catchFieldResponse(null, fieldID, resIndex);
  }
  
  /*Here you can save the signature as a Image*/
  saveSignPad(fieldID: number, resIndex: number) {
    const base64ImageData = this.signPad.toDataURL();
    this.signImage = base64ImageData;
    //Here you can save your signature image using your API call.

    console.log(base64ImageData);
    this.catchFieldResponse(base64ImageData, fieldID, resIndex);

  }


  handleInputResponse(response) {
    this.catchFieldResponse(response.value, response.fieldID, response.resIndex);
  }


  showAlert(field: Field, resIndex: number): Boolean {
    return (
        // we're not on the faq, we have been to the page before, and the field isn't a label
        !this.isFAQ &&
        field.fieldTypeName !== 'Label' &&
        this.visited &&
        // this isn't just a download field
        field.fieldTypeName !== 'FileDownload' &&
        (
          // we are missing a response or have an empty response
          (!field.fieldResponses || !field.fieldResponses[resIndex] || !field.fieldResponses[resIndex].response || field.fieldResponses[resIndex].response === '') ||
          // or this is a date response that has a response but the day, month, or year are incomplete (equal to 0)
          (field.fieldTypeName === 'Text' && this.formService.hasRegexMismatch(field)) ||
          (field.fieldTypeName === 'DateRange' && this.formService.hasRegexMismatch(field))
        )
    );
  }

  removeResponse(fieldResponse: FieldResponse) {
    const currentField = this.myFields.find(field => field.fieldID === fieldResponse.fieldID);
    currentField.fieldResponses = currentField.fieldResponses.filter(
      fr => fr.repeatNumber !== fieldResponse.repeatNumber && fr.responseID !== fieldResponse.responseID
    );
  }

  catchFieldResponse(fieldResponse: string, fieldID: number, resIndex: number) {
    const currentField = this.myFields.find(field => field.fieldID === fieldID);

    let currFieldRes: FieldResponse;
    let fieldRes: any;

    if (currentField.fieldResponses) {
      currFieldRes = currentField.fieldResponses[resIndex];
    }

    if (fieldResponse === this.dropDownListDefaultValue) {
      fieldResponse = '';
    }

    //checkboxes .. take multiple values
    if (currentField.fieldTypeName === 'Checkbox') {
      if (!currFieldRes.response.includes(fieldResponse))
        fieldResponse += "," + currFieldRes.response; 
      else
        fieldResponse = currFieldRes.response.replace("," + fieldResponse, "").replace(fieldResponse + ",", "");
    }

    //store signature as a stream
    if (currentField.fieldTypeName === 'Signature') {
      const base64ImageData = this.signPad.toDataURL();
      fieldResponse = base64ImageData;    
      console.log(fieldResponse);
    }


    let dateObj = {day: 0, month: 0, year: 0};
    if (currentField.fieldTypeName === 'Calendar') {
      dateObj = this.formService.deconstructDate(fieldResponse);
    }

    if (currFieldRes) {
      if (currFieldRes.response !== fieldResponse) {
        fieldRes = {
          responseID: currFieldRes.responseID,
          applicantFormID: currFieldRes.applicantFormID,
          fieldID: currFieldRes.fieldID,
          formQuestionsID: currFieldRes.formQuestionsID,
          response: fieldResponse,
          repeatNumber: currFieldRes.repeatNumber,
          createdBy: this.applicantCode,
          updatedBy: this.applicantCode,
          day: dateObj.day,
          month: dateObj.month,
          year: dateObj.year
        };
      }
    }
    else {
      fieldRes = {
        responseID: 0,
        applicantFormID: this.applicantFormID,
        fieldID: fieldID,
        formQuestionsID: currentField.formQuestionID,
        response: fieldResponse,
        repeatNumber: 1,
        createdBy: this.applicantCode,
        updatedBy: this.applicantCode,
        day: dateObj.day,
        month: dateObj.month,
        year: dateObj.year
      };
      if (currentField.fieldTypeName === 'FileUpload') {
        // push the newly added file to the UI, so we don't have to make a round trip
        (currentField.fieldResponses || (currentField.fieldResponses = [])).push(fieldRes);
      }
    }


    this.eventBlur.emit(fieldRes);
  }
}
