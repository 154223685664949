<body>
  <form name="form" #f="ngForm" novalidate>
    <div class="form d-flex justify-content-center align-items-center" *ngIf="incomeRent">
      <div [ngClass]="!isMobile ? 'col-md-6 mt-2 mb-5':'mt-2 mb-5'">
        <div class="text-center mb-5">
          <hr><h2>Income and Rent</h2><hr>
        </div>
        <div class="formgroup col-lg-12 justify-content-between">
            <div class="form-group mb-2">
              <label class="control-label">Is the head of household or anyone in the household 
                    receiving Social Security, Supplemental Security Income (SSI),  
                    or other payments due to a long-term disability? 
              </label>
              <div class="input-group mb-3 col-md-3 col-lg-3">
                <select [disabled]='readonly' data-val="true" [(ngModel)]="incomeRent.resSSI" class="form-control input-small" #resSSI="ngModel" required id="resSSI" name="resSSI">
                  <option class="option-label" value=1>Yes</option>
                  <option class="option-label" value=0>No</option>
                </select>
                <div *ngIf="resSSI.invalid && (resSSI.dirty || formInvalid)" class="errormsg">Required</div>                        
              </div>  
            </div>

          <div class="form-group mb-2">
              <label class="control-label">What is the current MONTHLY gross income for ALL household members from ALL sources?
              </label>
              <div class="input-group mb-3 col-md-8 col-lg-8">
                <div class="input-group-prepend">
                  <span class="input-group-text">$</span>
                  <!-- <span class="input-group-text">0.00</span> -->
                </div>
                <input [disabled]='readonly' type="number" min="0" [(ngModel)]="incomeRent.monthlyGross" id="monthlyGross" name="monthlyGross" class="form-control" #monthlyGross="ngModel" required [minNumber]="0" [maxNumber]="maxAmount" aria-label="Amount (to the nearest dollar)">
                <div *ngIf="monthlyGross.invalid && monthlyGross.errors.required && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                <div *ngIf="monthlyGross.invalid && monthlyGross.errors.maxNumber && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Income is too high</div>
                <div *ngIf="monthlyGross.invalid && monthlyGross.errors.minNumber && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
              </div>
            </div>

            <div class="form-group mb-2">
              <label class="control-label">Do you rent a room, home or apartment (not a motel or hotel room)?
              </label>
              <div [ngClass]="incomeRent.renting == 1? 'input-group mb-3 col-md-8 col-lg-8' : 'input-group mb-5 col-md-8 col-lg-8'">
                <select [disabled]='readonly' data-val="true" [(ngModel)]="incomeRent.renting" class="form-control input-small" #renting="ngModel" required id="renting" name="renting">
                  <option class="option-label" value=1>Yes</option>
                  <option class="option-label" value=0>No</option>
                </select>
                <div *ngIf="renting.invalid && (renting.dirty || formInvalid)" class="errormsg">Required</div>                                      
              </div>  
            </div>
          <div *ngIf="incomeRent.renting == 1">
              <div class="form-group mb-2">
                <label class="control-label">What is your current MONTHLY rent payment?
                </label>
                <div class="input-group mb-3 col-md-8 col-lg-8">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                    <!-- <span class="input-group-text">0.00</span> -->
                  </div>
                  <input [disabled]='readonly' type="number" min="0" [(ngModel)]="incomeRent.monthlyRent" id="monthlyRent" name="monthlyRent" class="form-control" #monthlyRent="ngModel" required [minNumber]="0" [maxNumber]="maxAmount" aria-label="Amount (to the nearest dollar)">
                  <div *ngIf="monthlyRent.invalid && monthlyRent.errors.required && (monthlyRent.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                  <div *ngIf="monthlyRent.invalid && monthlyRent.errors.maxNumber && (monthlyRent.dirty || formInvalid)" class="errormsg ml-2">Rent is too high</div>
                  <div *ngIf="monthlyRent.invalid && monthlyRent.errors.minNumber && (monthlyRent.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                </div>
              </div>

              <div class="form-group mb-5">
                <label class="control-label">What is your current MONTHLY utility payment?
                </label>
                <div class="input-group mb-3 col-md-8 col-lg-8">
                  <div class="input-group-prepend">
                    <span class="input-group-text">$</span>
                    <!-- <span class="input-group-text">0.00</span> -->
                  </div>
                  <input [disabled]='readonly' type="number" min="0" [(ngModel)]="incomeRent.monthlyUtility" id="monthlyUtility" name="monthlyUtility" class="form-control" #monthlyUtility="ngModel" required [minNumber]="0" [maxNumber]="maxAmount" aria-label="Amount (to the nearest dollar)">
                  <div *ngIf="monthlyUtility.invalid && monthlyUtility.errors.required && (monthlyUtility.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                  <div *ngIf="monthlyUtility.invalid && monthlyUtility.errors.maxNumber && (monthlyUtility.dirty || formInvalid)" class="errormsg ml-2">Utility is too high</div>
                  <div *ngIf="monthlyUtility.invalid && monthlyUtility.errors.minNumber && (monthlyUtility.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                </div>
              </div>
          </div>
          <div class="mb-3">
            <button type="button" (click)="back(f.form)"  class="btn btn-secondary btn-sm float-left mr-3">Back</button>
          </div>

          <div class="mb-5">
            <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
          </div> 
        </div>       
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>
    </div>
  </form>
</body>
