import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationParameters } from '../models/navigationParameters';
import { PortalParameters } from '../models/portalParameters';
import { ResourceLinks } from '../models/resourceLinks';
import { WaitlistService } from '../services/waitlist.service';
import { ActivatedRoute } from '@angular/router';


@Component({
    selector: 'announcement',
    templateUrl: './announcement.component.html' ,
    styleUrls: ['./announcement.component.scss']
})

export class AnnouncementComponent implements OnInit{
    navigationParams:NavigationParameters = new NavigationParameters();
    announcement:ResourceLinks = new ResourceLinks();
    resourceID:number;

    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private router:Router,
        private activatedRoute:ActivatedRoute
    ) {}
    


    ngOnInit(){
        let params:PortalParameters = new PortalParameters();
        this.resourceID = +this.activatedRoute.snapshot.queryParamMap.get('resourceId');
        console.log(this.resourceID);
        params.category = "Announcements";
        this.waitlistService.getResourceLinks(params)
        .subscribe(a =>{
            this.announcement = a.find(r =>r.resourceID == this.resourceID);
        });
    }
}