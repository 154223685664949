import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { NavigationParameters } from "../models/navigationParameters";
import { WaitlistPortalService } from "../services/waitlist-portal.service";

@Component ({
    selector : 'hcv-programs-compare',
    templateUrl : './hcv-programs-compare.component.html',
    styleUrls: ['./hcv-programs-compare.component.scss']
})

export class HCVProgramsCompareComponent implements OnInit{
    isMobile:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    imageScaleNormal:boolean = true;
    imgFolder: string = environment.imgFolder;
    langCode: string = "en";

    constructor (
        private waitlistPortalService:WaitlistPortalService
        )
        {
            this.isMobile = window.innerWidth < 768;
            waitlistPortalService.getselectedLanguage.subscribe(l => this.langCode = l);
        }

    ngOnInit(){
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.langCode || this.langCode == '' || this.langCode == 'en')
            this.langCode = JSON.parse(localStorage.getItem('langCode'));

    }


    expand(e:Event){
        var elem = e.target as HTMLElement;

        if (this.imageScaleNormal)
        {
            elem.style.transform = "scale(2)";
            elem.style.backgroundColor = "white";
            elem.classList.remove('img-expand');
            elem.classList.add('img-shrink');
        }
        else
        {
            elem.style.transform = "scale(1)";
            elem.style.backgroundColor = "transparent";
            elem.classList.remove('img-shrink');            
            elem.classList.add('img-expand');
        }

        elem.style.transition = "transform 0.25s ease";
        this.imageScaleNormal = !this.imageScaleNormal;
    }

    openInANewTab(e:Event){
        var elem = e.target as HTMLImageElement;
        window.open(elem.src);
    }

    getImagePath(imgName: string): string {

        let imgPath: string = this.imgFolder + "en/" + imgName;

        if (this.langCode == "es" || this.langCode == "hmn" || this.langCode == "ru" || this.langCode == "vi" || this.langCode == "zh-CN" || this.langCode == "zh-TW")
            imgPath = this.imgFolder + this.langCode + "/" + imgName;

        return imgPath;
    }

}