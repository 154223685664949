<div *ngIf="incomeLimit" class="container">
   <!-- <div class="page-heading-secondary">Income Limits</div> -->
   <!-- <div *ngIf="showPrograms" class="">
      <span class="ml-5 page-text"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked"/>Housing Choice Voucher</span>
      <span class="ml-5 page-text"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />Public Housing</span>
      <span class="ml-5 page-text"><input type="radio" class="mr-2" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />Affordable Housing</span>
   </div> -->
   <div *ngIf="!showSmall">
      <div class="table-responsive">
         <table class="table table-sm table-bordered text-center mb-5" id="tableOne">
            <thead>
               <tr>
                  <th class="table-header">Household Size</th>
                  <th *ngIf="incomeLimit.person1 > 0">1</th>
                  <th *ngIf="incomeLimit.person2 > 0">2</th>
                  <th *ngIf="incomeLimit.person3 > 0">3</th>
                  <th *ngIf="incomeLimit.person4 > 0">4</th>
                  <th *ngIf="incomeLimit.person5 > 0">5</th>
                  <th *ngIf="incomeLimit.person6 > 0">6</th>
                  <th *ngIf="incomeLimit.person7 > 0">7</th>
                  <th *ngIf="incomeLimit.person8 > 0">8</th>
                  <th *ngIf="incomeLimit.person9 > 0">9</th>
                  <th *ngIf="incomeLimit.person10 > 0">10</th>
                  <th *ngIf="incomeLimit.person11 > 0">11</th>
                  <th *ngIf="incomeLimit.person12 > 0">12</th>
                  <th *ngIf="incomeLimit.person13 > 0">13</th>
                  <th *ngIf="incomeLimit.person14 > 0">14</th>
                  <th *ngIf="incomeLimit.person15 > 0">15</th>
                  <th *ngIf="incomeLimit.person16 > 0">16</th>
               </tr>
            </thead>
            <tbody>
               <tr class="text-small">
                  <td class="table-header">Income</td>
                  <td *ngIf="incomeLimit.person1 > 0">{{incomeLimit.person1 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person2 > 0">{{incomeLimit.person2 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person3 > 0">{{incomeLimit.person3 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person4 > 0">{{incomeLimit.person4 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person5 > 0">{{incomeLimit.person5 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person6 > 0">{{incomeLimit.person6 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person7 > 0">{{incomeLimit.person7 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person8 > 0">{{incomeLimit.person8 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person9 > 0">{{incomeLimit.person9 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person10 > 0">{{incomeLimit.person10 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person11 > 0">{{incomeLimit.person11 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person12 > 0">{{incomeLimit.person12 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person13 > 0">{{incomeLimit.person13 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person14 > 0">{{incomeLimit.person14 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person15 > 0">{{incomeLimit.person15 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person16 > 0">{{incomeLimit.person16 | currency:'USD':'symbol':'1.0'}}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>
   <div *ngIf="showSmall">
      <div class="table-responsive">
         <table class="table table-sm table-bordered text-center mb-5" id="tableOne">
            <thead>
               <tr>
                  <th class="table-header-small" *ngIf="incomeLimit.person1 > 0">1 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person2 > 0">2 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person3 > 0">3 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person4 > 0">4 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person5 > 0">5 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person6 > 0">6 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person7 > 0">7 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person8 > 0">8 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person9 > 0">9 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person10 > 0">10 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person11 > 0">11 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person12 > 0">12 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person13 > 0">13 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person14 > 0">14 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person15 > 0">15 Person</th>
                  <th class="table-header-small" *ngIf="incomeLimit.person16 > 0">16 Person</th>
               </tr>
            </thead>
            <tbody>
               <tr class ="text-smaller">
                  <td *ngIf="incomeLimit.person1 > 0">{{incomeLimit.person1 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person2 > 0">{{incomeLimit.person2 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person3 > 0">{{incomeLimit.person3 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person4 > 0">{{incomeLimit.person4 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person5 > 0">{{incomeLimit.person5 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person6 > 0">{{incomeLimit.person6 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person7 > 0">{{incomeLimit.person7 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person8 > 0">{{incomeLimit.person8 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person9 > 0">{{incomeLimit.person9 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person10 > 0">{{incomeLimit.person10 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person11 > 0">{{incomeLimit.person11 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person12 > 0">{{incomeLimit.person12 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person13 > 0">{{incomeLimit.person13 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person14 > 0">{{incomeLimit.person14 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person15 > 0">{{incomeLimit.person15 | currency:'USD':'symbol':'1.0'}}</td>
                  <td *ngIf="incomeLimit.person16 > 0">{{incomeLimit.person16 | currency:'USD':'symbol':'1.0'}}</td>
               </tr>
            </tbody>
         </table>
      </div>
   </div>   
</div>