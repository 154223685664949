import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalLookups } from '../models/portalLookups';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { WaitlistPrograms } from '../models/waitlistPrograms';
import { Waitlists } from '../models/waitlists';
import { WaitlistsExtended } from '../models/waitlistsExtended';
import { WaitlistTypes } from '../models/waitlistTypes';
import { AuthService } from '../services/auth.service';
import { FAQPanelService } from '../services/faq-panel.service';
import { MessageService } from '../services/message.service';
import { PopupMessageService } from '../services/popup.message.service';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { WaitlistService } from '../services/waitlist.service';


@Component({
    selector: 'referral-program',
    templateUrl: './referral-program.component.html' ,
    styleUrls: ['./referral-program.component.scss']
})

export class ReferralProgramComponent implements OnInit{
    wltypes:WaitlistTypes[] = [];
    wlprograms:WaitlistPrograms[] = [];
    waitlistSelected:Waitlists = new Waitlists();
    waitlists: Waitlists[] = [];
    waitlistTypeSelected: string;
    property:string = "";
    params:PortalParameters = new PortalParameters();
    title:string = "";
    wlPreferences:Preferences[] = [];
    applicantParams:Parameters = new Parameters();
    waitlistsExtended: WaitlistsExtended[] = [];
    waitlistsExtendedFilter: WaitlistsExtended[] = [];
    portalParams:PortalParameters = new PortalParameters;

    role:string = "";
    loggedIn:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    imageScaleNormal:boolean = true;

    waitlistFilterList: PortalLookups[] = [];
    preferenceFilterList: PortalLookups[] = [];
    wlFilter:string = "All";
    preferenceFilter:string[] = [];
    isMobile:boolean = false;
    imgFolder: string = environment.imgFolder;
    langCode: string = "en";

    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private authService: AuthService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService,
        private router:Router,
        private faqPanelService: FAQPanelService,
        private popupMessageService:PopupMessageService,
        private viewportScroller: ViewportScroller

    ) {
        this.isMobile = window.innerWidth < 768;
        waitlistPortalService.getselectedLanguage.subscribe(l => this.langCode = l);
    }

    
    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams =  JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();
        this.navigationParams.resultMessage = "";
        this.navigationParams.returnLocation = "";
        this.navigationParams.waitlist = "";
        this.navigationParams.updateAction = "";
        this.navigationParams.formMode = "";

        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.role = this.navigationParams.userRole;
        this.loggedIn =  this.navigationParams.loggedIn = this.authService.isUserAuthenticated();
        this.title = 'Referral Programs and Homelessness Resources';

        if (!this.langCode || this.langCode == '' || this.langCode == 'en')
        this.langCode = JSON.parse(localStorage.getItem('langCode'));

        this.portalParams.category = "ReferralFilter";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data: any) => {
           this.waitlistFilterList = data;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "RefPreferencesFilter";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((p: any) => {
           this.preferenceFilterList = p;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.waitlistService.getWaitlistPrograms()
        .subscribe(p  => { 
            this.wlprograms = p;

            this.waitlistService.getWaitlistTypes()
                .subscribe(data  => { 
                    this.wltypes = data;
                    // this.wltypes = this.wltypes.filter(t => !t.waitlistTypeCategory.toUpperCase().includes('WAITING LISTS')); //filter waiting lists types
                    // this.wlprograms = this.wlprograms.filter(p => this.wltypes.find(wlt => wlt.waitlistProgramID == p.waitlistProgramID)); //filter programs to exclude waiting lists

                    this.wlprograms = this.wlprograms.filter(p => p.name == "Referrals"); //filter programs to exclude waiting lists
                    this.wltypes = this.wltypes.filter(t => this.wlprograms.filter(p => p.waitlistProgramID == t.waitlistProgramID).length > 0); //filter waiting lists types

                    this.waitlistService.getWaitlists()
                    .subscribe(d=>{
                        this.waitlists = d;
                        this.waitlists = this.waitlists.filter(w => this.wltypes.find(wlt => wlt.waitlistTypeID == w.waitlistTypeID)); //filter to exclude waiitng lists
                        this.setupWaitlistsGeneral(this.waitlists);

                        this.waitlistsExtendedFilter = this.waitlistsExtended;
                    }, (err) => {
                        this.spinnerService.hide();            
                        this.msg.showError(err, "Loading Error");
                    })

                }, (error) => {
                    this.spinnerService.hide();            
                    this.msg.showError(error, "Loading Error");
                });
            }, (error) => {
                this.spinnerService.hide();            
                this.msg.showError(error, "Loading Error");
            });

        this.spinnerService.hide();            

    }



    scrollToAnchor(anchor:string){
        //setup scrolling
        this.viewportScroller.scrollToAnchor(anchor);
    }

    expand(e:Event){
        var elem = e.target as HTMLElement;

        if (this.imageScaleNormal)
        {
            elem.style.transform = "scale(1.7)";
            elem.style.backgroundColor = "white";
            elem.classList.remove('img-expand');
            elem.classList.add('img-shrink');
        }
        else
        {
            elem.style.transform = "scale(1)";
            elem.style.backgroundColor = "transparent";
            elem.classList.remove('img-shrink');            
            elem.classList.add('img-expand');
        }

        elem.style.transition = "transform 0.25s ease";
        this.imageScaleNormal = !this.imageScaleNormal;
    }


    getImagePath(imgName: string): string {

        let imgPath: string = this.imgFolder + "en/" + imgName;

        if (this.langCode == "es" || this.langCode == "hmn" || this.langCode == "ru" || this.langCode == "vi" || this.langCode == "zh-CN" || this.langCode == "zh-TW")
            imgPath = this.imgFolder + this.langCode + "/" + imgName;

        return imgPath;
    }

    infoClick(infoText:string){
        this.faqPanelService.show(infoText, "lg");
    }

    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }

    getWaitlistsCount(t:WaitlistTypes):number{
        return this.waitlistsExtendedFilter.filter(w => w.waitlist.waitlistTypeID == t.waitlistTypeID).length;
    }

    getWaitlistsExtendedFilterByWLType(WLTID: number):WaitlistsExtended[]{
        return this.waitlistsExtendedFilter.filter(w=>w.waitlist.waitlistTypeID === WLTID);
    }

    // goToPropertyProfile(w:WaitlistsExtended){

    //     localStorage.setItem('waitlist', JSON.stringify(w.waitlist));
    //     localStorage.setItem('waitlistType', JSON.stringify(w.waitlistType));
    //     localStorage.setItem('preferences', JSON.stringify(w.preferences));
    //     localStorage.setItem('waitlistStatus', JSON.stringify(w.wlStatus));
    //     localStorage.setItem('waitlistProgram', JSON.stringify(w.waitlistProgram));

    //     this.router.navigate(['/property']);
    // }
    

    goToPropertyProfile(w:WaitlistsExtended){
        this.navigationParams.waitlistTypesModel = this.wltypes.find(t =>t.waitlistTypeID == w.waitlist.waitlistTypeID);
        this.navigationParams.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == w.waitlist.waitlistTypeID).waitlistProgramID).name;
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));

        localStorage.setItem('waitlistExtended', JSON.stringify(w));
        this.router.navigate(['/property']);
    }


    // updateWLFilter(filter:any){

    //     if (filter.toUpperCase() == "ALL")
    //         this.waitlistsExtendedFilter = this.waitlistsExtended;
    //     else        
    //         this.waitlistsExtendedFilter = this.waitlistsExtended.filter(w => w.waitlistTypeCategory == filter
    //                             || w.waitlistProgram == filter
    //                             || w.waitlistType == filter);
        
    // }


    updateWLFilter(filter:any){
        this.wlFilter = filter;
        this.filterWaitlists();
    }


    updatePreferenceFilter(filter:any, event){

        if (event.target.checked) 
            this.preferenceFilter.push(filter);
        else {
              var index = this.preferenceFilter.indexOf(filter);
              this.preferenceFilter.splice(index,1);
        }

        this.filterWaitlists();
    }

    filterWaitlists(){

        if (this.wlFilter.toUpperCase() == "ALL")
            this.waitlistsExtendedFilter = this.waitlistsExtended;
        else
            this.waitlistsExtendedFilter = this.waitlistsExtended.filter(w => this.wlFilter.toUpperCase().includes(w.wlStatus.toUpperCase())
                                || w.waitlistProgram.toUpperCase().includes(this.wlFilter.toUpperCase())
                                || w.waitlistType.toUpperCase().includes(this.wlFilter.toUpperCase())
                                || (w.waitlistType + " " + w.waitlistTypeCategory).toUpperCase().includes(this.wlFilter.toUpperCase()));

        //filter for preferences
        if (this.preferenceFilter && this.preferenceFilter.length > 0)
        {
            let waitlistsExtendedFilterNew: WaitlistsExtended[] = [];
            this.preferenceFilter.forEach(x => {
                if (this.waitlistsExtendedFilter.filter(w1 => w1.preferences.includes(x) || w1.shraRequirements.includes(x)).length > 0 )
                    this.waitlistsExtendedFilter.filter(w1 => w1.preferences.includes(x)  || w1.shraRequirements.includes(x)).forEach(w2 => {
                        waitlistsExtendedFilterNew.push(w2)
                    }
                )
            });                                        

            this.waitlistsExtendedFilter = waitlistsExtendedFilterNew;
        }
    }
    
    setupPreferences(prefs:Preferences[]):string{

        let preferences = "";
 
        prefs.forEach(p => {
            if (p && p.name.length && p.name.length > 0)
                preferences += p.name + ", ";
        });
 
        //remove last ,
        preferences = preferences.substring(0, preferences.length - 2);

        return preferences;

    }

  
    //General User (All public users)
    setupWaitlistsGeneral(wls:Waitlists[]){

        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name;
            wle.waitlistType = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).name;
            wle.waitlistTypeCategory = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistTypeCategory;

            //get only referrals - exclude waiting lists
            if (wle.waitlistTypeCategory.toUpperCase() != "WAITING LISTS")
            {
                wle.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistProgramID).name;
                wle.waitlist = wl;
                this.params.waitlist = wl.name;
                wle.accessCode = "";
                wle.accessCodeValid = true;
                //get waitlist status
                this.params.lookupID = wl.statusID
                this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                    wle.wlStatus = l.description;

                    //setup preferences
                    this.params.waitlist = wl.name;
                    this.params.itemType = "Preference";
                    this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                        wle.preferences = this.setupPreferences(p);
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                            wle.shraRequirements = this.setupPreferences(r);
                            this.waitlistsExtended.push(wle);                    
                        });
                    });
                }); 
            }               
        }); 
    }
   
}