import { ViewportScroller } from "@angular/common";
import {Component, HostListener, OnInit} from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from "../helpers/pendingChanges.guard";
import { Applicant } from '../models/applicant';
import { FamilyMember } from '../models/familyMember';
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";
import { MessageService } from '../services/message.service';
import { WaitlistPortalService } from "../services/waitlist-portal.service";

@Component ({
    selector : 'family-members',
    templateUrl : './family-members.component.html',
    styleUrls: ['./family-members.component.scss']
})

export class FamilyMembersComponent implements OnInit, routingAway{
    applicant: Applicant;
    familyMembers:FamilyMember[] = [];
    params:Parameters = new Parameters();
    disableAdd:boolean = false;
    index:number;
    readonly:boolean = false;
    formInvalid:boolean = false;
    ethnicityRequired:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService,
        private waitlistPortalService: WaitlistPortalService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private viewportScroller: ViewportScroller,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;        
    }

    ngOnInit(){

        this.spinnerService.show();

        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.familyMembers = this.applicant.familyMembers;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        if ((!this.familyMembers && this.applicant.generalInfo.pCode) || (this.applicant.generalInfo.pCode && this.familyMembers && this.familyMembers.findIndex(f => f.hMy == this.applicant.generalInfo.hmy) < 0))
        {
            
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getFamilyMembersCNV(this.params)
                .subscribe(data  => { 
                    if (data)
                    { 
                        this.familyMembers = data;
                        this.applicant.familyMembers = this.familyMembers;
                        localStorage.setItem('applicant', JSON.stringify(this.applicant));
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getFamilyMembersHCV(this.params)
                .subscribe(data  => { 
                    if (data)
                    { 
                        this.familyMembers = data;
                        this.applicant.familyMembers = this.familyMembers;
                        localStorage.setItem('applicant', JSON.stringify(this.applicant));
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getFamilyMembersAff(this.params)
                .subscribe(data  => { 
                    if (data)
                    { 
                        this.familyMembers = data;
                        this.applicant.familyMembers = this.familyMembers;
                        localStorage.setItem('applicant', JSON.stringify(this.applicant));
                    }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.familyMembers) this.familyMembers = [];
        this.spinnerService.hide();
    }

    scrollToAnchor(anchor:string){
        //setup scrolling
        this.viewportScroller.scrollToAnchor(anchor);
    }

    addMember(){

        let fm:FamilyMember = {
            hMy:this.applicant.generalInfo.hmy,
            memberHMy:0,
            firstName:"",
            lastName:"",
            gender:"",
            dob:"",
            ssn:"",
            relationship:"",
            disability:null,
            ethnicity:"",
            primaryLanguage:"",
            englishUnderstanding:null,
            raceAmericanIndian:0,
            raceAsian:0,
            raceBlack:0,
            racePacificIslander:0,
            raceWhite:0
        }

        this.familyMembers.push(fm);
        this.disableAdd = true;
        this.scrollToAnchor("newfm");
    }

    
    onCancel(fm:FamilyMember){
        const index = this.familyMembers.findIndex(f=> f.memberHMy === fm.memberHMy);
        if(fm.memberHMy == 0)
            this.familyMembers.splice(index, 1);

        this.applicant.familyMembers = this.familyMembers;
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.disableAdd = false;
        this.formInvalid = false;
    }

    onUpdate(fm:FamilyMember){
        const index = this.familyMembers.findIndex(f=> f.memberHMy === fm.memberHMy);
        if(fm.memberHMy == 0)
            fm.memberHMy = Math.max.apply(Math, this.familyMembers.map(function(o) { return o.memberHMy; })) + 1;

        this.familyMembers[index] = fm;
        this.applicant.familyMembers = this.familyMembers;
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.disableAdd = false;
        this.formInvalid = false;        
    }

    onDelete(fm:FamilyMember){
        const index = this.familyMembers.indexOf(fm, 0);
        if (index > -1) {
            this.familyMembers.splice(index, 1);
        }
        this.applicant.familyMembers = this.familyMembers;
        localStorage.setItem('applicant', JSON.stringify(this.applicant));
        this.disableAdd = false;
        this.formInvalid = false;        
    }

    back()
    {
        let fmedit:string = JSON.parse(localStorage.getItem('fmedit'));

        if ((this.disableAdd || fmedit == 'edit') && !this.readonly)
            this.formInvalid = true;
        else
        {
            this.setFieldValues();
            this.applicant.familyMembers = this.familyMembers;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/incomeRent']);
        }
    }

    next(){

        let fmedit:string = JSON.parse(localStorage.getItem('fmedit'));

        if ((this.disableAdd || fmedit == 'edit') && !this.readonly)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            this.setFieldValues();
            this.applicant.familyMembers = this.familyMembers;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));

            // let yardiDB:string = "";
            // if (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB)
            //     yardiDB = this.navigationParams.waitlistTypesModel.yardiDB;
            // else
            // {
            //     if ((this.navigationParams.cnvTCode && this.navigationParams.cnvTCode.length > 0) || (this.navigationParams.hcvTCode && this.navigationParams.hcvTCode.length > 0) 
            //     || this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            //         yardiDB = "";
            //     else
            //         yardiDB = "AFF";
            // }

            //route to veteranstatus if no disabled member
            //if (this.waitlistPortalService.checkForDisabledMember(this.applicant) && yardiDB != "AFF")
            if (this.waitlistPortalService.checkForDisabledMember(this.applicant))
            {
                if (!this.applicant.generalInfo.pCode || (this.applicant.generalInfo.pCode && this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() != 'P'))
                {
                    this.spinnerService.hide();
                    this.router.navigate(['/disabilityAccommodation']);
                }
                else
                {
                    this.spinnerService.hide();                
                    this.router.navigate(['/veteranStatus']);
                }
            }
            else
            {
                this.spinnerService.hide();                
                this.router.navigate(['/veteranStatus']);
            }
        }
    }

    setFieldValues(){
        this.familyMembers.forEach(f =>{
            f.englishUnderstanding = + f.englishUnderstanding;
            f.raceAmericanIndian = + f.raceAmericanIndian;
            f.raceAsian = + f.raceAsian;
            f.raceBlack = + f.raceBlack;
            f.racePacificIslander = + f.racePacificIslander;
            f.raceWhite = + f.raceWhite;
    
            if (f.englishUnderstanding == 1) f.primaryLanguage = "";
        })
    }

    validateFamilyMembers():boolean{

        this.familyMembers.forEach(f => {
            if (!f.ethnicity || f.ethnicity == null || f.ethnicity.length == 0)
                return false;            
        });
        return true
    }

}