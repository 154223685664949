import {Component, Input, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationParameters } from '../models/navigationParameters';
import { WaitlistService } from '../services/waitlist.service';
import { GeneralInfo } from '../models/generalInfo';
import { Parameters } from '../models/parameters';
import { PortalParameters } from '../models/portalParameters';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalLookups } from '../models/portalLookups';
import { ConfirmationMessageService } from '../services/confirm-message.service';
import { Router } from '@angular/router';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { Applicant } from '../models/applicant';
import { IncomeRent } from '../models/incomeRent';


@Component({
    selector: 'update-contact-info',
    templateUrl: './update-contact-info.component.html' ,
    styleUrls: ['./update-contact-info.component.scss']
})

export class UpdateContactInfoComponent implements OnInit{
    @Input() updateParam:string;
    applicant:Applicant = new Applicant();    
    generalInfo:GeneralInfo;
    incomeRent:IncomeRent = new IncomeRent;
    isIE:boolean = false;
    params:Parameters = new Parameters();
    portalParams:PortalParameters = new PortalParameters();
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    stateList:PortalLookups[] = [];
    cityList:PortalLookups[] = [];
    formInvalid:boolean = false;
    noChanges:boolean;
    maxAmount:number = 199999;

constructor(
    private spinnerService: NgxSpinnerService,
    private waitlistService: WaitlistService,
    private waitlistPortalService: WaitlistPortalService,
    private router: Router,
    private modalService: NgbModal,
    private confirmationService: ConfirmationMessageService
) {
    this.isMobile = window.innerWidth < 768;
}


    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.updateParam = (!this.updateParam || this.updateParam == '') ? "contact" : this.updateParam;
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.generalInfo = this.applicant.generalInfo;
        this.incomeRent = this.applicant.incomeRent;
        this.applicant.generalInfo.cellTel = (!this.applicant.generalInfo.cellTel || this.applicant.generalInfo?.cellTel == "") ? 
                        this.applicant.generalInfo.homeTel : this.applicant.generalInfo.cellTel;

        this.portalParams.category = "SacCity";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
            this.cityList = data;
        }, (error) => {
            this.spinnerService.hide();
        });

        this.portalParams.category = "states";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
            this.stateList = data;
        }, (error) => {
            this.spinnerService.hide();
        });

        this.spinnerService.hide();
   }
   

    updateContactInfo(f:any){
        if (f.invalid)
            this.formInvalid = true;
        else
        {
            let applicant1 = JSON.parse(localStorage.getItem('applicant'));
            this.spinnerService.show();
            if (applicant1.generalInfo.mailingAddress != this.applicant.generalInfo.mailingAddress || applicant1.generalInfo.apt != this.applicant.generalInfo.apt || 
                applicant1.generalInfo.city != this.applicant.generalInfo.city || applicant1.generalInfo.otherCity != this.applicant.generalInfo.otherCity || 
                applicant1.generalInfo.state != this.applicant.generalInfo.state || applicant1.generalInfo.zipCode != this.applicant.generalInfo.zipCode ||
                applicant1.generalInfo.email != this.applicant.generalInfo.email || applicant1.generalInfo.homeTel != this.applicant.generalInfo.homeTel || 
                applicant1.generalInfo.cellTel != this.applicant.generalInfo.cellTel)
            {
                let updateMsg: string = this.updateParam == "phone" ? "Phone number" : this.updateParam[0].toUpperCase() + this.updateParam.substring(1).toLocaleLowerCase();

                this.waitlistPortalService.updateAllApplicantsContactInfo(this.generalInfo).then(h=>{
                    this.applicant.generalInfo = this.generalInfo;
                    localStorage.setItem('applicant', JSON.stringify(this.applicant));
                    this.confirmationService.confirm('Update Info', updateMsg + ' updated successfully.', 'OK', true)
                    .then(result => {
                        this.spinnerService.hide();
                        this.modalService.dismissAll();
                        window.location.reload();
                    });
                });
            }

            else
            {
                this.confirmationService.confirm('Update Info', 'No changes reported.', 'OK', true)
                .then(result => {
                    this.spinnerService.hide();
                    this.modalService.dismissAll();
                    window.location.reload();
                });
            }
            this.spinnerService.hide();
        }
    }


    updateIncomeRent(f:any){
        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            let applicant1 = JSON.parse(localStorage.getItem('applicant'));
            if (applicant1.incomeRent.monthlyGross != this.applicant.incomeRent.monthlyGross)
            {
                let updateMsg: string = this.updateParam[0].toUpperCase() + this.updateParam.substring(1).toLocaleLowerCase();

                this.waitlistPortalService.updateAllApplicantsIncomeRent(this.incomeRent).then(h=>{
                    this.applicant.incomeRent = this.incomeRent;
                    localStorage.setItem('applicant', JSON.stringify(this.applicant));
                    this.confirmationService.confirm('Update Info', updateMsg + ' updated successfully.', 'OK', true)
                    .then(result => {
                        this.spinnerService.hide();
                        this.modalService.dismissAll();
                        window.location.reload();
                    });
                });
            }
            else
            {
                this.confirmationService.confirm('Update Info', 'No changes reported.', 'OK', true)
                .then(result => {
                    this.spinnerService.hide();
                    this.modalService.dismissAll();
                    window.location.reload();
                });
            }
            this.spinnerService.hide();
        }
    }



    toggleNochangesValue(){
        this.noChanges = !this.noChanges;
    }

    updateApplication(){
        this.spinnerService.show();
        if (this.noChanges)
        {
            this.params.applicantCode = this.applicant.generalInfo.pCode;
            this.params.hUser = this.applicant.generalInfo.firstName.substring(0,1) + this.applicant.generalInfo.lastName.replace(' ', '');
            this.params.chronoText = "Application updated by the applicant with no changes";
            this.waitlistPortalService.InsertChronoforAllApplicants(this.params).then(h => {
                this.confirmationService.confirm('Update Info','Application updated with no changes', 'OK', true)
                .then(result => {
                    this.spinnerService.hide();
                    this.modalService.dismissAll();
                    window.location.reload();
                });
            });
        }
        else
        {
            this.spinnerService.hide();
            this.navigationParams.resultMessage = "Hello.  Your application has been updated.";
            this.router.navigate(['/generalInfo']);                
            this.modalService.dismissAll();
        }
        this.spinnerService.hide();
    }

    cancel(){
        this.modalService.dismissAll();
    }

}