import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ApplicantForm } from '../models/applicantForm';
import { ApplicantProcess } from '../models/applicantProcess';
import { ApplicantStep } from '../models/applicantStep';
import { Field } from '../models/field';
import { FieldGroup } from '../models/fieldGroup';
import { FieldResponse } from '../models/fieldResponse';
import { PortalParameters } from '../models/portalParameters';
import { ProcessLookup } from '../models/processLookup';
import { Question } from '../models/question';
import { Rule } from '../models/rule';
import { Section } from '../models/section';


@Injectable()
export class ApplicantProcessesService{

    httpOptions = {
        headers: new HttpHeaders({
            'content-Type':'application/json',
            'Access-Control-Allow-Origin':'*',
            'Accept':'application/json'
        })
    
    };
    
    baseAPIUrl:string = environment.baseAPIUrl;

    constructor(private http:HttpClient) {
        //this.httpOptions.headers.append("Authorization", localStorage.getItem("jwtoken"));
    }

    sendSelectionInitialNotifications(){
        return this.http.post(this.baseAPIUrl + 'api/applicantprocess/SendSelectionInitialNotifications', null, this.httpOptions)
            .pipe(catchError(this.handleError('sendSelectionInitialNotifications', null)))
    }

    getApplicantProcesses(params:PortalParameters):Observable<ApplicantProcess[]> {
        return this.http.post<ApplicantProcess[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantProcesses', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantProcess[]>('getApplicantProcesses', [])));
    }

    getApplicantSteps(params:PortalParameters):Observable<ApplicantStep[]> {
        return this.http.post<ApplicantStep[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantSteps', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantStep[]>('getApplicantSteps', [])));
    }

    getApplicantStepsByApplicantProcess(params:PortalParameters):Observable<ApplicantStep[]> {
        return this.http.post<ApplicantStep[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantStepsByApplicantProcess', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantStep[]>('getApplicantStepsByApplicantProcess', [])));
    }

    getApplicantForms(params:PortalParameters):Observable<ApplicantForm[]> {
        return this.http.post<ApplicantForm[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantForms', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantForm[]>('getApplicantForms', [])));
    }

    getApplicantFormsByApplicantProcess(params:PortalParameters):Observable<ApplicantForm[]> {
        return this.http.post<ApplicantForm[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantFormsByApplicantProcess', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantForm[]>('getApplicantFormsByApplicantProcess', [])));
    }

    getApplicantFormsByApplicantStep(params:PortalParameters):Observable<ApplicantForm[]> {
        return this.http.post<ApplicantForm[]>(this.baseAPIUrl + 'api/applicantprocess/GetApplicantFormsByApplicantStep', params, this.httpOptions)
            .pipe(catchError(this.handleError<ApplicantForm[]>('getApplicantFormsByApplicantStep', [])));
    }

    getSectionsByForm(params:PortalParameters):Observable<Section[]> {
        return this.http.post<Section[]>(this.baseAPIUrl + 'api/applicantprocess/GetSectionsByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<Section[]>('getSectionsByForm', [])));
    }

    getQuestionsByForm(params:PortalParameters):Observable<Question[]> {
        return this.http.post<Question[]>(this.baseAPIUrl + 'api/applicantprocess/GetQuestionsByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<Question[]>('getQuestionsByForm', [])));
    }

    // getQuestionsBySection(params:PortalParameters):Observable<Question[]> {
    //     return this.http.post<Question[]>(this.baseAPIUrl + 'api/applicantprocess/GetQuestionsBySection', params, this.httpOptions)
    //         .pipe(catchError(this.handleError<Question[]>('getQuestionsBySection', [])));
    // }


    getFieldGroupsByForm(params:PortalParameters):Observable<FieldGroup[]> {
        return this.http.post<FieldGroup[]>(this.baseAPIUrl + 'api/applicantprocess/GetFieldGroupsByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<FieldGroup[]>('getFieldGroupsByForm', [])));
    }


    // getFieldGroupsByQuestion(params:PortalParameters):Observable<FieldGroup[]> {
    //     return this.http.post<FieldGroup[]>(this.baseAPIUrl + 'api/applicantprocess/GetFieldGroupsByQuestion', params, this.httpOptions)
    //         .pipe(catchError(this.handleError<FieldGroup[]>('getFieldGroupsByQuestion', [])));
    // }

    getFieldsByForm(params:PortalParameters):Observable<Field[]> {
        return this.http.post<Field[]>(this.baseAPIUrl + 'api/applicantprocess/GetFieldsByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<Field[]>('getFieldsByForm', [])));
    }

    // getFieldsByFieldGroup(params:PortalParameters):Observable<Field[]> {
    //     return this.http.post<Field[]>(this.baseAPIUrl + 'api/applicantprocess/GetFieldsByFieldGroup', params, this.httpOptions)
    //         .pipe(catchError(this.handleError<Field[]>('getFieldsByFieldGroup', [])));
    // }


    getProessLookupsByForm(params:PortalParameters):Observable<ProcessLookup[]> {
        return this.http.post<ProcessLookup[]>(this.baseAPIUrl + 'api/applicantprocess/GetProessLookupsByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<ProcessLookup[]>('getProessLookupsByForm', [])));
    }

    // getProessLookupsByField(params:PortalParameters):Observable<processLookup[]> {
    //     return this.http.post<processLookup[]>(this.baseAPIUrl + 'api/applicantprocess/GetProessLookupsByField', params, this.httpOptions)
    //         .pipe(catchError(this.handleError<processLookup[]>('getProessLookupsByField', [])));
    // }

    getRulesByForm(params:PortalParameters):Observable<Rule[]> {
        return this.http.post<Rule[]>(this.baseAPIUrl + 'api/applicantprocess/GetRulesByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<Rule[]>('getRulesByForm', [])));
    }

    // getRulesBySection(params:PortalParameters):Observable<Rule[]> {
    //     return this.http.post<Rule[]>(this.baseAPIUrl + 'api/applicantprocess/GetRulesBySection', params, this.httpOptions)
    //         .pipe(catchError(this.handleError<Rule[]>('getRulesBySection', [])));
    // }

    
    getResponsesByForm(params:PortalParameters):Observable<FieldResponse[]> {
        return this.http.post<FieldResponse[]>(this.baseAPIUrl + 'api/applicantprocess/GetResponsesByForm', params, this.httpOptions)
            .pipe(catchError(this.handleError<FieldResponse[]>('getResponsesByForm', [])));
    }

    getResponsesBySection(params:PortalParameters):Observable<FieldResponse[]> {
        return this.http.post<FieldResponse[]>(this.baseAPIUrl + 'api/applicantprocess/GetResponsesBySection', params, this.httpOptions)
            .pipe(catchError(this.handleError<FieldResponse[]>('getResponsesBySection', [])));
    }


    saveFieldResponses(fieldResponses:FieldResponse[]):Observable<string> {
        return this.http.post<string>(this.baseAPIUrl + 'api/applicantprocess/SaveFieldResponses', fieldResponses, this.httpOptions)
            .pipe(catchError(this.handleError<string>('saveFieldResponses', "")));
    }

    upload(file: File): Observable<HttpEvent<any>> {
        const formData: FormData = new FormData();
    
        formData.append('file', file);
    
        const req = new HttpRequest('POST', `${this.baseAPIUrl}/api/applicantprocess/upload`, formData, {
          reportProgress: true,
          responseType: 'json'
        });
    
        return this.http.request(req);
    }
    
    getFiles(): Observable<any> {
        return this.http.get(`${this.baseAPIUrl}/api/applicantprocess/files`);
    }

    private handleError<T> (operation = 'operation', result?:T){
        return (error:any): Observable<T> => {
            console.error(error);
            return of(result as T)
        }
    }

}