import {Component, Input, OnChanges, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { FAQ } from '../models/faq';
import { FAQForm } from '../models/faqForm';
import { PortalParameters } from '../models/portalParameters';
import { MessageService } from '../services/message.service';
import { WaitlistService } from '../services/waitlist.service';

@Component({
    selector: 'faq',
    templateUrl: './faq.component.html' ,
    styleUrls: ['./faq.component.scss']
})

export class FAQComponent implements OnChanges{
    @Input() item:string;
    @Input() itemType:string;
    @Input() itemTitle:string;
    @Input() showStyle:string;
    faqForm:FAQForm;
    questions:string[] =[];
    faqs:FAQ[] = [];
    params: PortalParameters = new PortalParameters();
    imagePath:string = "./assets/images/"   // "./assets/images/";

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService
        ){}


    ngOnChanges()
    {
        this.spinnerService.show();
        this.questions = [];

        this.params.item = this.item;
        this.params.itemType = this.itemType;
        this.params.category = this.itemTitle;
        this.waitlistService.getFAQFormByItem(this.params)
        .subscribe(d => {
            this.faqForm = d;
            this.imagePath += this.faqForm.title + "/";
            this.params.faqFormID = this.faqForm.faqFormID;
            this.waitlistService.getFormFAQs(this.params)
            .subscribe(data=>{
                this.faqs = data;
                this.faqs.forEach(q =>{
                     var index = this.questions.indexOf(q.question);
                    if (index < 0) 
                        this.questions.push(q.question);
                })
            }, (error) => {
                this.msg.showError(error, "FAQ Error")
            });
        }, (err) => {
            this.msg.showError(err, "FAQ Error");
        });  
        this.spinnerService.hide();          
    }

    toggleAccordian(event) {
        var element = event.target;
        element.classList.toggle("active");
        var panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }
}