import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FAQPanelComponent } from '../faq/faq-panel.component';


@Injectable()
export class FAQPanelService {

  constructor(private modalService: NgbModal) { }

  public show(
    selectedForm: string,
    dialogSize: 'sm'|'lg'|'xl'|'xxl' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(FAQPanelComponent, { size: dialogSize, centered: true, scrollable: true, windowClass: 'faq-panel' });
    modalRef.componentInstance.selectedForm = selectedForm;
    return modalRef.result;
  }

}
