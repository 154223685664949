import { animate, state, style, transition, trigger } from '@angular/animations';
import {Component, OnInit} from '@angular/core';
import { FAQPanelService } from '../services/faq-panel.service';

@Component({
    selector: 'faq-widget',
    templateUrl: './faq-widget.component.html' ,
    styleUrls: ['./faq-widget.component.scss'],
    animations: [
        trigger('expand', [
            state('open', style({
                height: 'auto',
                opacity:1
            })),
            state('close', style({
                height: '0px',
                opacity:0
            })),
            transition('open=>close', animate('0.2s ease-out')),
            transition('close=>open', animate('0.2s ease-in'))
        ])
    ]
})

export class FAQWidgetComponent implements OnInit{

    constructor (
        private faqPanelService: FAQPanelService
        ){}


    ngOnInit()
    {
   
    }

    showFAQ(){
        this.faqPanelService.show('', "xl");
    }

}