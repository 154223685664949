import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MinDate } from './min-date.validator';


@Directive({
    selector: '[minDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinDateDirective, multi: true }]
})
export class MinDateDirective implements Validator {
    @Input('minDate') minDate: Date;

    validate(control: UntypedFormControl): ValidationErrors {
        return MinDate(this.minDate)(control);
    }
}