<nav class="navbar navbar-expand-lg">
    <!-- <div class="p1 text-center">TEST WAITLIST PORTAL</div>   -->

    <div class="navbar  ml-5 mr-5" id="navbarToggle">
        <a class="nav-link mr-3" href="#"><img src="{{imgFolder}}SHRA logo_white.png" height="50px" width="132px" alt="" ></a>                
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbaritems1" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbaritems1">
            <ul class="navbar-nav mt-3">
                <li *ngIf="loggedIn && role == 'Applicant'" class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('applicantDashboard')"><p>My Dashboard<span *ngIf="!isMobile" class="ml-3">|</span></p></a> 
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('waitingList')"><p>Waiting Lists<span *ngIf="!isMobile" class="ml-3">|</span></p></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('referralProgram')"><p>Referral Programs<span *ngIf="!isMobile" class="ml-3">|</span></p></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('learnMore')"><p>Learn More<span *ngIf="!isMobile" class="ml-3">|</span></p></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('contact')"><p>Contact</p></a>
                </li>
            </ul>
        
            <ul class="navbar-nav ml-auto mt-3">
                <li class="nav-item dropdown" *ngIf="loggedIn">
                    <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <img src="{{imgFolder}}icon-my account-white.png" height="50px" width="50px" alt="" >
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <div class="dropdown-item" *ngIf="generalInfo && role == 'Applicant'">
                            <p2>{{userEmail}}<br/>{{generalInfo?.pCode}}<br/>{{generalInfo?.firstName}} {{generalInfo?.lastName}}</p2>
                        </div>
                        <!-- <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item" style="border-top: #00713c solid 0.05px; border-bottom: #00713c solid 0.05px;" (click)="routeTo('userChangePassword')" href="javascript:void(0)"><p2>Change Password</p2></a>
                        <!-- <div class="dropdown-divider"></div> -->
                        <a class="dropdown-item" href="javascript:void(0)" (click)="routeTo('userLogout')" href="#"><p2>Logout</p2></a>
                    </div>
                </li>
                <li *ngIf="!loggedIn" class="nav-item">
                    <a class="nav-link" href="javascript:void(0)" (click)="routeTo('userlogin')"><p>Sign In</p></a> 
                </li>
                <li *ngIf="!loggedIn" class="nav-item">
                    <button type="button" (click)="signUp()" class="btn btn-header btn-general">Sign Up</button>
                </li>
                <li>
                    <div class="ml-2" id="google_translate_element2" style="display: none;"></div>
                </li>
            </ul>
        </div>
    </div>
    <div class="navbar-nav ml-auto mt-3 mr-3 notranslate">
        <select id="languageSelector" (change)="changeGoogleTranslateLanguage($event)" [value]='selectedLanguage'>
            <option value="en">English</option>
            <option value="af">Afrikaans</option>
            <option value="sq">Albanian</option>
            <option value="am">Amharic</option>
            <option value="ar">Arabic</option>
            <option value="hy">Armenian</option>
            <option value="as">Assamese</option>
            <option value="ay">Aymara</option>
            <option value="az">Azerbaijani</option>
            <option value="bm">Bambara</option>
            <option value="eu">Basque</option>
            <option value="be">Belarusian</option>
            <option value="bn">Bengali</option>
            <option value="bho">Bhojpuri</option>
            <option value="bs">Bosnian</option>
            <option value="bg">Bulgarian</option>
            <option value="ca">Catalan</option>
            <option value="ceb">Cebuano</option>
            <option value="zh-CN">Chinese (Simplified)</option>
            <option value="zh-TW">Chinese (Traditional)</option>
            <option value="co">Corsican</option>
            <option value="hr">Croatian</option>
            <option value="cs">Czech</option>
            <option value="da">Danish</option>
            <option value="dv">Dhivehi</option>
            <option value="doi">Dogri</option>
            <option value="nl">Dutch</option>
            <option value="eo">Esperanto</option>
            <option value="et">Estonian</option>
            <option value="ee">Ewe</option>
            <option value="fil">Filipino (Tagalog)</option>
            <option value="fi">Finnish</option>
            <option value="fr">French</option>
            <option value="fy">Frisian</option>
            <option value="gl">Galician</option>
            <option value="ka">Georgian</option>
            <option value="de">German</option>
            <option value="el">Greek</option>
            <option value="gn">Guarani</option>
            <option value="gu">Gujarati</option>
            <option value="ht">Haitian Creole</option>
            <option value="ha">Hausa</option>
            <option value="haw">Hawaiian</option>
            <option value="he">Hebrew</option>
            <option value="hi">Hindi</option>
            <option value="hmn">Hmong</option>
            <option value="hu">Hungarian</option>
            <option value="is">Icelandic</option>
            <option value="ig">Igbo</option>
            <option value="ilo">Ilocano</option>
            <option value="id">Indonesian</option>
            <option value="ga">Irish</option>
            <option value="it">Italian</option>
            <option value="ja">Japanese</option>
            <option value="jv">Javanese</option>
            <option value="kn">Kannada</option>
            <option value="kk">Kazakh</option>
            <option value="km">Khmer</option>
            <option value="rw">Kinyarwanda</option>
            <option value="gom">Konkani</option>
            <option value="ko">Korean</option>
            <option value="kri">Krio</option>
            <option value="ku">Kurdish</option>
            <option value="ckb">Kurdish (Sorani)</option>
            <option value="ky">Kyrgyz</option>
            <option value="lo">Lao</option>
            <option value="la">Latin</option>
            <option value="lv">Latvian</option>
            <option value="ln">Lingala</option>
            <option value="lt">Lithuanian</option>
            <option value="lg">Luganda</option>
            <option value="lb">Luxembourgish</option>
            <option value="mk">Macedonian</option>
            <option value="mai">Maithili</option>
            <option value="mg">Malagasy</option>
            <option value="ms">Malay</option>
            <option value="ml">Malayalam</option>
            <option value="mt">Maltese</option>
            <option value="mi">Maori</option>
            <option value="mr">Marathi</option>
            <option value="mni-Mtei">Meiteilon (Manipuri)</option>
            <option value="lus">Mizo</option>
            <option value="mn">Mongolian</option>
            <option value="my">Myanmar (Burmese)</option>
            <option value="ne">Nepali</option>
            <option value="no">Norwegian</option>
            <option value="ny">Nyanja (Chichewa)</option>
            <option value="or">Odia (Oriya)</option>
            <option value="om">Oromo</option>
            <option value="ps">Pashto</option>
            <option value="fa">Persian</option>
            <option value="pl">Polish</option>
            <option value="pt">Portuguese</option>
            <option value="pa">Punjabi</option>
            <option value="qu">Quechua</option>
            <option value="ro">Romanian</option>
            <option value="ru">Russian</option>
            <option value="sm">Samoan</option>
            <option value="sa">Sanskrit</option>
            <option value="gd">Scots Gaelic</option>
            <option value="nso">Sepedi</option>
            <option value="sr">Serbian</option>
            <option value="st">Sesotho</option>
            <option value="sn">Shona</option>
            <option value="sd">Sindhi</option>
            <option value="si">Sinhala (Sinhalese)</option>
            <option value="sk">Slovak</option>
            <option value="sl">Slovenian</option>
            <option value="so">Somali</option>
            <option value="es">Spanish</option>
            <option value="su">Sundanese</option>
            <option value="sw">Swahili</option>
            <option value="sv">Swedish</option>
            <option value="tl">Tagalog (Filipino)</option>
            <option value="tg">Tajik</option>
            <option value="ta">Tamil</option>
            <option value="tt">Tatar</option>
            <option value="te">Telugu</option>
            <option value="th">Thai</option>
            <option value="ti">Tigrinya</option>
            <option value="ts">Tsonga</option>
            <option value="tr">Turkish</option>
            <option value="tk">Turkmen</option>
            <option value="ak">Twi (Akan)</option>
            <option value="uk">Ukrainian</option>
            <option value="ur">Urdu</option>
            <option value="ug">Uyghur</option>
            <option value="uz">Uzbek</option>
            <option value="vi">Vietnamese</option>
            <option value="cy">Welsh</option>
            <option value="xh">Xhosa</option>
            <option value="yi">Yiddish</option>
            <option value="yo">Yoruba</option>
            <option value="zu">Zulu</option>
        </select>
    </div>
    <!-- <span *ngIf="selectedLanguage != 'en'" class="ml-1 notranslate"><a class="lnk-text" href="javascript:void(0)" (click)="backToEnglish()">English</a></span> -->
</nav>
<!-- <div class="bg-shralightorange page-subtitle1 text-white text-center mb-2 row" style="padding: 0.5%; margin: 0%;">
    <div *ngIf="!isMobile">
        <div class="col-xs-05" style="font-size: xx-large;">
            <i class="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div class="col-xs-115">
            <div class="page-text text-white">
                We are currently experiencing technical difficulties that are causing this portal to run slower than usual. Our team is actively working to resolve the issue and restore normal service as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience and understanding.
            </div>
        </div>
    </div>
    <div *ngIf="isMobile">
        <div style="font-size: xx-large;">
            <i class="bi bi-exclamation-triangle-fill"></i>
        </div>
        <div>
            <div class="page-text text-white ml-5 mr-5">
                We are currently experiencing technical difficulties that are causing this portal to run slower than usual. Our team is actively working to resolve the issue and restore normal service as quickly as possible. We apologize for any inconvenience this may cause and appreciate your patience and understanding.
            </div>
        </div>
    </div>
 </div> -->