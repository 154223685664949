import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';

@Component ({
    selector: 'result',
    templateUrl: './result.component.html',
    styleUrls: ['./result.component.scss']
})

export class ResultComponent implements OnInit{
    resultMessage:string = "";
    returnLocation:string = "";
    readonly:boolean = false;
    applicant:Applicant;
    affApplicant:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    backButtonCaption:string = "";
    
    constructor (
        private router:Router){
    }

    ngOnInit(){
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.navigationParams.formMode = "";
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));

        if (this.navigationParams.affPCode && this.navigationParams.affPCode.length > 0) this.affApplicant = true;

        if (this.applicant && this.applicant.generalInfo)
            if (this.applicant.generalInfo.tenantStatus?.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus?.toUpperCase() == 'NOTICE')
                this.readonly = true;

        this.resultMessage = this.navigationParams.resultMessage;

        //format color for result message
        if (this.navigationParams.resultMessage?.toUpperCase().includes('NOT BEEN ADDED'))
            this.resultMessage = "<div class='text-danger'>" + this.resultMessage + "</div>";
        else if (this.navigationParams.resultMessage?.toUpperCase().includes('RESIDENT PORTAL'))
            this.resultMessage = "<div class='text-warning'>" + this.resultMessage + "</div>";
        else
            this.resultMessage = "<div class='text-green'>" + this.resultMessage + "</div>";


        this.returnLocation = this.navigationParams.returnLocation;
        
        if (this.returnLocation=='waitlistType' || this.returnLocation=='waitlistTypeNew' || this.returnLocation=='waitingList') 
            this.backButtonCaption = "Go Back to Waiting Lists";
        else if (this.returnLocation=='applicantDashboard') 
            this.backButtonCaption = "Go Back to Applicant Dashboard";
        else
            this.returnLocation = "";

    }

    goToDashboard()
    {
        this.router.navigate(['/home']);
    }

    viewMyApplication(){
        this.navigationParams.formMode = "hcvview";
        this.navigationParams.updateAction = "";
        this.navigationParams.resultMessage = "";
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/generalInfo']);
    }

    updateMyAFFApplication(){
        this.navigationParams.formMode = "affupdate";
        this.navigationParams.updateAction = "";
        this.navigationParams.resultMessage = "";
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/generalInfo']);
    }

    goBack(){
        let returnLocation:string = this.returnLocation == "" ? "/home" : "/" + this.returnLocation;
        this.router.navigate([returnLocation]);
    }

}