import {Component, HostListener, OnInit} from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from "../helpers/pendingChanges.guard";
import { Applicant } from '../models/applicant';
import { DisabilityAccommodation } from "../models/disabilityAccommodation";
import { DisplacementStatus } from '../models/displacementStatus';
import { HomelessStatus } from "../models/homelessStatus";
import { LeaseInPlaceStatus } from "../models/leaseInPlaceStatus";
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";
import { MessageService } from '../services/message.service';
import { WaitlistPortalService } from "../services/waitlist-portal.service";

@Component ({
    selector : 'displacemen-tstatus',
    templateUrl : './displacement-status.component.html',
    styleUrls: ['./displacement-status.component.scss']
})

export class DisplacementStatusComponent implements OnInit, routingAway{
    applicant: Applicant;
    params:Parameters = new Parameters();
    displacementStatus:DisplacementStatus = new DisplacementStatus();
    readonly:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters(); 
    yardiDB:string = "";
    homelessStatus:boolean = false;
    projectBasedVoucher: boolean = false;
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.displacementStatus = this.applicant.displacementStatus;
        this.params.hmy = this.applicant.generalInfo.hmy;
        
        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        if (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB)
            this.yardiDB = this.navigationParams.waitlistTypesModel.yardiDB;

        if (this.applicant.homelessStatus && (this.applicant.homelessStatus.homelessPref == 1 || this.applicant.homelessStatus.homelessPref2 == 1 || 
            this.applicant.homelessStatus.homelessPref3 == 1 || this.applicant.homelessStatus.homelessPref4 == 1))
            this.homelessStatus = true;

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.navigationParams.formMode.toUpperCase() == 'AFFUPDATE') this.yardiDB = 'AFF';

            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || this.yardiDB == 'AFF')
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        if ((this.navigationParams.updateAction && this.navigationParams.updateAction.toUpperCase() == "APPLY")
            && (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.name.toLocaleUpperCase() == "PROJECT-BASED"))
                this.projectBasedVoucher = true;

        if ((!this.displacementStatus && this.applicant.generalInfo.pCode) || (this.displacementStatus && this.displacementStatus.hmy != this.applicant.generalInfo.hmy))
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getDisplacementStatusCNV(this.params)
                .subscribe(data  => { 
                        this.displacementStatus = data;
                        if (!this.displacementStatus){
                            this.displacementStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                mortPrePayment : null,
                                mortInsContractVolTerm : null,
                                lackFundHousingTerm : null,
                                phProjectDemolition : null,
                                disasterDamage : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getDisplacementStatusHCV(this.params)
                .subscribe(data  => { 
                        this.displacementStatus = data;
                        if (!this.displacementStatus){
                            this.displacementStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                mortPrePayment : null,
                                mortInsContractVolTerm : null,
                                lackFundHousingTerm : null,
                                phProjectDemolition : null,
                                disasterDamage : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");

                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getDisplacementStatusAff(this.params)
                .subscribe(data  => { 
                        this.displacementStatus = data;
                        if (!this.displacementStatus){
                            this.displacementStatus = {
                                hmy:this.applicant.generalInfo.hmy,
                                mortPrePayment : null,
                                mortInsContractVolTerm : null,
                                lackFundHousingTerm : null,
                                phProjectDemolition : null,
                                disasterDamage : null
                            }
                        }
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");

                });
            }
        }
        if (!this.displacementStatus) this.displacementStatus = new DisplacementStatus();
        this.spinnerService.hide();
    }

    back(f:any)
    {
        // if (this.readonly || (f.valid && !this.readonly))
        // {
            this.setFieldValues();
            this.applicant.displacementStatus = this.displacementStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));

            //bv- commenting the lines below to show homeless always
            //ticket #37548
            // if (this.applicant.incomeRent.renting)
            //     this.router.navigate(['/veteranStatus']);
            // else
                this.router.navigate(['/homelessStatus']);
        // }
    }

    next(f:any)
    {
        if (this.readonly || (f.valid && !this.readonly))
        {
            this.setFieldValues();
            this.applicant.displacementStatus = this.displacementStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/leaseInPlaceStatus']);
        }
    }

    submit(f:any){
        
        if (this.readonly || (f.valid && !this.readonly))
        {
            this.spinnerService.show();
            //this.setFieldValues();
            if (!this.applicant.disabilityAccommodation) 
                this.applicant.disabilityAccommodation = new DisabilityAccommodation();

            if (!this.applicant.homelessStatus) 
                this.applicant.homelessStatus = new HomelessStatus();

            if (!this.applicant.leaseInPlaceStatus) 
                this.applicant.leaseInPlaceStatus = new LeaseInPlaceStatus();

            this.applicant.displacementStatus = this.displacementStatus;                
            
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            let resultMsg:string = "";

            if (this.navigationParams.updateAction && this.navigationParams.updateAction.toUpperCase() == "APPLY")
            {
                //check if the applicant qualifies - age and BRSize
                //let waitlistProgram:string = JSON.parse(localStorage.getItem('waitlistProgram'));
                //let waitlist:string = JSON.parse(localStorage.getItem('waitlist'));
                if (!this.readonly)
                {
                    if (this.navigationParams.waitlist.toLowerCase().includes('elderly'))
                    {
                        var ageEligible = this.waitlistPortalService.checkForElderlyMember(this.applicant);
                        if (!ageEligible) resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the age requirement for this Elderly Only property";
                    }

                    //available BR size for the selected waitlist
                    let availableBR:string = this.navigationParams.availableBR + ",";
                    let roomCount:number;
                    if (this.navigationParams.waitlistProgram == 'Housing Choice Voucher')
                        roomCount = this.waitlistPortalService.calculateBRSizeHCV(this.applicant);
                    
                    if (this.navigationParams.waitlistProgram == 'Public Housing' || this.navigationParams.waitlistProgram == 'Affordable Housing')                        
                        roomCount = this.waitlistPortalService.calculateBRSizeCNV(this.applicant);

                    // if (this.navigationParams.waitlistProgram == 'Affordable Housing')                        
                    //     roomCount = this.waitlistPortalService.calculateBRSizeCNV(this.applicant);

                    let brEligible:boolean = false;
                    if (availableBR == "0," || availableBR.includes(roomCount.toString() + ","))                        
                            brEligible = true;

                    if (availableBR.includes('SRO') && roomCount < 2)
                            brEligible = true;


                    if (!brEligible) 
                        // resultMessage = "Hello. You have NOT been added to " + waitlist + " waiting list. " + 
                        //                 "Your household does not meet the bedroom size requirement for available bedrooms. " + 
                        //                 "Your household is eligible for " + roomCount + " bedroom, " +
                        //                 " but only " + availableBR.substring(0, availableBR.length - 2) + " bedrooms are available.";
                        resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the bedroom size requirement for available bedrooms";                                        

                }

                localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                //apply if eligible
                if (resultMsg == "") 
                    this.waitlistPortalService.applyToWaitlist(true);
                else
                {
                    this.navigationParams.resultMessage = resultMsg;
                    localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                    this.waitlistPortalService.applyToWaitlist(false);                    
                }
    

                localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));

                setTimeout(() =>{
                    if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                        this.waitlistPortalService.updateAFFApplicantsOnly(this.applicant);
                    else
                        this.waitlistPortalService.updateAllApplicants(this.applicant);
                }, 10000);
                
                this.navigationParams.updateAction = "";
                localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
            }
            else
            {
                if (this.navigationParams.formMode.toUpperCase() == 'AFFUPDATE')
                {
                    this.navigationParams.resultMessage = 'Hello! Your Affordable Housing application has been updated. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                    localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
                    this.waitlistPortalService.updateAFFApplicantsOnly(this.applicant);
                }
                else
                    this.waitlistPortalService.updateAllApplicants(this.applicant);
            }


            setTimeout(() =>{
                this.spinnerService.hide();
                this.router.navigate(['/result']);
            }, 3000);
        }
    }

    setFieldValues(){
        this.displacementStatus.disasterDamage = + this.displacementStatus.disasterDamage;
        this.displacementStatus.lackFundHousingTerm = + this.displacementStatus.lackFundHousingTerm;
        this.displacementStatus.mortInsContractVolTerm = + this.displacementStatus.mortInsContractVolTerm;
        this.displacementStatus.mortPrePayment = + this.displacementStatus.mortPrePayment;
        this.displacementStatus.phProjectDemolition = + this.displacementStatus.phProjectDemolition;
    }
}