import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from "../models/parameters";
import { MessageService } from "../services/message.service";
import { PopupMessageService } from "../services/popup.message.service";
import { WaitlistPortalService } from "../services/waitlist-portal.service";

@Component ({
    selector : 'learn-more',
    templateUrl : './learn-more.component.html',
    styleUrls: ['./learn-more.component.scss']
})

export class LearnMoreComponent implements OnInit{
    params:Parameters = new Parameters();
    title:string = "";
    showVideo:boolean = false;

    yardiIncomeLimitsCode: string = "vli";
    yardiIncomeLimitsDB: string = "HCV";
    isMobile:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    imageScaleNormal:boolean = true;
    imgFolder: string = environment.imgFolder;
    langCode: string = "en";

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService,
        private popupMessageService:PopupMessageService,
        private router:Router)
        {
            this.isMobile = window.innerWidth < 768;
            waitlistPortalService.getselectedLanguage.subscribe(l => this.langCode = l);
    }

    ngOnInit(){
        this.title = 'Learn More';
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.langCode || this.langCode == '' || this.langCode == 'en')
            this.langCode = JSON.parse(localStorage.getItem('langCode'));

    }

    showHideVideo(){
        this.showVideo = !this.showVideo;
    }


    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }

    
    expand(e:Event){
        var elem = e.target as HTMLElement;

        if (this.imageScaleNormal)
        {
            elem.style.transform = "scale(2)";
            elem.style.backgroundColor = "white";
            elem.classList.remove('img-expand');
            elem.classList.add('img-shrink');
        }
        else
        {
            elem.style.transform = "scale(1)";
            elem.style.backgroundColor = "transparent";
            elem.classList.remove('img-shrink');            
            elem.classList.add('img-expand');
        }

        elem.style.transition = "transform 0.25s ease";
        this.imageScaleNormal = !this.imageScaleNormal;
    }

    openInANewTab(e:Event){
        var elem = e.target as HTMLImageElement;
        window.open(elem.src);
    }

    getImagePath(imgName: string): string {

        let imgPath: string = this.imgFolder + "en/" + imgName;

        if (this.langCode == "es" || this.langCode == "hmn" || this.langCode == "ru" || this.langCode == "vi" || this.langCode == "zh-CN" || this.langCode == "zh-TW")
            imgPath = this.imgFolder + this.langCode + "/" + imgName;

        return imgPath;
    }

    selectIncomeLimitCode(e:any){
        
        let code = e.target.value;
        if (code == "HCV")
        {
            this.yardiIncomeLimitsCode = "vli";
            this.yardiIncomeLimitsDB = "HCV"
        }
        if (code == "CNV")
        {
            this.yardiIncomeLimitsCode = "li";
            this.yardiIncomeLimitsDB = "CNV"
        }
        if (code == "AFF")
        {
            this.yardiIncomeLimitsCode = "59vli";
            this.yardiIncomeLimitsDB = "CNV"
        }
    }
}