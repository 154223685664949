<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="container fst-italic text-left">
            <div *ngIf="resetStatus">
                <div *ngIf="resetStatus == 'success'" class="page-text">
                    Password changed successfully. Go to <a routerLink="/home">dashboard</a>
                </div>
                <div *ngIf="resetStatus == 'failed'" class="alert alert-danger">
                    Password change failed. Try again.
                </div>
            </div>
            <br/>
            <div *ngIf="resetStatus != 'success'" class="form-group">
                
                <div *ngIf="currentPassword.invalid && (currentPassword.dirty || formInvalid)" class="errormsg">Current password is required</div>
                <input [type]="showCurrentPassword ? 'text' : 'password'" [(ngModel)]="currentPassword" name="currentPassword" id="currentPassword" placeholder="Current Password" required /> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showCurrentPassword, 'fa-eye': showCurrentPassword}" (click)="toggleShowCurrentPassword()"></i>

                <div *ngIf="password.invalid && (password.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.pattern">Password should follow the pattern below</div>                    
                </div>
                <small class="info-field-label-sm" id="passwordHelp">Min. 8 characters with at least one upper case, one lower case, one number and one special character</small>
                <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="user.password" name="password" id="password" #password="ngModel" placeholder="Password" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$" /> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="toggleShowPassword()"></i>

                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords do not match</div>
                </div>
                <input [type]="showConfirmPassword ? 'text' : 'password'" [(ngModel)]="user.confirmPassword" name="confirmPassword" id="confirmPassword" #confirmPassword="ngModel" placeholder="Re-enter Password" required [mustMatch]="['password', 'confirmPassword']"/> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showConfirmPassword, 'fa-eye': showConfirmPassword}" (click)="toggleShowConfirmPassword()"></i>

                <div class="form-group text-right">
                    <button class="btn btn-general" type="submit" (click)="changePassword(f.form)">Change Password</button>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>