import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { RouterModule } from '@angular/router';
import { appRoutes } from './routes';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { FormsModule } from '@angular/forms';
import { DpDatePickerModule } from 'ng2-date-picker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { JwtModule } from '@auth0/angular-jwt';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { ApplicantAppComponent } from './applicant-app.component';

import { HeaderComponent } from './nav/header.component';
import { FooterComponent } from './nav/footer.component';

import { HomeComponent } from './home/home.component';
// import { DashboardComponent } from './dashboard_TBD/dashboard.component';
import { AdminFunctionsComponent } from './admin-functions/admin-functions.component';
import { AnnouncementComponent } from './announcement/announcement.component';
import { ContactComponent } from './contact/contact.component';
// import { ContactFormComponent } from './contact-form_TBD/contact-form.component';
import { PaperApplicationRequestComponent } from './paper-application-request/paper-application-request.component';
// import { PaperApplicationFaqComponent } from './paper-application-faq_TBD/paper-application-faq.component';
import { LearnMoreComponent } from './learn-more/learn-more.component';
import { ApplicantDashboardComponent } from './applicant-dashboard/applicant-dashboard.component';
// import { AdditionalResourcesComponent } from './additional-resources_TBD/additional-resources.component';
import { FAQComponent } from './faq/faq.component';

import { FormComponent } from './form/form.component';
import { SectionComponent } from './section/section.component';
import { FieldGroupComponent } from './field-group/field-group.component';
import { SingatureFieldGroupComponent } from './field-group/signature-field-group.component';
import { FileUploadFieldGroupComponent } from './field-group/file-upload-field-group.component';

import { LoginMainComponent } from './login/login-main.component';
import { LoginComponent} from './login/login/login.component';
import { RegistrationComponent } from './login/registration/registration.component';
import { RegistrationNewComponent } from './login/registration-new/registration-new.component';
import { RegistrationConfirmationComponent } from './login/registration-confirmation/registration-confirmation.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { PasswordResetRequestComponent } from './login/password-reset-request/password-reset-request.component';
import { PasswordResetComponent } from './login/password-reset/password-reset.component';

import { PropertyComponent } from './property/property.component';
import { WaitingListComponent } from './waiting-list/waiting-list.component';
import { ReferralProgramComponent } from './referral-program/referral-program.component';
import { WaitlistProgramComponent } from './waitlist-program/waitlist-program.component';
import { WaitlistTypeComponent } from './waitlist-type/waitlist-type.component';
import { WaitlistsComponent } from './waitlists/waitlists.component';
import { WaitlistPreferenceComponent } from './waitlist-preference/waitlist-preference.component';
import { WaitlistIncomeLimitComponent } from './waitlist-income-limit/waitlist-income-limit.component';

import { BasicInfoComponent } from './basic-info/basic-info.component';
import { GeneralInfoComponent } from './general-info/general-info.component';
import { RaceEthnicityComponent } from './race-ethnicity/race-ethnicity.component';
import { IncomeRentComponent } from './income-rent/income-rent.component';
import { DisabilityAccomComponent } from './disability-accom/disability-accom.component';
import { FamilyMemberComponent } from './family-member/family-member.component';
import { FamilyMembersComponent } from './family-members/family-members.component';
import { DisplacementStatusComponent } from './displacement-status/displacement-status.component';
import { HomelessStatusComponent } from './homeless-status/homeless-status.component';
import { VeteranStatusComponent } from './veteran-status/veteran-status.component';
import { LIPStatusComponent } from './lease-in-place-status/lease-in-place-status.component';
import { ResultComponent } from './result/result.component';

import { ApplicantService } from './services/applicant.service';
import { MessageService } from './services/message.service';
import { WaitlistService } from './services/waitlist.service';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { WaitlistPortalService } from './services/waitlist-portal.service';
import { PortalUtilitiesService } from './services/portal-utilities.service';
import { AdminFunctionsService } from './services/admin-functions.service';
import { ApplicantProcessesService } from './services/applicant-processes.service';

import { MustMatchDirective } from './directives/must-match.directive';
import { OrderByPipe } from './helpers/orderBy.pipe';
import { InvalidDateDirective } from './directives/invalid-date.directive';
import { lnbrPipe } from './helpers/lnbr.pipe';
import { MaxDateDirective } from './directives/max-date.directive';
import { MinDateDirective } from './directives/min-date.directive';
import { MaxNumberDirective } from './directives/max-number.directive';
import { MinNumberDirective } from './directives/min-number.directive';
import { SafePipe } from './helpers/safe.pipe';
import { TrimPipe } from './helpers/trim.pipe';
import { MyTooltipDirective } from './directives/my-tooltip.directive';
import { MyTooltipTemplateDirective } from './directives/my-tooltip-template.directive';
import { AutoFocusDirective } from './directives/auto-focus.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationMessageService } from './services/confirm-message.service';
import { FAQPanelComponent } from './faq/faq-panel.component';
import { FAQPanelService } from './services/faq-panel.service';
import { FAQWidgetComponent } from './faq/faq-widget.component';
import { UpdateContactInfoComponent } from './applicant-dashboard/update-contact-info.component';
import { HCVProgramsCompareComponent } from './learn-more/hcv-programs-compare.component';
import { NgxCurrencyDirective } from "ngx-currency";
import { PopupMessageComponent } from './popup-message/popup-message.component';
import { PopupMessageService } from './services/popup.message.service';
import { BannerComponent } from './banner/banner.component';
import { ApplicationPendingChangesService } from './services/application-pending-changes.service';
import { ApplicantProcessComponent } from './applicant-process/applicant-process.component';

declare var require: any;

export function tokenGetter(){
  return JSON.parse(localStorage.getItem("jwtoken"));
}

@NgModule({
  declarations: [
    ApplicantAppComponent,
    LoginComponent,
    HeaderComponent,
    DisabilityAccomComponent,
    DisplacementStatusComponent,
    FamilyMemberComponent,
    GeneralInfoComponent,
    RaceEthnicityComponent,
    HomelessStatusComponent,
    IncomeRentComponent,
    LIPStatusComponent,
    ResultComponent,
    VeteranStatusComponent,
    // DashboardComponent,
    WaitlistsComponent,
    FamilyMembersComponent,
    FooterComponent,
    WaitlistProgramComponent,
    WaitlistTypeComponent,
    RegistrationComponent,
    RegistrationNewComponent,
    PasswordResetComponent,
    WaitlistIncomeLimitComponent,
    FAQComponent,
    WaitlistPreferenceComponent,
    RegistrationConfirmationComponent,
    ChangePasswordComponent,
    PasswordResetRequestComponent,
    MustMatchDirective,
    OrderByPipe,
    InvalidDateDirective,
    BasicInfoComponent,
    AdminFunctionsComponent,
    lnbrPipe,
    MaxDateDirective,
    MinDateDirective,
    MaxNumberDirective,
    MinNumberDirective,
    AnnouncementComponent,
    // ContactFormComponent,
    PaperApplicationRequestComponent,
    // PaperApplicationFaqComponent,
    FieldGroupComponent,
    SingatureFieldGroupComponent,
    FileUploadFieldGroupComponent,
    SectionComponent,
    LearnMoreComponent,
    ApplicantDashboardComponent,
    PropertyComponent,
    WaitingListComponent,
    ReferralProgramComponent,
    // AdditionalResourcesComponent,
    FormComponent,
    HomeComponent,
    ContactComponent,
    LoginMainComponent,
    SafePipe,
    TrimPipe,
    MyTooltipDirective,
    MyTooltipTemplateDirective,
    AutoFocusDirective,
    ConfirmationDialogComponent,
    FAQPanelComponent,
    FAQWidgetComponent,
    UpdateContactInfoComponent,
    HCVProgramsCompareComponent,
    PopupMessageComponent,
    BannerComponent,
    ApplicantProcessComponent
  ],
  imports: [
    BrowserModule,
     RouterModule.forRoot(appRoutes, {
    // useHash: true,
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled'
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    HttpClientModule,
    FormsModule,
    DpDatePickerModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    JwtModule.forRoot({
        config:{ 
            tokenGetter:tokenGetter,
            allowedDomains: ["http://shraweb2:8001/", "http://shraweb:8001/", "http://localhost:55546/"],
            disallowedRoutes: []
        }
    }),
    NgbModule,
    NgxExtendedPdfViewerModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxCurrencyDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    ApplicantService, 
    MessageService, 
    WaitlistService, 
    AuthService, 
    UserService,
    WaitlistPortalService,
    {
    provide: HTTP_INTERCEPTORS,
    useClass: JwtInterceptor,
    multi: true
    },
    PortalUtilitiesService,
    AdminFunctionsService,
    ApplicantProcessesService,
    ConfirmationMessageService,
    FAQPanelService,
    PopupMessageService,
    ApplicationPendingChangesService
  ],
  bootstrap: [ApplicantAppComponent]
})
export class AppModule { }
