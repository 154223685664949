<div class="modal-content">
    <div class="modal-header bg-shradarkgreen">
        <div class="page-subtitle1 text-white text-center">{{title}}</div>
        <button type="button" class="close" aria-label="Close" (click)="closeClick()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container page-text text-center p-3">{{content}}</div>
    </div>
</div>
