import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lnbr'
})

export class lnbrPipe implements PipeTransform {

    transform(value: string): string {
        return value.replace(/\n/g, '<br/>');
    }

}