<body>
    <div class="wrapper justify-content-center align-items-center" *ngIf="waitlistExtended">
        <div class="container bg-shradarkgreen justify-content-center mt-5">
            <div class="row">
                <div [ngClass]="!isMobile ? 'page-title text-white text-left mt-5 ml-5 mb-2' : 'page-title text-white text-center mt-5 mb-2'">{{waitlist.name | titlecase}}
                    <div class="page-subtitle2 text-white">{{waitlistExtended.waitlistProgram}}<span *ngIf="waitlistExtended.waitlist.showWaitlistType"> - {{waitlistExtended.waitlistType}}</span></div>
                </div>
            </div>
            <div class="row">
                <div [ngClass]="!isMobile ? 'column1 page-text text-white text-left mb-5 ml-5' : 'page-text text-white mb-5 text-center'">
                    {{waitlist.location}}
                </div>
            </div>
            <div class="row">
                <div [ngClass]="!isMobile ? 'column1 text-white mb-2 ml-5 mr-5' : 'text-white text-center mb-2'">
                    <div class="page-subtitle text-white mb-2">Overview</div>
                    <div [innerHTML] = "waitlist.description" class="mb-5"></div>
                    <div *ngIf="waitlist.name == 'Housing Choice Voucher'" class="mb-5">
                        <div class="text-center">
                            <table class="table-responsive mb-3">
                               <thead class="">
                                  <tr>
                                     <th class="col-1 table-content bdr-none"></th>
                                     <th class="col-2 table-content text-white page text bdr-header bg-shralightgreen page">Tenant-Based Vouchers</th>
                                  </tr>
                               </thead>
                               <tbody class="bg-light text-dark">
                                  <tr class="text-small">
                                     <td class="table-content bdr-bottom">Where will I live?</td>
                                     <td class="table-content bdr-left-bottom text-left">It's your choice! You find and choose your rental unit anywhere in Sacramento County. It is the tenant's responsibility to find a home compatible with their family's needs, including any accessibility needs.</td>
                                  </tr>
                                  <tr class="text-small">
                                     <td class="table-content bdr-bottom">Can I move?</td>
                                     <td class="table-content bdr-left-bottom text-left">Yes, after living at the unit for the initial lease period (typically a year), you can use your housing voucher at another rental unit of your choice. Moves require SHRA approval before moving.</td>
                                  </tr>
                                  <tr class="text-small">
                                     <td class="table-content bdr-bottom">How much will I pay in rent? </td>
                                     <td class="table-content bdr-left-bottom text-left">During the first year, voucher holders pay 30-40% of their monthly income towards rent and utilities.</td>
                                  </tr>
                                  <tr class="text-small">
                                     <td class="table-content bdr-bottom">Does SHRA have to approve the unit?</td>
                                     <td class="table-content bdr-left-bottom text-left">Yes, there are several checks SHRA must complete before inspecting the units and all rental units must pass inspection before the landlord can begin to receive the subsidy.</td>
                                  </tr>
                                  <tr>
                                     <td class=""></td>
                                     <td class="border-left">
                                        <img src="{{imgFolder}}tenant-based vouchers-01.png" alt="" width="80%" height="80%">
                                     </td>
                                  </tr>
                               </tbody>
                            </table>
                        </div>
                    </div>
                    <div *ngIf="waitlistExtended.waitlist.shraRequirements" class="mb-5">
                        <div class="page-subtitle text-white mb-2">Eligibility Requirements</div>
                        <div [innerHTML]="waitlistExtended.waitlist.shraRequirements"></div>
                    </div>
                    <div *ngIf="!waitlistExtended.waitlist.name.includes('Referral') && waitlistExtended.preferences && waitlistExtended.preferences.length > 0" class="mb-5">
                        <div *ngIf='waitlistExtended.waitlistTypeCategory?.includes("Waiting Lists")'>
                            <div class="page-subtitle text-white mb-2">Preferences</div>
                            {{waitlistExtended.preferences}}
                            <span class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('Preferences')"></i></span>
                        </div>
                        <div *ngIf='!waitlistExtended.waitlistTypeCategory?.includes("Waiting Lists")'>
                            <div class="page-subtitle text-white mb-2">Site Requirements</div>
                            {{waitlistExtended.preferences}}
                            <span class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('site requirements')"></i></span>
                        </div>
                    </div>
                    <div *ngIf='!waitlistExtended.waitlistTypeCategory?.includes("Waiting Lists")'>
                        <div *ngIf="waitlistExtended.shraRequirements && waitlistExtended.shraRequirements.length > 0" class="mb-5">
                            <div class="page-subtitle text-white mb-2">SHRA Requirements</div>                        
                            <div>{{waitlistExtended.shraRequirements}}
                                <span class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="infoClick('Preferences')"></i></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='!waitlistExtended.waitlist.waitlistSubType?.includes("Tenant-Based")'>
                        <div *ngIf="communityResourceLinks && communityResourceLinks.length > 0">
                            <div class="page-subtitle text-white mb-2">Community Resources</div>                        
                            <div *ngFor="let c of communityResourceLinks">
                                <div *ngIf="c.description!='How to Apply'"><a href="{{c.linkAddress}}" target="blank" class="link-light mb-2" [innerHTML] = "c.resourceName"></a></div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="column2"  style="border-left: 2px white solid"></div> -->
                <div class="vr p-0"></div>
                <div [ngClass]="!isMobile ? 'column3 text-white ml-5 mr-5' : 'text-white text-center'">
                    <div *ngIf='waitlistExtended.waitlist.waitlistSubType?.includes("Tenant-Based")' class="mb-5">
                        <div *ngIf="communityResourceLinks && communityResourceLinks.length > 0">
                            <div class="page-subtitle text-white mb-2">Community Resources</div>                        
                            <div *ngFor="let c of communityResourceLinks">
                                <div *ngIf="c.description!='How to Apply'"><a href="{{c.linkAddress}}" target="blank" class="link-light mb-2" [innerHTML] = "c.resourceName"></a></div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf='!waitlistExtended.waitlist.waitlistSubType?.includes("Tenant-Based")'>
                        <div *ngIf='!waitlistExtended.waitlistTypeCategory?.includes("Waiting Lists")'>
                            <div *ngIf="waitlistExtended.waitlist.notes && waitlistExtended.waitlist.notes.length > 0" class="mb-5">                            
                                <div class="page-subtitle text-white mb-2">Who to Contact</div>                        
                                <div [innerHTML]="waitlistExtended.waitlist.notes"></div>
                            </div>
                        </div>
                        <div *ngIf="waitlistExtended.waitlist.schoolDistrict && waitlistExtended.waitlist.schoolDistrict.length > 0" class="mb-5">
                            <div class="page-subtitle text-white mb-2">School District</div>
                            <div><a class="link-light" href="{{waitlistExtended.waitlist.schoolDistrictLink}}" target="_blank">Map of {{waitlistExtended.waitlist.schoolDistrict}}</a></div>
                        </div>
                        <div *ngIf="amenities && amenities.length > 0" class="mb-5">
                            <div class="page-subtitle text-white mb-2">Amenities</div>                        
                            <div *ngFor='let a of amenities'>
                                <div [innerHTML]="a.amenity"></div>
                            </div>
                        </div>
                        <div *ngIf="publicTransportations && publicTransportations.length > 0" class="mb-5">
                            <div class="page-subtitle text-white mb-2">Public Transportation</div>                        
                            <div *ngFor='let t of publicTransportations'>
                                <div [innerHTML]="t.amenity"></div>
                            </div>
                        </div>
                        <div *ngIf="waitlistExtended.waitlist.showNeighborhoodScores" class="">
                            <div class="page-subtitle text-white mb-2">Neighborhood Scores</div>
                            <div><a class="link-light" href="https://www.walkscore.com/CA/Sacramento" target="_blank">Check Walk Score</a></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <div class="container text-center mt-5">
                    <div class="ml-5 mr-5 font-italic" *ngIf="waitlistExtended.wlStatus =='Open'">
                        <div *ngIf="waitlistExtended.action =='apply' && !waitlistExtended.waitlist.name.includes('Referral')">
                            <div class="p3 mb-5" *ngIf="!waitlistExtended.applicantBREligibility">Your household does not meet the bedroom size requirement for available bedrooms<br/></div>
                            <div class="p3 mb-5" *ngIf="waitlistExtended.waitlist.name == 'Elderly Only'">
                                <div *ngIf="!waitlistExtended.applicantBREligibility && !waitlistExtended.applicantAgeEligibility">or the age requirement for this property<br/></div>
                                <div *ngIf="waitlistExtended.applicantBREligibility && !waitlistExtended.applicantAgeEligibility">Your household does not meet the age requirement for this property<br/></div>
                            </div>
                            <div class="page-text message-bdr-white-curved text-white mb-2" *ngIf="waitlistExtended.applicantBREligibility && waitlistExtended.applicantAgeEligibility && waitlist.accessCodeRequired == 1">{{waitlist.accessCodeText | uppercase}}</div>
                            <div class="mb-5" *ngIf="waitlistExtended.applicantBREligibility && waitlistExtended.applicantAgeEligibility && waitlistExtended.waitlist.accessCodeRequired == 1">
                                <input type="text" [(ngModel)]="waitlistExtended.accessCode" name="accessCode" placeholder="Access Code" required />
                                <div class="text-warning" *ngIf="!waitlistExtended.accessCodeValid">Invalid access code</div>                  
                            </div>                  
                            <div class="mb-5" *ngIf="waitlistExtended.applicantBREligibility && waitlistExtended.applicantAgeEligibility"><button type="button" (click)="applyToWaitlist()" class="btn btn-general">Apply to this waitlist</button></div>
                        </div>
                     </div>
                     <div *ngIf="waitlistExtended.wlStatus !='Open'"><button disabled="true" class="btn btn-disabled mb-5">Closed</button></div>
                </div>
            </div>
        </div>
        <div class="container bg-shragreen justify-content-center mb-5">
            <div *ngIf='!waitlist.waitlistSubType?.includes("Tenant-Based")' class="row">
                <div class="column1" *ngIf='waitlist.showImage'>
                    <div id="carouselImages" class="carousel slide" data-ride="carousel">
                        <div class="carousel-indicators">
                            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselImages" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner">
                            <div *ngFor="let i of imageCount" [ngClass]="i==1 ? 'carousel-item active' : 'carousel-item'">
                                <img class="d-block img-fluid img" src="{{imgFolder}}waiting lists/{{waitlist.name.replace('/', '').replace('.', '')}}{{i}}.jpg" alt="{{waitlist.name.replace('/', ' ').replace('.', '')}}{{i}}.jpg">
                            </div>
                        </div>                            
                        <a class="carousel-control-prev" href="#carouselImages" role="button" data-slide="prev">
                            <span class="" aria-hidden="true"><i class="bi bi-caret-left-fill page-title text-white"></i></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselImages" role="button" data-slide="next">
                            <span class="" aria-hidden="true"><i class="bi bi-caret-right-fill page-title text-white"></i></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
                <div *ngIf="waitlist.showGoogleMap && gmapurl != '' && !isMobile" class="column4">
                    <div class="iframe-container">
                        <iframe class="responsive-iframe" [src]="gmapurl | safe: 'resourceUrl'" frameborder="0" style="border:0" allowfullscreen>iFrames are not supported on this page.</iframe>
                    </div>
                </div>
            </div>
            <div *ngIf="isMobile && waitlist.showGoogleMap" class="row mb-5">
                <div class="text-center">
                    <a [href]="gmapurlMobile" target="_blank">View on Google Maps</a>
                </div>
            </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
         </ngx-spinner>
    </div>
</body>
