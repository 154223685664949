<div class="modal-header bg-shradarkgreen">
    <div class="page-subtitle1 text-white text-center">{{title}}</div>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div [innerHTML]="message"></div>
</div>
<div class="modal-footer">
    <button *ngIf="!btnCancelHide" type="button" class="btn btn-secondary btn-sm" (click)="decline()">{{btnCancelText}}</button>
    <button type="button" class="btn btn-general btn-sm" (click)="accept()">{{btnOkText}}</button>
</div>
