<body>
    <div class="container-fluid bg-transparent jumbotron">
        <div class="container bg-transparent justify-content-center  mt-5 mb-5 p-5">
            <div class="row">
                <div [ngClass]="!isMobile ? 'column1 text-left ml-5' : 'column1 text-center'">
                    <div [ngClass]="!isMobile ? 'col-md-8' : ''">
                        <div class="page-title ">Changing Lives.</div>
                        <div class="page-title ">One Household at a Time.</div>
                     </div>
                     <div [ngClass]="!isMobile ? 'col-md-10 mt-3' : 'mt-3'">
                        <div class="page-text ">Welcome to Sacwaitlist.com - a Sacramento Housing and Redevelopment Agency (SHRA) website.</div>
                     </div>
                     <div  [ngClass]="!isMobile ? 'col-md-8 mt-3 mb-5' : 'mt-3 mb-5'">
                        <button type="button" (click)="login()" class="btn btn-general">Sign In</button>
                     </div>
                </div>

                <div [ngClass]="!isMobile ? 'column1 mr-5' : ''">
                    <form name="form" #f="ngForm" novalidate>
                        <div class="container fst-italic text-left">
                            <div *ngIf="resetStatus">
                                <div *ngIf="resetStatus == 'success'"  class="page-subtitle1">
                                    Password updated successfully.
                                </div>
                                <div *ngIf="resetStatus == 'fail'" class="alert alert-danger">
                                    Password reset failed.
                                </div>
                            </div>

                            <div *ngIf="resetStatus == ''" class="form-group">
                                <div *ngIf="password.invalid && (password.dirty || formInvalid)" class="errormsg">
                                    <div *ngIf="password.errors.required">Password is required</div>
                                    <div *ngIf="password.errors.pattern">Password should follow the pattern below</div>                    
                                </div>
                                <small class="info-field-label-sm" id="passwordHelp">Min. 8 characters with at least one upper case, one lower case, one number and one special character</small>
                                <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="user.password" name="password" id="password" #password="ngModel" placeholder="Password" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$" /> 
                                <i class="far" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="toggleShowPassword()"></i>
                                                                
                                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || formInvalid)" class="errormsg">
                                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords do not match</div>
                                </div>
                                <input [type]="showConfirmPassword ? 'text' : 'password'" [(ngModel)]="user.confirmPassword" name="confirmPassword" id="confirmPassword" #confirmPassword="ngModel" placeholder="Re-enter Password" required [mustMatch]="['password', 'confirmPassword']"/> 
                                <i class="far" [ngClass]="{'fa-eye-slash': !showConfirmPassword, 'fa-eye': showConfirmPassword}" (click)="toggleShowConfirmPassword()"></i>

                                <div class="form-group text-right">
                                    <button class="btn btn-general" type="submit" (click)="resetPassword(f.form)">Reset Password</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
         </ngx-spinner>
    </div>
</body>