<!--     card-deck     -->
<form name="form" #f="ngForm" novalidate>
    <div class="card mt-5 mr-5 ml-5">
        <div class="card-body text-center ">
            <div *ngIf="!edit">
                <p><img class="img-fluid" src="{{imgFolder}}Fam-member.png" alt="card image"></p>
                <h4 class="card-title mb-5">{{familyMember.firstName==""? "Unknown":familyMember.firstName}} {{familyMember.lastName==""? "Unknown":familyMember.lastName}}</h4>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">D.O.B</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">XX/XX/XXXX</p>
                    </div>
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Gender</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">{{familyMember.gender}}</p>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">SSN</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">XXX-XX-XXXX</p>
                    </div>
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Relation</p>
                    </div>
                    <div class ="col-md-7 col-sm-5">
                        <p class="field-label text-left">{{familyMember.relationship == 'Youth under 18 years of age' ? 'Youth under 18' : familyMember.relationship == 'Student over 18 years of age'? 'Student over 18' : familyMember.relationship}}</p>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Disabled</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">{{familyMember.disability == 1 ? 'Yes' : 'No'}}</p>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Race</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <div *ngIf="!isMobile" class="form-group text-left"> 
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceWhite">White</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="familyMember.raceWhite" #raceWhite="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceBlack">Black</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="familyMember.raceBlack" #raceBlack="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAmericanIndian">American Indian</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="familyMember.raceAmericanIndian" #raceAmericanIndian="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAsian">Asian</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="familyMember.raceAsian" #raceAsian="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="racePacificIslander">Pacific Islander</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="familyMember.racePacificIslander" #racePacificIslander="ngModel">
                            </span>
                        </div>

                        <div *ngIf="isMobile" class="form-group text-left"> 
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceWhite">White</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="familyMember.raceWhite" #raceWhite="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceBlack">Black</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="familyMember.raceBlack" #raceBlack="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAmericanIndian">American Indian</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="familyMember.raceAmericanIndian" #raceAmericanIndian="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAsian">Asian</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="familyMember.raceAsian" #raceAsian="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="racePacificIslander">Pacific Islander</label>
                                <input [disabled]='true' class="checkbox mr-5" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="familyMember.racePacificIslander" #racePacificIslander="ngModel">
                            </div>
                        </div>
                       
                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Ethnicity</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">{{familyMember.ethnicity}}</p>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">English Understanding</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">{{familyMember.englishUnderstanding == 1 ? 'Yes' : 'No'}}</p>
                    </div>
                </div>

                <div *ngIf="familyMember.englishUnderstanding==0" class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Primary Language</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <p class="field-label text-left">{{familyMember.primaryLanguage}}</p>
                    </div>  
                </div>
        
                <div *ngIf='!readonly'>
                    <button type="button" (click)="editMember()" class="btn btn-edit btn-sm">Edit/Delete</button>
                </div>                
            </div>

            <div *ngIf="edit">
                <p><img class="img-fluid" src="{{imgFolder}}Fam-member.png" alt="card image"></p>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">First Name</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <input type="text" [(ngModel)]="familyMember.firstName" class="form-control text-left input-small" name="firstName" #firstName="ngModel" required/>
                        <div *ngIf="firstName.invalid && (firstName.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">                
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Last Name</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <input type="text" [(ngModel)]="familyMember.lastName" class="form-control text-left input-small" name="lastName" #lastName="ngModel" required/>
                        <div *ngIf="lastName.invalid && (lastName.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">D.O.B</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <input [(ngModel)]="familyMember.dob" class="form-control text-left input-small" name="dob" #dob="ngModel" required [maxDate]="maxDate|date" [minDate]="minDate|date" pattern="^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|(0[1-9])|([1-2][0-9])|(3[0-1]))\/((19|20)\d{2})$"/>
                        <div *ngIf="dob.invalid && (dob.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Gender</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select [(ngModel)]="familyMember.gender" class="form-control input-small text-left" name="gender" #gender="ngModel" required>
                            <option class="option-label" value="Male">Male</option>
                            <option class="option-label" value="Female">Female</option>
                        </select>
                        <div *ngIf="gender.invalid && (gender.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">SSN (If not available, enter all 9's. no dashes)</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <input type="text" [(ngModel)]="familyMember.ssn" class="form-control text-left input-small" name="ssn" #ssn="ngModel" required pattern="\d{9}"/>
                        <div *ngIf="ssn.invalid && (ssn.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Relation</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select [(ngModel)]="familyMember.relationship" class="form-control input-small text-left" name="relationship" #relationship="ngModel" required>
                            <option class="option-label" *ngFor="let rel of relationshipList" [value]="rel.lkuStrItem">{{rel.lkuStrItem}}</option>
                        </select>
                        <div *ngIf="relationship.invalid && (relationship.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
                <div class ="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Disabled</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select [(ngModel)]="familyMember.disability" class="form-control input-small text-left" name="disability" #disability="ngModel" required>
                            <option class="option-label" value=1>Yes</option>
                            <option class="option-label" value=0>No</option>
                        </select>
                        <div *ngIf="disability.invalid && (disability.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>                        
                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Race</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <div *ngIf="!isMobile" class="form-group text-left">    
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceWhite">White</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="familyMember.raceWhite" #raceWhite="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceBlack">Black</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="familyMember.raceBlack" #raceBlack="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAmericanIndian">American Indian</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="familyMember.raceAmericanIndian" #raceAmericanIndian="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAsian">Asian</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="familyMember.raceAsian" #raceAsian="ngModel">
                            </span>
                            <span class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="racePacificIslander">Pacific Islander</label>
                                <input class="checkbox mr-5" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="familyMember.racePacificIslander" #racePacificIslander="ngModel">
                            </span>
                        </div>

                        <div *ngIf="isMobile" class="form-group text-left">    
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceWhite">White</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="familyMember.raceWhite" #raceWhite="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceBlack">Black</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="familyMember.raceBlack" #raceBlack="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAmericanIndian">American Indian</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="familyMember.raceAmericanIndian" #raceAmericanIndian="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="raceAsian">Asian</label>
                                <input class="checkbox mr-5" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="familyMember.raceAsian" #raceAsian="ngModel">
                            </div>
                            <div class="whitespacenowrap">
                                <label class="checkbox-label mr-2" for="racePacificIslander">Pacific Islander</label>
                                <input class="checkbox mr-5" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="familyMember.racePacificIslander" #racePacificIslander="ngModel">
                            </div>
                        </div>

                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Ethnicity</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select [(ngModel)]="familyMember.ethnicity" class="form-control input-small text-left" name="ethnicity" id="ethnicity" #ethnicity='ngModel' required>
                            <option class="option-label" value="Hispanic or Latino">Hispanic or Latino</option>
                            <option class="option-label" value="Not Hispanic or Latino">Not Hispanic or Latino</option>
                        </select>
                        <div *ngIf="ethnicity.invalid && (ethnicity.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>

                <div class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">English Understanding</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select [(ngModel)]="familyMember.englishUnderstanding" class="form-control input-small text-left" name="englishUnderstanding" id="englishUnderstanding" #englishUnderstanding='ngModel' required>
                            <option class="option-label" value=1>Yes</option>
                            <option class="option-label" value=0>No</option>
                        </select>
                        <div *ngIf="englishUnderstanding.invalid && (englishUnderstanding.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>

                <div *ngIf="familyMember.englishUnderstanding==0" class="row mb-2">
                    <div class ="col-md-5 col-sm-3">
                        <p class="control-label text-left">Primary Language</p>
                    </div>
                    <div class ="col-md-7 col-sm-3">
                        <select class="col form-control" [(ngModel)]="familyMember.primaryLanguage" name="primaryLanguage" #primaryLanguage="ngModel" [required]="familyMember.englishUnderstanding==0">
                            <option class="option-label" *ngFor="let l of LEPLanguages" [value]="l.lkuStrItem">{{l.lkuStrItem}}</option>
                        </select>
                        <div *ngIf="primaryLanguage.invalid && (primaryLanguage.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>  
                </div>

                <div [hidden]="!duplicateSSN" class="errormsg mb-5">This SSN belongs to another family member</div>
                <div [hidden]="!spouseNotAdult" class="errormsg mb-5">Spouse must be an adult. </div>

                <div class="text-center">
                    <span class="mr-3"><button type="button" (click)="save(f.form)" class="btn btn-save btn-sm">Save</button></span>
                    <span class="mr-3"><button type="button" (click)="cancel()" class="btn btn-cancel btn-sm">Cancel</button></span>   
                    <span class="mr-3"><button type="button" (click)="delete()" class="btn btn-delete btn-sm">Delete</button></span>         
                </div>
            </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
        </ngx-spinner>
    </div>
</form>