<body *ngIf="showStyle==''">
   <div>
      <div *ngIf="faqForm && questions && faqs">
         <h1 class="text-center mb-5 mt-5">{{faqForm.description}}</h1>
         <div *ngIf="faqs.length == 0" class="text-center mb-5">
            <h2>No content available</h2>
         </div>
         <div *ngFor="let q of questions">
            <div class="text-center">
               <button (click)="toggleAccordian($event)" class="accordion" [innerHTML] = "q"></button>
               <div class="panel bg-transparent">
                  <div *ngFor="let f of faqs">
                     <div *ngIf="f.question==q">
                        <div class="text-dark ml-5 mb-2" *ngIf="f.answerType == 'text'"><div class="p" [innerHTML] = "f.answer"></div></div>
                        <div class="text-dark ml-5 mb-2" *ngIf="f.answerType == 'list'"><div class="p1" [innerHTML] = "f.answer"></div></div>
                        <div class="text-dark ml-5 mb-2" *ngIf="f.answerType == 'img'"><div><img src="{{imagePath}}{{f.answer}}" alt=""></div></div>
                     </div>
                  </div>
               </div>
            </div>
         </div>            
      </div>
   </div>
 </body>
 <div *ngIf="showStyle=='popup'" class="bg-light">
   <div *ngIf="faqForm && questions && faqs">
      <div class="headingpopup text-center mb-5 mt-5">{{faqForm.description}}</div>
      <div *ngIf="faqs.length == 0" class="text-center mb-5">
         <div>No content available</div>
      </div>
      <div *ngFor="let q of questions">
         <div class="text-left">
            <div class="subheadingpopup" [innerHTML] = "q"></div>
            <div *ngFor="let f of faqs">
               <div *ngIf="f.question==q">
                  <div class="textpopup ml-5 mb-2" *ngIf="f.answerType == 'text'"><div class="" [innerHTML] = "f.answer"></div></div>
                  <div class="textpopup ml-5 mb-2" *ngIf="f.answerType == 'list'"><div class="" [innerHTML] = "f.answer"></div></div>
                  <div class="textpopup ml-5 mb-2" *ngIf="f.answerType == 'img'"><div><img src="{{imagePath}}{{f.answer}}" alt=""></div></div>
               </div>
            </div>
         </div>
      </div>            
   </div>
</div>
<div *ngIf="showStyle=='collapse'">
   <div>
      <div *ngIf="faqForm && questions && faqs">
         <div *ngIf="faqs.length == 0" class="container page-text text-lef mb-5">
            No content available
         </div>
         <div *ngFor="let q of questions">
            <div class="text-left mb-5">
               <div class="page-subtitle ml-5 mb-2" >{{q}}</div>
               <div *ngFor="let f of faqs">
                  <div *ngIf="f.question==q">
                     <div class="page-text ml-5 mb-2" *ngIf="f.answerType == 'text'"><div class="" [innerHTML] = "f.answer"></div></div>
                     <div class="page-text ml-5 mb-2" *ngIf="f.answerType == 'list'"><div class="p2" [innerHTML] = "f.answer"></div></div>
                     <div class="page-text ml-5 mb-2" *ngIf="f.answerType == 'img'"><div><img src="{{imagePath}}{{f.answer}}" alt=""></div></div>
                  </div>
               </div>
            </div>
         </div>            
      </div>
   </div>
</div>
