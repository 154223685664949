import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, of } from "rxjs";

@Injectable()
export class PortalUtilitiesService{

    constructor(
        ) {}


    calculateAge(dob:Date):number{
        var diff_ms = Date.now() - dob.getTime();
        var age_dt = new Date(diff_ms); 
        
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    
    dateAdd(dt:Date, unit:string, noOfunits:number):Date{
        var year  = new Date(dt).getFullYear();
        var month = new Date(dt).getMonth();
        var day   = new Date(dt).getDate();
        
        if (unit.toUpperCase().substring(0,1) == "Y")
            return new Date(year + noOfunits, month, day);

        if (unit.toUpperCase().substring(0,1) == "M")
            return new Date(year, month + noOfunits, day);

        if (unit.toUpperCase().substring(0,1) == "D")
            return new Date(year, month, day + noOfunits);

    }

    getRandomNumber(min: number, max: number): number {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
    }

}