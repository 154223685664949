import { Component, OnInit} from "@angular/core";
import { Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Applicant } from '../models/applicant';
import { GeneralInfo } from '../models/generalInfo';
import { NavigationParameters } from "../models/navigationParameters";
import { AuthService } from '../services/auth.service';
import { WaitlistPortalService } from "../services/waitlist-portal.service";

@Component({
    selector: 'portalheader',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit{
    applicant:Applicant;
    generalInfo:GeneralInfo;
    loggedIn:boolean = false;
    userEmail:string = "";
    role:string = "";
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false; 
    selectedLanguage:string = "en";
    imgFolder: string = environment.imgFolder;

    constructor (
        private authService:AuthService,
        private router:Router,
        private waitlistPortalService:WaitlistPortalService
        ){
            waitlistPortalService.getLoggedInApplicant.subscribe(a => this.setApplicant(a));
            this.isMobile = window.innerWidth < 768;
        }


    ngOnInit()
    {
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();

        this.loggedIn = this.navigationParams.loggedIn = this.authService.isUserAuthenticated();

        this.role =  this.navigationParams.userRole;
        if (this.loggedIn)
        {
            this.applicant = JSON.parse(localStorage.getItem('applicant'));
            this.applicant? this.generalInfo = this.applicant.generalInfo : this.generalInfo = null;
            this.userEmail = this.navigationParams.loginEmail;
        }
        else
            localStorage.setItem('applicant', null);
       
    }

    changeGoogleTranslateLanguage(e:any) {
        let langCode:string = e.target.value;
        const translateElement = document.querySelector('.goog-te-combo') as HTMLSelectElement;

        // Set the language of Google Translate
        translateElement.value = langCode;
        this.selectedLanguage = langCode;
        // Trigger change event to apply the selected language
        let event = new Event('change');
        translateElement.dispatchEvent(event);

        localStorage.setItem('langCode', JSON.stringify(langCode));
        //set selected language
        this.waitlistPortalService.setSelectedLanguageCode(langCode);         
    }

    backToEnglish(){
        let langCode:string = 'en';
        const translateElement = document.querySelector('.goog-te-combo') as HTMLSelectElement;

        // Set the language of Google Translate
        translateElement.value = langCode;
        this.selectedLanguage = langCode;
        // Trigger change event to apply the selected language
        let event = new Event('change');
        translateElement.dispatchEvent(event);

        localStorage.setItem('langCode', JSON.stringify(langCode));
        //set selected language
        this.waitlistPortalService.setSelectedLanguageCode(langCode);         
    }

    setApplicant(applicant:Applicant){
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.applicant = applicant;
        if (this.applicant)
            this.generalInfo = this.applicant.generalInfo;
        this.userEmail = this.navigationParams.loginEmail;

        this.loggedIn =  this.navigationParams.loggedIn;
        this.role =  this.navigationParams.userRole;
    }

    routeTo(routePath:string){
        document.querySelector('.navbar-collapse').classList.remove('show'); //close hamburger menu

        if (routePath == 'userChangePassword') this.changePassword();
        if (routePath == 'userlogin') this.login();
        if (routePath == 'userLogout') this.logout();                

        this.router.navigate(['/' + routePath]);
    }

    changePassword(){
        localStorage.setItem('returnLocation', JSON.stringify('changepassword'));
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate(['/loginMain']));
    }

    signUp(){
        localStorage.setItem('returnLocation', JSON.stringify('newregistration'));
        document.querySelector('.navbar-collapse').classList.remove('show'); //close hamburger menu
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate(['/loginMain']));
    }

    login(){
        localStorage.setItem('returnLocation', JSON.stringify('userlogin'));
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            this.router.navigate(['/loginMain']));
    }

    logout(){
        this.loggedIn = false;
        this.authService.logout(); 
    }

}