import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { WaitlistService } from '../services/waitlist.service';
import { GeneralInfo } from '../models/generalInfo';
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { PortalParameters } from '../models/portalParameters';
import { Waitlists } from '../models/waitlists';
import { WaitlistsExtended } from '../models/waitlistsExtended';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { ApplicantProcessesService } from '../services/applicant-processes.service';
import { ApplicantProcess } from '../models/applicantProcess';
import { ApplicantStep } from '../models/applicantStep';
import { ApplicantForm } from '../models/applicantForm';
import { ConfirmationMessageService } from '../services/confirm-message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PortalLookups } from '../models/portalLookups';
import { environment } from 'src/environments/environment';
import { PopupMessageService } from '../services/popup.message.service';



@Component({
    selector: 'applicant-dashboard',
    templateUrl: './applicant-dashboard.component.html' ,
    styleUrls: ['./applicant-dashboard.component.scss']
})

export class ApplicantDashboardComponent implements OnInit{
    applicant:Applicant = new Applicant();
    generalInfo:GeneralInfo;
    loggedIn:boolean = false;
    phoneNumber:string;
    noAddress:boolean = false;
    role:string = "";
    userEmail:string;
    isIE:boolean = false;
    params:Parameters = new Parameters();
    portalParams:PortalParameters = new PortalParameters();
    navigationParams:NavigationParameters = new NavigationParameters();
    familySize:number;
    lastUpdatedDate:string;
    waitlistsExtended: WaitlistsExtended[] = [];
    applicantProcesses: ApplicantProcess[] =[];
    applicantSteps: ApplicantStep[] = [];
    applicantForms: ApplicantForm[] = [];
    outdated:boolean = false;
    applicationUpdateOutdateMonths:number;
    applicationUpdateOutdateDays:number;
    monthlyIncome:number;
    isMobile:boolean = false;
    @ViewChild("content",{static:true}) content:ElementRef;
    // @ViewChild("contentWLStatus",{static:true}) contentWLStatus:ElementRef;   
    contentTitle: string = "Contact";
    stateList:PortalLookups[] = [];
    cityList:PortalLookups[] = [];
    applicantStatuses:PortalLookups[] = [];
    formInvalid:boolean = false;
    infoTextTitle: string;
    infoTextDescription:string;
    updateParam:string;
    imgFolder: string = environment.imgFolder;

constructor(
    private spinnerService: NgxSpinnerService,
    private waitlistService:WaitlistService,
    private applicantService:ApplicantService,
    private applicantProcessService:ApplicantProcessesService,
    private waitlistPortalService:WaitlistPortalService,
    private router:Router,
    private confirmationService: ConfirmationMessageService,
    private popupMessageService:PopupMessageService,
    private modalService: NgbModal
) {
    this.isMobile = window.innerWidth < 768;
}


    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.loggedIn =  this.navigationParams.loggedIn;
        this.role =  this.navigationParams.userRole;
        this.userEmail = this.navigationParams.loginEmail;


        if (this.role.toUpperCase() != "APPLICANT" || !this.applicant)
        {
            this.spinnerService.hide();
            this.router.navigate(['/home']);
        }
        else
        {
            // this.applicant = JSON.parse(localStorage.getItem('applicant'));
            this.generalInfo = this.applicant ? this.applicant.generalInfo : null;
            this.familySize = this.applicant.familyMembers?.length + 1;
            this.phoneNumber = this.generalInfo.cellTel?.length > 0 && this.generalInfo.homeTel?.length > 0 ? 'both' : this.generalInfo.cellTel?.length > 0 ? this.generalInfo.cellTel : this.generalInfo.homeTel?.length > 0 ? this.generalInfo.homeTel : "Add a phone number";
            this.monthlyIncome = this.applicant.incomeRent?.monthlyGross;

            this.noAddress = true;
            if (this.generalInfo && this.generalInfo.mailingAddress && this.generalInfo.mailingAddress.length > 0) this.noAddress = false;
            if (this.generalInfo && this.generalInfo.city && this.generalInfo.city.length > 0) this.noAddress = false;
            if (this.generalInfo && this.generalInfo.state && this.generalInfo.state.length > 0) this.noAddress = false;
            if (this.generalInfo && this.generalInfo.zipCode && this.generalInfo.zipCode.length > 0) this.noAddress = false;

            this.portalParams.category = "ApplicationUpdateOutdateMonths";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((m) => {
               this.applicationUpdateOutdateMonths = +m[0].description;
            }, (error) => {
                this.spinnerService.hide();
            });

            this.portalParams.category = "ApplicationUpdateOutdateDays";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((d) => {
               this.applicationUpdateOutdateDays = +d[0].description;
            }, (error) => {
                this.spinnerService.hide();
            });

            this.params.applicantCode = this.applicant.generalInfo.pCode;
            if (this.params.applicantCode.substring(0,2) == 't5')
            {
                this.applicantService.getApplicantLastUpdatedDateCNV(this.params)
                .subscribe(data  => { 
                    if (data) 
                        this.lastUpdatedDate = data.lastUpdatedDate;
                        this.outdated = this.isOutdated(this.lastUpdatedDate);
                });
            }

            if (this.params.applicantCode.substring(0,2) == 't0')
            {
                this.applicantService.getApplicantLastUpdatedDateHCV(this.params)
                .subscribe(data  => { 
                    if (data) 
                        this.lastUpdatedDate = data.lastUpdatedDate;
                        this.outdated = this.isOutdated(this.lastUpdatedDate);
                });
            }

            if (this.params.applicantCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getApplicantLastUpdatedDateAFF(this.params)
                .subscribe(data  => { 
                    if (data) 
                        this.lastUpdatedDate = data.lastUpdatedDate;
                });
            }

            this.portalParams.category = "SacCity";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
               this.cityList = data;
            }, (error) => {
                this.spinnerService.hide();
            });
    
            this.portalParams.category = "states";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
               this.stateList = data;
            }, (error) => {
                this.spinnerService.hide();
            });

            this.portalParams.category = "ApplicantStatus";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
               this.applicantStatuses = data;
            }, (error) => {
                this.spinnerService.hide();
            });
            
            this.portalParams.category = "Waiting Lists";
            this.waitlistService.getWaitlistsByWLTypeCategory(this.portalParams)
            .subscribe(d=>{
                this.setupWaitlists(d);
            })

            if (this.outdated)
                this.showContactInfo("application");

            if (!this.applicantProcesses || this.applicantProcesses == null)
                this.getApplicantProcesses();

            this.spinnerService.hide();
        }
        
    }
   
    getApplicantProcesses(){
        //get applicant processes
        this.portalParams.applicantCode = this.applicant.generalInfo.pCode;
        this.applicantProcessService.getApplicantProcesses(this.portalParams)
        .subscribe(p => {
            this.applicantProcesses = p;
            if (p)
            {
                p.forEach(p1 => {
                    this.portalParams.applicantProcessID = p1.applicantProcessID;
                    this.applicantProcessService.getApplicantStepsByApplicantProcess(this.portalParams)
                    .subscribe(s => {
                        if (s) {
                            s.forEach(s1 => {
                                this.portalParams.applicantStepID = s1.applicantStepID;
                                this.applicantProcessService.getApplicantFormsByApplicantStep(this.portalParams)
                                .subscribe(f => {
                                    s1.applicantForms = f;
                                })
                            })
                        }
                        p1.applicantSteps = s;
                    })
                })
            }
        });
    }



    popupClick(infoText:string){
        let statusDescription = this.applicantStatuses.find(s => s.description.substring(0, 12).includes(infoText)).description.replace(infoText + "-", "");
        this.popupMessageService.show("Waiting List Status - " + infoText, statusDescription, true, "md");
    }


    isOutdated(dt:string):boolean{
            let month = Number(dt.slice(0, 2));
            let day = Number(dt.slice(3, 5));
            let year = Number(dt.slice(6,10));

            let dtOutdate = new Date(year, month + this.applicationUpdateOutdateMonths, day + this.applicationUpdateOutdateDays);
            let dtToday = new Date(Date.now());

            if (dtOutdate < dtToday)
                return true;
            
            return false;
    }

    goToPropertyProfile(w:WaitlistsExtended){
            localStorage.setItem('waitlistExtended', JSON.stringify(w));
            this.router.navigate(['/property']);
    }

    goToForm(processID:string,formID:string, applicantFormID:string, formTitle:string, tCode:string){
        localStorage.setItem('tcode', JSON.stringify(tCode));
        localStorage.setItem('FormID', JSON.stringify(formID));
        localStorage.setItem('applicantFormID', JSON.stringify(applicantFormID));
        localStorage.setItem('ProcessID', JSON.stringify(processID));
        localStorage.setItem('isapplicantFormLocked', JSON.stringify('false'));
        localStorage.setItem('FormTitle', JSON.stringify(formTitle));
        this.router.navigate(['/form']);
    }
  

    updateApplication(){

        if (!this.loggedIn) 
        {
            localStorage.clear();
            this.router.navigate(['/loginMain']);
        }

        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams.returnLocation = "applicantDashboard";

        if ((!this.navigationParams.affPCode || this.navigationParams.affPCode?.length == 0) && (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
        {
            this.confirmationService.confirm('Application Update','Hello! Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>', 'OK', true)
            .then(result => { });
        }
        else
        {
            if (this.navigationParams.affPCode?.length > 0 && (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
                this.navigationParams.resultMessage = 'Hello! Your Affordable Housing application has been updated. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
            else
                this.navigationParams.resultMessage = "Hello!  Your application has been updated.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/generalInfo']);
        }
        // localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
    }  


    showContactInfo(updateParam:string){

        this.updateParam = updateParam;
        let size:string = updateParam == "contact" ? "lg" : 'md';
        this.contentTitle = updateParam == "contact" ? "Contact Information" : updateParam;

        if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
        {
            this.confirmationService.confirm('Contact Information Update','Hello! Your contact information update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>', 'OK', true)
            .then(result => { });
        }
        else
            this.modalService.open(this.content, { size: size, centered: true});
    }


    applyWaitlist(){
        this.router.navigate(['/waitingList']);
    }

    setupWaitlists(wls:Waitlists[]){
        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name;
            wle.waitlist = wl;
            this.portalParams.waitlist = wl.name;
            this.portalParams.waitlistProgram = wl.yardiDB.toUpperCase() == "AFF" ? "Affordable Housing" : wl.yardiDB.toUpperCase() == "CNV" ? "Public Housing" : "Housing Choice Voucher";
            this.waitlistPortalService.getApplicantWaitlistStatus(this.portalParams).then(h =>{
                if (h && h.waitlistStatus != "Not On List" && h.waitlistStatus != "Not Placed on List")
                {
                    wle.applicantStatus = h.waitlistStatus;
                    wle.applicantBedroom = h.bedroomSize;
                    this.waitlistsExtended.push(wle); 
                }
            });
        });
    }

}