export class HomelessStatus{
    hmy:number;
    homelessPref?:number;
    homelessPrefA?:number;
    homelessPrefB?:number;
    homelessPrefC?:number;
    homelessPrefD?:number;
    homelessPrefE?:number;
    homelessPrefF?:number;
    homelessPrefG?:number;
    homelessPref2?:number;
    homelessPref3?:number;
    homelessPref4?:number;    
    homelessSvcs?:Number;
}