<body>
   <div class="container-fluid jumbotron" *ngIf="wltypes && waitlistsExtended">
      <h1 class="page-heading-primary mb-5">{{title | uppercase}} {{params.waitlistTypeCategory | uppercase}}</h1>

      <div class="container bg-transparent">
         <div class="mt-5"></div>
         <div *ngFor="let wlt of wltypes; let i=index">
            <h2 class="page-heading-secondary mt-3">{{wlt.name}}</h2>
            <p class="lnbr">{{wlt.descriptionText}}</p>
            
            <div *ngIf='i == wltypes.length - 1 && wlt.yardiIncomeLimitsCode && wlt.yardiDB'> 
               <div class="mt-5"></div>
               <!-- <waitlist-income-limit [item]='params.waitlistProgram' [itemType]='"WaitlistProgram"'></waitlist-income-limit> -->
               <waitlist-income-limit [item]='wlt.yardiIncomeLimitsCode' [itemType]='wlt.yardiDB'></waitlist-income-limit>
            </div>
         </div>
         <div class="mt-5"></div>
         <div *ngIf="waitlistsExtended.length == 0" class="mt-5">
            <div class="p2">This waiting list is currently closed</div>
            <div class="mt-3 mb-1 text-center">
               <span class="font-italic"><button type="button" (click)="backtoWaitlistPrograms()" class="btn btn-warning">BACK TO WAITING LIST PROGRAMS</button></span>
            </div>
         </div>
      </div>
   </div>
   <div *ngIf="waitlistsExtended.length > 0" class="container">
      <!-- <div *ngIf="role == ''" class="mt-3 mb-1 text-center">
         <span class="font-italic"><button type="button" (click)="backtoWaitlistPrograms()" class="btn btn-warning">BACK TO WAITLIST PROGRAMS</button></span>
      </div> -->
      <div>
         <!-- <span class="font-italic" style="float: left;"><button type="button" (click)="backtoWaitlistPrograms()" class="btn btn-warning">BACK TO WAITING LIST PROGRAMS</button></span>
         <span *ngIf="role == 'Applicant' && loggedIn" class="font-italic" style="float: right;"><button type="button" (click)="updateApplication()" class="btn btn-warning">UPDATE MY APPLICATION</button></span> -->
         <span *ngIf="!loggedIn && wltypes[0].allowOnlineApplication" class="font-italic" style="float: right;">
            <a routerLink="/login" class="btn btn-warning">Sign In</a><span class="p4 ml-3 mr-3">|</span>
            <a routerLink="/registrationNew" class="btn btn-warning">Sign Up</a><span class="p4 ml-3">to apply</span>
         </span>
      </div>
      <br/>
      <div *ngIf='wltypes[0].allowOnlineApplication' class="mt-5 mb-5">
         <span style="float: left;"><a href="javascript:void(0)" (click)="propertyClick('name', 'instructions')"><P5>Application Instructions</P5></a><br/></span>
         <span style="float: right;"><a href="javascript:void(0)" (click)="propertyClick('name', 'faqProgram')"><P5>Program FAQs</P5></a><br/></span>
      </div>
      <!-- <div class="row mb-5"></div> -->
      <div class="mt-5" *ngFor="let t of wltypes">
         <h2>{{t.description}} {{params.waitlistTypeCategory}}</h2>
         <table class="table table-bordered table-striped table-responsive-stack mb-5" id="tableOne">
            <thead class="thead-dark">
               <tr>
                  <th>
                     <!-- <span *ngIf='t.waitlistTypeCategory.includes("Programs")'>Program</span>
                     <span *ngIf='!t.waitlistTypeCategory.includes("Programs")'>Property</span> -->
                     <span *ngIf='t.name == "Shelters" || t.name == "Tenant-Based"'>Program</span>
                     <span *ngIf='t.name != "Shelters" && t.name != "Tenant-Based"'>Property</span>
                     <br/>(Click the link for details)
                  </th>
                  <th *ngIf="t.showLocation">Location</th>              
                  <th *ngIf="t.showSHRARequirements">SHRA Requirements</th>
                  <th *ngIf="t.showSiteRequirements">Site Requirements</th> 
                  <th *ngIf="t.showNotes">Contact</th>
                  <th *ngIf="t.showBedroom">Bedroom Open</th>
                  <th *ngIf="t.showServing">Serving</th>
                  <th *ngIf="t.showApplicantStatus && loggedIn && role == 'Applicant'">Your Status</th>
                  <th *ngIf="t.allowOnlineApplication && loggedIn">Action</th>
               </tr>
            </thead>
            <tbody>
               <tr *ngFor="let w of waitlistsExtended">                  
                  <ng-container *ngIf="w.waitlist.waitlistSubType == t.description">
                     <td class="font-weight-bold"><a href="javascript:void(0)" (click)="propertyClick(w.waitlist.name, 'waitlist')" [innerHTML] = "w.waitlistExtendedName.replace('/', ' / ')"></a>
                        <span *ngIf='t.waitlistTypeCategory == "Waiting Lists"'><br>{{w.wlStatus | titlecase}}</span>
                     </td>
                     <td class="font-weight-bold" *ngIf="t.showLocation">{{w.waitlist.location}}</td>              
                     <td class="font-weight-bold" *ngIf="t.showSHRARequirements">{{w.shraRequirements | titlecase}}<br/>
                        <div *ngIf='w.shraRequirements'><a href="javascript:void(0)" (click)="propertyClick(w.waitlist.name, 'SHRA Requirements')">Details</a><br/></div>
                     </td>                             
                     <td *ngIf="t.showSiteRequirements" class="font-weight-bold">{{w.preferences | titlecase}}<br/>
                        <div *ngIf='w.preferences'><a href="javascript:void(0)" (click)="propertyClick(w.waitlist.name, 'Site Requirements')">Details</a><br/></div>
                     </td>
                     <!-- <td class="font-weight-bold lnbr" *ngIf="t.showNotes">{{w.waitlist.notes}}</td> -->
                     <td class="font-weight-bold lnbr" *ngIf="t.showNotes"><div [innerHTML] = "w.waitlist.notes"></div></td>
                     <td *ngIf="t.showBedroom">
                         <div class="font-weight-bold" *ngIf="w.wlStatus == 'Open'">{{w.waitlist.bedroomsAvailable}}</div>
                     </td>
                     <td class="font-weight-bold" *ngIf="t.showServing">
                        <div [innerHTML] = "w.preferences"></div>
                        <a href="javascript:void(0)" (click)="propertyClick(w.waitlist.name, 'Preferences')">Preferences</a><br/>
                     </td>                             
                     <td class="font-weight-bold" *ngIf="t.showApplicantStatus && loggedIn && role == 'Applicant'">
                        <div *ngIf="!w.waitlist.name.includes('Referral')">{{w.applicantStatus | titlecase}}</div>
                     </td>                             
                     <td class="font-weight-bold" *ngIf="t.allowOnlineApplication && loggedIn">
                        <div *ngIf="w.action =='apply'">
                           <div *ngIf="!w.waitlist.name.includes('Referral')">
                              <div class="p3" *ngIf="!w.applicantBREligibility">Your household does not meet the bedroom size requirement for available bedrooms<br/></div>
                              <div class="p3" *ngIf="w.waitlist.name == 'Elderly Only'">
                                 <div *ngIf="!w.applicantBREligibility && !w.applicantAgeEligibility">or the age requirement for this property<br/></div>
                                 <div *ngIf="w.applicantBREligibility && !w.applicantAgeEligibility">Your household does not meet the age requirement for this property<br/></div>
                              </div>
                              <div class="p3 mb-2" *ngIf="!w.accessCodeValid">Invalid access code</div>                  
                              <div class="mb-2" *ngIf="w.applicantBREligibility && w.applicantAgeEligibility && w.waitlist.accessCodeRequired == 1"><input type="text" [(ngModel)]="w.accessCode" name="accessCode" placeholder="Access Code" required /></div>                  
                              <div class="font-italic" *ngIf="w.applicantBREligibility && w.applicantAgeEligibility"><button type="button" (click)="applyToWaitlist(w)" class="btn btn-warning">APPLY</button></div>
                              <div *ngIf="w.applicantBREligibility && w.applicantAgeEligibility && w.waitlist.accessCodeRequired == 1">{{w.waitlist.accessCodeText}}</div>
                           </div>
                           <div *ngIf="w.waitlist.name.includes('Referral')">
                              <div class="font-weight-bold lnbr" [innerHTML] = "w.waitlist.notes"></div>
                           </div>
                        </div>
                     </td>
                  </ng-container>
               </tr>
            </tbody>
         </table>
      </div>
      <div class="mb-5">
         <span class="font-italic" style="float: left;"><button type="button" (click)="backtoWaitlistPrograms()" class="btn btn-warning">BACK TO WAITING LIST PROGRAMS</button></span>
         <span *ngIf="role == 'Applicant' && loggedIn" class="font-italic" style="float: right;"><button type="button" (click)="updateApplication()" class="btn btn-warning">UPDATE MY APPLICATION</button></span>
      </div>
      <br/>
      <div *ngIf='wltypes[0].allowOnlineApplication' class="mt-5 mb-5">
         <span class="font-italic" style="float: left;"><button type="button" (click)="paperApplicationRequest()" class="btn btn-warning">I Am Unable to Apply Online</button></span>
      </div>
      <br/>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
         <p style="color: white;">Please wait.</p>
      </ngx-spinner>      
   </div>

   <div class="mt-5"></div>
   <div id='wlsec' class="container" *ngIf='property=="waitlist"'>
      <waitlists [waitlist]="waitlistSelected" [waitlistType]="waitlistTypeSelected"></waitlists> 
   </div>
   <div id='faq' class="container" *ngIf='property=="faq" || property=="instructions"'>
      <faq [item]='item' [itemType]='itemType' [itemTitle]='itemTitle' [showStyle]="''"></faq>
   </div>
   <div id='faqProgram' class="container" *ngIf='property=="faqProgram"'>
     <faq [item]='item' [itemType]='itemType' [itemTitle]='itemTitle' [showStyle]="''"></faq>
  </div>   
  <div id='preferences' class="container" *ngIf='property=="Preferences" || property=="Site Requirements" || property=="SHRA Requirements"'>
      <waitlist-preference [waitlist]='waitlistSelected.name' [title]='property'></waitlist-preference>
   </div>                

</body>
