<body>
  <form name="form" #f="ngForm" novalidate>
    <div class=" form d-flex justify-content-center align-items-center" *ngIf="homelessStatus">
      <div class="mt-2 mb-5">

        <div class="text-center mb-5">
          <hr><h2>Homeless Status</h2><hr>
        </div>

        <div class="row mb-5 mt-5 ml-2 mr-2">
          <div class="formgroup col-lg-12 justify-content-between">
            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Are you currently homeless?
              <!-- <span class="page-subtitle1 imgbutton text-search ml-2"><i class="bi bi-question-circle-fill" (click)="itemClick('Currently Homeless')"></i></span></label> -->
              <div class="small ml-5"><a href="javascript:void(0)" (click)="itemClick('Currently Homeless')">Currently Homeless means...</a></div></label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref' id="homelessPref" name="homelessPref" #homelessPref="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref' id="homelessPref" name="homelessPref" #homelessPref="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Are you an individual or family who are at-risk of homelessness?
              <div class="small ml-5"><a href="javascript:void(0)" (click)="itemClick('At Risk of Homelessness')">At Risk of Homeless means...</a></div></label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref2' id="homelessPref2" name="homelessPref2" #homelessPref2="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref2' id="homelessPref2" name="homelessPref2" #homelessPref2="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Are you an individual or family who is recently homeless?
              <div class="small ml-5"><a href="javascript:void(0)" (click)="itemClick('Recently Homeless')">Recently Homeless means...</a></div></label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref3' id="homelessPref3" name="homelessPref3" #homelessPref3="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref3' id="homelessPref3" name="homelessPref3" #homelessPref3="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Are you an individual or family who is fleeing, or attempting to flee, domestic violence,<br/>
              dating violence, sexual assault, stalking, or other dangerous or life-threatening conditions?
              <div class="small ml-5"><a href="javascript:void(0)" (click)="itemClick('In a Dangerous Situation')">In Dangerous Situation means...</a></div></label>              
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref4' id="homelessPref4" name="homelessPref4" #homelessPref4="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessPref4' id="homelessPref4" name="homelessPref4" #homelessPref4="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-5' : 'mb-3'">
              <label class="control-label mr-5">Are you receiving services from a local organization because you are homeless?</label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessSvcs' id="homelessSvcs" name="homelessSvcs" #homelessSvcs="ngModel" [value]=1 (change)="acknowledgeSvcsRequirement()" required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='homelessStatus.homelessSvcs' id="homelessSvcs" name="homelessSvcs" #homelessSvcs="ngModel" [value]=0 (change)="acknowledgeSvcsRequirement()" [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [hidden]="!f.form.invalid" class="errormsg mb-5">Please select yes or no for all options</div>
            
            <div class="mb-3">
              <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
            </div>

            <div class="mb-5">
              <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
            </div>
          </div>
        </div>
        <div id='details' class="container mb-5" *ngIf='item!=""'>
          <faq [item]='item' [itemType]='itemType' [itemTitle]='itemTitle' [showStyle]="''"></faq>
       </div>   
    
      </div>
      <ng-template #content let-modal>
        <div class="modal-header bg-shradarkgreen">
           <div class="page-subtitle2 text-white mb-2">{{itemTitle}}</div>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
           <!-- <div class="container page-text text-center p-3">{{contentText}}</div> -->
           <div id='details' class="container" *ngIf='item!=""'>
            <faq [item]='item' [itemType]='itemType' [itemTitle]='itemTitle' [showStyle]="''"></faq>
         </div>   
        </div>
     </ng-template>
  
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>

    </div>

  </form>
</body>
