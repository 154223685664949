import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { PortalParameters } from '../models/portalParameters';
import { ResourceLinks } from '../models/resourceLinks';
import { WaitlistService } from '../services/waitlist.service';
import { AuthService } from '../services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Preferences } from '../models/preferences';
import { environment } from 'src/environments/environment';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { PopupMessageService } from '../services/popup.message.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html' ,
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit{
    applicant:Applicant = new Applicant();
    loggedIn:boolean = false;
    preferences:Preferences[] = [];
    //role:string = "";
    isIE:boolean = false;
    communityResourceLinks:ResourceLinks[] = [];
    navigationParams:NavigationParameters = new NavigationParameters();
    announcements:ResourceLinks[] = [];
    itemTitle:string = "";
    itemType:string = "";
    item:string = "";
    showStyle:string = "popup";
    @ViewChild("content1",{static:true}) content1:ElementRef;
    @ViewChild("content2",{static:true}) content2:ElementRef;
    @ViewChild("content3",{static:true}) content3:ElementRef;
    @ViewChild("content4",{static:true}) content4:ElementRef;
    @ViewChild("content5",{static:true}) content5:ElementRef;
    @ViewChild("content6",{static:true}) content6:ElementRef;    
    siteMsgSeen: string = "false";
    isMobile:boolean = false;
    portalParams:PortalParameters = new PortalParameters;
    btnBackCaption:string = "< Back";
    btnNextCaption:string = "Next >";
    yardiIncomeLimitsCode: string = "";
    yardiIncomeLimitsDB: string = "";
    modalSizeTour:string = "lg";
    imageScaleNormal:boolean = true;
    imgFolder: string = environment.imgFolder;
    flyerFolder: string = environment.flyerFolder;
    langCode: string = "en";


constructor(
    private spinnerService: NgxSpinnerService,
    private authService: AuthService,
    private waitlistService:WaitlistService,
    private waitlistPortalService:WaitlistPortalService,
    private router:Router,
    private modalService: NgbModal,
    private popupMessageService:PopupMessageService
) {
    this.isMobile = window.innerWidth < 768;
    waitlistPortalService.getselectedLanguage.subscribe(l => this.langCode = l);
}


    ngOnInit(){

        this.spinnerService.show();
        this.yardiIncomeLimitsCode = "vli";
        this.yardiIncomeLimitsDB = "HCV";

        this.siteMsgSeen = sessionStorage.getItem("siteMsgSeen") ?? this.siteMsgSeen;

        if (this.siteMsgSeen == "false"){
            this.siteMsgSeen = "true";
            sessionStorage.setItem("siteMsgSeen", this.siteMsgSeen);
            this.modalService.open(this.content1, { size: this.modalSizeTour, centered: true});            
        }

        this.itemTitle = "Frequently Asked Questions";
        this.itemTitle = "FAQs";
        this.itemType = "WaitlistProgram";
        this.item = "Housing Choice Voucher";

        if (!this.langCode || this.langCode == '' || this.langCode == 'en')
            this.langCode = JSON.parse(localStorage.getItem('langCode'));

        if (!this.authService.isUserAuthenticated) 
        {
            this.loggedIn = false;
            localStorage.clear();
        }
        
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();
        this.navigationParams.resultMessage = "";
        this.navigationParams.returnLocation = "";
        this.navigationParams.waitlist = "";
        this.navigationParams.updateAction = "";
        this.navigationParams.formMode = "";

        //check for login
        this.loggedIn =  this.navigationParams.loggedIn;
    
        let params:PortalParameters = new PortalParameters();
        params.category = "Community Resources";
        this.waitlistService.getResourceLinks(params)
        .subscribe(c =>{
            this.communityResourceLinks = c;
        });

        params.category = "Announcements";
        this.waitlistService.getResourceLinks(params)
        .subscribe(a =>{
            this.announcements = a;
        });

        if (!this.navigationParams.hcvTBWLText || this.navigationParams.hcvTBWLText?.length == 0)
        {
            this.portalParams.category = "HCVTBWLText";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((d) => {
                this.navigationParams.hcvTBWLText = d[0].description;
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            });
        }

        if (!this.navigationParams.emergencyHousingText || this.navigationParams.emergencyHousingText?.length == 0)
        {
            this.portalParams.category = "EmergencyHousingText";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((e) => {
                this.navigationParams.emergencyHousingText = e[0].description;
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            });
        }

        let prefList = ['RESIDENCY','DISABLED','VETERAN','LEASE IN-PLACE PREFERENCE','HOMELESS'];
        this.waitlistService.getPreferences(). subscribe(p => {
            this.preferences = p.filter(pre => prefList.includes(pre.name.toUpperCase()))
        });

        this.spinnerService.hide();
        //detect if browser is IE
        //if (window.navigator.userAgent.indexOf("MSIE") != -1 ) this.isIE = true;
    }
   

    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }


    //tour functions
    next(i:number)
    {
        this.modalService.dismissAll();
        if (i == 1)
            this.modalService.open(this.content2, { size: this.modalSizeTour, centered: true});
        if (i == 2)
            this.modalService.open(this.content3, { size: this.modalSizeTour, centered: true});
        if (i == 3)
            this.modalService.open(this.content4, { size: this.modalSizeTour, centered: true});
        if (i == 4)
            this.modalService.open(this.content5, { size: this.modalSizeTour, centered: true});
        if (i == 5)
            this.modalService.open(this.content6, { size: this.modalSizeTour, centered: true});
    }

    back(i:number)
    {
        this.modalService.dismissAll();
        if (i == 2)
            this.modalService.open(this.content1, { size: this.modalSizeTour, centered: true});
        if (i == 3)
            this.modalService.open(this.content2, { size: this.modalSizeTour, centered: true});
        if (i == 4)
            this.modalService.open(this.content3, { size: this.modalSizeTour, centered: true});
        if (i == 5)
            this.modalService.open(this.content4, { size: this.modalSizeTour, centered: true});
        if (i == 6)
            this.modalService.open(this.content5, { size: this.modalSizeTour, centered: true});
    }

    closeClick(){
        this.modalService.dismissAll();
    }

    toggleAccordian(event) {
        var element = event.target;
        element.classList.toggle("active");
        var panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }



    expand(e:Event){
        var elem = e.target as HTMLElement;

        if (this.imageScaleNormal)
        {
            elem.style.transform = "scale(1.3)";
            elem.style.backgroundColor = "white";
            elem.classList.remove('img-expand');
            elem.classList.add('img-shrink');
        }
        else
        {
            elem.style.transform = "scale(1)";
            elem.style.backgroundColor = "transparent";
            elem.classList.remove('img-shrink');            
            elem.classList.add('img-expand');
        }

        elem.style.transition = "transform 0.25s ease";
        this.imageScaleNormal = !this.imageScaleNormal;
    }

    getImagePath(imgName: string): string {

        let imgPath: string = this.imgFolder + "en/" + imgName;

        if (this.langCode == "es" || this.langCode == "hmn" || this.langCode == "ru" || this.langCode == "vi" || this.langCode == "zh-CN" || this.langCode == "zh-TW")
            imgPath = this.imgFolder + this.langCode + "/" + imgName;

        return imgPath;
    }

  
    selectIncomeLimitCode(e:any){
        
        let code = e.target.value;
        if (code == "HCV")
        {
            this.yardiIncomeLimitsCode = "vli";
            this.yardiIncomeLimitsDB = "HCV"
        }
        if (code == "CNV")
        {
            this.yardiIncomeLimitsCode = "li";
            this.yardiIncomeLimitsDB = "CNV"
        }
        if (code == "AFF")
        {
            this.yardiIncomeLimitsCode = "59vli";
            this.yardiIncomeLimitsDB = "CNV"
        }
    }

    signUp(){
        this.navigationParams.returnLocation = 'newregistration';
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/loginMain']);
    }

    login(){
        this.navigationParams.returnLocation = 'login';
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/loginMain']);
    }

    paperApplicationRequest(){
        this.router.navigate(['/paperApplicationFaq']);
    }

    updateApplication(){

        if (!this.loggedIn) 
        {
            localStorage.clear();
            this.router.navigate(['/loginMain']);
        }

        if (this.loggedIn && this.navigationParams.userRole!='Applicant')
            this.findApplication();
        else
        {

            this.applicant = JSON.parse(localStorage.getItem('applicant'));
            this.navigationParams.returnLocation = "dashboard";

            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
            {
                //let msg:string = "Hello.  Your update cannot be completed online because you’re a participant in current status or in a move process. Please submit a written request to your caseworker to update your household information.";
                this.navigationParams.resultMessage = 'Hello! Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                this.router.navigate(['/result']);
            }
            else
            {
                this.navigationParams.resultMessage = "Hello.  Your application has been updated.";
                this.router.navigate(['/generalInfo']);
            }
            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        }
    }   
    
    findApplication(){
        this.navigationParams.returnLocation = "dashboard";
        this.navigationParams.resultMessage = "Hello.  Your application has been updated.";
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.router.navigate(['/basicInfo']);
    }  
    
}