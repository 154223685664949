import {Component, OnInit} from "@angular/core";
import { environment } from "src/environments/environment";
import { NavigationParameters } from "../models/navigationParameters";
import { PortalParameters } from "../models/portalParameters";
import { ResourceLinks } from "../models/resourceLinks";
import { PopupMessageService } from "../services/popup.message.service";
import { WaitlistService } from "../services/waitlist.service";


@Component ({
    selector : 'contact',
    templateUrl : './contact.component.html',
    styleUrls: ['./contact.component.scss']
})

export class ContactComponent implements OnInit{
    title:string = "";
    communityResourceLinks:ResourceLinks[] = [];
    isMobile:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    imgFolder: string = environment.imgFolder;
    gmapurl:string = "https://maps.google.it/maps?q=630 I STREET, SACRAMENTO, CA 95814";

    constructor (
        private waitlistService:WaitlistService,
        private popupMessageService:PopupMessageService
        ){
            this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.title = "Contact & Resources";
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        let params:PortalParameters = new PortalParameters();
        params.category = "Community Resources";
        this.waitlistService.getResourceLinks(params)
        .subscribe(c =>{
            this.communityResourceLinks = c;
        });
    }

    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }

}