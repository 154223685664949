import { EventEmitter, Injectable, Output } from '@angular/core';
import { Applicant } from '../models/applicant';
import { FamilyMember } from '../models/familyMember';
import { GeneralInfo } from '../models/generalInfo';
import { IncomeRent } from '../models/incomeRent';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalLookups } from '../models/portalLookups';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { User } from '../models/user';
import { ApplicantService } from './applicant.service';
import { MessageService } from './message.service';
import { PortalUtilitiesService } from './portal-utilities.service';
import { UserService } from './user.service';
import { WaitlistService } from './waitlist.service';

@Injectable()
export class WaitlistPortalService{
    @Output() getLoggedInApplicant: EventEmitter<Applicant> = new EventEmitter();
    @Output() getselectedLanguage: EventEmitter<string> = new EventEmitter();
    applicant:Applicant = new Applicant();
    navigationParams:NavigationParameters = new NavigationParameters();

    constructor(
        private applicantService:ApplicantService,
        private waitlistService:WaitlistService,
        private userService:UserService,
        private portalUtilitiesService:PortalUtilitiesService,
        private msg:MessageService
        ) {}


    setupCurrentUser(user:User, params:Parameters)
    {
        if (user.token) localStorage.setItem('jwtoken', JSON.stringify(user.token));

        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();

        this.navigationParams.userRole = user.role;
        this.navigationParams.hcvTCode = user.hcvTcode;
        this.navigationParams.cnvTCode = user.cnvTcode;
        this.navigationParams.affPCode = user.affPcode;
        this.navigationParams.loggedIn = true;
        this.navigationParams.loginEmail = user.email;
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        
        if ((user.hcvTcode && user.hcvTcode.length > 0) || (user.cnvTcode && user.cnvTcode.length > 0) || (user.affPcode && user.affPcode.length > 0))
        {
            params.applicantCode = (user.hcvTcode && user.hcvTcode.length > 0) ? user.hcvTcode : (user.cnvTcode && user.cnvTcode.length > 0) ? user.cnvTcode : user.affPcode;
            
            if (params.applicantCode.substring(0,2) == 't5')
            {
                this.applicantService.getGeneralInfoCNV(params)
                .subscribe(data  => { 
                    this.applicant.generalInfo = data;
                    this.navigationParams.tenantStatusCNV = data ? data.tenantStatus: "";
                    params.hmy = data.hmy;
                    this.applicantService.getFamilyMembersCNV(params)
                    .subscribe (f => {
                        this.applicant.familyMembers = f;
                        this.applicantService.getIncomeRentCNV(params)
                        .subscribe (i => {
                            this.applicant.incomeRent = i;
                            localStorage.setItem('applicant', JSON.stringify(this.applicant));
                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            this.getLoggedInApplicant.emit(this.applicant);
                        });
                    });
                }, (error) => {
                    this.msg.showError(error, "Login Error");
                });
            }
            else
            {
                if (params.applicantCode.substring(0,2) == 't0')
                {
                    this.applicantService.getGeneralInfoHCV(params)
                    .subscribe(data  => { 
                        this.applicant.generalInfo = data;
                        this.navigationParams.tenantStatusHCV = data ? data.tenantStatus: "";
                        //for testing only. delete the line below for GO LIVE
                        //this.applicant.generalInfo.tenantStatus = 'Applicant';
                        params.hmy = data.hmy;
                        this.applicantService.getFamilyMembersHCV(params)
                        .subscribe (f => {
                            this.applicant.familyMembers = f;
                            this.applicantService.getIncomeRentHCV(params)
                            .subscribe (i => {
                                this.applicant.incomeRent = i;
                                localStorage.setItem('applicant', JSON.stringify(this.applicant));
                                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                                this.getLoggedInApplicant.emit(this.applicant);
                            });
                            //check tenant status in CNV database
                            if (this.applicant.generalInfo.tenantStatus.toUpperCase() != 'CURRENT' && this.applicant.generalInfo.tenantStatus.toUpperCase() != 'NOTICE')
                            {
                                if (user.cnvTcode != "")
                                {
                                    params.applicantCode = user.cnvTcode;
                                    this.applicantService.getGeneralInfoCNV(params)
                                    .subscribe(data  => { 
                                        if (data)
                                        {
                                            if (data.tenantStatus.toUpperCase() == 'CURRENT' || data.tenantStatus.toUpperCase() == 'NOTICE')
                                            {
                                                //only set the tenantstatus
                                                this.applicant.generalInfo.tenantStatus = data.tenantStatus;
                                                this.navigationParams.tenantStatusCNV = data.tenantStatus;
                                                localStorage.setItem('applicant', JSON.stringify(this.applicant));
                                                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                                                this.getLoggedInApplicant.emit(this.applicant);
                                            }
                                        }
                                    }, (error) => {
                                        this.msg.showError(error, "Login Error");
                                    });
                                }
                            }
                        });
                    }, (error) => {
                        this.msg.showError(error, "Login Error");
                    });   
                }
                else
                {
                    this.applicantService.getGeneralInfoAff(params)
                    .subscribe(data  => { 
                        this.applicant.generalInfo = data;
                        this.navigationParams.tenantStatusAFF = data ? data.tenantStatus: "";
                        params.hmy = data.hmy;
                        this.applicantService.getFamilyMembersAff(params)
                        .subscribe (f => {
                            this.applicant.familyMembers = f;
                            this.applicantService.getIncomeRentAff(params)
                            .subscribe (i => {
                                this.applicant.incomeRent = i;
                                this.getLoggedInApplicant.emit(this.applicant);
                                localStorage.setItem('applicant', JSON.stringify(this.applicant));
                                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            });
                            //check tenant status in HCV/CNV database
                            if (user.cnvTcode != "")
                            {
                                params.applicantCode = user.cnvTcode;
                                this.applicantService.getGeneralInfoCNV(params)
                                .subscribe(data  => { 
                                    if (data)
                                    {
                                        if (data.tenantStatus.toUpperCase() == 'CURRENT' || data.tenantStatus.toUpperCase() == 'NOTICE')
                                        {
                                            //only set the tenantstatus
                                            this.applicant.generalInfo.tenantStatus = data.tenantStatus;
                                            this.navigationParams.tenantStatusCNV = data.tenantStatus;
                                            localStorage.setItem('applicant', JSON.stringify(this.applicant));
                                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                                            this.getLoggedInApplicant.emit(this.applicant);
                                        }
                                        else //check HCV database
                                        {
                                            if (user.hcvTcode != "")
                                            {
                                                params.applicantCode = user.hcvTcode;
                                                this.applicantService.getGeneralInfoHCV(params)
                                                .subscribe(data  => { 
                                                    if (data)
                                                    {
                                                        if (data.tenantStatus.toUpperCase() == 'CURRENT' || data.tenantStatus.toUpperCase() == 'NOTICE')
                                                        {
                                                            //only set the tenantstatus
                                                            this.applicant.generalInfo.tenantStatus = data.tenantStatus;
                                                            this.navigationParams.tenantStatusHCV = data.tenantStatus;
                                                            localStorage.setItem('applicant', JSON.stringify(this.applicant));
                                                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                                                            this.getLoggedInApplicant.emit(this.applicant);
                                                        }
                                                    }
                                                }, (error) => {
                                                    this.msg.showError(error, "Login Error");
                                                });
                                            }
                                        }
                                    }
                                }, (error) => {
                                    this.msg.showError(error, "Login Error");
                                });
                            }
                        });
                    }, (error) => {
                        this.msg.showError(error, "Login Error");
                    });   
                }                 
            }
        }
        else
            this.getLoggedInApplicant.emit(this.applicant);

    }


    setSelectedLanguageCode(langCode:string){
        this.getselectedLanguage.emit(langCode);
    }

    checkForDisabledMember(applicant:Applicant):boolean{

        let disabledMemberExists:boolean = false;

        if (applicant.generalInfo.disability == 1 || applicant.incomeRent.resSSI == 1)
            disabledMemberExists = true;

        var fm = applicant.familyMembers.find(f=> f.disability === 1);
        if (fm)
            disabledMemberExists = true;
        
        return disabledMemberExists;
    }

    checkForElderlyMember(applicant:Applicant):boolean{
        let dob:Date = new Date(applicant.generalInfo.dob);
       
        if (this.portalUtilitiesService.calculateAge(dob) > 49) return true;
        //check for elderly spouse if exists
        if (applicant.familyMembers.find(f => f.relationship.toLocaleUpperCase().includes('SPOUSE')))
        {
            dob = new Date(applicant.familyMembers.find(f => f.relationship.toLocaleUpperCase().includes('SPOUSE')).dob);
            if (this.portalUtilitiesService.calculateAge(dob) > 49) return true;
        }
        return false;
    }
    



    calculateBRSizeHCV(applicant:Applicant):number{
        let familyMembers: FamilyMember[] = [];
        let roomCount:number = 0; 
        
        roomCount += 1;         //HOH and Spouse - 1 bedroom
        if (applicant?.familyMembers.find(f => f.relationship == "Live In Aide")) roomCount +=1;             //Live-in-Aide - 1 room 
        //All others - 2 people share a room
        familyMembers = applicant.familyMembers.filter(f => f.relationship != "Live In Aide" && f.relationship != "Spouse or Partner");
        if (familyMembers && familyMembers.length && familyMembers.length > 0)
                roomCount += Math.round(familyMembers.length/2);
        return roomCount;
    }

    calculateBRSizeCNV(applicant:Applicant):number{
        let familyMembers: FamilyMember[] = [];
        let roomCount:number = 0; 
        
        roomCount += 1;         //HOH and Spouse - 1 bedroom

        familyMembers = applicant?.familyMembers.filter(f => f.relationship != "Spouse or Partner");

        if (familyMembers?.length > 0)
        {
            familyMembers.forEach((x, i) => {
                let age:number = this.portalUtilitiesService.calculateAge(new Date(x.dob));
                roomCount += 1;                     //give a room for this member
                if (age < 6)            //can share the room with another child under 6 or a minor of same gender
                {
                    let index:number = familyMembers.findIndex(f => f.memberHMy != x.memberHMy && this.portalUtilitiesService.calculateAge(new Date(f.dob)) < 6);
                    if (index > i) familyMembers.splice(index, 1);
                    else
                    {
                        index = familyMembers.findIndex(f => f.memberHMy != x.memberHMy && this.portalUtilitiesService.calculateAge(new Date(f.dob)) < 18 && f.gender == x.gender);
                        if (index > i) familyMembers.splice(index, 1);
                    }
                }

                if (age > 5 && age < 18) //can share the room with another minor of same gender if they ae 10 years apart
                {
                    let index:number = familyMembers.findIndex(f => f.memberHMy != x.memberHMy && this.portalUtilitiesService.calculateAge(new Date(f.dob)) < 18 && f.gender == x.gender
                                                                && Math.abs(this.portalUtilitiesService.calculateAge(new Date(f.dob)) - this.portalUtilitiesService.calculateAge(new Date(x.dob))) < 10);
                    if (index > i) familyMembers.splice(index, 1);
                                        
                }

                if (age > 17)           //can share the room with another adult of same gender
                {
                    let index:number = familyMembers.findIndex(f => f.memberHMy != x.memberHMy && this.portalUtilitiesService.calculateAge(new Date(f.dob)) > 17 && f.gender == x.gender);
                    if (index > i) familyMembers.splice(index, 1);
                }
            });
        }
        return roomCount;
    }



    getPortalLookupsByID(params:PortalParameters):Promise<PortalLookups>{

        return new Promise((resolve, reject) => {
            this.waitlistService.getPortalLookupsByID(params)
            .subscribe(data  => { 
                resolve(data);
            });
        });

    }

    getHCVYardiTCode(params:Parameters):Promise<string>{

        if (params.ssn.length == 9)
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoBySSNDOBHCV(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
        else
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoHCV(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
    }

    getCNVYardiTCode(params:Parameters):Promise<string>{
        if (params.ssn.length == 9)
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoBySSNDOBCNV(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
        else
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoCNV(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
    }


    getAffYardiPCode(params:Parameters):Promise<string>{
        if (params.ssn.length == 9)
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoBySSNDOBAff(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
        else
        {
            return new Promise((resolve, reject) => {
                this.applicantService.getAllGeneralInfoAff(params)
                .subscribe(data  => { 
                    if(data && data[0])
                        resolve(data[0].pCode.toString());
                    else
                        resolve("");
                });
            });
        }
    }


    updateCNVYardiEmail(params:Parameters):Promise<any>{
        return new Promise((resolve, reject) => {
            this.applicantService.updateApplicantEmailCNV(params)
            .subscribe((data)  => { 
                    resolve(data);
            });
        });
    }


    updateHCVYardiEmail(params:Parameters):Promise<any>{
        return new Promise((resolve, reject) => {
            this.applicantService.updateApplicantEmailHCV(params)
            .subscribe((data)  => { 
                    resolve(data);
            });
        });
    }


    updateAffYardiEmail(params:Parameters):Promise<any>{
        return new Promise((resolve, reject) => {
            this.applicantService.updateApplicantEmailAff(params)
            .subscribe((data)  => { 
                    resolve(data);
            });
        });
    }

    getWaitlistPreferences(params:PortalParameters):Promise<Preferences[]>{
        return new Promise((resolve, reject) => {
            this.waitlistService.getWaitlistPreferences(params)
                .subscribe(data  => { 
                    resolve(data);
                });
        });
    }


    getApplicantPreferences():Promise<Preferences[]>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {

            let applicantParams: Parameters = new Parameters();
            applicantParams.applicantCode = this.navigationParams.hcvTCode;
            if (applicantParams.applicantCode == "")
            {
                applicantParams.applicantCode = this.navigationParams.cnvTCode;
                this.applicantService.getPreferencesCNV(applicantParams).subscribe(h =>{
                    resolve(h);
                });
            }
            else
            {
                this.applicantService.getPreferencesHCV(applicantParams).subscribe(h =>{
                    resolve(h);
                });
            }
        });        
    }


    getApplicantWaitlistStatus(params:PortalParameters):Promise<Parameters>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {

            let applicantParams: Parameters = new Parameters();
            applicantParams.waitList = params.waitlist;
            if (params.waitlistProgram == "Housing Choice Voucher" || params.waitlist == "Moderate Rehab")
            {
                applicantParams.applicantCode = this.navigationParams.hcvTCode;
                if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
                {
                    let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
                    applicantParams.waitlistStatus = "Not On List";
                    applicantParams.bedroomSize = this.calculateBRSizeHCV(applicant);
                    resolve(applicantParams);

                    // applicantParams.applicantCode = this.navigationParams.cnvTCode;
                    // this.getCNVApplicantHCVWaitlistStatus(applicantParams).then(h =>{
                    //     resolve(h);
                    // });
                }
                else
                {
                    this.getApplicantHCVWaitlistStatus(applicantParams).then(h =>{
                        resolve(h);
                    });
                }
            }
            if (params.waitlistProgram == "Public Housing")
            {
                applicantParams.applicantCode = this.navigationParams.cnvTCode;
                if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
                {
                    let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
                    applicantParams.waitlistStatus = "Not On List";
                    applicantParams.bedroomSize = this.calculateBRSizeCNV(applicant);
                    resolve(applicantParams);

                    // applicantParams.applicantCode = this.navigationParams.hcvTCode;
                    // this.getHCVApplicantCNVWaitlistStatus(applicantParams).then(h =>{
                    //     resolve(h);
                    // });

                }
                else
                {
                    this.getApplicantCNVWaitlistStatus(applicantParams).then(h =>{
                        resolve(h);
                    });
                }
            }
            if (params.waitlistProgram == "Affordable Housing" && params.waitlist != "Moderate Rehab")
            {
                applicantParams.applicantCode = this.navigationParams.affPCode;
                if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
                {
                    let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
                    applicantParams.waitlistStatus = "Not On List";
                    applicantParams.bedroomSize = 999;
                    resolve(applicantParams);
                }
                else
                {
                    this.getApplicantAffWaitlistStatus(applicantParams).then(h =>{
                        resolve(h);
                    });
                }
            }
        });        
    }
    
    getApplicantHCVWaitlistStatus(params:Parameters):Promise<Parameters>{
        return new Promise((resolve, reject) => {
            this.applicantService.getApplicantWaitlistStatusHCV(params)
            .subscribe(data  => { 
                if(data)
                {
                    params.waitlistStatus = data.waitlistStatus;
                    params.bedroomSize = data.bedroomSize;
                }
                resolve(params);
            });
        });
    }

    
    getApplicantCNVWaitlistStatus(params:Parameters):Promise<Parameters>{
        return new Promise((resolve, reject) => {
            this.applicantService.getApplicantWaitlistStatusCNV(params)
            .subscribe(data  => { 
                if(data)
                {
                    params.waitlistStatus = data.waitlistStatus;
                    params.bedroomSize = data.bedroomSize;
                }
                resolve(params);
            });
        });
    }


    getApplicantAffWaitlistStatus(params:Parameters):Promise<Parameters>{
        return new Promise((resolve, reject) => {
            this.applicantService.getApplicantWaitlistStatusAff(params)
            .subscribe(data  => { 
                if(data)
                {
                    params.waitlistStatus = data.waitlistStatus;
                    params.bedroomSize = data.bedroomSize;
                }
                resolve(params);
            });
        });
    }


    //get bedroom size eligibility of HCV applicant for CNV waitlists (applicant not in CNV DB)
    getHCVApplicantCNVWaitlistStatus(params:Parameters):Promise<Parameters>{
        return new Promise((resolve, reject) => {
            this.applicantService.getApplicantCNVWaitlistStatusHCV(params)
            .subscribe(data  => { 
                if(data)
                {
                    params.waitlistStatus = data.waitlistStatus;
                    params.bedroomSize = data.bedroomSize;
                }
                resolve(params);
            });
        });
    }


    //get bedroom size eligibility of CNV applicant for HCV waitlists (applicant not in HCV DB)
    getCNVApplicantHCVWaitlistStatus(params:Parameters):Promise<Parameters>{
        return new Promise((resolve, reject) => {
            this.applicantService.getApplicantHCVWaitlistStatusCNV(params)
            .subscribe(data  => { 
                if(data)
                {
                    params.waitlistStatus = data.waitlistStatus;
                    params.bedroomSize = data.bedroomSize;
                }
                resolve(params);
            });
        });
    }
    

    InsertChronoforAllApplicants(params:Parameters):Promise<Parameters>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
    
            params.applicantCode = this.navigationParams.hcvTCode && this.navigationParams.hcvTCode.length > 0 ? this.navigationParams.hcvTCode : this.navigationParams.cnvTCode;
            if (params.applicantCode.substring(0,2) == "t0")
            {
                this.applicantService.insertChronoHCV(params)
                .subscribe( h => {
                    if (this.navigationParams.cnvTCode && this.navigationParams.cnvTCode.length > 0)
                    {
                        params.applicantCode = this.navigationParams.cnvTCode;
                        this.applicantService.insertChronoCNV(params).subscribe(()=>{
                            resolve(params);
                        });
                    }
                    else
                        resolve(params);
                });                
            }
            else
            {
                this.applicantService.insertChronoCNV(params).subscribe(()=>{
                    resolve(params);
                });
            }
        });
    }


    updateAllApplicantsContactInfo(genInfo:GeneralInfo):Promise<GeneralInfo>{
        return new Promise((resolve, reject) => {
            this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));    
            genInfo.pCode = this.navigationParams.hcvTCode && this.navigationParams.hcvTCode.length > 0 ? this.navigationParams.hcvTCode : this.navigationParams.cnvTCode;
            if (genInfo.pCode.substring(0,2) == "t0")
            {
                this.applicantService.updateApplicantContactInfoHCV(genInfo)
                .subscribe( h => {
                    if (this.navigationParams.cnvTCode && this.navigationParams.cnvTCode.length > 0)
                    {
                        genInfo.pCode = this.navigationParams.cnvTCode;
                        this.applicantService.updateApplicantContactInfoCNV(genInfo).subscribe(()=>{
                            resolve(genInfo);
                        });
                    }
                    else
                        resolve(genInfo);
                });                
            }
            else
            {
                this.applicantService.updateApplicantContactInfoCNV(genInfo).subscribe(()=>{
                    resolve(genInfo);
                });
            }
        });
    }


    updateAllApplicantsIncomeRent(incomeRent:IncomeRent):Promise<IncomeRent>{

        return new Promise((resolve, reject) => {
            this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
            let param: Parameters = new Parameters();
            param.applicantCode = this.navigationParams.hcvTCode && this.navigationParams.hcvTCode.length > 0 ? this.navigationParams.hcvTCode : this.navigationParams.cnvTCode;
            let generalInfos:GeneralInfo[] = [];

            if (param.applicantCode.substring(0,2) == "t0")
            {
                this.applicantService.getAllGeneralInfoByTCodeHCV(param)
                .subscribe(gh => {
                    gh.forEach(gh1 => {
                        incomeRent.hmy = gh1.hmy
                        this.applicantService.updateApplicantIncomeRentHCV(incomeRent)
                        .subscribe(h => {
                            resolve(incomeRent);
                        });
                    })
                    if (this.navigationParams.cnvTCode && this.navigationParams.cnvTCode.length > 0)
                    {
                        let paramC:Parameters = new Parameters();
                        let incomeRentC = incomeRent;
                        paramC.applicantCode = this.navigationParams.cnvTCode;
                        this.applicantService.getAllGeneralInfoByTCodeCNV(paramC)
                        .subscribe(gc => {
                            gc.forEach(gc1 => {
                                incomeRentC.hmy = gc1.hmy
                                this.applicantService.updateApplicantIncomeRentCNV(incomeRentC)
                                .subscribe(c => {
                                    resolve(incomeRent);
                                });
                            })
                        });                
                    }
                })
            }
            else
            {
                param.applicantCode = this.navigationParams.cnvTCode;
                this.applicantService.getAllGeneralInfoByTCodeCNV(param)
                .subscribe(gc => {
                    gc.forEach(gc1 => {
                        incomeRent.hmy = gc1.hmy
                        this.applicantService.updateApplicantIncomeRentCNV(incomeRent)
                        .subscribe(c => {
                            resolve(incomeRent);
                        });
                    })
                });                
            }
            resolve(incomeRent);
        });
    }


    updateAllHCVRecords(applicant:Applicant):Promise<Applicant[]>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
    
            //get all applicants in HCV before update
            //store all applicants before update. First item is applicant with updated data.
            let applicants:Applicant[] = [];
            let curApplicant:Applicant = new Applicant;
            let params:Parameters = new Parameters;

            //first item is current updated record
            //applicants.push(applicant);
            params.applicantCode = this.navigationParams.hcvTCode;
            this.applicantService.getAllApplicantsHCV(params)
            .subscribe( h => {
                applicants = h;
                //check if applicant is in current or notice status
                for (var index in applicants){
                    curApplicant = applicants[index];
                    applicant.generalInfo.hmy = curApplicant.generalInfo.hmy;
                    applicant.generalInfo.pCode = curApplicant.generalInfo.pCode;
                    this.applicantService.upateApplicantHCV(applicant).subscribe(()=>{
                        params.hmy = curApplicant.generalInfo.hmy;
                        this.applicantService.deleteFamilyMembersHCV(params).subscribe(
                            data => {
                            applicant.familyMembers.forEach(fH=>{
                                fH.hMy = curApplicant.generalInfo.hmy;
                                this.applicantService.insertFamilyMemberHCV(fH).subscribe(()=>{});
                            });},
                            err => {
                                this.msg.showError(err, "Update Error");
                            },
                            () => {
                                params.applicantCode = curApplicant.generalInfo.pCode;
                                this.applicantService.finalUploadProcessesByHMyHCV(params).subscribe(()=>{});
                            }
                        );
                        //FileNet chrono should have the pCode that the file should be searched for. 
                        //the tcode of the record that is shown on the portal is used for filenet upload
                        params.applicantCode = applicant.generalInfo.pCode;
                        this.applicantService.updateChronoForFileNetUploadHCV(params).subscribe(()=>{});

                    });                
                }
                resolve(applicants);
            })
        });
    }


    updateAllCNVRecords(applicantC:Applicant):Promise<Applicant[]>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
    
            //get all applicants in HCV before update
            //store all applicants before update. First item is applicant with updated data.
            let applicantsC:Applicant[] = [];
            let curApplicantC:Applicant = new Applicant;
            let paramsC:Parameters = new Parameters;

            //first item is current updated record
            //applicants.push(applicant);
            paramsC.applicantCode = this.navigationParams.cnvTCode; 
            this.applicantService.getAllApplicantsCNV(paramsC)
            .subscribe( c => {
                applicantsC = c;
                for (var i in applicantsC){
                    curApplicantC = applicantsC[i];
                    applicantC.generalInfo.hmy = curApplicantC.generalInfo.hmy;
                    applicantC.generalInfo.pCode = curApplicantC.generalInfo.pCode;
                    this.applicantService.upateApplicantCNV(applicantC).subscribe(()=>{
                        paramsC.hmy = curApplicantC.generalInfo.hmy;
                        this.applicantService.deleteFamilyMembersCNV(paramsC).subscribe(
                            d => {
                            applicantC.familyMembers.forEach(fC=>{
                                fC.hMy = curApplicantC.generalInfo.hmy;
                                this.applicantService.insertFamilyMemberCNV(fC).subscribe(()=>{});
                            });},
                            err => {
                                this.msg.showError(err, "Update Error");
                            },
                            () => {
                                paramsC.applicantCode = curApplicantC.generalInfo.pCode;
                                this.applicantService.finalUploadProcessesByHMyCNV(paramsC).subscribe(()=>{});
                            }
                        );
                        //FileNet chrono should have the pCode that the file should be searched for. 
                        //the tcode of the record that is shown on the portal is used for filenet upload
                        paramsC.applicantCode = applicantC.generalInfo.pCode;
                        this.applicantService.updateChronoForFileNetUploadCNV(paramsC).subscribe(()=>{});

                    });                
                }
                resolve(applicantsC);
            })
        });

    }


    updateAllAFFRecords(applicantA:Applicant):Promise<Applicant[]>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
    
            //get all applicants in HCV before update
            //store all applicants before update. First item is applicant with updated data.
            let applicantsA:Applicant[] = [];
            let curApplicantA:Applicant = new Applicant;
            let paramsA:Parameters = new Parameters;

            //first item is current updated record
            //applicants.push(applicant);
            paramsA.applicantCode = this.navigationParams.affPCode;
            this.applicantService.getAllApplicantsAff(paramsA)
            .subscribe( a => {
                applicantsA = a;
                for (var i in applicantsA){
                    curApplicantA = applicantsA[i];
                    applicantA.generalInfo.hmy = curApplicantA.generalInfo.hmy;
                    applicantA.generalInfo.pCode = curApplicantA.generalInfo.pCode;
                    this.applicantService.upateApplicantAff(applicantA).subscribe(()=>{
                        paramsA.hmy = curApplicantA.generalInfo.hmy;
                        this.applicantService.deleteFamilyMembersAff(paramsA).subscribe(
                            d => {
                            applicantA.familyMembers.forEach(fA=>{
                                fA.hMy = curApplicantA.generalInfo.hmy;
                                this.applicantService.insertFamilyMemberAff(fA).subscribe(()=>{});
                            });},
                            err => {
                                this.msg.showError(err, "Update Error");
                            },
                            () => {
                                paramsA.applicantCode = curApplicantA.generalInfo.pCode;
                                this.applicantService.finalUploadProcessesByHMyAff(paramsA).subscribe(()=>{});
                            }
                        );
                        //FileNet chrono should have the pCode that the file should be searched for. 
                        //the tcode of the record that is shown on the portal is used for filenet upload
                        paramsA.applicantCode = applicantA.generalInfo.pCode;
                    });                
                }
                resolve(applicantsA);
            })
        });

    }

    
    applyToWaitlist(addToWL:boolean){

        let applicantParams:Parameters = new Parameters;
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        applicantParams.waitList =  this.navigationParams.waitlist; 

        if (this.navigationParams.waitlistProgram == "Housing Choice Voucher" || this.navigationParams.waitlist == "Moderate Rehab")
        {
            //check if applicant already in HCV db. If not, insertapplicant. Update new tcode in portal user table
            applicantParams.applicantCode = this.navigationParams.hcvTCode; 
            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.insertApplicantAndSetupUserHCV()
                .then((d) => {
                    applicantParams.applicantCode = d;
                    if (addToWL) this.applicantService.addApplicantToWaitlistHCV(applicantParams).subscribe();
                })
            }
            else
                if (addToWL) this.applicantService.addApplicantToWaitlistHCV(applicantParams).subscribe();
        }

        if (this.navigationParams.waitlistProgram == "Public Housing")
            {            
            //check if applicant already in CNV db. If not, insertapplicant. Update new tcode in portal user table
            applicantParams.applicantCode = this.navigationParams.cnvTCode;
            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.insertApplicantAndSetupUserCNV()
                .then((d) => {
                    applicantParams.applicantCode = d;
                    if (addToWL) this.applicantService.addApplicantToWaitlistCNV(applicantParams).subscribe();
                })
            }
            else
                if (addToWL) this.applicantService.addApplicantToWaitlistCNV(applicantParams).subscribe();
        }

        if (this.navigationParams.waitlistProgram == "Affordable Housing" && this.navigationParams.waitlist != "Moderate Rehab")
        {            
            //check if applicant already in CNV db. If not, insertapplicant. Update new tcode in portal user table
            applicantParams.applicantCode = this.navigationParams.affPCode;

            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.insertApplicantAndSetupUserAFF()
                .then((d) => {})
            }
        }
    }

    //Insertapplicant. Update new tcode in portal user table
    insertApplicantAndSetupUserHCV():Promise<string>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
            let applicantParams: Parameters = new Parameters();
            applicantParams.applicantCode = this.navigationParams.cnvTCode;
            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.applicantService.insertApplicantBasicHCV(applicant.generalInfo)
                .subscribe(h =>{
                    let user:User = new User();
                    user.email = this.navigationParams.loginEmail;
                    user.hcvTcode = this.navigationParams.hcvTCode = h.pCode;
                    h.dob = new Date(h.dob);
                    applicant.generalInfo = h;
                    applicant.generalInfo.tenantStatus = this.navigationParams.tenantStatusHCV = "On List";
                    localStorage.setItem('applicant', JSON.stringify(applicant)); 
                    localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));                   
                    this.userService.updateHCVTCode(user)
                    .subscribe(d=>{
                        user.role = this.navigationParams.userRole = "Applicant";
                        this.userService.updateUserRole(user)
                        .subscribe(()=>{
                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            this.updateAllApplicants(applicant, "Yes");
                            this.getLoggedInApplicant.emit(applicant);
                        })
                    });
                    resolve(h.pCode);
                });
            }
            else
            {
                this.applicantService.insertApplicantFromCNVRecordHCV(applicantParams)
                .subscribe((data) =>{
                    let user:User = new User();
                    user.email = this.navigationParams.loginEmail;
                    user.hcvTcode = this.navigationParams.hcvTCode = data.applicantCode;
                    this.navigationParams.hcvTCode = data.applicantCode;
                    localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                    this.userService.updateHCVTCode(user)
                    .subscribe(d=>{
                        this.updateAllApplicants(applicant);
                        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                    });
                    resolve(data.applicantCode);
                });
            }
        });
    }

    //Insertapplicant. Update new tcode in portal user table
    insertApplicantAndSetupUserCNV():Promise<string>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));

        return new Promise((resolve, reject) => {
            let applicantParams: Parameters = new Parameters();
            applicantParams.applicantCode = this.navigationParams.hcvTCode;
            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.applicantService.insertApplicantBasicCNV(applicant.generalInfo)
                .subscribe(c =>{
                    let user:User = new User();
                    user.email = this.navigationParams.loginEmail;
                    user.cnvTcode = this.navigationParams.cnvTCode = c.pCode;
                    applicant.generalInfo = c;
                    applicant.generalInfo.tenantStatus = this.navigationParams.tenantStatusCNV = "On List";
                    localStorage.setItem('applicant', JSON.stringify(applicant));
                    localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));                   
                    this.userService.updateCNVTCode(user)
                    .subscribe(d=>{
                        user.role = this.navigationParams.userRole = "Applicant";
                        this.userService.updateUserRole(user)
                        .subscribe(()=>{
                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            this.updateAllApplicants(applicant, "Yes");
                            this.getLoggedInApplicant.emit(applicant);
                        })
                    });
                    resolve(c.pCode);
                });
            }
            else
            {
                this.applicantService.insertApplicantFromHCVRecordCNV(applicantParams)
                .subscribe((data) =>{
                    let user:User = new User();
                    user.email = this.navigationParams.loginEmail;
                    user.cnvTcode = this.navigationParams.cnvTCode = data.applicantCode;
                    localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                    this.userService.updateCNVTCode(user)
                    .subscribe(d=>{
                        this.updateAllApplicants(applicant);
                        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                    });
                    resolve(data.applicantCode);
                });
            }
        });
    }


    //Insertapplicant. Update new tcode in portal user table
    insertApplicantAndSetupUserAFF():Promise<string>{
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        return new Promise((resolve, reject) => {
            let applicantParams: Parameters = new Parameters();
            applicantParams.applicantCode = this.navigationParams.affPCode;
            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));

            if (!applicantParams.applicantCode || applicantParams.applicantCode == "")
            {
                this.applicantService.insertApplicantBasicAff(applicant)
                .subscribe(a =>{
                    let user:User = new User();
                    user.email = this.navigationParams.loginEmail;
                    user.affPcode = this.navigationParams.affPCode = a.pCode;
                    applicant.generalInfo = a;
                    applicant.generalInfo.tenantStatus = this.navigationParams.tenantStatusAFF = "On List";
                    localStorage.setItem('applicant', JSON.stringify(applicant));
                    localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));                   
                    this.userService.updateAffPCode(user)
                    .subscribe(d=>{
                        user.role = this.navigationParams.userRole = "Applicant";
                        this.userService.updateUserRole(user)
                        .subscribe(()=>{
                            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                            this.updateAllAFFRecords(applicant).then (a => {
                                this.getLoggedInApplicant.emit(applicant);
                            });
                        })
                    });
                    resolve(a.pCode);
                });
            }
        });
    }

        
    updateAllApplicants(applicant, newApplicant?:string){
        let applicants:Applicant [] = [];
        let applicantsHCV:Applicant[] = [];
        let applicantsCNV:Applicant[] = [];
        let applicantsAFF:Applicant[] = [];
        let addApplicant:boolean = true;
        if (newApplicant && newApplicant == "Yes") addApplicant = false;
        applicants.push(applicant);
        this.updateAllHCVRecords(applicant).then(h =>{
            applicantsHCV = h;
            if (addApplicant)
                applicantsHCV.forEach((ah)=>{ applicants.push(ah);
            })
            this.updateAllCNVRecords(applicant).then(c =>{
                applicantsCNV = c;
                if (addApplicant)
                    applicantsCNV.forEach((ac)=>{ applicants.push(ac);
                })
                this.updateAllAFFRecords(applicant).then(a =>{
                    applicantsAFF = a;
                    if (addApplicant)
                        applicantsAFF.forEach((aa)=>{ applicants.push(aa);
                    })

                    this.applicantService.uploadPdfToFileNetHCV(applicants).subscribe(()=>{});
                });
            });
        });
    }

    updateAFFApplicantsOnly(applicant, newApplicant?:string){
        let applicants:Applicant [] = [];
        let applicantsAFF:Applicant[] = [];
        let addApplicant:boolean = true;
        if (newApplicant && newApplicant == "Yes") addApplicant = false;
        applicants.push(applicant);
        this.updateAllAFFRecords(applicant).then(a =>{
            applicantsAFF = a;
            if (addApplicant)
                applicantsAFF.forEach((aa)=>{ applicants.push(aa);
            })
            
            this.applicantService.uploadPdfToFileNetHCV(applicants).subscribe(()=>{});
        });
    }

    showForm(formName:string):boolean {
        
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));

        if (formName == 'DisabilityAccommodations')
        {
            if (this.navigationParams.waitlistTypesModel.yardiDB == '')
            {
                
            }

        }

        if (formName == 'LeaseInPlaceStatus')
        {

        }

        return true;
    }

    formReadOnly(formName:string):boolean{

        return true;
    }


}