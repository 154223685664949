import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from "src/environments/environment";
import { Applicant } from "../models/applicant";
import { NavigationParameters } from "../models/navigationParameters";
import { PortalParameters } from "../models/portalParameters";
import { ResourceLinks } from "../models/resourceLinks";
import { WaitlistAmenities } from "../models/waitlistAmenities";
import { Waitlists } from "../models/waitlists";
import { WaitlistsExtended } from "../models/waitlistsExtended";
import { AuthService } from "../services/auth.service";
import { FAQPanelService } from "../services/faq-panel.service";
import { MessageService } from "../services/message.service";
import { WaitlistPortalService } from "../services/waitlist-portal.service";
import { WaitlistService } from "../services/waitlist.service";

@Component ({
    selector : 'property',
    templateUrl : './property.component.html',
    styleUrls: ['./property.component.scss']
})

export class PropertyComponent implements OnInit{
    waitlist:Waitlists;
    waitlistExtended:WaitlistsExtended;
    amenities: WaitlistAmenities[] = [];
    publicTransportations: WaitlistAmenities[] = [];
    params: PortalParameters = new PortalParameters();
    yardiIncomeLimitsCode: string = "";
    yardiIncomeLimitsDB: string = "";  
    imageCount: number[] = [1,2,3];
    communityResourceLinks:ResourceLinks[] = [];

    role:string = "";
    loggedIn:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    // mapurl:string = "https://gisshra.maps.arcgis.com/apps/instant/basic/index.html?appid=0c17687a4a6540f08dd3df8e585e9c6c";
    //mapurl:string = "https://experience.arcgis.com/experience/affcd93a489e477d9ba92484b642292b/page/Mirasol/"; // map per prrperty
    mapurl:string = "https://experience.arcgis.com/experience/affcd93a489e477d9ba92484b642292b/page/Wait-List-Map/";
    gmapurl:string = "https://maps.google.it/maps?q=sacramento&output=embed";
    gmapurlMobile:string = "https://maps.google.it/maps?q=sacramento";
    isMobile:boolean = false;
    imgFolder: string = environment.imgFolder;

    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private authService: AuthService,
        private router:Router,
        private waitlistPortalService:WaitlistPortalService,
        private faqPanelService: FAQPanelService,
        private msg:MessageService
    ) {
        this.isMobile = window.innerWidth < 768;
    }


    ngOnInit(): void {
        this.spinnerService.show();
        this.navigationParams =  JSON.parse(localStorage.getItem('navigationParams'));
        this.navigationParams.resultMessage = "";
        this.navigationParams.returnLocation = "";
        this.navigationParams.waitlist = "";
        this.navigationParams.updateAction = "";
        this.navigationParams.formMode = "";

        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.role = this.navigationParams.userRole;

        this.loggedIn =  this.navigationParams.loggedIn = this.authService.isUserAuthenticated();

        this.waitlistExtended =  JSON.parse(localStorage.getItem('waitlistExtended'));
        this.waitlist = this.waitlistExtended.waitlist;
        this.gmapurl = this.waitlist.location && this.waitlist.location.length > 0 ? this.gmapurl.replace('sacramento', this.waitlist.location) : this.gmapurl ;
        this.gmapurlMobile = this.waitlist.location && this.waitlist.location.length > 0 ? this.gmapurlMobile.replace('sacramento', this.waitlist.location) : this.gmapurl ;

        this.params.waitlist = this.waitlistExtended.waitlist.name;
        this.waitlistService.getWaitlistAmenities(this.params)
        .subscribe(d => {
            this.amenities = d.filter(a => a.amenityCategoryName.toUpperCase() == 'AMENITY');
            this.publicTransportations = d.filter(t => t.amenityCategoryName.toUpperCase() == 'PUBLIC TRANSPORTATION');
        }, (err) => {
            this.spinnerService.hide();            
            this.msg.showError(err, "waitlists error");
        });  

        this.params.category = "Community Resources";
        this.waitlistService.getResourceLinks(this.params)
        .subscribe(c =>{
            this.communityResourceLinks = c;
        });

        //set up income limits codes
        let yardiDB = this.waitlistExtended.waitlist.yardiDB.toUpperCase();
        this.yardiIncomeLimitsDB = yardiDB == "AFF" ? "CNV" : yardiDB;
        this.yardiIncomeLimitsCode = yardiDB == "AFF" ? "59vli" : "vli";
        
        this.spinnerService.hide();
    }


    infoClick(infoText:string){
        this.faqPanelService.show(infoText, "lg");
    }

    applyToWaitlist(){

        if (!this.loggedIn)
            this.router.navigate(['/loginMain']);

        if (this.loggedIn && this.role.toUpperCase() == 'REGISTEREDUSER')
            this.applyToWaitlistRegisteredUser();

        if (this.loggedIn && this.role.toUpperCase() == "APPLICANT")
                this.applyToWaitlistApplicant();
    }

   
  
    //Registered User
    applyToWaitlistRegisteredUser(){
    
        //check if waitlist needs an access code and supplied access code is valid
        if (this.waitlist.accessCodeRequired == 1 && this.waitlistExtended.accessCode != this.waitlist.accessCode)
            this.waitlistExtended.accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = this.waitlist.name;
            this.navigationParams.returnLocation = "property";
            this.navigationParams.availableBR = this.waitlist.bedroomsAvailable.toString();
            this.navigationParams.updateAction = "apply";

            if (this.waitlist.name == "Housing Choice Voucher")
                this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
            else
               this.navigationParams.resultMessage = "Hello. You have been added to " + this.waitlist.name + " waiting list with your application update.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/basicInfo']);
       }
    }


    //Applicant
    applyToWaitlistApplicant(){
    
        //check if waitlist needs an access code and supplied access code is valid
        if (this.waitlist.accessCodeRequired == 1 && this.waitlistExtended.accessCode != this.waitlist.accessCode)
            this.waitlistExtended.accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = this.waitlist.name;
            this.navigationParams.returnLocation = "property";
            this.navigationParams.availableBR = this.waitlist.bedroomsAvailable.toString();

            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));

            if (this.waitlist.yardiDB != 'AFF' && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
            {
                if (this.waitlist.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. '
                        + 'Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                else
                    this.navigationParams.resultMessage = 'Hello! You have been added to ' + this.waitlist.name + ' waiting list. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                // this.waitlistPortalService.applyToWaitlist();   
                this.applyWithoutUpdate(applicant);
                this.router.navigate(['/result']);
            }
            else
            {
                this.navigationParams.updateAction = "apply";
                if (this.waitlist.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
                else
                    this.navigationParams.resultMessage = "Hello! You have been added to " + this.waitlist.name + " waiting list with your application update.";
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                this.router.navigate(['/generalInfo']);
            }
        }
    }


    applyWithoutUpdate(applicant:Applicant){
        let resultMsg = "";        
        if (this.navigationParams.waitlist.toLowerCase().includes('elderly'))
        {
            var ageEligible = this.waitlistPortalService.checkForElderlyMember(applicant);
            if (!ageEligible) resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the age requirement for this Elderly Only property";
        }

        //available BR size for the selected waitlist
        let availableBR:string = this.navigationParams.availableBR + ",";
        let roomCount:number;
        if (this.navigationParams.waitlistProgram == 'Housing Choice Voucher')
            roomCount = this.waitlistPortalService.calculateBRSizeHCV(applicant);
        
        if (this.navigationParams.waitlistProgram == 'Public Housing' || this.navigationParams.waitlistProgram == 'Affordable Housing')                        
            roomCount = this.waitlistPortalService.calculateBRSizeCNV(applicant);

        let brEligible:boolean = false;
        if (availableBR == "0," || availableBR.includes(roomCount.toString() + ","))                        
                brEligible = true;

        if (availableBR.includes('SRO') && roomCount < 2)
                brEligible = true;

        if (!brEligible) 
            resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the bedroom size requirement for available bedrooms";                                        
        
        if (resultMsg == "") 
            this.waitlistPortalService.applyToWaitlist(true);
        else
        {
            this.navigationParams.resultMessage = resultMsg;
            localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
            this.waitlistPortalService.applyToWaitlist(false);                    
        }

        localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));          
    }


}