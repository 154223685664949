import {Component, OnInit} from "@angular/core";
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { PortalParameters } from '../models/portalParameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationParameters } from "../models/navigationParameters";
import { WaitlistService } from "../services/waitlist.service";
import { ContactForm } from "../models/contactForm";
import { WaitlistPrograms } from "../models/waitlistPrograms";
import { WaitlistTypes } from "../models/waitlistTypes";
import { Waitlists } from "../models/waitlists";


@Component ({
    selector : 'paper-application-request',
    templateUrl : './paper-application-request.component.html',
    styleUrls: ['./paper-application-request.component.scss']
})

export class PaperApplicationRequestComponent implements OnInit{
    wlPrograms:WaitlistPrograms[] = [];
    wlTypes: WaitlistTypes[] = [];
    waitlists: Waitlists[] = [];
    portalParams:PortalParameters = new PortalParameters;
    formInvalid:boolean = false;
    contactForm:ContactForm = new ContactForm();
    navigationParams:NavigationParameters = new NavigationParameters();
    emailSubject:string = "Paper Application Request";
    title:string = "";
    wlNotSelected:boolean = false;
    phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
    isMobile:boolean = false;

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService,
        private router:Router
    ){
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){

        this.title = "Paper Application Request";
        this.portalParams.category = "Paper Application Request";

        this.waitlistService.getWaitlistPrograms()
            .subscribe(data  => { 
                this.wlPrograms = data;
                this.waitlistService.getWaitlistTypes()
                .subscribe(d => {
                    this.wlTypes = d;
                    this.wlTypes.forEach(t => {
                        this.portalParams.waitlistType = t.name;
                        this.portalParams.waitlistTypeCategory = "Waiting Lists";
                        this.portalParams.waitlistProgram = this.wlPrograms.find(y => y.waitlistProgramID == t.waitlistProgramID)?.name;
                        this.waitlistService.getWaitlistsByType(this.portalParams)
                        .subscribe(w => {
                            if (w && w.length > 0){
                                if (w.filter(x => x.statusID == 84 && !x.name.includes('Referral')).length == 0)
                                    t.descriptionText = "closed";
                                else
                                {
                                    w.forEach(w1 => {
                                        if (w1.statusID == 84){
                                            w1.accessCodeRequired = 0;  //using accesscodeRequired field for selection checkbox
                                            if (this.waitlists.filter(w2 => w2.waitlistID == w1.waitlistID).length == 0) this.waitlists.push(w1);
                                        }
                                    })
                                }
                            }
                           else
                                t.descriptionText = "closed";
                        })
                    });
            });
        }, (error) => {
            this.msg.showError(error, "Loading Error");
        });

    }

    sendEmail(f:any)
    {
        if (f.invalid)
            this.formInvalid = true;
        else if (this.waitlists.filter(x=>x.accessCodeRequired == 1).length == 0)
        {
            this.wlNotSelected = true;
        }
        else
        {
            this.spinnerService.show();

            this.waitlistService.getEmailTemplateByName(this.portalParams)
            .subscribe(t  => { 
                if (t) 
                {
                    t.message = this.buildMessage();
                    this.waitlistService.sendMail(t)
                    .subscribe(data  => { 
                        this.navigationParams.returnLocation = "dashboard";
                        this.navigationParams.resultMessage = "Hello!  Thank you for submitting the paper application request. Someone will follow up with you within 3 business days. Thank you.";
                        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                        this.spinnerService.hide();
                        this.router.navigate(['/result']);
                    }, (error) => {
                        this.spinnerService.hide();
                        this.msg.showError(error, "Loading Error");
                    });
                }
            }, (error) => {
                this.spinnerService.hide();
                this.msg.showError(error, "Loading Error");
            });
        }
    }

    buildMessage():string{

        let msg:string;

        msg = "<b>Name: </b>" + this.contactForm.requestorFirstName + " " + this.contactForm.requestorLastName + "<br/>";
        msg += "<b>Phone: </b>" + this.contactForm.requestorPhone + "<br/>";
        msg += "<b>Email: </b>";
        if (this.contactForm.requestorEmail && this.contactForm.requestorEmail.length > 0)
            msg += this.contactForm.requestorEmail + "<br/>";
        else
            msg += "<br/>";

        msg += "<b>Waiting Lists: </b><br/>";            
        msg += "<ol>"
        this.waitlists.forEach(w => {
            if (w.accessCodeRequired == 1)
            {
                msg +=  "<oi>" + w.name + "</oi></br>";
            }
        })
        msg += "</ol>"
        msg += "<br/>";

        if (this.contactForm.requestorMessage && this.contactForm.requestorMessage.length > 0)
            msg += "<br/>" + this.contactForm.requestorMessage + "<br/>";

        return msg;
    }

    cancel()
    {
        this.router.navigate(['/home']);
    }
}