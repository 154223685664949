export class User{
    id:string;
    email:string;
    confirmEmail:string;
    emailConfirmed:boolean;
    password:string;
    confirmPassword:string;
    token:string;
    cnvTcode:string;
    hcvTcode:string;
    affPcode: string;
    role:string;
}