<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="container">
            <div class="text-left fst-italic mb-5" *ngIf="userstatus">
                <div *ngIf="userstatus == 'already registered'" class="page-subtitle1">
                    An account exists with this email address. Click <a href="javascript:void(0)" (click)="setContent('login')">here</a> to login
                </div>
                <div *ngIf="userstatus == 'already registered with another email'" class="page-subtitle1">
                    <!-- <div>You already have an account with another email address.</div> -->
                    <div class="mb-5">You already have an account with the email address {{existingUser.email}}.</div>
                    <div class="mb-5">Click <a href="javascript:void(0)" (click)="setContent('login')">here</a> to login with {{existingUser.email}}.</div>
                    <div class="mb-5">Click <a href="javascript:void(0)" (click)="resetAndRegister()">here</a> to delete the current login and register with the new email.</div>
                </div>
                <div *ngIf="userstatus == 'success'" class="page-subtitle1">
                    <div *ngIf="resetEmail == ''">
                        Registration is successful. An email with a confirmation link is sent to this email address. Please use the link to confirm your registration.
                    </div>
                    <div *ngIf="resetEmail == 'resetEmail'">
                        Email reset is successful. An email with a confirmation link is sent to the new email address. Please use the link to confirm your new email address.
                    </div>
                </div>
                <div *ngIf="userstatus == 'not found'" class="alert alert-danger">
                    We could not locate your record with the information provided. Try again.
                </div>
                <div *ngIf="userstatus == 'failure'" class="alert alert-danger">
                    An error occurred during the registration process. Try again.
                </div>
                <div *ngIf="userstatus == 'delete failure'" class="alert alert-danger">
                    An error occurred deleting the current login. Try again.
                </div>
                <div *ngIf="userstatus == 'registration not confirmed'" class="alert alert-danger">
                    An account exists with this email but registration has not been confirmed yet. Please check your inbox for an email with a confirmation link and use the link to confirm your registration.
                    <div class="mt-3"><a href="javascript:void(0)" (click)="resendConfirmationLink()">Resend</a> a confirmation link</div>
                </div>
                <div *ngIf="userstatus == 'resent'" class="page-subtitle1">
                    An email with a confirmation link is sent to your email address. Please use the link to confirm your registration.
                </div>
                <div *ngIf="userstatus == 'resent error'" class="alert alert-danger">
                    Resend confirmation link failed. Invalid account. 
                </div>
            </div>

            <div *ngIf="userstatus == '' || userstatus == 'failure' || userstatus == 'not found' || userstatus == 'delete failure'" class="form-group fst-italic">
                <div *ngIf="email.invalid && (email.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="email.errors.required">Email address is required</div>
                    <div *ngIf="email.errors.pattern">Email address is not valid</div>
                </div>
                <small class="info-field-label" id="emailHelp">This email address will be used as your contact email</small>
                <input type="email" [(ngModel)]="user.email" name="email" id="email" #email="ngModel" placeholder="Email Address" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"/>

                <div *ngIf="confirmEmail.invalid && (confirmEmail.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="confirmEmail.errors.mustMatch">Email addresses do not match</div>
                    <div *ngIf="confirmEmail.dirty && confirmEmail.errors.required">Re-enter Email Address is required</div>
                </div>
                <input type="email" autocomplete="off" [(ngModel)]="user.confirmEmail" name="confirmEmail" id="confirmEmail"  #confirmEmail="ngModel" placeholder="Re-enter Email Address" required [mustMatch]="['email', 'confirmEmail']" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"/> 

                <div *ngIf="password.invalid && (password.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.pattern">Password should follow the pattern below</div>
                </div>
                <small class="info-field-label-sm" id="passwordHelp">Min. 8 characters with at least one upper case, one lower case, one number and one special character</small>
                <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="user.password" name="password" id="password" #password="ngModel" placeholder="Password" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$"/> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="toggleShowPassword()"></i>

                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords do not match</div>
                    <div *ngIf="confirmPassword.dirty && confirmPassword.errors.required">Re-enter Password is required</div>
                </div>
                <input [type]="showConfirmPassword ? 'text' : 'password'" [(ngModel)]="user.confirmPassword" name="confirmPassword" id="confirmPassword" #confirmPassword="ngModel" placeholder="Re-enter Password" required [mustMatch]="['password', 'confirmPassword']" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"/> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showConfirmPassword, 'fa-eye': showConfirmPassword}" (click)="toggleShowConfirmPassword()"></i>

                <div *ngIf="ssn.invalid && (ssn.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="ssn.errors.required">SSN is required</div>
                    <div *ngIf="ssn.errors.pattern">Please enter all 9 digits with no dashes</div>
                </div>
                <input type="text" [(ngModel)]="params.ssn" name="ssn" id="ssn" #ssn="ngModel" placeholder="Social Security # of the Head of Household - no dashes" required pattern="\d{9}"/>

                <div *ngIf="dob.invalid && (dob.dirty|| formInvalid)" class="errormsg">
                    <div *ngIf="dob.errors.required">Date of birth of head of household is required</div>
                    <div *ngIf="dob.errors.pattern">Date of birth must be a valid date in the format MM/DD/YYYY</div>
                    <div *ngIf="dob.errors.maxDate">Head of household cannot be younger than 14 years of age</div>
                </div>
                <input type="text" [(ngModel)]="params.dob" name="dob" id="dob" #dob="ngModel" placeholder="Date of Birth of Head of Household,   format MM/DD/YYYY" required pattern="^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|(0[1-9])|([1-2][0-9])|(3[0-1]))\/((19|20)\d{2})$" [maxDate]="maxDate|date"/> 

                <div class="text-right">
                    <button class="btn btn-general" type="submit" (click)="register(f.form)">Sign Up</button>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>