import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError} from 'rxjs';
import { GeneralInfo } from '../models/generalInfo';
import { Parameters } from '../models/parameters';
import { catchError } from 'rxjs/operators';
import { IncomeRent } from '../models/incomeRent';
import { FamilyMember } from '../models/familyMember';
import { VeteranStatus } from '../models/veteranStatus';
import { HomelessStatus } from '../models/homelessStatus';
import { DisplacementStatus } from '../models/displacementStatus';
import { LeaseInPlaceStatus } from '../models/leaseInPlaceStatus';
import { Applicant } from '../models/applicant';
import { DisabilityAccommodation } from '../models/disabilityAccommodation';
import { Lookup } from '../models/lookup';
import { RaceEthnicity } from '../models/raceEthnicity';
import { Preferences } from '../models/preferences';
import { IncomeLimits } from '../models/incomeLimits';
import { WaitlistApplication } from '../models/waitlistApplication';
import { environment } from 'src/environments/environment';


@Injectable()
export class ApplicantService{

    httpOptions = {
        headers: new HttpHeaders({
            'content-Type':'application/json',
            'Access-Control-Allow-Origin':'*',
            'Accept':'application/json'
        })
    };
    
    baseAPIUrl:string = environment.baseAPIUrl;

    constructor(private http:HttpClient) {
        //this.httpOptions.headers.append("Authorization", localStorage.getItem("jwtoken"));
    }

    getAllApplicantsHCV(params:Parameters):Observable<Applicant[]> {
        return this.http.post<Applicant[]>(this.baseAPIUrl + 'api/hcvapplicants/GetAllApplicantsBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant[]>('getAllApplicantsHCV', [])));
    }

    getAllApplicantsCNV(params:Parameters):Observable<Applicant[]> {
        return this.http.post<Applicant[]>(this.baseAPIUrl + 'api/cnvapplicants/GetAllApplicantsBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant[]>('getAllApplicantsCNV', [])));
    }

    getAllApplicantsAff(params:Parameters):Observable<Applicant[]> {
        return this.http.post<Applicant[]>(this.baseAPIUrl + 'api/affapplicants/GetAllApplicantsBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant[]>('getAllApplicantsaff', [])));
    }

    getWaitlistApplicationHCV(params:Parameters):Observable<WaitlistApplication> {
        return this.http.post<WaitlistApplication>(this.baseAPIUrl + 'api/hcvapplicants/GetWaitlistApplication', params, this.httpOptions)
            .pipe(catchError(this.handleError<WaitlistApplication>('getWaitlistApplicationHCV', null)));
    }

    
    getAllGeneralInfoBySSNDOBHCV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/hcvapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoBySSNDOBHCV', [])));
    }

    getAllGeneralInfoBySSNDOBCNV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/cnvapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoBySSNDOBCNV', [])));
    }

    getAllGeneralInfoBySSNDOBAff(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/affapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoBySSNDOBAff', [])));
    }


    getAllGeneralInfoByWaitlistAndStatusHCV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/hcvapplicants/GetGeneralInfosByWaitlistAndStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoByWaitlistAndStatusHCV', [])));
    }

    getAllGeneralInfoByWaitlistAndStatusCNV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/cnvapplicants/GetGeneralInfosByWaitlistAndStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoByWaitlistAndStatusCNV', [])));
    }


    getAllGeneralInfoHCV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/hcvapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoHCV', [])));
    }

    getAllGeneralInfoCNV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/cnvapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoCNV', [])));
    }

    getAllGeneralInfoAff(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/affapplicants/GetGeneralInfoBySSNDOB', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoAff', [])));
    }


    getGeneralInfoHCV(params:Parameters):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/hcvapplicants/GetGeneralInfoBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('getGeneralInfoHCV', null)));
    }

    getGeneralInfoCNV(params:Parameters):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/cnvapplicants/GetGeneralInfoBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('getGeneralInfoCNV', null)));
    }

    getGeneralInfoAff(params:Parameters):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/affapplicants/GetGeneralInfoBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('getGeneralInfoAff', null)));
    }


    getAllGeneralInfoByTCodeHCV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/hcvapplicants/GetAllGeneralInfosBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoHCV', [])));
    }

    getAllGeneralInfoByTCodeCNV(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/cnvapplicants/GetAllGeneralInfosBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoCNV', [])));
    }

    getAllGeneralInfoByPCodeAff(params:Parameters):Observable<GeneralInfo[]> {
        return this.http.post<GeneralInfo[]>(this.baseAPIUrl + 'api/affapplicants/GetAllGeneralInfosBytCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo[]>('getAllGeneralInfoAff', [])));
    }


    getRaceEthnicityHCV(params:Parameters):Observable<RaceEthnicity> {
        return this.http.post<RaceEthnicity>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantRaceEthnicity', params, this.httpOptions)
            .pipe(catchError(this.handleError<RaceEthnicity>('getRaceEthnicityHCV', null)));
    }

    getRaceEthnicityCNV(params:Parameters):Observable<RaceEthnicity> {
        return this.http.post<RaceEthnicity>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantRaceEthnicity', params, this.httpOptions)
            .pipe(catchError(this.handleError<RaceEthnicity>('getRaceEthnicityCNV', null)));
    }

    getRaceEthnicityAff(params:Parameters):Observable<RaceEthnicity> {
        return this.http.post<RaceEthnicity>(this.baseAPIUrl + 'api/affapplicants/GetApplicantRaceEthnicity', params, this.httpOptions)
            .pipe(catchError(this.handleError<RaceEthnicity>('getRaceEthnicityAff', null)));
    }


    getIncomeRentHCV(params:Parameters):Observable<IncomeRent> {
        return this.http.post<IncomeRent>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantIncomeRent', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeRent>('getIncomeRentHCV', null)));
    }

    getIncomeRentCNV(params:Parameters):Observable<IncomeRent> {
        return this.http.post<IncomeRent>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantIncomeRent', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeRent>('getIncomeRentCNV', null)));
    }

    getIncomeRentAff(params:Parameters):Observable<IncomeRent> {
        return this.http.post<IncomeRent>(this.baseAPIUrl + 'api/affapplicants/GetApplicantIncomeRent', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeRent>('getIncomeRentAff', null)));
    }


    getDisabilityAccommodationHCV(params:Parameters):Observable<DisabilityAccommodation> {
        return this.http.post<DisabilityAccommodation>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantDisabilityAccommodation', params, this.httpOptions)
            .pipe(catchError(this.handleError<DisabilityAccommodation>('getDisabilityAccommodationHCV', null)));
    }

    getDisabilityAccommodationCNV(params:Parameters):Observable<DisabilityAccommodation> {
        return this.http.post<DisabilityAccommodation>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantDisabilityAccommodation', params, this.httpOptions)
            .pipe(catchError(this.handleError<DisabilityAccommodation>('getDisabilityAccommodationCNV', null)));
    }

    getFamilyMembersHCV(params:Parameters):Observable<FamilyMember[]> {
        return this.http.post<FamilyMember[]>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantFamilyMembers', params, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember[]>('getFamilyMembersHCV', [])));
    }

    getFamilyMembersCNV(params:Parameters):Observable<FamilyMember[]> {
        return this.http.post<FamilyMember[]>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantFamilyMembers', params, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember[]>('getFamilyMembersCNV', [])));
    }

    getFamilyMembersAff(params:Parameters):Observable<FamilyMember[]> {
        return this.http.post<FamilyMember[]>(this.baseAPIUrl + 'api/affapplicants/GetApplicantFamilyMembers', params, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember[]>('getFamilyMembersAff', [])));
    }


    getVeteranStatusHCV(params:Parameters):Observable<VeteranStatus> {
        return this.http.post<VeteranStatus>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantVeteranStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<VeteranStatus>('getVeteranStatusHCV', null)));
    }

    getVeteranStatusCNV(params:Parameters):Observable<VeteranStatus> {
        return this.http.post<VeteranStatus>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantVeteranStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<VeteranStatus>('getVeteranStatusCNV', null)));
    }

    getVeteranStatusAff(params:Parameters):Observable<VeteranStatus> {
        return this.http.post<VeteranStatus>(this.baseAPIUrl + 'api/affapplicants/GetApplicantVeteranStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<VeteranStatus>('getVeteranStatusAff', null)));
    }


    getHomelessStatusHCV(params:Parameters):Observable<HomelessStatus> {
        return this.http.post<HomelessStatus>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantHomelessStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<HomelessStatus>('getHomelessStatusHCV', null)));
    }

    getHomelessStatusCNV(params:Parameters):Observable<HomelessStatus> {
        return this.http.post<HomelessStatus>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantHomelessStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<HomelessStatus>('getHomelessStatusCNV', null)));
    }

    getHomelessStatusAff(params:Parameters):Observable<HomelessStatus> {
        return this.http.post<HomelessStatus>(this.baseAPIUrl + 'api/affapplicants/GetApplicantHomelessStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<HomelessStatus>('getHomelessStatusAff', null)));
    }


    getDisplacementStatusHCV(params:Parameters):Observable<DisplacementStatus> {
        return this.http.post<DisplacementStatus>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantDisplacementStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<DisplacementStatus>('getDisplacementStatusHCV', null)));
    }

    getDisplacementStatusCNV(params:Parameters):Observable<DisplacementStatus> {
        return this.http.post<DisplacementStatus>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantDisplacementStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<DisplacementStatus>('getDisplacementStatusCNV', null)));
    }

    getDisplacementStatusAff(params:Parameters):Observable<DisplacementStatus> {
        return this.http.post<DisplacementStatus>(this.baseAPIUrl + 'api/affapplicants/GetApplicantDisplacementStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<DisplacementStatus>('getDisplacementStatusAff', null)));
    }


    getLeaseInPlaceStatusHCV(params:Parameters):Observable<LeaseInPlaceStatus> {
        return this.http.post<LeaseInPlaceStatus>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantLeaseInPlaceStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<LeaseInPlaceStatus>('getLeaseInPlaceStatusHCV', null)));
    }

    getLeaseInPlaceStatusCNV(params:Parameters):Observable<LeaseInPlaceStatus> {
        return this.http.post<LeaseInPlaceStatus>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantLeaseInPlaceStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<LeaseInPlaceStatus>('getLeaseInPlaceStatusCNV', null)));
    }


    getPreferencesHCV(params:Parameters):Observable<Preferences[]> {
        return this.http.post<Preferences[]>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantPreferences', params, this.httpOptions)
            .pipe(catchError(this.handleError<Preferences[]>('getPreferencesHCV', null)));
    }

    getPreferencesCNV(params:Parameters):Observable<Preferences[]> {
        return this.http.post<Preferences[]>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantPreferences', params, this.httpOptions)
            .pipe(catchError(this.handleError<Preferences[]>('getPreferencesCNV', null)));
    }


    getApplicantWaitlistStatusHCV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantWaitlistStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantWaitlistStatusHCV', null)));
    }


    getApplicantWaitlistStatusCNV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantWaitlistStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantWaitlistStatusCNV', null)));
    }


    getApplicantWaitlistStatusAff(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/affapplicants/GetApplicantWaitlistStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantWaitlistStatusAff', null)));
    }


    //This method is to get the bedroom size eligibility of a HCV applicant for CNV waitlists (applicant does not exist in CNV yet)
    getApplicantCNVWaitlistStatusHCV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/GetCNVApplicantWaitlistStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantCNVWaitlistStatusHCV', null)));
    }

        
    //This method is to get the bedroom size eligibility of a CNV applicant for HCV waitlists (applicant does not exist in HCV yet)
    getApplicantHCVWaitlistStatusCNV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/GetHCVApplicantWaitlistStatus', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantHCVWaitlistStatusCNV', null)));
    }


    getApplicantLastUpdatedDateHCV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/GetApplicantLastUpdatedDate', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantLastUpdatedDateHCV', null)));
    }


    getApplicantLastUpdatedDateCNV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/GetApplicantLastUpdatedDate', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantLastUpdatedDateCNV', null)));
    }


    getApplicantLastUpdatedDateAFF(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/affapplicants/GetApplicantLastUpdatedDate', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('getApplicantLastUpdatedDateAFF', null)));
    }


    getLookupsByCategoryHCV(params:Parameters):Observable<Lookup[]> {
        return this.http.post<Lookup[]>(this.baseAPIUrl + 'api/hcvapplicants/GetLookupsByCategory', params, this.httpOptions)
            .pipe(catchError(this.handleError<Lookup[]>('getLookupsByCategoryHCV', null)));
    }

    getLookupsByCategoryCNV(params:Parameters):Observable<Lookup[]> {
        return this.http.post<Lookup[]>(this.baseAPIUrl + 'api/cnvapplicants/GetLookupsByCategory', params, this.httpOptions)
            .pipe(catchError(this.handleError<Lookup[]>('getLookupsByCategoryCNV', null)));
    }


    getIncomeLimitsByCodeHCV(params:Parameters):Observable<IncomeLimits> {
        return this.http.post<IncomeLimits>(this.baseAPIUrl + 'api/hcvapplicants/GetIncomeLimitsByCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeLimits>('getIncomeLimitsByCodeHCV', null)));
    }

    getIncomeLimitsByCodeCNV(params:Parameters):Observable<IncomeLimits> {
        return this.http.post<IncomeLimits>(this.baseAPIUrl + 'api/cnvapplicants/GetIncomeLimitsByCode', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeLimits>('getIncomeLimitsByCodeCNV', null)));
    }


    upateApplicantHCV(applicant:Applicant) {
        return this.http.post<Applicant>(this.baseAPIUrl + 'api/hcvapplicants/UpdateApplicant', applicant, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant>('upateApplicantHCV', null)));
    }

    upateApplicantCNV(applicant:Applicant) {
        return this.http.post<Applicant>(this.baseAPIUrl + 'api/cnvapplicants/UpdateApplicant', applicant, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant>('upateApplicantCNV', null)));
    }

    upateApplicantAff(applicant:Applicant) {
        return this.http.post<Applicant>(this.baseAPIUrl + 'api/affapplicants/UpdateApplicant', applicant, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant>('upateApplicantAff', null)));
    }


    insertApplicantBasicHCV(generalInfo:GeneralInfo):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/hcvapplicants/InsertApplicantBasic', generalInfo, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('insertApplicantBasicHCV', null)));
    }

    insertApplicantBasicCNV(generalInfo:GeneralInfo):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/cnvapplicants/InsertApplicantBasic', generalInfo, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('insertApplicantBasicCNV', null)));
    }

    insertApplicantBasicAff(applicant:Applicant):Observable<GeneralInfo> {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/affapplicants/InsertApplicantBasic', applicant, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('insertApplicantBasicAff', null)));
    }


    insertApplicantFromCNVRecordHCV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/InsertApplicantFromCNVRecord', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('insertApplicantFromCNVRecordHCV', null)));
    }

    insertApplicantFromHCVRecordCNV(params:Parameters):Observable<Parameters> {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/InsertApplicantFromHCVRecord', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('insertApplicantFromHCVRecordCNV', null)));
    }

    insertSHRAWLApplicantHCV(applicant:Applicant) {
        return this.http.post<Applicant>(this.baseAPIUrl + 'api/hcvapplicants/InsertSHRAWLApplicant', applicant, this.httpOptions)
            .pipe(catchError(this.handleError<Applicant>('insertSHRAWLApplicantHCV', null)));
    }


    insertFamilyMemberHCV(familyMember:FamilyMember) {
        return this.http.post<FamilyMember>(this.baseAPIUrl + 'api/hcvapplicants/InsertFamilyMember', familyMember, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember>('insertFamilyMemberHCV', null)));
    }

    insertFamilyMemberCNV(familyMember:FamilyMember) {
        return this.http.post<FamilyMember>(this.baseAPIUrl + 'api/cnvapplicants/InsertFamilyMember', familyMember, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember>('insertFamilyMemberCNV', null)));
    }

    insertFamilyMemberAff(familyMember:FamilyMember) {
        return this.http.post<FamilyMember>(this.baseAPIUrl + 'api/affapplicants/InsertFamilyMember', familyMember, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember>('insertFamilyMemberAff', null)));
    }

    insertSHRAWLFamilyMember(familyMember:FamilyMember) {
        return this.http.post<FamilyMember>(this.baseAPIUrl + 'api/hcvapplicants/InsertSHRAWLFamilyMember', familyMember, this.httpOptions)
            .pipe(catchError(this.handleError<FamilyMember>('insertSHRAWLFamilyMember', null)));
    }


    deleteFamilyMembersHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/DeleteFamilyMembersByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('deleteFamilyMembersHCV', null)));
    }

    deleteFamilyMembersCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/DeleteFamilyMembersByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('deleteFamilyMembersCNV', null)));
    }

    deleteFamilyMembersAff(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/affapplicants/DeleteFamilyMembersByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('deleteFamilyMembersAff', null)));
    }


    insertChronoHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/InsertChrono', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('insertChronoHCV', null)));
    }

    insertChronoCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/InsertChrono', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('insertChronoCNV', null)));
    }


    updateChronoForFileNetUploadHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/UpdateChronoForFileNetUpload', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('updateChronoForFileNetUploadHCV', null)));
    }

    updateChronoForFileNetUploadCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/UpdateChronoForFileNetUpload', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('updateChronoForFileNetUploadCNV', null)));
    }


    updateApplicantEmailHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/UpdateApplicantEmail', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('updateApplicantEmailHCV', null)));
    }

    updateApplicantEmailCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/UpdateApplicantEmail', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('updateApplicantEmailCNV', null)));
    }

    updateApplicantEmailAff(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/affapplicants/UpdateApplicantEmail', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('updateApplicantEmailAff', null)));
    }


    updateApplicantContactInfoHCV(generalInfo:GeneralInfo) {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/hcvapplicants/UpdateApplicantContactInfo', generalInfo, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('updateApplicantContactInfoHCV', null)));
    }

    updateApplicantContactInfoCNV(generalInfo:GeneralInfo) {
        return this.http.post<GeneralInfo>(this.baseAPIUrl + 'api/cnvapplicants/UpdateApplicantContactInfo', generalInfo, this.httpOptions)
            .pipe(catchError(this.handleError<GeneralInfo>('updateApplicantContactInfoCNV', null)));
    }


    updateApplicantIncomeRentHCV(incomeRent:IncomeRent) {
        return this.http.post<IncomeRent>(this.baseAPIUrl + 'api/hcvapplicants/UpdateApplicantIncomeRent', incomeRent, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeRent>('updateApplicantIncomeRentHCV', null)));
    }

    updateApplicantIncomeRentCNV(incomeRent:IncomeRent) {
        return this.http.post<IncomeRent>(this.baseAPIUrl + 'api/cnvapplicants/UpdateApplicantIncomeRent', incomeRent, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeRent>('updateApplicantIncomeRentCNV', null)));
    }


    addApplicantToWaitlistHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/AddApplicantToWaitlist', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('addApplicantToWaitlistHCV', null)));
    }

    addApplicantToWaitlistCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/AddApplicantToWaitlist', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('addApplicantToWaitlistCNV', null)));
    }


    finalUploadProcessesByHMyHCV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/hcvapplicants/FinalUploadProcessesByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('finalUploadProcessesByHMyHCV', null)));
    }

    finalUploadProcessesByHMyCNV(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/cnvapplicants/FinalUploadProcessesByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('finalUploadProcessesByHMyCNV', null)));
    }

    finalUploadProcessesByHMyAff(params:Parameters) {
        return this.http.post<Parameters>(this.baseAPIUrl + 'api/affapplicants/FinalUploadProcessesByHMy', params, this.httpOptions)
            .pipe(catchError(this.handleError<Parameters>('finalUploadProcessesByHMyAff', null)));
    }


    uploadPdfToFileNetHCV(applicants:Applicant[]):Observable<string> {
        return this.http.post<string>(this.baseAPIUrl + 'api/hcvapplicants/UploadPdfToFileNet', applicants, this.httpOptions)
            .pipe(catchError(this.handleError<string>('uploadPdfToFileNetHCV', '')));
    }


    private handleError<T> (operation = 'operation', result?:T){
        return (error:any): Observable<T> => {
            console.error(error);
            return of(result as T)
        }
    }

}