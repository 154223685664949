<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="container fst-italic text-left">
            <div *ngIf="!loggedIn" class="text-left">
                <div class="alert alert-danger" *ngIf="emailConfirmed && userstatus == ''">Invalid email or password</div>
                <div class="alert alert-danger" *ngIf="!emailConfirmed && userstatus == ''">Your registration has not been confirmed yet. Please check your inbox for an email with a confirmation link and use the link to confirm your registration.</div>
                <div class="page-text" *ngIf="!emailConfirmed && userstatus != 'resent'" class="mt-3"><a href="javascript:void(0)" (click)="resendConfirmationLink()">Resend</a> a confirmation link</div>
                <div class="page-text" *ngIf="userstatus == 'resent'">
                    An email with a confirmation link is sent to your email address. Please use the link to confirm your registration.
                </div>
                <div class="alert alert-danger" *ngIf="userstatus == 'resent error'">
                    Resend confirmation link failed. Invalid account
                </div>
            </div>
            <br/>
            <div class="form-group fst-italic">
                <div *ngIf="email.invalid && (email.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="email.errors.required">Email is required</div>
                    <div *ngIf="email.errors.pattern">Email is not valid</div>
                </div>
                <input type="email" [(ngModel)]="user.email" name="email" id="email" #email="ngModel" placeholder="Email Address" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"/>

                <div *ngIf="password.invalid && (password.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="password.errors.required">Password is required</div>
                </div>
                <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="user.password" name="password" id="password" #password="ngModel" placeholder="Password" required autofocus /> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="toggleShowPassword()" aria-hidden="true"></i>
                <div>
                    <a href="javascript:void(0)" (click)="setContent('passwordresetrequest')" class="lnk-text-black"><b><i>Forgot Password?</i></b></a>
                </div>
                <!-- <div>
                    <a href="javascript:void(0)" (click)="resetEmail()" class="lnk-text-black"><b><i>Reset Email</i></b></a>
                </div> -->
            </div>
            <div class="form-group text-right">
                <button (click)="applicantLogin(f.form)" type="submit" class="btn btn-general">Sign In</button>
                <!-- <a routerLink="/registrationNew" class="btn btn-link">Sign Up</a> -->
                <!-- <a routerLink="/passwordResetRequest" class="btn btn-link">Forgot Password?</a> -->
            </div>
            <br/>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>