<div class="page-subtitle1 text-center mt-5 mb-3">See How the Programs Compare</div>
<div class="text-center">
   <table class="table-responsive mb-3">
      <thead class="text-white">
         <tr>
            <th class="col-1 table-content bdr-none"></th>
            <th class="col-2 table-content page text bdr-header bg-shradarkgreen page">Tenant-Based Vouchers</th>
            <th class="col-2 table-content page text bdr-header bg-shralightgreen">Project-Based Vouchers</th>
         </tr>
      </thead>
      <tbody class="bg-light">
         <tr class="text-small">
            <td class="table-content bdr-bottom">Where will I live?</td>
            <td class="table-content bdr-left-bottom text-left">It's your choice! You find and choose your rental unit anywhere in Sacramento County. It is the tenant's responsibility to find a home compatible with their family's needs, including any accessibility needs.</td>
            <td class="table-content bdr-left-bottom text-left">The rental assistance is attached to a specific rental unit. This removes the stress of finding your own home!</td>
         </tr>
         <tr class="text-small">
            <td class="table-content bdr-bottom">Can I move?</td>
            <td class="table-content bdr-left-bottom text-left">Yes, after living at the unit for the initial lease period (typically a year), you can use your housing voucher at another rental unit of your choice. Program participants must receive SHRA approval before moving.</td>
            <td class="table-content bdr-left-bottom text-left">Yes, after living at the site for 12 months you may be eligible for a tenant-based voucher when one becomes available.</td>
         </tr>
         <tr class="text-small">
            <td class="table-content bdr-bottom">How much will I pay in rent? </td>
            <td class="table-content bdr-left-bottom text-left">During the first year, voucher holders pay 30-40% of their monthly income towards rent and utilities.</td>
            <td class="table-content bdr-left-bottom text-left">Tenants will pay 30% of their income to rent and utilities.</td>
         </tr>
         <tr class="text-small">
            <td class="table-content bdr-bottom">Does SHRA have to approve the unit?</td>
            <td class="table-content bdr-left-bottom text-left">Yes. SHRA must review the unit to ensure it is approvable before it can be inspected. All units must pass inspection before the landlord can receive the subsidy.</td>
            <td class="table-content bdr-left-bottom text-left">Yes, the unit must pass inspection.</td>
         </tr>
         <tr>
            <td class=""></td>
            <td class="border-left">
               <img class="img-expand img-fluid mb-5" [src]="getImagePath('tenant based vouchers.png')" alt="" width="100%" height="100%" (click)="openInANewTab($event)">
            </td>
            <td class="bdr-left table-content">
               <img class="img-expand img-fluid mb-5" [src]="getImagePath('project based vouchers.png')" alt="" width="100%" height="100%" (click)="openInANewTab($event)">
            </td>
         </tr>
      </tbody>
   </table>
</div>
