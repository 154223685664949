import { UntypedFormControl, FormGroup } from '@angular/forms';

// custom validator to check that two fields match
export function MaxNumber(maxNumber:number) {
    return (control: UntypedFormControl) => {
        // return null if controls haven't initialised yet
        if (!control) {
          return null;
        }

        // set error on matchingControl if validation fails
        //if (!isNaN(control.value) || control.value > maxNumber) {
        if (control.value > maxNumber) {            
            setTimeout(() => {
                control.setErrors({ maxNumber: true });
              });
        } else {
            control.setErrors(null);
        }

    }
}