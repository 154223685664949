import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../models/user';
import { UserService } from './user.service';

@Injectable()
export class AuthService{

    constructor(
        private jwtHelper:JwtHelperService,
        private userService:UserService,
        private router:Router
        ) {}

        

    isUserAuthenticated(){
        const token = JSON.parse(localStorage.getItem("jwtoken"));

        if (token && !this.jwtHelper.isTokenExpired(token)){
            return true;
        }
        return false;        
    }

    getUser(user:User):Promise<User>{
        return new Promise((resolve, reject) => {
            this.userService.getUser(user)
            .subscribe(u =>{
                resolve(u);
            });
        });
    }

    register(user:User):Promise<User>{
        return new Promise((resolve, reject) => {
            this.userService.register(user)
            .subscribe(u =>{
                resolve(u);
            });
        });
    }

    deleteUser(user:User):Promise<User>{
        return new Promise((resolve, reject) => {
            this.userService.deleteUser(user)
            .subscribe(u =>{
                resolve(u);
            });
        });
    }

    confirmRegistration(user:User):Promise<User>{
        return new Promise((resolve, reject) => {
            this.userService.confirmRegistration(user)
            .subscribe(u =>{
                resolve(u);
            });
        });
    }
    
    resendConfirmationLink(user:User):Promise<User>{
        return new Promise((resolve, reject) => {
            this.userService.resendConfirmationLink(user)
            .subscribe(u =>{
                resolve(u);
            });
        });
    }

    logout(){
        localStorage.clear();
        this.router.navigate(['/home']);
    }

}