<div class="col-md-12 form-component">
    <div class="row">
      <div class="col-md-12">
        <!-- <span (click)="backToDashboard()" class="link"><i class="fas fa-arrow-left"></i> Back to Tenant Dashboard</span> -->
      </div>
      <div class="col-md-12 p-0 text-center mt-5">
        <h3 class="page-title text-green">{{myForm.formTitle}}</h3>
      </div>
    </div>
    <div class="row mt-5 mb-5 ml-5">
      <ul class="nav nav-tabs col-md-12 p-0" *ngIf='mySectionTabs && mySectionTabs.length'>
        <li *ngFor='let sec of mySectionTabs let i = index;' class="nav-item cursor mr-5" [hidden]='sec.isHidden'>
          <a data-toggle="pill" [class]="'activeMenu' == 'sec.sectionID.toString()' ? 'activeTab' : 'text-primary'" href="#tab{{sec.sectionID}}" (click)='setActiveTab(sec.sectionID, sec.sectionName, sec.visited);'>{{sec.sectionNumberLabel}}</a>
        </li>
      </ul>
      <div class="col-md-12 p-0">
        <section #activeSection *ngIf='currentSection' [myRouteParams]='myRouteParams' [section]='currentSection' [allResponses]='myFieldResponses' [allRules]='myRules' (navigateEvent)="handleNavigation($event)"></section>
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>      
    </div> 
</div>
