import {Component, OnInit} from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
    selector: 'portalfooter',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit{
    currentYear:number;
    isMobile:boolean = false; 
    imgFolder: string = environment.imgFolder;

    ngOnInit()
    {
        this.currentYear = new Date(Date.now()).getFullYear();
        this.isMobile = window.innerWidth < 768;
    }

}