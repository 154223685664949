import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError} from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';



@Injectable()
export class UserService{

    httpOptions = {
        headers: new HttpHeaders({
            'content-Type':'application/json',
            'Access-Control-Allow-Origin':'*',
            'Accept':'application/json'
        })
    };

    baseAPIUrl:string = environment.baseAPIUrl;

    constructor(private http:HttpClient) {
        this.httpOptions.headers.append("Authorization", localStorage.getItem("jwtoken"));
    }

    getUser(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/GetUser', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('GetUser', null)));
    }


    register(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/Register', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('Register', null)));
    }

    login(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/Login', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('Login', null)));
    }

    confirmRegistration(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ConfirmRegistration', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('ConfirmRegistration', null)));
    }

    resendConfirmationLink(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ResendConfirmationLink', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('resendConfirmationLink', null)));
    }

    resetPasswordRequest(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ResetPasswordRequest', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('ResetPasswordRequest', null)));
    }

    resetEmailRequest(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ResetEmailRequest', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('ResetEmailRequest', null)));
    }

    getResetPasswordUser(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/GetResetPasswordUser', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('GetResetPasswordUser', null)));
    }

    resetPassword(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ResetPassword', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('ResetPassword', null)));
    }

    changePassword(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/ChangePassword', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('ChangePassword', null)));
    }

    updateHCVTCode(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/UpdateUserHCVtcode', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('updateHCVTCode', null)));
    }

    updateCNVTCode(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/UpdateUserCNVtcode', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('updateCNVTCode', null)));
    }

    updateAffPCode(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/UpdateUserAffpcode', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('updateAffPCode', null)));
    }

    updateUserRole(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/UpdateUserRole', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('updateUserRole', null)));
    }

    deleteUser(user:User):Observable<User>{
        return this.http.post<User>(this.baseAPIUrl + 'api/portalusers/DeleteUser', user, this.httpOptions)
            .pipe(catchError(this.handleError<User>('deleteUser', null)));
    }

    private handleError<T> (operation = 'operation', result?:T){
        return (error:any): Observable<T> => {
            console.error(error);
            return of(result as T)
        }
    }

}