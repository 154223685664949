export class GeneralInfo{
    hmy:number;
    pCode:string;
    firstName:string;
    middleInitial:string;
    lastName:string;
    mailingAddress:string;
    apt:string;
    city:string;
    otherCity:string;
    state:string;
    zipCode:string;
    ssn:string;
    dob:Date;
    gender:string;
    homeTel:string;
    cellTel:string;
    locatedSacramento?:number;
    disability?:number;
    email:string;
    tenantStatus:string;
}