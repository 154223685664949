import { WaitlistTypes } from "./waitlistTypes";

export class NavigationParameters{
    loggedIn:boolean;
    loginEmail:string;
    userRole:string;
    tenantStatusHCV:string;
    tenantStatusCNV:string;
    tenantStatusAFF:string;
    returnLocation:string;
    resultMessage:string;
    resultMessageType:string;
    cnvTCode:string;
    hcvTCode:string;
    affPCode:string;
    updateAction:string;
    waitlistProgram:string;
    waitlistCategory:string;
    waitlistType:string;
    waitlistTypesModel:WaitlistTypes;
    waitlist:string;
    availableBR:string;
    formMode:string;
    formNavigation:string;
    recordSource:string;
    hcvTBWLText:string;
    emergencyHousingText:string;
}