<div class="card section-component">
  <div class="card-header card-primary">
    <div class="row">
      <div class="col-md-12 text-center">
        <div class="page-subtitle1" id="tab1" [innerHtml]='sectionTitle' *ngIf="!section.isHidden">
        </div>
      </div>
    </div>
  </div>
  <div class="card-body" *ngIf='myQuestions && formIsReady'>
    <div class="row" *ngFor='let question of myQuestions'>
      <div class="col-md-12" *ngIf="!question.isHidden">
        <div class="card mb-3 question-container">
          <!-- <div [ngClass]="question.isBold ? 'q-label card-header' : 'card-header card-info'" [id]="'questionID-' + question.questionID"              -->
          <div [ngClass]="question.questionFieldType == 'QuestionTitle' ? 'question-title' : question.questionFieldType == 'QuestionSubTitle1' ? 'question-subtitle1' : question.questionFieldType == 'QuestionSubTitle2' ? 'question-subtitle2' : 'question-label'" [id]="'questionID-' + question.questionID"             
            attr.data-sectionname="{{question.sectionName}}"  attr.data-sectionlabel="{{question.sectionLabel}}">
            <!-- {{question.questionNumberLabel}} {{question.questionLabel}} -->
            <div *ngIf="question.questionLabel != 'No Question'" class="ml-2">
              {{question.questionNumberLabel}}
              <span [innerHTML] = "question.questionLabel"></span>
            </div>
          </div>
          <div *ngFor='let fieldGroup of question.fieldGroups let i = index;'>
            <div class="card-body" [ngClass]="{'d-none': fieldGroup.isHidden || fieldGroup.groupName == 'No Field Group'}">
              <table id="{{fieldGroup.groupName}}">
                <tr id="{{fieldGroup.groupName}}1">
                  <td>
                    <div *ngIf="!fieldGroup.isHidden">
                      <div [ngClass]="fieldGroup.groupName">
                        <div *ngFor="let field of fieldGroup.fields; let j=index;">
                          <div class="col-sm-12" *ngIf="j==0">
                            <div *ngIf="field.fieldResponses?.length" class="row">
                              <div id="{{fieldGroup.groupName}}1" class="col-sm-12" *ngFor="let response of field.fieldResponses; let k=index;">
                                <hr class="m-1"  *ngIf='fieldGroup.isRepeatable'/>
                                <div class="col-sm-12 text-right" *ngIf='fieldGroup.isRepeatable && editMode && field.fieldResponses.length > 1 && !fieldGroup.removeButtonHide'>
                                    <i id="Button{{fieldGroup.groupName}}1" class="fas fa-times" (click)="removeFieldGroup(question.questionID, fieldGroup.fieldGroupID, k)"></i>  
                                </div>
                                <field-group 
                                  [myRouteParams]='myRouteParams' 
                                  [visited]='section.visited'
                                  [isFAQ]='isFAQ'
                                  [fieldGroups]='fieldGroup' 
                                  [resIndex]=k 
                                  [editMode]="editMode" 
                                  (eventBlur)="handleEventBlur($event)"
                                  class="w-100">
                                </field-group>
                                <hr class="m-1"  *ngIf='fieldGroup.isRepeatable'/>
                              </div>
                            </div>
                            <div *ngIf="!field.fieldResponses?.length" class="row">
                              <hr class="m-1"  *ngIf='fieldGroup.isRepeatable'/>
                              <field-group 
                                [myRouteParams]='myRouteParams' 
                                [visited]='section.visited'
                                [isFAQ]='isFAQ'
                                [fieldGroups]='fieldGroup' 
                                [resIndex]=0
                                [editMode]="editMode" 
                                (eventBlur)="handleEventBlur($event)"
                                class="w-100">
                              </field-group>
                              <hr class="m-1"  *ngIf='fieldGroup.isRepeatable'/>
                            </div>
                          </div>
                        </div>
                      </div>
                      
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class='col-md-12'>
                    <div *ngIf="!fieldGroup.isHidden" class="col-md-12">
                      <div *ngIf='fieldGroup.isRepeatable && !fieldGroup.addButtonHide' class='col-md-2 pl-0 repeatable'>
                        <button [disabled]="!checkResponsesExist(fieldGroup) || !editMode" (click)="addFieldGroup(question.questionID, fieldGroup.fieldGroupID)"
                          id="Add {{fieldGroup.groupName}}" class="btn btn-outline-primary btn-sm">
                          <i class="fas fa-plus"></i> Add More
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="card-footer text-muted" *ngIf="!question.isBold && !question.isHidden && question.questionFieldType != 'QuestionTitle' && question.questionFieldType != 'QuestionSubTitle1' && question.questionFieldType != 'QuestionSubTitle2'"></div>
        </div>
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>
    </div>

    <!-- Nothing to save from the FAQ, hide the Save button -->
    <div *ngIf="routerLocation !== '/faq' && section?.sectionID !== 0 && section?.editMode && !completedForm" class="row nav-buttons">
      <div class="col">
        <button *ngIf="hasBackButton" (click)="navigate('back')" id="button1" class="btn btn-primary btn-group-sm pull-left">Go back</button>
      </div>

      <div class="col text-right" *ngIf="!isFinalSaveAndContinue || isFinalSaveAndContinue && readyToSubmit">
        <button (click)="navigate('next')" id="button1" class="btn btn-primary btn-group-sm pull-right">Save and continue</button>
      </div>
      <div class="col text-right" *ngIf="isFinalSaveAndContinue && !readyToSubmit">
          <span class="pr-3 text-info" *ngIf="showSavedMessage">Form Saved..</span>
          <button (click)="saveFieldResponses()" id="button1" class="btn btn-primary btn-group-sm pull-right">Save</button>
      </div>

      <div class="col-12 mt-4" *ngIf="isFinalSaveAndContinue && !readyToSubmit">
        <div class="alert alert-warning text-center" role="alert">
            One or more sections has incomplete answers. Please complete all sections before attempting to submit your form.
        </div>
      </div>
    </div>

    <!--We're not on FAQ and SectionID is 0, so we're on the Review Tab-->
    <div *ngIf="routerLocation !== '/faq' && section?.sectionID === 0 && (section?.editMode || !completedForm)" class="row nav-buttons">
      <div class="col-12 mb-3">
        <app-signature-pad (signedEvent)="handleEventSigned($event)"></app-signature-pad>
      </div>
      <div class="col">
        <button *ngIf="hasBackButton" (click)="navigate('back')" id="button1" class="btn btn-primary btn-group-sm pull-left">Go back</button>
      </div>
      <div class="col text-right">
        <button [disabled]="!hasSignature" (click)="saveAndComplete()" id="button2" class="btn btn-primary btn-group-sm">Submit</button>
      </div>
    </div>
  </div>
</div>