import { animate, state, style, transition, trigger } from '@angular/animations';
import {Component, Input, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { FAQ } from '../models/faq';
import { FAQForm } from '../models/faqForm';
import { PortalParameters } from '../models/portalParameters';
import { MessageService } from '../services/message.service';
import { WaitlistService } from '../services/waitlist.service';

@Component({
    selector: 'faq-panel',
    templateUrl: './faq-panel.component.html' ,
    styleUrls: ['./faq-panel.component.scss'],
    animations: [
        trigger('expand', [
            state('open', style({
                height: 'auto',
                opacity:1
            })),
            state('close', style({
                height: '0px',
                opacity:0
            })),
            transition('open=>close', animate('0.2s ease-out')),
            transition('close=>open', animate('0.2s ease-in'))
        ])
    ]
})

export class FAQPanelComponent implements OnInit{

    @Input() selectedForm:string;
    faqForm:FAQForm;
    faqForms:FAQForm[] =[];
    questions:string[] =[];
    allFaqs:FAQ[] = [];
    faqs:FAQ[] = [];
    params: PortalParameters = new PortalParameters();
    imgFolder: string = environment.imgFolder;
    selectedFAQFormID:number = 0;
    isMobile:boolean = false;
    

    constructor (
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private msg:MessageService,
        private router:Router,
        private modalService: NgbModal
        ){
            this.isMobile = window.innerWidth < 768;
        }


    ngOnInit()
    {
        this.spinnerService.show();
        this.questions = [];

        this.imgFolder = this.imgFolder + "faq/";
        this.waitlistService.getFAQForms()
        .subscribe(d => {
            this.waitlistService.getFAQs()
            .subscribe(f=>{
                this.allFaqs = f;
                this.faqForms = d;
                //filter only FAQs
                this.faqForms = this.faqForms.filter(f=> f.notes?.toUpperCase().includes('FAQ'));
                this.selectedForm = this.selectedForm && this.selectedForm.length > 0 && this.faqForms.find(f => f.title.toUpperCase().includes(this.selectedForm.toUpperCase()))?.title.length > 0 ? this.selectedForm : this.faqForms.find(f => f.displayOrder === 1).title;
                this.selectedFAQFormID = this.faqForms.find(f => f.description.toUpperCase().includes(this.selectedForm.toUpperCase())).faqFormID;
                this.itemClick(this.selectedFAQFormID);

            });
        }, (err) => {
            this.msg.showError(err, "FAQ Error");
        });  
        this.spinnerService.hide();          
    }


    // ngAfterViewInit (){
    //     if(document.getElementById('close')){
    //         // document.getElementById('close').addEventListener('click', this.closeClick.bind(this));
    //         document.getElementById('close').onclick = (e) => this.closeClick();
    //     }
    // } 


    ngAfterViewChecked (){
        if(document.getElementById('close')){
            // document.getElementById('close').addEventListener('click', this.closeClick.bind(this));
            document.getElementById('close').onclick = (e) => this.closeClick();
        }
    } 

    toggleAccordian(event) {
        var element = event.target;
        element.classList.toggle("active");
        var panel = element.nextElementSibling;
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
        }
    }

    toggleContentAccordian(event) {
        var element = event.target;
        element.classList.toggle("active");

        var panel = element.nextElementSibling;
        var parentPanel = element.closest('.panel');
        if (panel.style.maxHeight) {
          panel.style.maxHeight = null;
          parentPanel.style.maxHeight = parentPanel.scrollHeight + "px";
        } else {
          panel.style.maxHeight = panel.scrollHeight + "px";
          parentPanel.style.maxHeight = parentPanel.scrollHeight + panel.scrollHeight + "px";
        }

    }

    contentClose(event){
        var panel = event.target.closest('.panel');
        panel.style.maxHeight = null;

        var element = panel.previousElementSibling;
        element.classList.toggle("active");
    }


    goToItem(item:string){
        this.selectedForm = item.replace('Go to ', '');
        this.selectedFAQFormID = this.faqForms.find(f => f.description.toUpperCase().includes(this.selectedForm.toUpperCase())).faqFormID;
        this.itemClick(this.selectedFAQFormID);
    }

    gotoPage(pageRoute:string){
        this.router.navigate(['/' + pageRoute]);
        this.closeClick();
    }
    

    itemClick(formID:number){

        //clear searchText
        var searchText = document.getElementById('searchText') as HTMLInputElement;
        if (searchText) searchText.value = "";

        this.faqForm = this.faqForms.find(fr => fr.faqFormID === formID);
        this.params.faqFormID = formID;
        this.questions = [];

        //set selection
        this.selectedFAQFormID = formID;
        
        this.faqs = this.allFaqs.filter(f=>f.formID === formID);
        this.faqs.forEach(q =>{
                var index = this.questions.indexOf(q.question);
            if (index < 0) 
                this.questions.push(q.question);
        })

    }


    isSelectedRow(formID:number):boolean{
        return this.selectedFAQFormID === formID;
    }


    search(searchText:string): void{

        if (searchText && searchText != "" && searchText.length > 0)
        {
            this.selectedFAQFormID = 0;
            this.faqs = this.allFaqs.filter(f=>f.question.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) 
                                            || f.answer.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
            this.questions = [];
            this.faqs.forEach(q =>{
                    var index = this.questions.indexOf(q.question);
                if (index < 0) 
                    this.questions.push(q.question);
            })
        }
        else
            this.itemClick(this.faqForms.find(f => f.displayOrder === 1).faqFormID);
    }

    closeClick(){
        this.selectedForm = "";
        this.modalService.dismissAll();
    }

}