<body>
    <div *ngIf="prefs">
       <h1 class="text-center mb-5 mt-5">{{title}}</h1>
       <div *ngFor="let p of prefs">
          <div class="text-center">
             <button (click)="toggleAccordian($event)" class="accordion" [innerHTML] = "p.name"></button>
             <div class="panel bg-transparent">
                <div class="text-white ml-5 lnbr"><p [innerHTML] = "p.detailText"></p></div>
              </div>
          </div>
       </div>
    </div>
</body>
 