
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Field } from './../models/field';
import { FieldGroup } from './../models/fieldGroup';
import { FieldResponse } from './../models/fieldResponse';
import { Question } from './../models/question';
import { Rule } from './../models/rule';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProcessLookup } from '../models/processLookup';
import { ApplicantProcessesService } from '../services/applicant-processes.service';
import { WaitlistService } from '../services/waitlist.service';
import { PortalParameters } from '../models/portalParameters';
import { FormService } from '../services/form.service';
import { PortalLookups } from '../models/portalLookups';

@Component({
  selector: 'section',
  templateUrl: 'section.component.html',
  styleUrls: ['section.component.scss']
})
export class SectionComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() section: any;
  @Input() myRouteParams: any;
  @Input() allRules: Rule[] = [];
  @Input() allResponses: FieldResponse[] = [];
  @Output() navigateEvent = new EventEmitter();

  params:PortalParameters = new PortalParameters();
  fieldResponsesSaveList: FieldResponse[] = [];
  showRepeatable = false;
  sectionTitle: string;
  activeSection: number;
  myQuestions: Question[];
  myFieldGroups: FieldGroup[] = [];
  myFields: Field[] = [];
  allFields: Field[] = [];
  allFieldGroups: FieldGroup[] = [];
  // allRules: Rule[] = [];
  ruleTypes: PortalLookups[] = [];
  // allResponses: FieldResponse[] = [];
  formID: number;
  applicantFormID: number;
  userLoggedIn: string;
  editMode = true;
  errorMessage: string;
  applicantCode: string;
  processID: string;
  routerLocation: any;
  firstLoad = true;
  formIsReady = false;
  hasSignature = false;
  signature: any;
  showSavedMessage = false;
  staffMode: boolean;
  dropDownListDefaultValue: string = '*** Choose ***';
  ruleTypeCategoryID: number = 15;

  constructor(
    private applicantProcessService: ApplicantProcessesService,
    private waitlistService:WaitlistService,
    private formService:FormService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnChanges(): void {
    this.sectionTitle = this.section.sectionDescription;
    this.myQuestions = this.section.questions;
    this.editMode = this.section.editMode == 1 ? true : false;
    this.formID = +JSON.parse(localStorage.getItem('FormID'));
    this.applicantFormID = +JSON.parse(localStorage.getItem('applicantFormID'));

    if (this.formID > 0 && !this.firstLoad) {
      this.allFields = this.getAllFields();
      this.allFieldGroups = this.getAllFieldGroups();
      this.firstLoad = false;
      this.setRules();
      this.removeFieldGroupsForRemovedResponseLookups();

    }
  }


  ngOnInit(): void {
    this.applicantCode =  JSON.parse(localStorage.getItem('tcode'));
    this.processID = JSON.parse(localStorage.getItem('ProcessID'));
    this.activeSection = 0;
    this.formID = +JSON.parse(localStorage.getItem('FormID'));
    this.applicantFormID = +JSON.parse(localStorage.getItem('applicantFormID'));
    this.userLoggedIn = this.applicantCode;
    this.routerLocation = this.router.url;
    this.allFields = this.getAllFields();

  }

  ngAfterViewInit() {
    this.setupSection();
  }

  handleEventSigned(signatureObject) {
    this.hasSignature = signatureObject.isSigned;
    this.signature = signatureObject.signature;
    console.log(this.signature);
  }

  checkForVisibleQuestions() {
    const sectionHasVisibleQuestions = this.myQuestions.filter(q => !q.isHidden);
    if (sectionHasVisibleQuestions.length === 0) {
        this.saveFieldResponses();
      }
  }

  // get all fieldGroups in a collection
  getAllFieldGroups(): FieldGroup[] {
    const curFieldGroups: FieldGroup[] = [];

    for (let i = 0; i < this.myQuestions.length; i++) {
      for (let j = 0; j < this.myQuestions[i].fieldGroups.length; j++) {
        curFieldGroups.push(this.myQuestions[i].fieldGroups[j]);
      }
    }

    return curFieldGroups;
  }

  // get all fields in a collection
  getAllFields(): Field[] {
    const curFields: Field[] = [];
    if (this.myQuestions) {
      for (let i = 0; i < this.myQuestions.length; i++) {
        for (let j = 0; j < this.myQuestions[i].fieldGroups.length; j++) {
          for (let k = 0; k < this.myQuestions[i].fieldGroups[j].fields.length; k++) {
            curFields.push(this.myQuestions[i].fieldGroups[j].fields[k]);
          }
        }
      }
    }
    return curFields;
  }

  // get all field responses in a collection
  getCurrentSectionResponses(): FieldResponse[] {
    const curFieldResponses: FieldResponse[] = [];

    for (let i = 0; i < this.myQuestions.length; i++) {
      for (let j = 0; j < this.myQuestions[i].fieldGroups.length; j++) {
        for (let k = 0; k < this.myQuestions[i].fieldGroups[j].fields.length; k++) {
          // if we're passing in a label, mark the field's card body as hidden and set the question to bold
          if (this.myQuestions[i].fieldGroups[j].fields[k].fieldTypeName === 'Label') {
            this.myQuestions[i].fieldGroups[j].isHidden = true;
          }

          if (this.myQuestions[i].fieldGroups[j].fields[k].fieldResponses) {
            for (let l = 0; l < this.myQuestions[i].fieldGroups[j].fields[k].fieldResponses.length; l++) {
              // split up dates into three props so we can work with them one one line
              if (this.myQuestions[i].fieldGroups[j].fields[k].fieldTypeName === 'Calendar'
                && this.myQuestions[i].fieldGroups[j].fields[k].fieldResponses[l].response.length > 0) {
                const splitDate = this.myQuestions[i].fieldGroups[j].fields[k].fieldResponses[l].response.split('/');
              }
              curFieldResponses.push(this.myQuestions[i].fieldGroups[j].fields[k].fieldResponses[l]);
            }
          }
        }
      }
    }
    return curFieldResponses;
  }

  setupSection() {
    this.spinner.show();
    this.params.categoryID = this.ruleTypeCategoryID;
    this.waitlistService.getPortalLookupsByCategoryID(this.params).subscribe((data: any) => {
      this.ruleTypes = data;
      // this.getAllResponses();
      this.firstLoad = false;
      this.setRules();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error);
    });
  }


  getLookupByCategory(categoryID: number): ProcessLookup[] {
    let lookupData: ProcessLookup[] = [];
    this.params.categoryID = categoryID;
    this.waitlistService.getPortalLookupsByCategoryID(this.params).subscribe((data: any) => {
      lookupData = data;
    }, (error) => {
      this.toastr.error(error);
    });
    return lookupData;
  }

  getRuleTypes() {
    this.params.categoryID = this.ruleTypeCategoryID;
    this.waitlistService.getPortalLookupsByCategoryID(this.params).subscribe((data: any) => {
      this.ruleTypes = data;
    }, (error) => {
      this.toastr.error(error);
    });
  }

  checkResponsesExist(fieldGroup: FieldGroup): boolean {

    let currField: Field;

    if (fieldGroup.fields.length) {
      for (let i = 0; i < fieldGroup.fields.length; i++) {
        currField = fieldGroup.fields[i];
        if (currField.fieldResponses && currField.fieldResponses.length) {
          const maxRepeatNumber = currField.fieldResponses[currField.fieldResponses.length - 1].repeatNumber;

          const fieldRes = currField.fieldResponses.find(res => res.repeatNumber === maxRepeatNumber);
          if (fieldRes) {
            const currFieldRes = fieldRes.response;
            if (currFieldRes && currFieldRes.length && currFieldRes !== this.dropDownListDefaultValue) {
              return true;
            }
          }
        }
      }
    }
    return false;
  }


  hasMaxResponses(fieldGroup) {
    // If this repeatable contains unique lookups, disable the button to add more formGroups once we've exhausted our lookup options
    const uniqueFields = fieldGroup.fields.find(cg => cg.lookupResponseForFormQuestionID !== 0);
    const uniqueResponses = fieldGroup.fields.find(cg => cg.fieldResponses);
    if (uniqueFields) {
      return uniqueFields.lookups.length === uniqueResponses.fieldResponses.length;
    }
  }

  updateAffectedFieldLookupResponses(formQuestionID: number) {
    // get all fields that has the updated field for lookup values
    this.allFields = this.getAllFields();
    const lookupCategoryField = this.allFields.find(f => f.formQuestionID === formQuestionID);
    const affectedFields = <Field[]>this.allFields.filter(f => f.lookupResponseForFormQuestionID === formQuestionID);

    if (affectedFields) {
      affectedFields.forEach((curField) => {
        // Clear the lookup value
        curField.lookups.splice(0, curField.lookups.length);

        lookupCategoryField.fieldResponses.forEach((fr) => {
          const curLookup = {
            fieldID: curField.fieldID,
            name: fr.response,
            value: fr.response
          };
          curField.lookups.push(<ProcessLookup>curLookup);
        });
      });
    }
  }

  // add a lookup value from the list for the given field
  addFieldLookupResponse(curField: Field, fieldResponse: string) {
    let curLookup: ProcessLookup;
    if (curField) {
      curLookup = curField.lookups.find(f => f.name === fieldResponse);
      const indexLU = curField.lookups.lastIndexOf(curLookup);
      // remove the lookup value
      if (indexLU === -1) {
        const newLookup = {
          fieldID: curField.fieldID,
          name: fieldResponse,
          value: fieldResponse
        };
        curField.lookups.push(<ProcessLookup>newLookup);
      }
    }
  }

  // remove a lookup value from the list for the given field
  removeFieldLookupResponse(curField: Field, fieldResponse: string) {
    let curLookup: ProcessLookup;
    if (curField) {
      curLookup = curField.lookups.find(f => f.name === fieldResponse);
      const indexLU = curField.lookups.lastIndexOf(curLookup);
      // remove the lookup value
      if (indexLU !== -1) {
        curField.lookups.splice(indexLU, 1);
      }
    }
  }

  addFieldGroup(questionID: number, fieldGroupID: number) {
    // Add a response for each field in the group increasing repeat number by 1
    const currentQuestion = this.myQuestions.find(q => q.questionID === questionID);
    this.myFieldGroups = currentQuestion.fieldGroups;
    this.myFields = this.myFieldGroups.find(fg => fg.fieldGroupID === fieldGroupID).fields;
    let maxRepeatNumber = 0;

    if (this.myFields[0].fieldResponses && this.myFields[0].fieldResponses.length) {
      maxRepeatNumber = this.myFields[0].fieldResponses[this.myFields[0].fieldResponses.length - 1].repeatNumber;
    }

    for (let i = 0; i < this.myFields.length; i++) {
      const fieldRes = {
        'responseID': 0,
        'applicantFormID': this.applicantFormID,
        'formQuestionsID': this.myFields[i].formQuestionID,
        'fieldID': this.myFields[i].fieldID,
        'response': '',
        'repeatNumber': maxRepeatNumber + 1,
        'createdBy': this.userLoggedIn,
        'updatedBy': this.userLoggedIn
      };

      this.myFields[i].fieldResponses = this.myFields[i].fieldResponses || [];

      this.myFields[i].fieldResponses.push(<FieldResponse>fieldRes);

      // code to add responses to the list to save
      this.fieldResponsesSaveList = this.fieldResponsesSaveList || [];

      this.fieldResponsesSaveList.push(<FieldResponse>fieldRes);
    }

  }

  // remove button will remove the group from the form and save list will be updated for the responses to be deleted
  removeFieldGroup(questionID: number, fieldGroupID: number, resIndex: number) {
    this.myFieldGroups = this.myQuestions.find(q => q.questionID === questionID).fieldGroups;
    this.myFields = this.myFieldGroups.find(fg => fg.fieldGroupID === fieldGroupID).fields;

    for (let i = 0; i < this.myFields.length; i++) {
      if (this.myFields[i].fieldResponses) {
        const curFieldRes = <FieldResponse>this.myFields[i].fieldResponses[resIndex];

        if (curFieldRes) {
          // remove related responses before removing this response
          this.removeRelatedResponsesForRemovedResponseLookup(curFieldRes);

          this.removeFieldResponse(curFieldRes, this.myFields[i]);
        }
      }
    }
  }

  // remove a response from a field
  removeFieldResponse(curFieldRes: FieldResponse, field: Field) {

    // Remove from field list
    const indexFR = field.fieldResponses.lastIndexOf(curFieldRes);

    if (indexFR !== -1) {
      field.fieldResponses.splice(indexFR, 1);
    }

    // Update lookuplist for affected fields
    this.updateAffectedFieldLookupResponses(curFieldRes.formQuestionsID);

    // Remove from save list
    const matchingFieldResInSaveList = this.fieldResponsesSaveList.find(res =>
      res.formQuestionsID === curFieldRes.formQuestionsID &&
      res.fieldID === curFieldRes.fieldID &&
      res.repeatNumber === curFieldRes.repeatNumber &&
      res.responseID === curFieldRes.responseID
    );

    const indexFRSaveList = this.fieldResponsesSaveList.lastIndexOf(
      matchingFieldResInSaveList
    );

    if (indexFRSaveList !== -1) {
      this.fieldResponsesSaveList.splice(indexFRSaveList, 1);
    }

    // If responseID > 0, add it to the save list with negative response ID to be removed from the database
    // If it's a newly added group, the responseIDs wont have been loaded, so flag it and we can handle it in the sp
    const fieldRes = {
      responseID: curFieldRes.responseID * -1,
      applicantFormID: curFieldRes.applicantFormID,
      formQuestionsID: curFieldRes.formQuestionsID,
      fieldID: curFieldRes.fieldID,
      response: curFieldRes.response,
      repeatNumber: curFieldRes.repeatNumber,
      createdBy: this.userLoggedIn,
      updatedBy: this.userLoggedIn,
      isNewDelete: curFieldRes.responseID > 0 ? false : true,
    };

    // code to add responses to the list to save
    this.fieldResponsesSaveList.push(<FieldResponse>fieldRes);
  }

  // the following action will delete field groups if a field response lookup from another section (or current section) is deleted
  removeFieldGroupsForRemovedResponseLookups() {

    // Find all fields that have field responses as lookupvalues
    const fieldListOfResponseLookups = this.allFields.filter(f => f.lookupResponseForFormQuestionID > 0);
    // for every field, check if there is a response not in the list of lookup values
    if (fieldListOfResponseLookups) {

      fieldListOfResponseLookups.forEach((fl) => {

        if (fl.fieldResponses && fl.lookups) {

          // for every response, check if the response is not in the list of lookup values
          fl.fieldResponses.forEach((fr) => {

            // if the response is not found in the list and if field type is file upload remove only the response
            if (fl.fieldTypeName === 'FileUpload') {
              // we need to find a response for form upload by checking for fileLookupResponseID
              const lookupRes = this.allResponses.find(r => r.responseID === fr.fileLookupResponseID);
              if (!lookupRes) {
                this.removeFieldResponse(fr, fl);
              }
            }
            else {
              const indexFR = fl.lookups.findIndex(l => l.name === fr.response);

              // if the response is not found in the list, it's not just added (responseID === 0), and field type is not file upload, remove field group
              if (indexFR === -1 && fr.responseID !== 0) {
                const indexRes = fl.fieldResponses.findIndex(r => r.response === fr.response);
                this.removeFieldGroup(fl.questionID, fl.fieldGroupID, indexRes);
              }
            }
          });
        }
      });
    }
  }

  // the following action will delete field groups if a field response lookup is deleted
  removeRelatedResponsesForRemovedResponseLookup(fieldRes: FieldResponse) {

    // Find all fields that have field responses as lookupvalues
    const fieldListOfResponseLookups = this.allFields.filter(f => f.lookupResponseForFormQuestionID === fieldRes.formQuestionsID);

    // for every field, check if there is a response not in the list of lookup values
    if (fieldListOfResponseLookups && fieldListOfResponseLookups.length) {
      fieldListOfResponseLookups.forEach((fl) => {

        if (fl.fieldResponses) {
          // find the field for lookup responses
          const matchingFieldRes = fl.fieldResponses.find(r => r.response === fieldRes.response);
          // if the response is not found in the list and field type is not file upload, remove field group
          if (matchingFieldRes && fl.fieldTypeName !== 'FileUpload') {
            // find the index of the field for lookup responses
            const indexFR = fl.fieldResponses.findIndex(r1 => r1.response === fieldRes.response);

            if (!this.allFields) { this.getAllFields(); }
            const curField = this.allFields.find(f => f.formQuestionID === matchingFieldRes.formQuestionsID);
            if (curField) {
              this.removeFieldGroup(curField.questionID, curField.fieldGroupID, indexFR);
            }
          }
        }
      });
    }
  }

  removeAllResponsesForFieldGroup(questionID: number, fieldGroupID: number) {
    let curFieldGroup: FieldGroup;
    curFieldGroup = this.myQuestions
      .find(q => q.questionID === questionID)
      .fieldGroups.find(fg => fg.questionID === questionID && fg.fieldGroupID === fieldGroupID);

    if (curFieldGroup && curFieldGroup.fields && curFieldGroup.fields[0].fieldResponses) {
      const cnt = curFieldGroup.fields[0].fieldResponses.length;

      for (let i = 0; i < cnt; i++) {
        this.removeFieldGroup(questionID, fieldGroupID, 0);
      }
    }
  }

  // this routine will remove any all null responses for a repeatable field group from a list of responses to be saved
  removeNullFieldGroupResponsesFromSaveList() {
    // find if all fields in a repeatable field group have null responses
    this.myFieldGroups = this.getAllFieldGroups();

    this.myFieldGroups.filter(fgs => fgs.isRepeatable).forEach((fg) => {

      if (fg.fields[0].fieldResponses && fg.fields[0].fieldResponses.length) {
        // for each repeat number, check if all responses are null
        for (let i = 0; i < fg.fields[0].fieldResponses.length; i++) {
          let allFieldsNull = true;
          for (let j = 0; j < fg.fields.length; j++) {
            const field = fg.fields[j].fieldResponses.find(r => r.repeatNumber === fg.fields[j].fieldResponses[i].repeatNumber);
            if (field) {
              const curResponse = field.response;
              if (curResponse.length > 0 && curResponse !== this.dropDownListDefaultValue) {
                allFieldsNull = false;
                break;
              }
            }
          }
          if (allFieldsNull) {
            this.removeFieldGroup(fg.questionID, fg.fieldGroupID, i);
          }
        }
      }
    });

  }

  // for any repeatable questions that have been added (ResponseID = 0) and then removed (ResponseID = -0), remove them from the save list.
  // 0 cannot be negative in C#, so they will save normally if not removed. Use object.is as -0 === 0 is true otherwise.
  filterRemovedNewQuestions() {
    this.fieldResponsesSaveList = this.fieldResponsesSaveList.filter(s => !Object.is(-0, s.responseID));
  }

  // get field response data from Field Group component and add it to the save list
  handleEventBlur(data) {
    if (data) {
      const fieldRes = <FieldResponse>data;
      // update field response
      this.updateFieldResponse(fieldRes);
      // Update save list
      this.updateResponsesSaveList(fieldRes);
      // set lookup list
      this.updateAffectedFieldLookupResponses(fieldRes.formQuestionsID);
      // add code to apply rules
      if (!this.myFields) {
        this.myFields = this.getAllFields();
      }
      const curField = this.myFields.find(f => f.formQuestionID === fieldRes.formQuestionsID && f.fieldID === fieldRes.fieldID);
      if (curField) {
        this.setFieldRules(curField);
      }
    }
  }


  isCompleteSection(): boolean {
    const visibleQuestions = this.myQuestions.filter(q => !q.isHidden);
    for (let q = 0; q < visibleQuestions.length; q++) {
      const visibleFieldGroups = visibleQuestions[q].fieldGroups.filter(g => !g.isHidden);
      for (let i = 0; i < visibleFieldGroups.length; i++) {
        const visibleFields = visibleFieldGroups[i].fields.filter(f => !f.isHidden);
        for (let j = 0; j < visibleFields.length; j++) {
          // the fieldgroup has fields -- safety check for null/out of range exceptions. Ignore download fields //TODO - find way to handle outside the ID
          if (
            visibleFields && visibleFields.length > 0 && visibleFields[j].fieldTypeName !== 'FileDownload' &&
            // the response doesn't exist, or it's an empty array
            (!visibleFields[j].fieldResponses || visibleFields[j].fieldResponses.length === 0
            // our response exists, but the value is null or empty
            || visibleFields[j].fieldResponses.findIndex(f => !f.response || f.response === '' || f.responseID < 0) > -1)
            // our response exists, but it's a text or date range input that doesn't meet the fieldDataType's regex requirement
            || visibleFields[j].fieldTypeName === 'Text' && this.formService.hasRegexMismatch(visibleFields[j])
            || visibleFields[j].fieldTypeName === 'DateRange' && this.formService.hasRegexMismatch(visibleFields[j])
            // our response exists, but it's a date string that contains a 0 (placeholder for empty month, date, or year)
            || visibleFields[j].fieldTypeName === 'Calendar' && this.formService.hasEmptyDates(visibleFields[j].fieldResponses)
            // our verification upload does not have as many responses as there are lookups
            || visibleFields[j].fieldTypeName === 'FileUpload' && (visibleFields[j] && visibleFields[j].lookups && this.formService.hasEmptyLookups(visibleFields[j].lookups, visibleFields[j].fieldResponses))
          ) {
            return false;
          }
        }
      }
    }
    return true;
  }

  // Update the responses in the save list with given response
  updateResponsesSaveList(fieldRes: FieldResponse) {
    const matchingFieldResInSaveList = this.fieldResponsesSaveList.find(res =>
      res.formQuestionsID === fieldRes.formQuestionsID &&
      res.fieldID === fieldRes.fieldID &&
      res.repeatNumber === fieldRes.repeatNumber &&
      res.responseID === fieldRes.responseID &&
      (!res.fileLookupResponseID || res.fileLookupResponseID === fieldRes.fileLookupResponseID)
    );

    const index: number = this.fieldResponsesSaveList.indexOf(<FieldResponse>matchingFieldResInSaveList);

    // update list to be saved
    if (index !== -1 && fieldRes.responseID > -1) {
      this.fieldResponsesSaveList.splice(index, 1, <FieldResponse>fieldRes);
    }
    // if we have an element added to the save list that has not yet been saved, and is then removed, pop it out of the list
    else if (index !== -1 && fieldRes.responseID < 0) {
      this.fieldResponsesSaveList.splice(index, 1);
    }
    else {
      this.fieldResponsesSaveList.push(<FieldResponse>fieldRes);
    }
  }

  // Update field model with a given response
  updateFieldResponse(fieldRes: FieldResponse) {
    this.myFields = this.getAllFields();

    const curField = this.myFields.find(f => f.formQuestionID === fieldRes.formQuestionsID && f.fieldID === fieldRes.fieldID);

    // if no field responses and null field response to all the fields in the field group
    if (!curField.fieldResponses) {
      this.addFieldGroup(curField.questionID, curField.fieldGroupID);
    }
    else if (!curField.fieldResponses.length) {
      this.addFieldGroup(curField.questionID, curField.fieldGroupID);
    }

    const matchingFieldRes = curField.fieldResponses.find(res =>
      res.formQuestionsID === fieldRes.formQuestionsID &&
      res.fieldID === fieldRes.fieldID &&
      res.repeatNumber === fieldRes.repeatNumber &&
      res.responseID === fieldRes.responseID &&
      (!res.fileLookupResponseID || res.fileLookupResponseID === fieldRes.fileLookupResponseID)
    );

    const index: number = curField.fieldResponses.indexOf(<FieldResponse>matchingFieldRes);

    if (index !== -1) {
      curField.fieldResponses.splice(index, 1, <FieldResponse>fieldRes);
    }
  }

  // This will set the rules for the objects in current section
  setRules() {
    // set rules for the section controls
    

    if (this.myQuestions) {

      for (let i = 0; i < this.myQuestions.length; i++) {
        this.setDesObjectRules(this.myQuestions[i], 'QUESTION');

        for (let j = 0; j < this.myQuestions[i].fieldGroups.length; j++) {
          this.setDesObjectRules(this.myQuestions[i].fieldGroups[j], 'FIELDGROUP');
          this.setDesObjectRules(this.myQuestions[i].fieldGroups[j], 'REPEATABLEFGADDBUTTON');
          this.setDesObjectRules(this.myQuestions[i].fieldGroups[j], 'REPEATABLEFGREMOVEBUTTON');

          for (let k = 0; k < this.myQuestions[i].fieldGroups[j].fields.length; k++) {
            this.setDesObjectRules(this.myQuestions[i].fieldGroups[j].fields[k], 'FIELD');
            // set rules for lookup
            this.setDesObjectRules(this.myQuestions[i].fieldGroups[j].fields[k], 'RESPONSELOOKUP');
          }
        }
      }
    }
    this.checkForVisibleQuestions();
    this.spinner.hide();
    this.formIsReady = true;
  }

  // setting rules for source object (mostly field)
  setFieldRules(curSourceField: Field) {
    let curRules: Rule[] = [];
    let curObjectType: string;

    // let curDestinationSection: Section;
    let curDestinationQuestion: Question;
    let curDestinationFieldGroup: FieldGroup;
    let curDestinationField: Field;

    this.allFieldGroups = this.getAllFieldGroups();

    // get rules for current object -- current object is source object for rules
    if (this.allRules) {
      curRules = this.allRules.filter(r =>
        r.sourceObjectID === curSourceField.fieldID &&
        (r.sourceObjectType === 'Field' || r.sourceObjectType === 'LookupCount' || r.sourceObjectType === 'UserType') &&
        r.sourceFormQuestionID === curSourceField.formQuestionID
      );

    }


    for (let i = 0; i < curRules.length; i++) {
      // find the destination field for every rule
      curObjectType = curRules[i].destinationObjectType.toString().toUpperCase();

      switch (curObjectType) {
        case 'QUESTION':
          curDestinationQuestion = <Question>this.myQuestions.find(q => q.questionID === curRules[i].destinationObjectID);
          if (curDestinationQuestion) {
            this.setDesObjectRules(curDestinationQuestion, curObjectType);
          }
          break;

        case 'FIELDGROUP':
          curDestinationFieldGroup = this.allFieldGroups.find(f => f.fieldGroupID === curRules[i].destinationObjectID);
          if (curDestinationFieldGroup) {
            this.setDesObjectRules(curDestinationFieldGroup, curObjectType);
          }
          break;

        case 'FIELD':
          curDestinationField = this.allFields.find(f =>
            f.fieldID === curRules[i].destinationObjectID &&
            f.formQuestionID === curRules[i].destinationFormQuestionID
          );
          if (curDestinationField) {
            this.setDesObjectRules(curDestinationField, curObjectType);
          }
          break;

        case 'RESPONSELOOKUP':
          curDestinationField = this.allFields.find(f =>
            f.fieldID === curRules[i].destinationObjectID &&
            f.formQuestionID === curRules[i].destinationFormQuestionID
          );
          if (curDestinationField) {
            this.setDesObjectRules(curDestinationField, curObjectType);
          }
          break;

        case 'SECTION':
          // destination object type = section
          break;

        default:
          break;
      }
    }
  }

  // setting rules for destination object
  setDesObjectRules(desObject: any, desObjectType: string) {
    /* tslint:disable */ //TODO:--remove once these are defined in the method
    let desObjectRules: Rule[];

    // let curDestinationSection: Section;
    let curDestinationQuestion: Question;
    let curDestinationFieldGroup: FieldGroup;
    let curDestinationField: Field;
    let curFields: Field[] = [];
    let curFormQuestionIDs: number[] = [];

    /* tslint:enable */

    // this.myFields = this.getAllFields();
    this.allFieldGroups = this.getAllFieldGroups();

    switch (desObjectType.toUpperCase()) {
      case 'QUESTION':
        curDestinationQuestion = <Question>desObject;
        if (curDestinationQuestion && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            // desObjectRules = this.allRules.filter(r =>
            //   r.destinationObjectID === curDestinationQuestion.questionID &&
            //   r.destinationFormQuestionID === curDestinationQuestion.formQuestionID &&
            //   r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
            //   r.ruleType === this.ruleTypes[x].name
            // );

            curFields = this.allFields.filter(f=> f.questionID === curDestinationQuestion.questionID);
            curFormQuestionIDs = [];
            curFields.forEach(f => {
              curFormQuestionIDs.push(f.formQuestionID);
            });

            // console.log("form Question IDs for Question:" + curDestinationQuestion.formQuestionID + " - " + curDestinationQuestion.questionLabel);
            // console.log(curFormQuestionIDs);

            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationQuestion.questionID &&
              curFormQuestionIDs.includes(r.destinationFormQuestionID) &&
              r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationQuestion, desObjectType, desObjectRules);
            }
          }
        }
        break;

      case 'FIELDGROUP':
        curDestinationFieldGroup = <FieldGroup>desObject;

        if (curDestinationFieldGroup && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            // desObjectRules = this.allRules.filter(r =>
            //   r.destinationObjectID === curDestinationFieldGroup.fieldGroupID &&
            //   r.destinationFormQuestionID === curDestinationFieldGroup.formQuestionID &&
            //   r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
            //   r.ruleType === this.ruleTypes[x].name
            // );

            curFields = this.allFields.filter(f=> f.questionID === curDestinationFieldGroup.questionID && f.fieldGroupID === curDestinationFieldGroup.fieldGroupID)
            curFormQuestionIDs = [];
            curFields.forEach(f => {
              curFormQuestionIDs.push(f.formQuestionID);
            });

            // console.log("Field Group - form Question IDs for field group:" + curDestinationFieldGroup.formQuestionID + " - " + curDestinationFieldGroup.groupName);
            // console.log(curFormQuestionIDs);

            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationFieldGroup.fieldGroupID &&
              curFormQuestionIDs.includes(r.destinationFormQuestionID) &&
              r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
              r.ruleType === this.ruleTypes[x].description
            );


            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationFieldGroup, desObjectType, desObjectRules);
            }
          }
        }
        break;

      case 'REPEATABLEFGADDBUTTON':
        curDestinationFieldGroup = <FieldGroup>desObject;

        curFields = this.allFields.filter(f=> f.questionID === curDestinationFieldGroup.questionID && f.fieldGroupID === curDestinationFieldGroup.fieldGroupID)
        curFormQuestionIDs = [];
        curFields.forEach(f => {
          curFormQuestionIDs.push(f.formQuestionID);
        });

        // console.log("Repeatable Add - form Question IDs for field group:" + curDestinationFieldGroup.formQuestionID + " - " + curDestinationFieldGroup.groupName);
        // console.log(curFormQuestionIDs);

        if (curDestinationFieldGroup && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationFieldGroup.fieldGroupID &&
              curFormQuestionIDs.includes(r.destinationFormQuestionID) &&
              r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationFieldGroup, desObjectType, desObjectRules);
            }
          }
        }
        break;

      case 'REPEATABLEFGREMOVEBUTTON':

        curDestinationFieldGroup = <FieldGroup>desObject;

        curFields = this.allFields.filter(f=> f.questionID === curDestinationFieldGroup.questionID && f.fieldGroupID === curDestinationFieldGroup.fieldGroupID)
        curFormQuestionIDs = [];
        curFields.forEach(f => {
          curFormQuestionIDs.push(f.formQuestionID);
        });

        // console.log("Repeatable Remove - form Question IDs for field group:" + curDestinationFieldGroup.formQuestionID + " - " + curDestinationFieldGroup.groupName);
        // console.log(curFormQuestionIDs);

        if (curDestinationFieldGroup && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationFieldGroup.fieldGroupID &&
              curFormQuestionIDs.includes(r.destinationFormQuestionID) &&
              r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationFieldGroup, desObjectType, desObjectRules);
            }
          }
        }
        break;

      case 'FIELD':
        curDestinationField = <Field>desObject;

        if (curDestinationField && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationField.fieldID &&
              r.destinationFormQuestionID === curDestinationField.formQuestionID &&
              r.destinationObjectType.toUpperCase() === desObjectType.toUpperCase() &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationField, desObjectType, desObjectRules);

            }
          }
        }
        break;

      case 'RESPONSELOOKUP':
        curDestinationField = <Field>desObject;

        if (curDestinationField && this.allRules) {
          for (let x = 0; x < this.ruleTypes.length; x++) {
            desObjectRules = this.allRules.filter(r =>
              r.destinationObjectID === curDestinationField.fieldID &&
              r.destinationObjectType &&
              r.destinationObjectType.toUpperCase() === desObjectType &&
              r.destinationFormQuestionID === curDestinationField.formQuestionID &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setRulesforObject(curDestinationField, desObjectType, desObjectRules);
            }
          }

          // set Lookupcount rules if there's any
          for (let x = 0; x < this.ruleTypes.length; x++) {
            desObjectRules = this.allRules.filter(r =>
              r.sourceObjectID === curDestinationField.fieldID &&
              r.sourceObjectType.toUpperCase() === 'LOOKUPCOUNT' &&
              r.sourceFormQuestionID === curDestinationField.formQuestionID &&
              r.ruleType === this.ruleTypes[x].description
            );

            if (desObjectRules && desObjectRules.length) {
              this.setFieldRules(curDestinationField);
            }
          }
        }
        break;

      case 'SECTION':
        // destination object type = section
        break;

      default:
        break;
    }
  }

  setRulesforObject(desObject: any, desObjectType: string, desObjectRules: Rule[]) {
    // var curRuleType: string;
    let curRuleOperator: string;
    let curRuleMet = false;
    let allRulesMet: boolean;

    let curFieldRes: FieldResponse;
    let repeatNumbers: number;
    let curField: Field;
    let curResponse = '';

    const curSectionFieldResponses = this.getCurrentSectionResponses();

    if (desObjectType.toUpperCase() === 'RESPONSELOOKUP' && this.allResponses) {
      repeatNumbers = this.allResponses.filter(r => r.formQuestionsID === (<Field>desObject).lookupResponseForFormQuestionID).length;
    }
    else if (desObjectType.toUpperCase() === 'FIELD' && this.allResponses) {
      repeatNumbers = (<Field>desObject).fieldResponses.length;
    }
    else {
      repeatNumbers = 1;
    }

    for (let i = 0; i < repeatNumbers; i++) {
      allRulesMet = false;

      for (let k = 0; k < desObjectRules.length; k++) {
        curRuleMet = false;

        // for every source object type find the actual responce
        // LookupCount type
        if (desObjectRules[k].sourceObjectType.toUpperCase() === 'LOOKUPCOUNT') {
          curField = this.allFields.find(f =>
            f.fieldID === desObjectRules[k].sourceObjectID &&
            f.formQuestionID === desObjectRules[k].sourceFormQuestionID
          );
          if (curField && curField.lookups) {
            curResponse = curField.lookups.length.toString();
          }
          else if (curField.fieldTypeName === 'FileUpload') {
            curResponse = '0';
          }
        }
        else if (desObjectRules[k].sourceObjectType.toUpperCase() === 'USERTYPE') {
          curResponse = JSON.parse(localStorage.getItem('UserType'));
        }
        else if (desObjectRules[k].sourceObjectType.toUpperCase() === 'USER') {
          curResponse = JSON.parse(localStorage.getItem('UserType'));
        }
        else if (desObjectRules[k].sourceObjectType.toUpperCase() === 'FIELDRESPONSE') {
          // This only checks if responses exist currently, move out into a new rule object if needed for value check
          const sourceFieldResponses = this.allResponses.filter(f => f.formQuestionsID === desObjectRules[k].sourceFormQuestionID);
          curResponse = sourceFieldResponses.length.toString();
        }
        else {
          // find field response in current section
          curFieldRes = curSectionFieldResponses.find(r =>
            r.formQuestionsID === desObjectRules[k].sourceFormQuestionID &&
            r.fieldID === desObjectRules[k].sourceObjectID &&
            r.repeatNumber === i + 1
          );

          // if not found in current section get it from other section
          if (!curFieldRes && this.allResponses) {
            curFieldRes = this.allResponses.find(r =>
              r.formQuestionsID === desObjectRules[k].sourceFormQuestionID &&
              r.fieldID === desObjectRules[k].sourceObjectID &&
              r.repeatNumber === i + 1
            );
          }
        }

        if (curFieldRes != null) {
          curResponse = curFieldRes.response;
        }

        if (curResponse != null) {
          curRuleMet = this.checkIfRuleMet(desObjectRules[k].conditionOperator, curResponse, desObjectRules[k].valueInPlace);
        }

        switch (curRuleOperator) {
          case 'AND':
            allRulesMet = allRulesMet && curRuleMet;
            break;
          case 'OR':
            allRulesMet = allRulesMet || curRuleMet;
            break;
          default:
            allRulesMet = curRuleMet;
            break;
        }

        // set the rule operator for next rule
        curRuleOperator = desObjectRules[k].ruleOperator;
      }

      // set action if conditions are met
      this.setRuleActionForObject(desObject, desObjectType, desObjectRules[0].ruleType, allRulesMet, i + 1);
    }
  }

  checkIfRuleMet(conditionOperator: string, actualResponse: string, requiredResponse: string): boolean {
    let curRuleMet = false;

    switch (conditionOperator) {
      case '==':
        curRuleMet = actualResponse === requiredResponse;
        break;
      case '<':
        curRuleMet = actualResponse < requiredResponse;
        break;
      case '>':
        curRuleMet = actualResponse > requiredResponse;
        break;
      case '<=':
        curRuleMet = actualResponse <= requiredResponse;
        break;
      case '>=':
        curRuleMet = actualResponse >= requiredResponse;
        break;
      case '!=':
        curRuleMet = actualResponse !== requiredResponse;
        break;
      default:
        break;
    }

    return curRuleMet;
  }

  // this will set the action for an object if rules are satisfield
  setRuleActionForObject(desObject: any, desObjectType: string, ruleType: string, allRulesMet: boolean, repeatNumber: number) {
    switch (ruleType.toUpperCase()) {
      case 'SHOW':
        if (allRulesMet) {
          this.showObject(desObject, desObjectType, repeatNumber);
        }
        else {
          this.hideObject(desObject, desObjectType, repeatNumber);
        }
        break;

      case 'HIDE':
        if (allRulesMet) {
          this.hideObject(desObject, desObjectType, repeatNumber);
        }
        else {
          this.showObject(desObject, desObjectType, repeatNumber);
        }
        break;

      case 'READONLY':
        // Handling this only for fields now. Can include other objects later if needed
        if (desObjectType.toUpperCase() === 'FIELD') {
          const curField = <Field>desObject;
          if (allRulesMet) {
            curField.isReadOnly = true;
          }
          else {
            desObject.isReadOnly = false;
          }
        }
        break;

      case 'PREVIOUS RECORD CHECK':
        // ADD CODE FOR PREVIOUS RECORD CHECK
        break;

      default:
        break;
    }
  }

  showObject(desObject: any, desObjectType: string, repeatNumber: number) {

    let curFieldRes: FieldResponse;
    let curFieldGroup: FieldGroup;
    let curField: Field;

    if (desObjectType.toUpperCase() === 'RESPONSELOOKUP' && this.allResponses) {
      curFieldRes = this.allResponses.find(r =>
        r.formQuestionsID === (<Field>desObject).lookupResponseForFormQuestionID &&
        r.repeatNumber === repeatNumber
      );
      this.addFieldLookupResponse(<Field>desObject, curFieldRes.response);
    }
    else if (desObjectType.toUpperCase() === 'REPEATABLEFGADDBUTTON') {
      (<FieldGroup>desObject).addButtonHide = false;
    }
    else if (desObjectType.toUpperCase() === 'REPEATABLEFGREMOVEBUTTON') {
      (<FieldGroup>desObject).removeButtonHide = false;
    }
    else if (desObjectType.toUpperCase() === 'FIELD') {
        curField = <Field>desObject;
        curFieldGroup = this.allFieldGroups.find(fg => fg.fieldGroupID === curField.fieldGroupID);
        if (curFieldGroup && curFieldGroup.isRepeatable === true)
        {
          curField.fieldResponses[repeatNumber-1].isFieldHidden = false;
        } 
        else
          curField.isHidden = false;    
    }
    else {
      desObject.isHidden = false;
    }

  }

  hideObject(desObject: any, desObjectType: string, repeatNumber: number) {

    let curFieldRes: FieldResponse;
    let curFieldGroup: FieldGroup;
    let curField: Field;

    if (desObjectType.toUpperCase() === 'RESPONSELOOKUP' && this.allResponses) {
      curFieldRes = this.allResponses.find(r =>
        r.formQuestionsID === (<Field>desObject).lookupResponseForFormQuestionID &&
        r.repeatNumber === repeatNumber
      );
      this.removeFieldLookupResponse(<Field>desObject, curFieldRes.response);
    }
    else if (desObjectType.toUpperCase() === 'REPEATABLEFGADDBUTTON') {
      (<FieldGroup>desObject).addButtonHide = true;
    }
    else if (desObjectType.toUpperCase() === 'REPEATABLEFGREMOVEBUTTON') {
      (<FieldGroup>desObject).removeButtonHide = true;
    }
    else if (desObjectType.toUpperCase() === 'FIELD') {

      curField = <Field>desObject;
      curFieldGroup = this.allFieldGroups.find(fg => fg.fieldGroupID === curField.fieldGroupID);

      if (curFieldGroup && curFieldGroup.isRepeatable === true)
      {
        (<Field>desObject).fieldResponses[repeatNumber-1].isFieldHidden = true;
      } 
      else
        desObject.isHidden = true;    
    }
    else {
      desObject.isHidden = true;
    }
    if (desObjectType.toUpperCase() === 'FIELDGROUP') {
      const curFieldGroup: FieldGroup = <FieldGroup>desObject;
      this.removeAllResponsesForFieldGroup(curFieldGroup.questionID, curFieldGroup.fieldGroupID);
    }

  }



  showSavedSuccessful() {
    this.showSavedMessage = true;
    setTimeout(function () {
      this.showSavedMessage = false;
    }.bind(this), 3000);
  }



  saveFieldResponses() {
    // Timing needs to be controlled here. Cannot nav until we've completed this save.
    return new Promise((resolve, reject) => {
      // remove null responses for a repeatable field group
      this.section.visited = true;
      if (this.fieldResponsesSaveList) {
        this.removeNullFieldGroupResponsesFromSaveList();
        this.filterRemovedNewQuestions();
        // Don't make the API call unless we have changed fields
        if (this.fieldResponsesSaveList.length > 0) {
          this.section.isCompleted = this.isCompleteSection();
          //update save method
          this.applicantProcessService.saveFieldResponses(<FieldResponse[]>this.fieldResponsesSaveList).subscribe(() => {
            this.fieldResponsesSaveList = [];
            this.showSavedSuccessful();
            resolve(true);
          }, (error) => {
            this.toastr.error(error);
          });
        }
        else {
          // nothing actually being saved, give them the message anyway to make them feel good
          this.showSavedSuccessful();
          this.fieldResponsesSaveList = [];
          resolve("");
        }
      }
      else {
        resolve("");
      }
    });
  }

  
  navigate(direction) {
    // save field responses
    this.saveFieldResponses();

    this.formIsReady = false;
    if (!this.isCompleteSection()) {
      this.toastr.warning('You did not complete ' + this.section.sectionNumberLabel + '. You may visit this section later to complete. You must complete all sections to submit the form.', this.section.sectionNumberLabel + ' Incomplete');
    }
    this.navigateEvent.emit(direction);
  }

  buildSectionObjectList(basicQuestions: any) {
    const listOfSections = ([...new Set(basicQuestions.map(item => new Object({ sectionLabel: item.sectionLabel, sectionName: item.sectionName })))]).filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj['sectionName']).indexOf(obj['sectionName']) === pos;
    });
    // TODO: This will work to sort tabs for now, but only because they all end in a number. We need to add display order to the section table and use that going forward
    listOfSections.sort((a, b) => a['sectionLabel'].slice(-1).localeCompare(b['sectionLabel'].slice(-1)));

    listOfSections.forEach(function (distinctSection) {
      distinctSection['basicQuestions'] = basicQuestions.filter(bq => bq.sectionLabel === distinctSection['sectionLabel']);
    });

    return listOfSections;
  }

  saveAndComplete() {
    const basicQuestions = [];

    // get all of the questions present on the review page
    const allDOMQuestions = document.getElementsByClassName('question-container');
    for (let i = 0; i < allDOMQuestions.length; i++) {
      const qText = allDOMQuestions[i].getElementsByClassName('card-info')[0] ? allDOMQuestions[i].getElementsByClassName('card-info')[0]['innerText'] : null;
      const qDataSet = allDOMQuestions[i].firstElementChild['dataset'];
      const basicQuestion = {
        questionText: qText,
        sectionLabel: qDataSet.sectionlabel,
        sectionName: qDataSet.sectionname,
        basicFieldGroups: [],
      };
      // get all of the fieldGroups present within the current question
      const allDOMFieldGroups = allDOMQuestions[i].getElementsByClassName('field-group-component');
      for (let j = 0; j < allDOMFieldGroups.length; j++) {
        let fLabel = allDOMFieldGroups[j].getElementsByClassName('control-label')[0] ? allDOMFieldGroups[j].getElementsByClassName('control-label')[0]['innerText'] : null;

        // check if we're working with a date (3 part answer)
        let fValue = '';
        const fValueMonth = allDOMFieldGroups[j].getElementsByClassName('date-m')[0] ? allDOMFieldGroups[j].getElementsByClassName('date-m')[0]['value'] : null;
        if (fValueMonth) {
          const fValueDay = allDOMFieldGroups[j].getElementsByClassName('date-d')[0] ? allDOMFieldGroups[j].getElementsByClassName('date-d')[0]['value'] : null;
          const fValueYear = allDOMFieldGroups[j].getElementsByClassName('date-y')[0] ? allDOMFieldGroups[j].getElementsByClassName('date-y')[0]['value'] : null;
          fValue = fValueMonth + '/' + fValueDay + '/' + fValueYear;
        }
        else {
          fValue = allDOMFieldGroups[j].getElementsByClassName('form-control')[0] ? allDOMFieldGroups[j].getElementsByClassName('form-control')[0]['value'] : null;
        }
        let fUpload = false;
        // both values came back null. check if they are file uploads
        if (!fLabel) {
          fLabel = allDOMFieldGroups[j].getElementsByClassName('file-user')[0] ? allDOMFieldGroups[j].getElementsByClassName('file-user')[0]['innerText'] : null;
          fUpload = true;
        }
        if (!fValue) {
          fValue = '';
          if (allDOMFieldGroups[j].getElementsByClassName('file-response')[0]) {
            for (let l = 0; l < allDOMFieldGroups[j].getElementsByClassName('file-response').length; l++) {
              fValue += allDOMFieldGroups[j].getElementsByClassName('file-response')[l]['dataset']['filepath'] + '\n';
            }
          }
          fUpload = true;
        }

        const basicFieldGroup = {
          fieldLabel: fLabel,
          fieldValue: fValue,
          isUpload: fUpload
        };
        basicQuestion.basicFieldGroups.push(basicFieldGroup);
      }
      basicQuestions.push(basicQuestion);
    }

    const basicSections = this.buildSectionObjectList(basicQuestions);
    const processName = document.getElementsByClassName('form-title')[0] ? document.getElementsByClassName('form-title')[0]['innerText'] : '';
    const processID = JSON.parse(localStorage.getItem('ProcessID'));
    const residentProcessID = JSON.parse(localStorage.getItem('ResidentProcessID'));
    const startDate = JSON.parse(localStorage.getItem('StartDate'));
    const user = this.userLoggedIn ? this.userLoggedIn : JSON.parse(localStorage.getItem('TenantCode'));
    const signer = (<HTMLInputElement>document.getElementById('signer')).value;
    //this.spinner.show();

    //add code to generate pdf and upload to filenet
    
    // this.formService.saveCertResponses(user, this.applicantFormID, basicSections, processName, processID, startDate, this.signature, signer, residentProcessID).subscribe((data: any) => {
    //   //this.completedForm = true;
    //   this.spinner.hide();
    //   this.router.navigate(['/tenant', this.processID]);
    // }, (error) => {
    //   this.spinner.hide();
    //   this.toastr.error(error);
    // });
  }
}
