import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PopupMessageComponent } from '../popup-message/popup-message.component';


@Injectable()
export class PopupMessageService {

  constructor(private modalService: NgbModal) { }

  public show(
    title: string,
    content:string,
    centered:boolean,
    dialogSize: 'sm'|'md'|'lg'|'xl'|'xxl' = 'sm'): Promise<boolean> {
    const modalRef = this.modalService.open(PopupMessageComponent, { size: dialogSize, centered: centered, scrollable: true, windowClass: 'faq-panel' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.content = content;
    return modalRef.result;
  }
}
