<body>
    <banner [showHCVMsg]="false"></banner>
    <div class="wrapper text-center mb-5">
        <div class="container-fluid justify-content-center mt-5">
            <div class="container">
                <div class="mt-5"></div>
                <div class="text-center mb-5">
                    <div class="page-title mb-3">{{title}}</div>
                    <div class="page-text mb-3">We're here to help.</div>
                    <!-- <div class="page-text text-danger">Please note: SHRA does not provide emergency housing.</div>
                    <div class="page-text text-danger mb-5">For emergency housing assistance and resources, dial 211.</div> -->
                    <div class="page-text mb-5">The SHRA offices are temporarily closed for in-person appointments. Please call our communication center at 916-440-1390 to speak with a representative.</div>
                </div>
            </div>
            <div class="mt-5"></div>
            <div class="container justify-content-center mt-5 mb-5">
                <div [ngClass]="!isMobile ? 'container row' : 'container'">
                    <div [ngClass]="!isMobile ? 'column1 text-center mb-5' : 'column3 text-center mb-5'">
                        <div class="mb-3"><a [href]="gmapurl" target="_blank"><img class="img-fluid" src="{{imgFolder}}icon-address.png"  height="60" width="60" alt=""></a></div>
                        <div class="page-text">630 I STREET</div>
                        <div class="page-text mb-5">SACRAMENTO, CA 95814</div>
                    </div>
                    <!-- <div class="column2"></div> -->
                    <div [ngClass]="!isMobile ? 'column1 text-center mb-5' : 'column3 text-center mb-5'">
                        <div class="mb-3"><img class="img-fluid" src="{{imgFolder}}icon-phone.png" height="60" width="60" alt=""></div>
                        <div class="page-text">916.440.1390</div>
                        <!-- <div *ngIf="!isMobile" class="page-text">916.440.1390</div>
                        <div *ngIf="isMobile" class="page-text"><a href="tel:+1-916-440-1390">916.440.1390</a></div> -->
                        <div class="page-text">For reasonable accommodation,</div>
                        <div class="page-text">call 916.440.1390 | tty 711</div>
                        <!-- <div *ngIf="!isMobile" class="page-text">call 916.440.1390 | tty 711</div>
                        <div *ngIf="isMobile" class="page-text">call <a href="tel:+1-916-440-1390">916.440.1390</a> | tty 711</div> -->
                        <div class="page-text mb-5">or 1.800.855.7100</div>
                        <!-- <div class="page-text mb-5">or 1.800.855.7100</div>
                        <div *ngIf="isMobile" class="page-text">call <a href="tel:+1-800-855-7100">1.800.855.7100</a></div> -->
                    </div>
                    <!-- <div class="column2"></div> -->
                    <div class="column2 text-center mb-5">
                        <div class="mb-3"><a href="http://www.shra.org" target="_blank"><img class="img-fluid" src="{{imgFolder}}icon-website.png" height="60" width="60" alt=""></a></div>
                        <div class="page-text">WWW.SHRA.ORG</div>
                    </div>
                </div>
            </div>
            <div class="container mb-5">
                <div class="text-center mb-5 mt-5">
                    <div class="page-subtitle mt-5 mb-3">Additional Resources</div>
                    <div *ngFor="let c of communityResourceLinks | orderby:'displayOrder':false">
                        <div *ngIf="c.description!='How to Apply'" class="page-text mb-3" [innerHTML] = "c.description"></div>
                    </div>
                </div>
            </div>         
        </div>                
    </div>
</body>
