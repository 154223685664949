import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError} from 'rxjs';
import { PortalParameters } from '../models/portalParameters';
import { catchError } from 'rxjs/operators';
import { PortalLookups } from '../models/portalLookups';
import { WaitlistPrograms } from '../models/waitlistPrograms';
import { WaitlistTypes } from '../models/waitlistTypes';
import { Waitlists } from '../models/waitlists';
import { Preferences } from '../models/preferences';
import { FAQ } from '../models/faq';
import { IncomeLimits } from '../models/incomeLimits';
import { FAQForm } from '../models/faqForm';
import { WaitlistAmenities } from '../models/waitlistAmenities';
import { ResourceLinks } from '../models/resourceLinks';
import { EmailTemplate } from '../models/emailTemplate';
import { environment } from 'src/environments/environment';



@Injectable()
export class WaitlistService{
    httpOptions = {
        headers: new HttpHeaders({
            'content-Type':'application/json',
            'Access-Control-Allow-Origin':'*',
            'Accept':'application/json'
        })
    };

    baseAPIUrl:string = environment.baseAPIUrl;
    
    constructor(private http:HttpClient) {
        //this.httpOptions.headers.append("Authorization", localStorage.getItem("jwtoken"));
    }


    getPortalLookupsByCategory(params:PortalParameters):Observable<PortalLookups[]> {
        return this.http.post<PortalLookups[]>(this.baseAPIUrl + 'api/waitlist/GetLookupsByCategory', params, this.httpOptions)
            .pipe(catchError(this.handleError<PortalLookups[]>('getPortalLookupsByCategory', [])));
    }


    getPortalLookupsByCategoryID(params:PortalParameters):Observable<PortalLookups[]> {
        return this.http.post<PortalLookups[]>(this.baseAPIUrl + 'api/waitlist/GetLookupsByCategoryID', params, this.httpOptions)
            .pipe(catchError(this.handleError<PortalLookups[]>('getPortalLookupsByCategoryID', [])));
    }


    getPortalLookupsByID(params:PortalParameters):Observable<PortalLookups> {
        return this.http.post<PortalLookups>(this.baseAPIUrl + 'api/waitlist/GetLookupsByID', params, this.httpOptions)
            .pipe(catchError(this.handleError<PortalLookups>('getPortalLookupsByID', null)));
    }


    getWaitlistPrograms():Observable<WaitlistPrograms[]> {
        return this.http.post<WaitlistPrograms[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistPrograms', null, this.httpOptions)
            .pipe(catchError(this.handleError<WaitlistPrograms[]>('getWaitlistPrograms', [])));
    }


    getWaitlistTypes():Observable<WaitlistTypes[]> {
        return this.http.post<WaitlistTypes[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistTypes', null, this.httpOptions)
            .pipe(catchError(this.handleError<WaitlistTypes[]>('getWaitlistTypes', [])));
    }


    getWaitlistTypesByProgram(params:PortalParameters):Observable<WaitlistTypes[]> {
        return this.http.post<WaitlistTypes[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistTypesByProgram', params, this.httpOptions)
            .pipe(catchError(this.handleError<WaitlistTypes[]>('getWaitlistTypesByProgram', [])));
    }


    getWaitlists():Observable<Waitlists[]> {
        return this.http.post<Waitlists[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlists', null, this.httpOptions)
            .pipe(catchError(this.handleError<Waitlists[]>('getWaitlists', [])));
    }


    getWaitlistsByWLTypeCategory(params:PortalParameters):Observable<Waitlists[]> {
        return this.http.post<Waitlists[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistsByWLTypeCategory', params, this.httpOptions)
            .pipe(catchError(this.handleError<Waitlists[]>('getWaitlistsByWLTypeCategory', [])));
    }


    getWaitlistsByType(params:PortalParameters):Observable<Waitlists[]> {
        return this.http.post<Waitlists[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistsByType', params, this.httpOptions)
            .pipe(catchError(this.handleError<Waitlists[]>('getWaitlistsByType', [])));
    }


    getWaitlistsByProgram(params:PortalParameters):Observable<Waitlists[]> {
        return this.http.post<Waitlists[]>(this.baseAPIUrl + 'api/waitlist/GetWaitlistsByProgram', params, this.httpOptions)
            .pipe(catchError(this.handleError<Waitlists[]>('getWaitlistsByProgram', [])));
    }


    getPreferences():Observable<Preferences[]> {
        return this.http.post<Preferences[]>(this.baseAPIUrl + 'api/waitlist/GetPreferences', null, this.httpOptions)
            .pipe(catchError(this.handleError<Preferences[]>('getPreferences', [])));
    }

    getWaitlistPreferences(params:PortalParameters):Observable<Preferences[]> {
        return this.http.post<Preferences[]>(this.baseAPIUrl + 'api/waitlist/GetPreferencesByWaitlist', params, this.httpOptions)
            .pipe(catchError(this.handleError<Preferences[]>('getWaitlistPreferences', [])));
    }


    getWaitlistAmenities(params:PortalParameters):Observable<WaitlistAmenities[]> {
        return this.http.post<WaitlistAmenities[]>(this.baseAPIUrl + 'api/waitlist/GetAmenitiesByWaitlist', params, this.httpOptions)
            .pipe(catchError(this.handleError<WaitlistAmenities[]>('getWaitlistPreferences', [])));
    }


    getFAQForms():Observable<FAQForm[]> {
        return this.http.post<FAQForm[]>(this.baseAPIUrl + 'api/waitlist/GetFAQForms', null, this.httpOptions)
            .pipe(catchError(this.handleError<FAQForm[]>('getFAQForms', [])));
    }


    getFAQFormByItem(params:PortalParameters):Observable<FAQForm> {
        return this.http.post<FAQForm>(this.baseAPIUrl + 'api/waitlist/GetFAQFormByItem', params, this.httpOptions)
            .pipe(catchError(this.handleError<FAQForm>('getFAQFormByItem', null)));
    }


    getFAQs():Observable<FAQ[]> {
        return this.http.post<FAQ[]>(this.baseAPIUrl + 'api/waitlist/GetFAQs', null, this.httpOptions)
            .pipe(catchError(this.handleError<FAQ[]>('getFAQs', [])));
    }


    getFormFAQs(params:PortalParameters):Observable<FAQ[]> {
        return this.http.post<FAQ[]>(this.baseAPIUrl + 'api/waitlist/GetFAQsByFormID', params, this.httpOptions)
            .pipe(catchError(this.handleError<FAQ[]>('getFormFAQs', [])));
    }


    getIncomeLimits(params:PortalParameters):Observable<IncomeLimits> {
        return this.http.post<IncomeLimits>(this.baseAPIUrl + 'api/waitlist/GetIncomeLimits', params, this.httpOptions)
            .pipe(catchError(this.handleError<IncomeLimits>('getIncomeLimits', null)));
    }


    getResourceLinks(params:PortalParameters):Observable<ResourceLinks[]> {
        return this.http.post<ResourceLinks[]>(this.baseAPIUrl + 'api/waitlist/GetResourceLinks', params, this.httpOptions)
            .pipe(catchError(this.handleError<ResourceLinks[]>('getResourceLinks', [])));
    }


    getEmailTemplates():Observable<EmailTemplate[]> {
        return this.http.post<EmailTemplate[]>(this.baseAPIUrl + 'api/waitlist/GetEmailTemplates', null, this.httpOptions)
            .pipe(catchError(this.handleError<EmailTemplate[]>('getEmailTemplates', [])));
    }


    getEmailTemplateByName(params:PortalParameters):Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(this.baseAPIUrl + 'api/waitlist/GetEmailTemplateByName', params, this.httpOptions)
            .pipe(catchError(this.handleError<EmailTemplate>('getEmailTemplateByName', null)));
    }


    sendMail(emailTemplate:EmailTemplate):Observable<EmailTemplate> {
        return this.http.post<EmailTemplate>(this.baseAPIUrl + 'api/waitlist/SendMail', emailTemplate, this.httpOptions)
            .pipe(catchError(this.handleError<EmailTemplate>('getResourceLinks', null)));
    }


    private handleError<T> (operation = 'operation', result?:T){
        return (error:any): Observable<T> => {
            console.error(error);
            return of(result as T)
        }
    }

}