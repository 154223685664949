import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from '../helpers/pendingChanges.guard';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { VeteranStatus } from '../models/veteranStatus';
import { WaitlistTypes } from '../models/waitlistTypes';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from '../services/application-pending-changes.service';
import { MessageService } from '../services/message.service';
import { WaitlistPortalService } from '../services/waitlist-portal.service';


@Component ({
    selector: 'veteran-status',
    templateUrl: './veteran-status.component.html',
    styleUrls: ['./veteran-status.component.scss']
})

export class VeteranStatusComponent implements OnInit, routingAway{
    applicant: Applicant;
    params:Parameters = new Parameters();
    veteranStatus:VeteranStatus = new VeteranStatus();
    readonly:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService,
        private waitlistPortalService:WaitlistPortalService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.spinnerService.show();

        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.veteranStatus = this.applicant.veteranStatus;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        if ((!this.veteranStatus && this.applicant.generalInfo.pCode) || (this.veteranStatus && this.veteranStatus.hmy != this.applicant.generalInfo.hmy))
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getVeteranStatusCNV(this.params)
                .subscribe(data  => { 
                    if (data) this.veteranStatus = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getVeteranStatusHCV(this.params)
                .subscribe(data  => { 
                    if (data) this.veteranStatus = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getVeteranStatusAff(this.params)
                .subscribe(data  => { 
                    if (data) this.veteranStatus = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.veteranStatus) this.veteranStatus = new VeteranStatus();
        this.spinnerService.hide();
    }

    veteranStatusCheck(){
        this.spinnerService.show();
        this.applicant.veteranStatus = this.veteranStatus;
        if (this.applicant.veteranStatus.veteran==0)
            this.applicant.veteranStatus.honorableDischarge = null;
        this.spinnerService.hide();
    }

    back(f:any)
    {
        // if (this.readonly || (f.valid && !this.readonly))
        // {
            this.spinnerService.show();
            this.applicant.veteranStatus = this.veteranStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));

            // let yardiDB:string = "";
            // if (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB)
            //     yardiDB = this.navigationParams.waitlistTypesModel.yardiDB;
            // else
            // {
            //     if ((this.navigationParams.cnvTCode && this.navigationParams.cnvTCode.length > 0) || (this.navigationParams.hcvTCode && this.navigationParams.hcvTCode.length > 0) 
            //     || this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            //         yardiDB = "";
            //     else
            //         yardiDB = "AFF";
            // }

            //route to family members if no disabled member
            if (this.waitlistPortalService.checkForDisabledMember(this.applicant))
            {
                if (!this.applicant.generalInfo.pCode || (this.applicant.generalInfo.pCode && this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() != 'P'))
                {
                    this.spinnerService.hide();
                    this.router.navigate(['/disabilityAccommodation']);
                }
                else
                {
                    this.spinnerService.hide();                
                    this.router.navigate(['/familyMembers']);
                }
            }
            else
            {
                this.spinnerService.hide();                
                this.router.navigate(['/familyMembers']);
            }
        // }
    }

    next(f:any){
        if (this.readonly || (f.valid && !this.readonly))
        {
            this.applicant.veteranStatus = this.veteranStatus;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));

            //bv- commenting the lines below to show homeless always
            //ticket #37548
            // if (this.applicant.incomeRent.renting && this.applicant.incomeRent.renting == 1)
            //     this.router.navigate(['/displacementStatus']);
            // else
                this.router.navigate(['/homelessStatus']);
        }
    }
}