<body>
    <div class="wrapper" *ngIf="waitlist">
        <ul class="flex cards">
            <li><h2 class="text-center">{{waitlist.name}} - {{waitlistType}}</h2>
                <div *ngIf='!waitlist.waitlistSubType.includes("Tenant-Based")' class="container mt-2"><img src="{{imgFolder}}{{waitlist.name.replace('/', '').replace('.', '')}}.jpg"></div>
                <div class="text-center mb-5"> 
                    <div [innerHTML] = "waitlist.description"></div>
                    <!-- <p>{{waitlist.description}}</p> -->
                    <div *ngIf="waitlistType != 'Referral'">
                        <div *ngIf="waitlist.location || waitlist.locationText">
                            <div class="mb-2"><p2>Location</p2></div>
                            <p>{{waitlist.location}}</p>
                            <p>{{waitlist.locationText}}</p>
                        </div>
                    </div>
                    <div *ngIf="amenities && amenities.length > 0">
                        <div class="mb-2"><p2>Amenities and Services</p2></div>
                        <div *ngFor='let a of amenities'>
                            <p3>* {{a.amenity}}</p3>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
         </ngx-spinner>
    </div>
</body>
