import {Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from '../helpers/pendingChanges.guard';
import { Applicant } from '../models/applicant';
import { DisabilityAccommodation } from '../models/disabilityAccommodation';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from '../services/application-pending-changes.service';
import { MessageService } from '../services/message.service';

@Component({
    selector: 'disability-accom',
    templateUrl:'./disability-accom.component.html',
    styleUrls: ['./disability-accom.component.scss']
})

export class DisabilityAccomComponent implements OnInit, routingAway{
    applicant: Applicant;
    params:Parameters = new Parameters();
    disabilityAccommodation:DisabilityAccommodation = new DisabilityAccommodation();
    readonly:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));

        this.disabilityAccommodation = this.applicant.disabilityAccommodation;
        this.params.hmy = this.applicant.generalInfo.hmy;

        if (this.navigationParams.formMode == null) 
            this.navigationParams.formMode = '';

        if (this.applicant.generalInfo.tenantStatus == 'Current' || this.applicant.generalInfo.tenantStatus == 'Notice' || this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;

        if (!this.disabilityAccommodation && this.applicant.generalInfo.pCode)
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getDisabilityAccommodationCNV(this.params)
                .subscribe(data  => { 
                    if (data) this.disabilityAccommodation = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            else
            {
                this.applicantService.getDisabilityAccommodationHCV(this.params)
                .subscribe(data  => { 
                      if (data) this.disabilityAccommodation = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.disabilityAccommodation) this.disabilityAccommodation = new DisabilityAccommodation();
        this.spinnerService.hide();
    }

    back(f:any)
    {
        // if (this.readonly || (f.valid && !this.readonly))
        // {
            this.setFieldValues();
            this.applicant.disabilityAccommodation = this.disabilityAccommodation;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/familyMembers']);
        // }
    }

    next(f:any){

        if (this.readonly || (f.valid && !this.readonly))
        {
            this.setFieldValues();
            this.applicant.disabilityAccommodation = this.disabilityAccommodation;        
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/veteranStatus']);
        }
    }

    setFieldValues(){
        this.disabilityAccommodation.noStairs = + this.disabilityAccommodation.noStairs;
        this.disabilityAccommodation.handRail = + this.disabilityAccommodation.handRail;
        this.disabilityAccommodation.hearing = + this.disabilityAccommodation.hearing;
        this.disabilityAccommodation.sight = + this.disabilityAccommodation.sight;
        this.disabilityAccommodation.wheelChair = + this.disabilityAccommodation.wheelChair;
    }
}