import { Preferences } from './preferences';
import { Waitlists } from './waitlists';

export class WaitlistsExtended{
    waitlistExtendedName:string;
    waitlist: Waitlists;
    wlStatus: string;
    preferences: string;
    shraRequirements: string;
    applicantStatus: string;
    applicantBedroom: number;
    applicantBREligibility: boolean;
    accessCode:string;
    accessCodeValid:boolean;
    action: string;
    waitlistRequirements:string;
    applicantAgeEligibility: boolean;   
    waitlistProgram: string; 
    waitlistType: string; 
    waitlistTypeCategory: string; 
    imageUrl: string;
    preferenceList:Preferences[];
}