import {Component, Input, OnInit} from "@angular/core";
import { environment } from "src/environments/environment";
import { NavigationParameters } from "../models/navigationParameters";
import { PortalParameters } from "../models/portalParameters";
import { PopupMessageService } from "../services/popup.message.service";
import { WaitlistService } from "../services/waitlist.service";


@Component ({
    selector : 'banner',
    templateUrl : './banner.component.html',
    styleUrls: ['./banner.component.scss']
})

export class BannerComponent implements OnInit{
    @Input() showHCVMsg:boolean = false;
    isMobile:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    portalParams:PortalParameters = new PortalParameters;

    constructor (
        private popupMessageService:PopupMessageService,
        private waitlistService:WaitlistService,
        ){
            this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();

        if (!this.navigationParams.hcvTBWLText || (this.navigationParams.hcvTBWLText && this.navigationParams.hcvTBWLText?.length == 0))
        {
            this.portalParams.category = "HCVTBWLText";
            this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((d) => {
                this.navigationParams.hcvTBWLText = d[0].description;
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            });
        }

    }

    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }

}