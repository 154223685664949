<body>
        <div class="container-fluid jumbotron mt-5">
                <div class="container bg-transparent">
                <div class="mt-5"></div>
                        <div class="mb-5">
                                <div [innerHTML] = "announcement.description"></div>
                        </div>
                </div>
        </div>                
</body>
 
