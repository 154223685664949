<form name="form" #f="ngForm" novalidate>
    <div class="form d-flex justify-content-center align-items-center" *ngIf="generalInfo">

        <div>
            <div *ngIf="updateParam == 'application'">
                <label class="page-subtitle1 mb-5">Your application update is past due. Please click the update button to update your application</label>
                <div class="whitespacenowrap">
                    <input class="checkbox" type="checkbox" [checked]="noChanges" (change)="toggleNochangesValue()">
                    <span class="control-label ml-2" for="noChanges">Check this box, if there are no changes <br/>to the application</span>
                </div>
            </div>

            <div *ngIf="updateParam == 'address'">

                <div class="form-group">
                    <label class="control-label">Mailing Address <small><b>(If homeless, please provide contact information for a friend, relative or service provider)</b></small>  </label>         
                    <div class="input-group">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.mailingAddress" class="form-control"  name="mailingAddress" #mailingAddress="ngModel" required/>
                        <small *ngIf="mailingAddress.invalid && (mailingAddress.dirty || formInvalid)" class="errormsg ml-2">Required</small>                        
                    </div>
                </div>

                <div class="form-group">
                        <label class="control-label">Apt. </label>
                        <div class="input-group">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.apt" class="form-control" name="apt" #apt="ngModel">
                        </div>
                </div>

                <div class="form-group">
                        <label class="control-label">City</label>
                        <div class="input-group">
                        <select [disabled]='readonly' class="form-control" [(ngModel)]="generalInfo.city" name="city" #city="ngModel" required>
                            <option class="option-label" *ngFor="let city of cityList" [value]="city.description">{{city.description}}</option>
                        </select>
                        <div *ngIf="city.invalid && (city.dirty || formInvalid)" class="errormsg ml-2">Required</div> 
                        </div>
                </div>

                <div class="form-group">    
                        <div *ngIf="generalInfo.city=='Other'" class="col form-group">
                        <label class="control-label">Other City</label>
                        <div class="input-group">
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.otherCity" class="form-control" name="otherCity" #otherCity="ngModel" placeholder="" required/>
                            <div *ngIf="otherCity.invalid && (otherCity.dirty || formInvalid)" class="errormsg ml-2">Required</div>                        
                        </div>
                        </div>
                </div>  

                <div class="form-group">    
                        <label class="control-label">State</label>
                        <div class="input-group">
                        <select [disabled]='readonly' [(ngModel)]="generalInfo.state" class="form-control input-small" name="state" #state="ngModel" required>
                            <option class="option-label" *ngFor="let state of stateList" [value]="state.description">{{state.description}}</option>
                        </select>
                        <div *ngIf="state.invalid && (state.dirty || formInvalid)" class="errormsg ml-2">Required</div>                        
                        </div>
                </div>

                <div class="form-group">
                        <label class="control-label">Zip Code</label>
                        <div class="input-group">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.zipCode" class="form-control" name="zipCode" #zipCode="ngModel" placeholder="" pattern="^[0-9]{5}(?:-[0-9]{4})?$" required/>
                        <div *ngIf="zipCode.invalid && (zipCode.dirty || formInvalid) && zipCode.errors.required" class="errormsg ml-2">Required</div>
                        <div *ngIf="zipCode.invalid && (zipCode.dirty || formInvalid) && zipCode.errors.pattern" class="errormsg ml-2">Invalid</div> 
                        </div>
                </div>

            </div>

            <div *ngIf="updateParam == 'email'">
                <div class="form-group">
                    <label class="control-label mb-5">Please note that this will only update the email in the application and NOT the login email.</label>
                    <label class="control-label">Email Address</label>
                    <div class="input-group">
                        <input type="text" [(ngModel)]="generalInfo.email" class="form-control" name="email" #email="ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
                        <div *ngIf="email.invalid && email.errors.required && (email.dirty || formInvalid) && email.errors.required" class="errormsg ml-2">Required</div> 
                        <div *ngIf="email.invalid && (email.dirty || formInvalid) && email.errors.pattern" class="errormsg ml-2">Invalid</div> 
                    </div>
                </div>
            </div>

            <div *ngIf="updateParam == 'phone'">
                <div class="form-group">
                    <label class="control-label">Phone (no dashes, include area code)</label>
                    <div class="input-group">
                        <input type="text" [(ngModel)]="generalInfo.cellTel" class="form-control" name="cellTel" #cellTel="ngModel" placeholder="" pattern="\d{10}" required>
                        <div *ngIf="cellTel.invalid && cellTel.errors.required && (cellTel.dirty || formInvalid)" class="errormsg ml-2">Required</div>                        
                        <div *ngIf="cellTel.invalid && cellTel.errors.pattern && (cellTel.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>                        
                    </div>
                </div>
                <div class="form-group">    
                    <label class="control-label">Alternate Phone (no dashes, include area code)</label>
                    <div class="input-group">
                        <input [disabled]='readonly' type="text"  [(ngModel)]="generalInfo.homeTel" class="form-control" name="homeTel" #homeTel="ngModel" placeholder="" pattern="\d{10}"/>
                        <div *ngIf="homeTel.invalid && homeTel.errors.pattern && (homeTel.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>                                                                        
                    </div>
                </div>                
            </div>

            <div *ngIf="updateParam == 'income'">
                <div class="form-group">
                    <label class="control-label">What is the current MONTHLY gross income for ALL household members from ALL sources?</label>
                    <div class="input-group">
                        <input type="number" min="0" [(ngModel)]="incomeRent.monthlyGross" id="monthlyGross" name="monthlyGross" class="form-control" #monthlyGross="ngModel" required [minNumber]="0" [maxNumber]="maxAmount" aria-label="Amount (to the nearest dollar)">
                        <div *ngIf="monthlyGross.invalid && monthlyGross.errors.required && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                        <div *ngIf="monthlyGross.invalid && monthlyGross.errors.maxNumber && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Income is too high</div>
                        <div *ngIf="monthlyGross.invalid && monthlyGross.errors.minNumber && (monthlyGross.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>
                    </div>
                </div>
            </div>

            <div class="mt-2 text-right">
                <div *ngIf="updateParam == 'application'" type="button" class="btn btn-sm btn-general " (click)="updateApplication()">Update</div>
                <div *ngIf="updateParam == 'address' || updateParam == 'phone' || updateParam == 'email'" type="button" class="btn btn-sm btn-general " (click)="updateContactInfo(f.form)">Update</div>
                <div *ngIf="updateParam == 'income'" type="button" class="btn btn-sm btn-general " (click)="updateIncomeRent(f.form)">Update</div>
                <button type="button" class="btn btn-sm btn-general ml-2" (click)="cancel()">Cancel</button>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait...</p>
            </ngx-spinner>             
        </div>
    </div>
 </form>
