import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';

export interface routingAway {
    routingAway(): boolean;
}

@Injectable({
  providedIn: 'root',
})
export class pendingChangesGuard implements CanDeactivate<routingAway> {
  canDeactivate(component: routingAway): Observable<boolean> | boolean {

    if (component.routingAway())
        return true;
    else
        return false;
  }
}