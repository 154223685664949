import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormGroup } from '@angular/forms';

import { InvalidDate } from './invalid-date.validator';

@Directive({
    selector: '[invalidDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: InvalidDateDirective, multi: true }]
})
export class InvalidDateDirective implements Validator {
    @Input('invalidDate') invalidDate: string = "";

    validate(formGroup: UntypedFormGroup): ValidationErrors {
        return InvalidDate(this.invalidDate)(formGroup);
    }
}