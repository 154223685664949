<body>
    <div class="container-fluid bg-transparent jumbotron">
        <div class="container bg-transparent justify-content-center  mt-5 mb-5">
            <div class="row">
                <div [ngClass]="!isMobile ? 'column1 text-left ml-5' : 'text-center'">
                    <div [ngClass]="!isMobile ? 'col-md-8' : ''">
                        <div class="page-title ">Changing Lives.</div>
                        <div class="page-title ">One Household at a Time.</div>
                     </div>
                     <div [ngClass]="!isMobile ? 'col-md-10 mt-3' : 'mt-3'">
                        <div class="page-text ">Welcome to Sacwaitlist.com - a Sacramento Housing and Redevelopment Agency (SHRA) website.</div>
                     </div>
                     <div *ngIf="showContent!='login' && showContent!='changepassword'" [ngClass]="!isMobile ? 'col-md-8 mt-3 mb-5' : 'mt-3 mb-5'">
                        <button type="button" (click)="setContent('login')" class="btn btn-general">Sign In</button>
                     </div>
                     <div *ngIf="showContent=='login'" [ngClass]="!isMobile ? 'col-md-8 mt-3 mb-5' : 'mt-3 mb-5'">
                        <button type="button" (click)="setContent('newregistration')" class="btn btn-general">Sign Up</button>
                     </div>
                </div>
                <div id="bottom" [ngClass]="!isMobile ? 'column1 mr-5' : 'text-center'">
                    <div *ngIf="showContent=='login'" class="slide-right">
                        <login (showContent)="setContent($event)"></login>
                    </div>
                    <div *ngIf="showContent=='registration'" class="slide-left">
                        <registration (showContent)="setContent($event)"></registration>
                    </div>
                    <div *ngIf="showContent=='newregistration'" class="slide-right">
                        <registration-new (showContent)="setContent($event)"></registration-new>
                    </div>
                    <div *ngIf="showContent=='passwordresetrequest'" class="slide-left">
                        <password-reset-request></password-reset-request>
                    </div>
                    <div *ngIf="showContent=='changepassword'" class="slide-right">
                        <change-password></change-password>
                    </div>
                </div>                
            </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
            <p style="color: white;">Please wait.</p>
         </ngx-spinner>
    </div>
</body>
