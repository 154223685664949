import { Component } from "@angular/core";
import { MessageService } from '../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector : 'password-reset',
    templateUrl:'./password-reset.component.html',
    styleUrls:['./password-reset.component.scss']
})

export class PasswordResetComponent {
    result:boolean = false;
    user:User = new User();
    resetStatus:string = "";
    formInvalid:boolean = false;
    showPassword:boolean = false;   
    showConfirmPassword:boolean = false;   
    isMobile:boolean = false;


    constructor (
        private userService:UserService,
        private msg:MessageService, 
        private spinnerService: NgxSpinnerService,
        private router:ActivatedRoute,
        private rter1:Router
    ){
        this.isMobile = window.innerWidth < 768;
    }

    ngOnInit(){
        this.spinnerService.show();

        //add code to get token from parameters and find if valid user
        this.user.id = this.router.snapshot.queryParamMap.get('userId');
        this.user.token = this.router.snapshot.queryParamMap.get('code');
        this.spinnerService.hide();
    }

    toggleShowPassword(){
        this.showPassword = !this.showPassword
    }

    toggleShowConfirmPassword(){
        this.showConfirmPassword = !this.showConfirmPassword
    }

    resetPassword(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            this.userService.resetPassword(this.user).subscribe(data=>{
                if (data)
                    this.resetStatus = "success";
            });
            this.spinnerService.hide();
        }
    }
    
    login(){
        this.rter1.navigate(['/loginMain']);
    }
}