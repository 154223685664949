<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="form d-flex justify-content-center align-items-center bg-light" *ngIf="generalInfo">
            <div class="mt-2">
                <hr>
                <div class= "text-center">
                    <h2>Portal Administration Functions</h2>
                </div>
                <hr>
                <div class="row">
                    <div class="form-group col-md-6">
                        <label class="control-label">Convert Twin Rivers Applicants to Mirasol</label>         
                        <button type="button" (click)="ConvertTwinRiversToMirasol()" class="btn btn-primary btn-xs float-left mr-3">Convert</button>
                    </div>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>    
    </form>
</body>
