import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalParameters } from '../models/portalParameters';
import { ApplicantProcessesService } from '../services/applicant-processes.service';
import { ApplicantProcess } from '../models/applicantProcess';
import { ApplicantStep } from '../models/applicantStep';
import { ApplicantForm } from '../models/applicantForm';
import { PopupMessageService } from '../services/popup.message.service';
import { environment } from 'src/environments/environment';



@Component({
    selector: 'applicant-process',
    templateUrl: './applicant-process.component.html' ,
    styleUrls: ['./applicant-process.component.scss']
})

export class ApplicantProcessComponent implements OnInit{
    applicant:Applicant = new Applicant();
    loggedIn:boolean = false;
    role:string = "";
    isIE:boolean = false;
    params:Parameters = new Parameters();
    portalParams:PortalParameters = new PortalParameters();
    navigationParams:NavigationParameters = new NavigationParameters();
    applicantProcesses: ApplicantProcess[] =[];
    applicantSteps: ApplicantStep[] = [];
    applicantForms: ApplicantForm[] = [];
    isMobile:boolean = false;
    formInvalid:boolean = false;
    imgFolder: string = environment.imgFolder;


constructor(
    private spinnerService: NgxSpinnerService,
    private applicantProcessService:ApplicantProcessesService,
    private router:Router,
    private popupMessageService:PopupMessageService
) {
    this.isMobile = window.innerWidth < 768;
}


    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.loggedIn =  this.navigationParams.loggedIn;
        this.role =  this.navigationParams.userRole;


        //get applicant processes
        this.portalParams.applicantCode = this.applicant.generalInfo.pCode;
        this.applicantProcessService.getApplicantProcesses(this.portalParams)
        .subscribe(p => {
            this.applicantProcesses = p;
            if (p)
            {
                p.forEach(p1 => {
                    this.portalParams.applicantProcessID = p1.applicantProcessID;
                    this.applicantProcessService.getApplicantStepsByApplicantProcess(this.portalParams)
                    .subscribe(s => {
                        if (s) {
                            s.forEach(s1 => {
                                this.portalParams.applicantStepID = s1.applicantStepID;
                                this.applicantProcessService.getApplicantFormsByApplicantStep(this.portalParams)
                                .subscribe(f => {
                                    s1.applicantForms = f;
                                })
                            })
                        }
                        p1.applicantSteps = s;
                    })
                })
            }
        });

        this.spinnerService.hide();

    }
   
   popupClick(infoText:string){
    this.popupMessageService.show("Waiting List Status - ", infoText, true, "md");
   }


   goToForm(processID:string,formID:string, applicantFormID:string, formTitle:string, tCode:string){
        localStorage.setItem('tcode', JSON.stringify(tCode));
        localStorage.setItem('FormID', JSON.stringify(formID));
        localStorage.setItem('applicantFormID', JSON.stringify(applicantFormID));
        localStorage.setItem('ProcessID', JSON.stringify(processID));
        localStorage.setItem('isapplicantFormLocked', JSON.stringify('false'));
        localStorage.setItem('FormTitle', JSON.stringify(formTitle));
        this.router.navigate(['/form']);
    }

}