<body>
   <div class="mb-5" *ngIf="wlPrograms && wlTypes">
         <div class="card-deck">
            <div *ngFor='let wlp of wlPrograms'  class="container text-center">
               <div class="card {{wlp.cardColor}}" >
                  <div class="col-sm-12 col-xs-12 container_foto"><h1>{{wlp.name}}</h1>
                     <div class="ver_mas text-center">
                        <span  class="lnr lnr-eye"></span>
                     </div>
                     <article class="text-left">
                        <div *ngFor="let c of waitlistTypeCategories">
                           <div class="mb-4" *ngIf="checkIfWLTypeExistsForProgramCategory(wlp.waitlistProgramID, c)">
                              <h2>{{c}}</h2>
                              <div *ngIf="wlp.showAllTypes">
                                    <a href="javascript:void(0)" (click)="programClick(wlp.name, 'All', c)"><h5 class="text-warning">{{wlp.name}} {{c}}</h5></a>
                              </div>
                              <div *ngIf="!wlp.showAllTypes">
                                 <div *ngFor='let wlt of wlTypes'>
                                    <div *ngIf="wlt.waitlistProgramID == wlp.waitlistProgramID && wlt.waitlistTypeCategory == c">
                                       <a href="javascript:void(0)" (click)="programClick(wlp.name, wlt.name, c)"><h5 class="text-warning">{{wlt.name}} {{c}}
                                          <span class="text-warning" *ngIf="wlp.name == 'Housing Choice Voucher' && wlt.name == 'Tenant-Based' && c == 'Waiting Lists'"> (Former Section 8)</span></h5>
                                       </a>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </article>
                     <img src="{{imgFolder}}Program{{wlp.name.replace('/', '').replace('.', '')}}.jpg" alt="">
                  </div>
               </div>
            </div>
         </div>   
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
         <p style="color: white;">Please wait.</p>
      </ngx-spinner>
   </div>
</body>