import { Component, EventEmitter, Output } from "@angular/core";
import { MessageService } from '../../services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { Parameters } from '../../models/parameters';
import { Applicant } from '../../models/applicant';
import { WaitlistPortalService } from '../../services/waitlist-portal.service';
import { NavigationParameters } from "../../models/navigationParameters";
import { AuthService } from "../../services/auth.service";

@Component({
    selector : 'login',
    templateUrl:'./login.component.html',
    styleUrls:['./login.component.scss']
})

export class LoginComponent {
    @Output() showContent:EventEmitter<string> = new EventEmitter(); 
    loggedIn:boolean = true;
    emailConfirmed:boolean = true;
    user:User = new User();
    title:string = "Login";
    params:Parameters = new Parameters();
    applicant:Applicant = new Applicant();
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    userstatus:string = "";
    showPassword:boolean = false;   


    constructor (
        private userService:UserService,
        private waitlistPortalService:WaitlistPortalService,
        private msg:MessageService, 
        private route: ActivatedRoute,
        private router:Router,
        private authService:AuthService,
        private spinnerService: NgxSpinnerService
    ){
    }

    ngOnInit(){

    }

    setContent(content:string){
        this.showContent.emit(content);
    }

    toggleShowPassword(){
        this.showPassword = !this.showPassword
    }

    applicantLogin(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            let loggedInUser:User;
            this.userService.login(this.user)
            .subscribe(data  => { 
                loggedInUser = data;
                if (loggedInUser)
                {
                    this.user.id = data.id;
                    if (loggedInUser.emailConfirmed == true) 
                    {
                        this.user = loggedInUser;
                        this.loggedIn = true; 
                        if (this.user.token) localStorage.setItem('jwtoken', JSON.stringify(this.user.token));
                        this.waitlistPortalService.setupCurrentUser(this.user, this.params);

                        setTimeout(() => {
                            const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/applicantDashboard';
                            this.spinnerService.hide();                        
                            this.router.navigateByUrl(returnUrl);
                            //this.router.navigate(['/home']);
                        }, 5000);
                    }
                    else
                    {
                        this.emailConfirmed = false;
                        this.loginError("Email Confirmation Error");
                        this.spinnerService.hide();                        
                    }
                }
                else
                {
                    this.loginError("Error logging in");
                    this.spinnerService.hide();                        
                }
                }, (error) => {
                    this.loginError(error);
                    this.spinnerService.hide();                        
            });
        }
    }

    loginError(error:string)
    {
        this.spinnerService.hide();
        this.msg.showError(error, this.title);
        this.loggedIn = false;
        localStorage.setItem('loggedIn', JSON.stringify(false));
    }


    resendConfirmationLink(){
        this.spinnerService.show();
        this.authService.resendConfirmationLink(this.user)
        .then(s=> {
            if (s)
                this.userstatus = s.email == "not found"? "resent error" : "resent";
            else
                this.userstatus = "resent error";
        });
        this.spinnerService.hide();
    }

    resetEmail(){
        localStorage.setItem('resetEmail', JSON.stringify("resetEmail"));
        this.setContent("registration");
    }

}