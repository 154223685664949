import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MaxDate } from './max-date.validator';


@Directive({
    selector: '[maxDate]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxDateDirective, multi: true }]
})
export class MaxDateDirective implements Validator {
    @Input('maxDate') maxDate: Date;

    validate(control: UntypedFormControl): ValidationErrors {
        return MaxDate(this.maxDate)(control);
    }
}