  <body>
    <form name="form" #f="ngForm" novalidate>
      <div class=" form d-flex justify-content-center align-items-center" *ngIf="disabilityAccommodation">
        <div class="mt-2 mb-5">
          <hr>
          <div class="text-center">
            <h2>Disability Accommodations</h2>
          </div>
          <hr>
          <div class="row mt-5 mb-5 ml-2 mr-2">
            <div class="formgroup col-lg-12 justify-content-between">
              <div class="d-flex justify-content-between mb-5">
                <label class="control-label">Please Select any of the following items that would help best accommodate your disability</label>
              </div>
 
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                  <label class="control-label mr-5">Unit without stairs:</label>
                  <div class="form-group ml-3">
                    <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]="disabilityAccommodation.noStairs" id="noStairs" name="noStairs" #noStairs="ngModel" [value]=1 required /><span class="control-label mr-2">Yes</span></span>
                    <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]="disabilityAccommodation.noStairs" id="noStairs" name="noStairs" #noStairs="ngModel" [value]=0 [disabled]="readonly" required /><span class="control-label">No</span></span>
                  </div>
              </div>
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label mr-5">Fully accessible (wheelchair accommodations) </label>
                <div class="form-group ml-3">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.wheelChair' id="wheelChair" name="wheelChair"  #wheelChair="ngModel" [value]=1 required /><span class="control-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.wheelChair' id="wheelChair" name="wheelChair" #wheelChair="ngModel" [value]=0 [disabled]="readonly" required /><span class="control-label">No</span></span>
                </div>
              </div>
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label mr-5">Unit with hand rails/ grab-bars </label>
                <div class="form-group ml-3">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.handRail' id="handRail" name="handRail" #handRail="ngModel" [value]=1 required /><span class="control-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.handRail' id="handRail" name="handRail" #handRail="ngModel" [value]=0 [disabled]="readonly" required /><span class="control-label">No</span></span>
                </div>
              </div>
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label mr-5">Unit for the hearing Impaired </label>
                <div class="form-group ml-3">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.hearing' id="hearing" name="hearing" #hearing="ngModel" [value]=1 required /><span class="control-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.hearing' id="hearing" name="hearing" #hearing="ngModel" [value]=0 [disabled]="readonly" required /><span class="control-label">No</span></span>
                </div>
              </div>
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-5' : 'mb-5'">
                <label class="control-label mr-5">Unit for the sight Impaired </label>
                <div class="form-group ml-3">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.sight' id="sight" name="sight" #sight="ngModel" [value]=1 required /><span class="control-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='disabilityAccommodation.sight' id="sight" name="sight" #sight="ngModel" [value]=0 [disabled]="readonly" required /><span class="control-label">No</span></span>
                </div>
              </div>

              <div [hidden]="!f.form.invalid" class="errormsg mb-5">Please select yes or no for all options</div>
              
              <div class="mb-3">
                  <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
              </div>

              <div class="mb-5">
                  <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
              </div>
            </div>
          </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
          <p style="color: white;">Please wait.</p>
        </ngx-spinner>
      </div>
    </form>
  </body>
