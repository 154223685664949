import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationParameters } from "../models/navigationParameters";

@Component({
    selector : 'login-main',
    templateUrl:'./login-main.component.html',
    styleUrls:['./login-main.component.scss']
})

export class LoginMainComponent implements OnInit {
    showContent:string = "login";
    navigationParams:NavigationParameters = new NavigationParameters();
    isMobile:boolean = false;

    constructor ( 
        private router:Router,
        private viewportScroller: ViewportScroller,
    ){
        this.isMobile = window.innerWidth < 768;
    }
    
    ngOnInit(){
        // this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        let returnLocation = JSON.parse(localStorage.getItem('returnLocation'));
        this.showContent = returnLocation ?? this.showContent;

        this.showContent =  this.showContent == '' || this.showContent.length == 0 || this.showContent == "userlogin" ? "login" : this.showContent

        if (this.isMobile)
            this.scrollToAnchor("bottom");
    }

    setContent(content:string){
        this.showContent = content;
        if (this.isMobile)
            this.scrollToAnchor("bottom");
    }

    scrollToAnchor(anchor:string){
        //setup scrolling
        this.viewportScroller.scrollToAnchor(anchor);
    }

}