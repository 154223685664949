// unsaved-changes.service.ts
import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { promise } from 'protractor';
import { filter } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ConfirmationMessageService } from './confirm-message.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationPendingChangesService {

    private appRoutes = ['/basicInfo','/generalInfo','/familyMembers','/raceEthnicity','/incomeRent','/disabilityAccommodation'
                    ,'/veteranStatus','/homelessStatus','/displacementStatus','/leaseInPlaceStatus','/result'];

    currentRoute: string;

    constructor(private router: Router, private confirmationService: ConfirmationMessageService, private modalService: NgbModal) {
        this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((event: NavigationStart) => {
            this.currentRoute = event.url; //currentroute
        });
    }

    getPendingChanges():boolean {
        let validRoute = this.appRoutes.find(x => x == this.currentRoute) ?? "";
        if (validRoute.length == 0)

            //standard message
            return confirm('Are you sure you want to leave? You may have unsaved changes. Please navigate till the end of the application to submit your changes.');

            //using confirmation message service
            // this.confirmationService.confirm('Unsaved Changes','Are you sure you want to leave? You may have unsaved changes. Please navigate till the end of the application to submit your changes.', 'Leave')
            // .then(result => {
            //     return result;
            // });
        else
            return true;
    }

}