import {Component, HostListener, OnInit} from "@angular/core";
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { routingAway } from "../helpers/pendingChanges.guard";
import { Applicant } from '../models/applicant';
import { IncomeRent } from '../models/incomeRent';
import { NavigationParameters } from "../models/navigationParameters";
import { Parameters } from '../models/parameters';
import { WaitlistTypes } from "../models/waitlistTypes";
import { ApplicantService } from '../services/applicant.service';
import { ApplicationPendingChangesService } from "../services/application-pending-changes.service";
import { MessageService } from '../services/message.service';


@Component ({
    selector: 'income-rent',
    templateUrl: './income-rent.component.html',
    styleUrls: ['./income-rent.component.scss']
})

export class IncomeRentComponent implements OnInit, routingAway{
    applicant: Applicant = new Applicant;
    incomeRent:IncomeRent = new IncomeRent;
    params:Parameters = new Parameters();
    readonly:boolean = false;
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();
    maxAmount:number = 199999;
    isMobile:boolean = false;
    @HostListener('window:beforeunload')
    canDeactivate() {
        return this.routingAway();
    }

    routingAway():boolean {
        return this.appPendingChangesService.getPendingChanges();
    }
    
    
    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private msg:MessageService,
        private router:Router,
        private appPendingChangesService:ApplicationPendingChangesService){
            this.isMobile = window.innerWidth < 768;        
    }

    ngOnInit(){
        this.spinnerService.show();
        this.applicant = JSON.parse(localStorage.getItem('applicant'));
        this.navigationParams = JSON.parse(localStorage.getItem('navigationParams'));
        this.incomeRent = this.applicant.incomeRent;
        this.params.hmy = this.applicant.generalInfo.hmy;
 
        if (this.navigationParams.formMode == null) this.navigationParams.formMode = '';
        if (this.navigationParams.affPCode == null) this.navigationParams.affPCode = '';

        //set readonly mode
        if (this.navigationParams.formMode.toUpperCase() == 'HCVVIEW')
            this.readonly = true;
        else
        {
            if (this.applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || this.applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE')
                if (this.navigationParams.affPCode.length > 0 || (this.navigationParams.waitlistTypesModel && this.navigationParams.waitlistTypesModel.yardiDB && this.navigationParams.waitlistTypesModel.yardiDB == 'AFF'))
                    this.readonly = false;
                else
                    this.readonly = true;
        }

        if ((!this.incomeRent && this.applicant.generalInfo.pCode) || (this.incomeRent && this.incomeRent.hmy != this.applicant.generalInfo.hmy))
        {
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't5')
            {
                this.applicantService.getIncomeRentCNV(this.params)
                .subscribe(data  => { 
                    if (data) this.incomeRent = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,2) == 't0')
            {
                this.applicantService.getIncomeRentHCV(this.params)
                .subscribe(data  => { 
                    if (data) this.incomeRent = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
            if (this.applicant.generalInfo.pCode.substring(0,1).toUpperCase() == 'P')
            {
                this.applicantService.getIncomeRentAff(this.params)
                .subscribe(data  => { 
                    if (data) this.incomeRent = data;
                }, (error) => {
                    this.spinnerService.hide();
                    this.msg.showError(error, "Loading Error");
                });
            }
        }
        if (!this.incomeRent) this.incomeRent = new IncomeRent();
        this.spinnerService.hide();
    }
 
    back(f:any)
    {
        // if (f.invalid && this.readonly == false)
        //     this.formInvalid = true;
        // else
        // {
            this.setFieldValues();
            this.applicant.incomeRent = this.incomeRent;
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/raceEthnicity']);
        // }
    }

    next(f:any){
        if (f.invalid && this.readonly == false)
            this.formInvalid = true;
        else
        {
            this.setFieldValues();
            this.applicant.incomeRent = this.incomeRent;        
            localStorage.setItem('applicant', JSON.stringify(this.applicant));
            this.router.navigate(['/familyMembers']);
        }
    }

    setFieldValues(){
        this.incomeRent.resSSI = + this.incomeRent.resSSI;
        this.incomeRent.renting = + this.incomeRent.renting;
        this.incomeRent.monthlyGross = this.incomeRent.monthlyGross == -0? 0 : + this.incomeRent.monthlyGross;
        this.incomeRent.monthlyRent = this.incomeRent.monthlyRent == -0 || this.incomeRent.renting == 0 ? 0 : + this.incomeRent.monthlyRent;
        this.incomeRent.monthlyUtility = this.incomeRent.monthlyUtility == -0 || this.incomeRent.renting == 0 ? 0 : + this.incomeRent.monthlyUtility;
    }
}