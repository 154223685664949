<body>
    <div class="container-fluid p-5" >
        <div class="container">
            <div class="">
                <div class= "text-center h4 mt-5 mb-5" ><div [innerHTML] = "resultMessage"></div></div>
                <div class="row text-center">
                    <span class="ml-3 mr-3">
                        <button type="button" (click)="goToDashboard()" class="btn btn-general text-center mb-5">Home</button>
                    </span>
                    <span class="ml-3 mr-3" *ngIf="readonly">
                        <button type="button" (click)="viewMyApplication()" class="btn btn-general text-center mb-5">View My HCV or PHA Application</button>
                    </span>
                    <span class="ml-3 mr-3" *ngIf="readonly && affApplicant">
                        <button type="button" (click)="updateMyAFFApplication()" class="btn btn-general text-center mb-5">Update My Affordable Housing Waiting List Application</button>
                    </span>
                    <span class="ml-3 mr-3" *ngIf="returnLocation != ''">
                        <button type="button" (click)="goBack()" class="btn btn-general text-center mb-5">{{backButtonCaption}}</button>
                    </span>
                </div>
            </div>
        </div>
    </div>
</body>

