<footer class="nav navbar navbar-expand-lg">
    <div [ngClass]="!isMobile ? 'navbar-nav  ml-5 mt-2 mb-2' : 'navbar-nav mt-2 mb-2 text-center'">
        <div class="navbar-item">
            <div><p>© {{currentYear}}, Sacramento Housing and Redevelopment Agency, all rights reserved.</p></div>
            <div><p>(916) 440-1390 | TTY 711 or 1 (800) 855-7100</p></div>
            <div>
                <span><a href="https://www.shra.org/disability-equal-housing-tft/" target="_blank"><img class="img-responsive img" alt="wheelchair logo" src="{{imgFolder}}wheelchair.png" /></a></span>
                <span><a href="https://www.shra.org/disability-equal-housing-tft/" target="_blank"><img class="img-responsive img" alt="house logo" src="{{imgFolder}}EHO.png" /></a></span>
                <span><a href="https://www.shra.org/disability-equal-housing-tft/" target="_blank"><img class="img-responsive img" alt="phone logo" src="{{imgFolder}}phone.png" /></a></span>
            </div>
        </div>
    </div>    
    <div [ngClass]="!isMobile ? 'navbar-nav mr-5 ml-auto' : 'navbar-nav text-center mb-5 ml-1'">
        <div class="navbar-item">
            <span><a href="https://twitter.com/shrahousing?lang=en" target="_blank"><img src="{{imgFolder}}twitter.png" class="img img-responsive" alt="twittwr"></a></span>
            <span><a href="https://www.facebook.com/SacramentoHousingAndRedevelopmentAgency/" target="_blank" title=""><img src="{{imgFolder}}facebook.png" class="img img-responsive" alt="facebook"></a></span>
            <span><a href="https://www.instagram.com/shrahome/" target="_blank"><img src="{{imgFolder}}instagram.png" class="img img-responsive" alt="instagram"></a></span>
            <span><a href="https://www.linkedin.com/company/sacramento-housing-and-redevelopment-agency/" target="_blank"><img src="{{imgFolder}}linkedin.png" class="img img-responsive" alt="linkedin"></a></span>
            <span><a href="https://www.youtube.com/channel/UCVJeKfDIfYIsp7q11onrhnQ" target="_blank"><img src="{{imgFolder}}youtube.png" class="img img-responsive" alt="youtube"></a></span>
        </div>
    </div>
</footer>