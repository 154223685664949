export class FamilyMember{
    hMy:number;
    memberHMy:number;
    firstName:string;
    lastName:string;
    gender:string;
    dob:string;
    ssn:string;
    relationship:string;
    disability?:number;
    raceWhite?: number;
    raceBlack?: number;
    raceAmericanIndian?: number;    
    raceAsian?: number;
    racePacificIslander?: number;
    ethnicity:string;
    englishUnderstanding?: number;
    primaryLanguage:string;
}