<body>
   <banner [showHCVMsg]="false"></banner>
   <div class="container-fluid bg-transparent jumbotron">
      <div class="container bg-transparent justify-content-center  mt-5 mb-5 p-5">
         <div class="row">
            <div [ngClass]="!isMobile ? 'column1 text-left ml-5' : 'column1 text-center'">
               <div [ngClass]="!isMobile ? 'col-md-8' : ''">
                  <div class="page-title ">Changing Lives.</div>
                  <div class="page-title ">One Household at a Time.</div>
               </div>
               <div [ngClass]="!isMobile ? 'col-md-10 mt-3' : 'mt-3'">
                  <div class="page-text ">Welcome to Sacwaitlist.com - a Sacramento Housing and Redevelopment Agency (SHRA) website.</div>
               </div>
               <div [ngClass]="!isMobile ? 'col-md-10 mt-3' : 'mt-3'">
                  <div class="page-text">View and apply for subsidized housing waiting lists in Sacramento and learn more about available Sacramento housing resources.</div>
               </div>
               <div *ngIf="!loggedIn" [ngClass]="!isMobile ? 'col-md-10 mt-3' : 'mt-3'">
                  <button type="button" (click)="login()" class="btn btn-general mr-1 ml-1 mb-5">Sign In</button>
                  <button type="button" (click)="signUp()" class="btn btn-general mr-1 ml-1 mb-5">Sign Up</button>
               </div>
            </div>
            <div [ngClass]="!isMobile ? 'container column1 mr-5' : 'container column1 mt-5'">
               <div class="container">
                  <div class="row align-items-center" style="border-bottom: solid 1px #00713c;">
                     <div [ngClass]="!isMobile ? 'col-md-8 text-left page-subtitle2 mb-2' : 'text-center page-subtitle2 mb-2'">VIEW WAITING LISTS</div>
                     <div [ngClass]="!isMobile ? 'col-md-4 text-right mb-2' : 'text-center mb-2'"><a type="button" routerLink="/waitingList" class="btn btn-light-green">View Lists</a></div> 
                  </div>
               </div>
               <div class="container">
                  <div class="row align-items-center" style="border-bottom: solid 1px #00713c;">
                     <div [ngClass]="!isMobile ? 'col-md-8 text-left page-subtitle2 mb-2' : 'text-center page-subtitle2 mb-2'">APPLY FOR A WAITING LIST</div>
                     <div [ngClass]="!isMobile ? 'col-md-4 text-right mb-2' : 'text-center mb-2'"><a type="button" routerLink="/waitingList" class="btn btn-light-green">APPLY NOW</a></div> 
                  </div>
               </div>
               <div class="container">
                  <div class="row align-items-center" style="border-bottom: solid 1px #00713c;">
                     <div [ngClass]="!isMobile ? 'col-md-8 text-left page-subtitle2 mb-2' : 'text-center page-subtitle2 mb-2'">CHECK WAITING LIST STATUS</div>
                     <div [ngClass]="!isMobile ? 'col-md-4 text-right mb-2' : 'text-center mb-2'"><a type="button" routerLink="/applicantDashboard" class="btn btn-light-green">VIEW PROFILE</a></div> 
                  </div>
               </div>
               <div class="container">
                  <div class="row align-items-center">
                     <div [ngClass]="!isMobile ? 'col-md-8 text-left page-subtitle2 mb-2' : 'text-center page-subtitle2 mb-2'">LEARN MORE ABOUT WAITING LISTS</div>
                     <div [ngClass]="!isMobile ? 'col-md-4 text-right mb-2' : 'text-center mb-2'"><a type="button" routerLink="/learnMore" class="btn btn-light-green">LEARN MORE</a></div> 
                  </div>
               </div>
            </div>
         </div>
         <div class="ml-5 mt-5 mb-5">
            <div class="page-text text-danger">
               <i>Be Alert! Recently, fliers have been circulating on social media advertising assistance with applying for Section 8 funding. The flyer and website "www.govassistance[dot]org" are NOT legitimate. All Sacramento Housing and Redevelopment Agency waiting lists are ONLY posted on Sacwaitlist.com. For more information about this scam please click here. </i>
               <a class="page-subtitle1 text-danger" href="{{flyerFolder}}Beware Waiting List Scam flyer.pdf" target="_blank"><i class="bi bi-file-earmark-text-fill"></i></a>
            </div>
         </div>
         <div *ngIf="!isMobile" class="text-center mb-5"><img class="img-expand" [src]="getImagePath('my application.png')" alt="" width="100%" height="100%" (click)="expand($event)"></div>
         <div *ngIf="isMobile" class="text-center mb-5"><img class="img-expand" [src]="getImagePath('my application mobile.png')" alt="" width="100%" height="100%" (click)="expand($event)"></div>
         <div class="">
            <div class="card-deck text-center">
               <div class="card align-items-center">
                  <a class="card-text lnk-text" routerLink="/referralProgram">Referral<br/>Programs</a>
               </div>
               <div class="card align-items-center">
                  <a class="card-text lnk-text" routerLink="/contact">Additional<br/>Resources</a>
               </div>
               <div class="card align-items-center">
                  <a class="card-text lnk-text"  href="javascript:void(0)" (click)="updateApplication()">Update My<br/>Application</a>
               </div>
               <div class="card align-items-center">
                  <a class="card-text lnk-text" href="https://www.shra.org/wp-content/uploads/2023/06/Project-Listing-for-website-2023-6-28-23.pdf" target="_blank">List of<br/>Affordable<br/>Rental<br/>Properties</a>
               </div>
            </div>
         </div>
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
         <p style="color: white;">Please wait.</p>
      </ngx-spinner>
   </div>
   <ng-template #content1 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle">Welcome to Sacwaitlist.com!</div>
            <div class="page-text-sm mb-4">A Sacramento Housing and Redevelopment Agency (SHRA) Website</div>
            <div class="page-subtitle1">We have a new look!</div>
            <div class="page-subtitle1">Is this your first time here?</div>
            <div class="row justify-content-between mt-4 mb-2">
               <div class="col-md-6">
                  <div class="page-text-sm mb-2">No, I am a returning visitor.</div>
                  <button type="button" class="btn btn-sm btn-general mb-3" style="min-width: 220px;" (click)="modal.close('Close click')">Take me to the site</button>
               </div>
               <div class="col-md-6">
                  <div class="page-text-sm mb-2">Yes, I am new to Sacwaitlist.com.</div>
                  <button type="button" class="btn btn-sm btn-general mb-3" style="min-width: 220px;" (click)="next(1)">Learn More</button>
               </div>
            </div>
         </div>
      </div>
   </ng-template>      
   <ng-template #content2 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle1 mb-4" style="margin-left: 10%; margin-right: 10%;">Sacwaitlist.com is an SHRA website where you can view and apply for subsidized housing waiting lists in Sacramento County and find more housing resources.</div>
            <div class="page-text-sm" style="margin-left: 20%; margin-right: 20%;">To be eligible for SHRA's assisted housing programs, families must have an income below certain thresholds based on the family size.</div>
            <div class="page-text-sm mb-4">Income limits may vary by program.</div>
            <div *ngIf="!isMobile" class="text-center page-text-sm mb-2">
               <span class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked"/>HOUSING CHOICE VOUCHER</span>
               <span class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />PUBLIC HOUSING</span>
               <!-- <span class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />AFFORDABLE HOUSING</span> -->
            </div>
            <div *ngIf="isMobile" class="text-center page-text-sm mb-2">
               <div class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="HCV" name="IncomeLimit" checked="checked"/>HOUSING CHOICE VOUCHER</div>
               <div class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="CNV" name="IncomeLimit" />PUBLIC HOUSING</div>
               <!-- <div class="page-text-sm ml-3"><input type="radio" class="mr-1" (click)="selectIncomeLimitCode($event)" value="AFF" name="IncomeLimit" />AFFORDABLE HOUSING</div> -->
            </div>
            <div>
               <waitlist-income-limit [item]='yardiIncomeLimitsCode' [itemType]='yardiIncomeLimitsDB' [showSmall]="'true'"></waitlist-income-limit>
            </div>
            <div class="row justify-content-between mt-2 mb-2">
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="back(2)">Back</button></div>
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="next(2)">Next</button></div>
            </div>
         </div>
      </div>
   </ng-template>      
   <ng-template #content3 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle1 mb-4">What is a waitling list? How do they work?</div>
            <div class="page-text-sm mb-4" style="margin-left: 10%; margin-right: 10%;">On sacwaitlist.com you can apply to join a waiting list to be considered for subsidized housing. By applying for a waiting list, 
               you are asking to be considered for a rental assistance program. Once you have applied for a waiting list, you will wait to hear back to see 
               if you have been selected. You can apply to more than one waiting list.</div>
            <div class="row justify-content-between mt-4 mb-4">
               <div class="col-md-6">
                  <img class="tour-image" src="./assets/images/approved app-01.svg">
                  <div class="page-subtitle2 mb-2">IF YOU ARE SELECTED</div>
                  <div class="page-text-sm mt-1">You will be notified that you are now on the waiting list. When there is availability in the program, you will be contacted to start the final eligibility process.</div>
               </div>
               <div class="col-md-6">
                  <img class="tour-image" src="./assets/images/denied app-01.svg">
                  <div class="page-subtitle2 mb-2">IF YOU ARE NOT SELECTED</div>
                  <div class="page-text-sm mt-1">You should keep applying to other programs.</div>
               </div>
            </div>
            <div class="page-text-sm text-danger">SHRA does not provide immediate emergency housing or hotel vouchers.</div>
            <div class="page-text-sm mb-4 text-danger">Need emergency housing now? Call 211.</div>
            <div class="row justify-content-between mt-4 mb-2">
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="back(3)">Back</button></div>
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="next(3)">Next</button></div>
            </div>
         </div>
      </div>
   </ng-template>      
   <ng-template #content4 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle1 mb-4">What programs are available?</div>
            <div class="page-subtitle2 mt-4 mb-1">HOUSING CHOICE VOUCHER (HCV) PROGRAM</div>
            <div class="page-text-sm" style="margin-left: 10%; margin-right: 10%;">Formerly known as the Section 8 program, HCV is a rental assistance program 
               that helps eligible individuals and families pay for housing by providing a housing subsidy paid directly to the landlord. Applicants can register to be 
               considered for two types of rental assistance under the HCV program:</div>
               <div class="page-text-sm mb-4" style="margin-left: 10%; margin-right: 10%;"><b>Tenant-Based Vouchers</b> or <b>Project-Based Vouchers</b></div>
               <button (click)="toggleAccordian($event)" class="accordion">Tenant-Based Vouchers</button>
               <div class="panel bg-transparent">
                  <div>
                     <div class="page-text-sm mb-4" style="margin-left: 10%; margin-right: 10%;">With <b>Tenant-Based Vouchers</b>, you find and choose your rental unit anywhere in
                        Sacramento County. It is the tenant's responsibility to find a home compatible with their family's needs, including any accessibility needs.</div>
                  </div>
               </div>
               <button (click)="toggleAccordian($event)" class="accordion">Project-Based Vouchers</button>
               <div class="panel bg-transparent">
                  <div>
                     <div class="page-text-sm mb-4" style="margin-left: 10%; margin-right: 10%;">With <b>Project-Based Vouchers</b>, the rental assistance is attached to a specific
                        rental unit. This removes the stress of finding your own home!</div>
                     </div>
               </div>
            <div class="page-subtitle2 mt-4 mb-1">PUBLIC HOUSING</div>
            <div class="page-text-sm mb-4" style="margin-left: 10%; margin-right: 10%;">Public housing apartments are owned and operated by SHRA. This includes one to five bedroom rental units located throughout the City and County of Sacramento.</div>
            <div class="row justify-content-between mt-4 mb-2">
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="back(4)">Back</button></div>
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="next(4)">Next</button></div>
            </div>
         </div>
      </div>
   </ng-template>      
   <ng-template #content5 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle1 mb-4">What are preferences?</div>
            <div class="page-subtitle2">Priority placement is given to applicants who qualify for a specific preference category.</div>
            <div class="page-subtitle2">Official documentation must be submitted to prove the household qualifies.</div>
            <div class="page-subtitle2 mb-2 mt-2">Here are a few of the most common preferences:</div>
            <div *ngFor="let p of preferences">
               <button (click)="toggleAccordian($event)" class="accordion">{{p.name}}</button>
               <div class="panel bg-transparent">
                  <div>
                     <div class="page-text-sm" [innerHTML]="p.description"></div>
                  </div>
               </div>
            </div>
            <div class="page-text-sm mt-2">AND MORE!</div>
            <div class="row justify-content-between mt-4 mb-2">
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="back(5)">Back</button></div>
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="next(5)">Next</button></div>
            </div>
         </div>
      </div>
   </ng-template>      
   <ng-template #content6 let-modal>
      <button type="button" class="close" aria-label="Close" (click)="closeClick()"><span aria-hidden="true">&times;</span></button>
      <div class="modal-body">
         <div class="text-center">
            <div class="page-subtitle1 mb-5">How do I get started?</div>
            <img [ngClass]="!isMobile ? 'tour-image-lg img-expand img-fluid mb-5' : 'tour-image-sm img-expand img-fluid mb-5'" [src]="getImagePath('my application mobile no title.png')" (click)="expand($event)">
            <div class="row justify-content-between mt-4 mb-2">
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="back(6)">Back</button></div>
               <div class="col-md-6"><button type="button" class="btn btn-sm btn-general mb-3" (click)="next(6)">Let's Get Started!</button></div>
            </div>
         </div>
      </div>
   </ng-template>      

</body>
