import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';

@Injectable()
export class JwtInterceptor implements HttpInterceptor{

    constructor(
        private jwtHelper:JwtHelperService
    ) {}

    intercept(request:HttpRequest<any>, next:HttpHandler):Observable<HttpEvent<any>>{
        //add authorization header with token if available
        let token = JSON.parse(localStorage.getItem("jwtoken"));
        if (token && !this.jwtHelper.isTokenExpired(token))
        {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request);
    }
}
