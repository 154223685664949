import { EventEmitter, Injectable, Output } from '@angular/core';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Field } from '../models/field';
import { FieldGroup } from '../models/fieldGroup';
import { FieldResponse } from '../models/fieldResponse';
import { Question } from '../models/question';
import { Section } from '../models/section';
import { Process } from '../models/process';
import { ProcessLookup } from '../models/processLookup';
import { Rule } from '../models/rule';
import { ApplicantProcessesService } from './applicant-processes.service';
import { PortalParameters } from '../models/portalParameters';
import { PortalLookups } from '../models/portalLookups';

@Injectable()
export class FormService {
  @Output() sectionStatusEvent: EventEmitter<any> = new EventEmitter();
  public fieldResponsesAll: FieldResponse[];

  constructor(
    private applicantProcessService: ApplicantProcessesService
  ) {}

  private handleError(error: Response) {
    console.log(error);
    return observableThrowError(error['error'] || 'Server error');
  }


  getSectionsByForm(params:PortalParameters):Promise<Section[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getSectionsByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getQuestionsByForm(params:PortalParameters):Promise<Question[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getQuestionsByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getFieldGroupsByForm(params:PortalParameters):Promise<FieldGroup[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getFieldGroupsByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getFieldsByForm(params:PortalParameters):Promise<Field[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getFieldsByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getFieldLookupsByForm(params:PortalParameters):Promise<ProcessLookup[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getProessLookupsByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getRulesByForm(params:PortalParameters):Promise<Rule[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getRulesByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  getFieldResponsesByForm(params:PortalParameters):Promise<FieldResponse[]>{
    return new Promise((resolve, reject) => {
        this.applicantProcessService.getResponsesByForm(params)
        .subscribe(data  => { 
            resolve(data);
        });
    });
  }


  downloadFile(data: any) {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    window.open(url);
  }

  sectionStatusNotifier(section: Section, isVerificationUnset: boolean) {
    const sectionObj = {
      isCompleted: section.isCompleted,
      sectionID: section.sectionID,
      isVerificationUnset: isVerificationUnset
    };
    this.sectionStatusEvent.emit(sectionObj);
  }


  isCompleteSection(sectionID, completedSectionIDs) {
    if (completedSectionIDs && sectionID && completedSectionIDs.split(',').indexOf(sectionID.toString()) > -1) {
      return true;
    }
    else {
      return false;
    }
  }

  numberArray(size: number, startAt: number = 0) {
    return [...Array(size).keys()].map(i => i + startAt);
  }

  setMonths() {
    return this.numberArray(12, 1);
  }

  setDays(month: number, year: number) {
    if (!year || year === 0) {
      year = new Date().getFullYear();
    }

    if (!month || month === 0) {
      month = new Date().getMonth();
    }

    const days = this.daysInMonth(month, year);
    return this.numberArray(days, 1);
  }

  setYears() {
    const years = new Date().getFullYear() - 1900;
    return this.numberArray(years, 1901);
  }

  daysInMonth (month, year) {
    return new Date(year, month, 0).getDate();
  }

  hasRegexMismatch(field: Field): Boolean {
    // ignore currency (4), no length requirement
    const fieldDataTypeNamesToCheck = [
      {name: 'Phone', length: 14},
      {name: 'SSN', length: 11},
      {name: 'Zip', length: 5},
      {name: 'DateRange', length: 21},
      {name: 'Year', length: 4}
    ];
    const fieldDataTypeMatch = fieldDataTypeNamesToCheck.find(f => f.name === field.fieldDataTypeName || f.name === field.fieldTypeName);

    if (fieldDataTypeMatch){
      for (let i = 0; i < field.fieldResponses.length; i++) {
        if (field.fieldResponses[i].response && (field.fieldResponses[i].response.includes('_') || field.fieldResponses[i].response.length !== fieldDataTypeMatch.length)) {
          return true;
        }
      }
    }
    return false;
  }

  hasEmptyDates(responses: FieldResponse[]): Boolean {
    for (let i = 0; i < responses.length; i++) {
      if (responses[i].response) {
        const dateObj = this.deconstructDate(responses[i].response);
        if ((dateObj.day === 0 || isNaN(dateObj.day)) || (dateObj.month === 0 || isNaN(dateObj.month)) || (dateObj.year === 0 || isNaN(dateObj.year))) {
          return true;
        }
      }
      else {
        return true;
      }
    }
    return false;
  }

  hasEmptyLookups(lookups: ProcessLookup[], responses: FieldResponse[]) {
    for (let i = 0; i < lookups.length; i++) {
      const lookupResponses = responses.find(r => r.fileLookupResponseID === lookups[i].responseID);
      if (!lookupResponses) {
        return true;
      }
    }
    return false;
  }

  deconstructDate(dateString: string) {
    const splitDate = dateString.split('/');
    const dateObj = {
      month: Number(splitDate[0]),
      day: Number(splitDate[1]),
      year: Number(splitDate[2])
    };
    return dateObj;
  }

  addNoQuestionsLabel(isFAQ: boolean): Question {
    const field: any = {
      fieldCssClass: '',
      fieldCssID: 0,
      fieldDisplayOrder: 1,
      fieldGroupID: 0,
      fieldID: 0,
      fieldLabel: '',
      fieldTypeName: 'Label',
      fieldName: 'Label',
      fieldResponses: null,
      fieldTypeID: 6,
      formQuestionID: 0,
      isDisabled: false,
      isHidden: false,
      isMultiple: false,
      isOptional: false,
      isReadOnly: false,
      lookupCategoryID: 0,
      lookupResponseForFormQuestionID: 0,
      fileNetIndexParameters: [],
      questionID: 0,
      rules: null,
      repeatNumber: 0,
      lookups: null
    };
    const fields: Field[] = [];
    fields.push(field);
    const fieldGroup: FieldGroup = {
      fieldGroupID: 0,
      fields: fields,
      formQuestionID: 0,
      groupName: 'LabelFieldGroup',
      isHidden: false,
      isRepeatable: false,
      fieldGroupDisplayOrder: 1,
      questionID: 0,
      sectionID: 0,
      addButtonHide: false,
      removeButtonHide: false,
      rules: []
    };
    const fieldGroups: FieldGroup[] = [];
    fieldGroups.push(fieldGroup);
    const labelText = isFAQ ? 'Sorry, no questions match your search criteria.' : 'Based on your answers, there are no required questions for this section. Please continue!';
    const question: Question = {
      fieldGroups: fieldGroups,
      formQuestionID: 0,
      sectionID: 0,
      questionDisplayOrder: 1,
      questionID: 0,
      questionLabel: labelText,
      questionNumberLabel: '',
      questionTip: labelText,
      questionFieldType: '',
      isHidden: false,
      rules: []
    };

    return question;
  }


  private extractData(res: Response) {
    const body = res.json();
    return body || {};
  }
}
