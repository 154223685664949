<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="form d-flex justify-content-center align-items-center" *ngIf="generalInfo">
            <div class="mt-2 mb-5">

                <div class= "text-center mb-5">
                    <hr>
                    <h2>Basic Info</h2>
                    <hr>
                </div>


                    <div class="form-group">
                        <label class="control-label">Social Security # of the Head of Household (no dashes)</label>  
                        <div class="input-group mb-3 ml-3">       
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.ssn" name="ssn" id="ssn" class="form-control" #ssn="ngModel" required pattern="\d{9}"/>
                            <div *ngIf="ssn.invalid && (ssn.dirty || formInvalid)" class="errormsg">
                                <div *ngIf="ssn.errors.required" class="errormsg ml-2">SSN is required</div>
                                <div *ngIf="ssn.errors.pattern" class="errormsg ml-2">SSN is invalid</div>
                            </div>
                        </div>
                    </div>
                
                    <div class="form-group">
                        <label class="control-label">Date of Birth of Head of Household</label>
                        <div class="input-group mb-3 ml-3">
                            <input [disabled]='readonly' [(ngModel)]="generalInfo.dob" name="dob" id="dob" class="form-control" #dob="ngModel" [maxDate]="maxDate|date" [minDate]="minDate|date" pattern="^(([1-9])|(0[1-9])|(1[0-2]))\/(([1-9])|(0[1-9])|([1-2][0-9])|(3[0-1]))\/((19|20)\d{2})$" required>
                            <div *ngIf="dob.invalid && (dob.dirty || formInvalid)" class="errormsg">
                                <div *ngIf="dob.errors.required" class="errormsg ml-2">Date of birth of head of household is required</div>
                                <div *ngIf="!dob.errors.required && !dob.errors.maxDate && !dob.errors.pattern" class="errormsg ml-2">Invalid date</div>
                                <div *ngIf="dob.errors.pattern" class="errormsg ml-2">Date of birth must be a valid date in the format MM/DD/YYYY</div>
                                <div *ngIf="dob.errors.maxDate" class="errormsg ml-2">Head of household cannot be younger than 14 years of age</div>
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label">First Name</label>         
                        <div class="input-group mb-3 ml-3">
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.firstName" name="firstName" id="firstName" #firstName="ngModel" class="form-control" required/>
                            <div *ngIf="firstName.invalid && (firstName.dirty || formInvalid)"  class="errormsg ml-2">First name is required</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Middle Initial</label>  
                        <div class="input-group mb-3 ml-3">       
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.middleInitial" name="middleInitial" id="middleInitial" #middleInitial="ngModel" class="form-control"/>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label">Last Name</label>
                        <div class="input-group mb-5 ml-3">
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.lastName" name="lastName" id="lastName" #lastName="ngModel" class="form-control" required>
                            <div *ngIf="lastName.invalid && (lastName.dirty || formInvalid)" class="errormsg ml-2">Last name is required</div>
                        </div>
                    </div>
                <div class="mb-3">
                    <button type="button" (click)="back()" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
                </div>

                <div class="mb-5">
                    <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>    
    </form>
</body>
