import { Component } from "@angular/core";
import { User } from '../../models/user';
import { UserService } from '../../services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector : 'password-reset-request',
    templateUrl:'./password-reset-request.component.html',
    styleUrls:['./password-reset-request.component.scss']
})

export class PasswordResetRequestComponent {
    result:boolean = false;
    user:User = new User();
    resetStatus:string = "";
    formInvalid:boolean = false;    

    constructor (
        private userService:UserService,
        private spinnerService: NgxSpinnerService
    ){
    }

    ngOnInit(){

    }

    resetPasswordRequest(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {

            this.spinnerService.show();
            this.userService.resetPasswordRequest(this.user).subscribe(data=>{
                if (data)
                    this.resetStatus = "success";
                else
                    this.resetStatus = "failure";
            });
            this.spinnerService.hide();
        }
    }
}