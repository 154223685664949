<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="container">

            <div class="text-left fst-italic mb-5" *ngIf="userstatus">
                <div *ngIf="userstatus == 'already registered'" class="page-subtitle1">
                    An account exists with this email address. Click <a href="javascript:void(0)" (click)="setContent('login')">here</a> to login
                </div>
                <div *ngIf="userstatus == 'success'" class="page-subtitle1">
                    Registration is successful. An email with a confirmation link is sent to this email address. Please use the link to confirm your registration.
                </div>
                <div *ngIf="userstatus == 'failure'" class="alert alert-danger">
                    An error occurred during the registration process. Try again.
                </div>
                <div *ngIf="userstatus == 'registration not confirmed'" class="alert alert-danger">
                    An account exists with this email address but registration has not been confirmed yet. Please check your inbox for an email with a confirmation link and use the link to confirm your registration.
                    <div class="mt-3"><a href="javascript:void(0)" (click)="resendConfirmationLink()">Resend</a> a confirmation link</div>
                </div>
                <div *ngIf="userstatus == 'resent'" class="page-subtitle1">
                    An email with a confirmation link is sent to your email address. Please use the link to confirm your registration.
                </div>
                <div *ngIf="userstatus == 'resent error'" class="alert alert-danger">
                    Resend confirmation link failed. Invalid account
                </div>
            </div>

            <div *ngIf="userstatus == '' || userstatus == 'failure' || userstatus == 'not found'" class="form-group fst-italic">
                <div *ngIf="email.invalid && (email.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="email.errors.required">Email address is required</div>
                    <div *ngIf="email.errors.pattern">Email address is not valid</div>
                </div>
                <small class="info-field-label" id="emailHelp">This email address will be used as your contact email</small>
                <input type="email" [(ngModel)]="user.email" name="email" id="email" #email="ngModel" placeholder="Email Address" required pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"/>


                <div *ngIf="confirmEmail.invalid && (confirmEmail.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="confirmEmail.errors.mustMatch">Email addresses do not match</div>
                    <div *ngIf="confirmEmail.dirty && confirmEmail.errors.required">Re-enter Email Address is required</div>
                </div>
                <input type="email" autocomplete="off" [(ngModel)]="user.confirmEmail" name="confirmEmail" id="confirmEmail"  #confirmEmail="ngModel" placeholder="Re-enter Email Address" required [mustMatch]="['email', 'confirmEmail']" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"/> 

                <div *ngIf="password.invalid && (password.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="password.errors.required">Password is required</div>
                    <div *ngIf="password.errors.pattern">Password should follow the pattern below</div>
                </div>
                <small class="info-field-label-sm" id="passwordHelp">Min. 8 characters with at least one upper case, one lower case, one number and one special character</small>
                <input [type]="showPassword ? 'text' : 'password'" [(ngModel)]="user.password" name="password" id="password" #password="ngModel" placeholder="Password" required pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,}$"/> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showPassword, 'fa-eye': showPassword}" (click)="toggleShowPassword()"></i>

                <div *ngIf="confirmPassword.invalid && (confirmPassword.dirty || formInvalid)" class="errormsg">
                    <div *ngIf="confirmPassword.errors.mustMatch">Passwords do not match</div>
                    <div *ngIf="confirmPassword.dirty && confirmPassword.errors.required">Re-enter Password is required</div>
                </div>
                <input [type]="showConfirmPassword ? 'text' : 'password'" [(ngModel)]="user.confirmPassword" name="confirmPassword" id="confirmPassword" #confirmPassword="ngModel" placeholder="Re-enter Password" required [mustMatch]="['password', 'confirmPassword']" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()" (paste)="$event.preventDefault()"/> 
                <i class="far" [ngClass]="{'fa-eye-slash': !showConfirmPassword, 'fa-eye': showConfirmPassword}" (click)="toggleShowConfirmPassword()"></i>

                <div class="page-text mt-3 mb-3">If you are already on any of our waiting lists and would like to access your application, click <a href="javascript:void(0)" (click)="setContent('registration')">here</a> to register.</div>

                <div class="text-right">
                    <button class="btn btn-general" type="submit" (click)="register(f.form)">Sign Up</button>
                </div>
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>