<body>
   <div class="d-flex justify-content-center align-items-center">
      <div class="container-fluid mb-5">
         <div class="page-title mt-5">Applicant Dashboard</div>
         <div class="container">
            <div class="row mt-5">
               <div class="column1 ml-5 mr-5">
                  <div class="container text-center">
                     <div class="card mt-1">
                        <div class="card-header text-center text-white mb-3">
                              <div class="img-top text-center text-white"><img src="{{imgFolder}}icon-profile.svg" width="100px" height="100px"/></div>
                              <div *ngIf="role == 'Applicant'" class="mt-5 mb-2 text-center">
                                 <div class="page-text text-white">{{generalInfo?.firstName}} {{generalInfo?.lastName}}</div>
                                 <div class="page-text text-white">{{generalInfo?.pCode}}</div>
                                 <button type="button" (click)="updateApplication()" class="btn btn-sm btn-general mt-2">Update My Application</button>
                                 <div *ngIf="role == 'Applicant'" class="page-text-sm text-white mt-1"><i>Last Updated: <span class="">{{lastUpdatedDate}}</span></i>
                                    <!-- <span class="ml-1" *ngIf="outdated"><img src="{{imgFolder}}attention.png" width="35px" height="25px"></span> -->
                                 </div>
                              </div>
                              <div *ngIf="role != 'Applicant'" class="mt-5 mb-2 text-center">
                                 <div class="page-text text-white">{{userEmail}}</div>
                              </div>
                        </div>
                        <div  class="card-body text-center">
                           <div class="mb-2 profile-body-title">My Application Information</div>
                           <div class="profile-body-label mb-3">
                              One application across all waitlists. <br/>
                              The below information will be used for any waiting lists applied to through sacwaitlist.com.
                           </div>
                           <div><img src="{{imgFolder}}icon-home.png" width="30px" height="30px"/></div>
                           <div *ngIf="noAddress" class="profile-body-field">Add mailing address <i class="bi bi-pencil-fill" (click)="showContactInfo('address')"></i></div>
                           <div *ngIf="!noAddress">
                              <div class="profile-body-field">{{generalInfo?.mailingAddress}}
                                 <span *ngIf="generalInfo?.apt != ''"> {{generalInfo?.apt}}</span>
                              </div>
                              <div class="profile-body-field mb-2">{{generalInfo?.city}}, {{generalInfo?.state}} {{generalInfo?.zipCode}} <i class="bi bi-pencil-fill" (click)="showContactInfo('address')"></i></div>
                           </div>
                           <div><img src="{{imgFolder}}icon-mail.png" width="30px" height="30px"/></div>
                           <!-- <div class="profile-body-field mb-2">{{generalInfo?.email}} <i class="bi bi-pencil-fill" (click)="showContactInfo('email')"></i></div> -->
                           <div class="profile-body-field mb-2">{{generalInfo?.email}}</div>               

                           <div><img src="{{imgFolder}}icon-phone.png" width="30px" height="30px"/></div>
                           <!-- <a href="tel:+1-916-367-1789">916 367 1789</a> -->
                           <!-- <a href="tel:{{location.phoneNumber}}">{{location.phoneNumber | phoneFilter}}</a> -->
                           <div *ngIf="phoneNumber!='both'" class="profile-body-field">{{phoneNumber}} <i class="bi bi-pencil-fill" (click)="showContactInfo('phone')"></i></div>
                           <div *ngIf="phoneNumber=='both'">
                              <div class="profile-body-field mb-3">{{generalInfo?.cellTel}}</div>
                              <div class="profile-body-field mb-3">{{generalInfo?.homeTel}}<i class="bi bi-pencil-fill" (click)="showContactInfo('phone')"></i></div>
                           </div>
                           <button type="button" (click)="updateApplication()" class="btn btn-sm btn-general mt-2">Update My Application</button>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="column2">
                  <div class="container text-center">
                     <div class="card">
                        <div class="card-header text-center text-white mb-3">
                           <div class="container page-subtitle1 text-white align-items-center">WAITING LISTS I'VE APPLIED TO</div>
                        </div>
                        <div class="card-body">
                           <div class="profile-body-label text-left ml-5 mt-2 mb-2">Household size: <span class="profile-body-field">{{familySize}}</span></div>
                           <div class="profile-body-label text-left ml-5 mt-2 mb-2">Monthly Income: <span class="profile-body-field">${{monthlyIncome}} <i class="bi bi-pencil-fill" (click)="showContactInfo('income')"></i></span></div>
                           <!-- <div class="profile-body-label">Status: <span class="profile-body-field">{{generalInfo?.tenantStatus}}</span></div> -->
                           <div *ngIf="waitlistsExtended.length > 0">
                              <div class="profile-body-label text-left ml-5 mt-2"># of Waiting Lists joined: <span class="profile-body-field">{{waitlistsExtended.length}}</span></div>
                              <table class="table table-borderless table-responsive-stack">
                                 <thead class="bg-transparent">
                                    <tr>
                                       <th></th>
                                       <th></th>
                                       <th></th>
                                       <th></th>                                                                        
                                    </tr>
                                 </thead>
                                 <tbody>
                                    <tr *ngFor="let w of waitlistsExtended | orderby:'waitlistExtendedName':false">                  
                                       <ng-container>
                                          <td class="table-content col-3"></td>
                                          <td class="table-content col-3">
                                             <a class="lnk-text-dark-green" href="javascript:void(0)" (click)="goToPropertyProfile(w)">{{w.waitlist.name.replace('/', ' / ') | titlecase}}</a>
                                          </td>
                                          <td class="table-content col-3">- {{w.applicantStatus | titlecase}} <i class="text-search bi bi-question-circle-fill" (click)="popupClick(w.applicantStatus)"></i></td>
                                          <td class="table-content col-1"></td>
                                       </ng-container>
                                    </tr>
                                 </tbody>
                              </table>
                              <div class="text-center mb-3">
                                 <button type="button" (click)="applyWaitlist()" class="btn btn-sm btn-general mt-2">Join a Waiting List</button>
                              </div>
                              <!-- <div *ngFor="let w of waitlistsExtended">
                                 <div class="profile-body-label">{{w.waitlistExtendedName.replace('/', ' / ') | titlecase}} - <span class="profile-body-field">{{w.applicantStatus | titlecase}}</span></div>
                              </div> -->
                           </div>
                           <div *ngIf="!waitlistsExtended || waitlistsExtended?.length == 0">
                              <div class="container mt-5 page-subtitle1 align-items-center">It looks like you haven't applied to a waiting list yet.</div>
                              <div class="text-center mt-3 mb-5">
                                 <button type="button" (click)="applyWaitlist()" class="btn btn-sm btn-general mt-2">Get started today!</button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <!-- <div class="mt-5">
                     <applicant-process></applicant-process>
                  </div> -->
                  <!-- <div *ngIf="applicantProcesses.length > 0" class="card">
                     <div class="card-header text-left text-white" style="height: 150px;"><img src="{{imgFolder}}sac wide1.jpg" width="100%" height="100%"/></div>
                     <div class="card-body">
                        <div *ngFor="let p of applicantProcesses">
                           <div class="profile-body-title">My {{p.processDisplayName}}</div>
                           <div *ngFor="let s of p.applicantSteps">
                              <div *ngFor="let f of s.applicantForms">
                                 <div class="card bg-shradarkgreen row justify-content-between text-white">
                                    <div class="text-left ml-5"><img src="{{imgFolder}}house icon.png" width="35px" height="25px"/></div>
                                    <div class="text-center">{{f.displayName}}</div>
                                    <div class="text-right mr-5" *ngIf="f.formStatus=='Not Started'"><button type="button" (click)="goToForm(p.processID, f.formID, f.applicantFormID, f.displayName, p.pCode)" class="btn btn-general">Start</button></div>
                                    <div class="text-right mr-5" *ngIf="f.formStatus=='In Progress'"><button type="button" (click)="goToForm(p.processID, f.formID, f.applicantFormID, f.displayName, p.pCode)" class="btn btn-general">Continue</button></div>
                                    <div class="text-right mr-5" *ngIf="f.formStatus=='Completed'"><button type="button" class="btn btn-general" disabled="true">Completed</button></div>
                                    <div class="text-right mr-5" *ngIf="f.formStatus=='Cancelled'"><button type="button" class="btn btn-general" disabled="true">Cancelled</button></div>
                                 </div>                              
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div *ngIf="applicantProcesses?.length == 0" class="card">
                     <div class="card-header text-left text-white" style="height: 150px;"><img src="{{imgFolder}}sac wide1.jpg" width="100%" height="100%"/></div>
                     <div class="card-body">
                        <div class="profile-body-title">Waiting Lists</div>
                        <div class="card bg-shralightgreen row justify-content-between text-white">
                           <span class="text-center">Currently there are no active processes</span>
                        </div>                              
                        <div class="profile-body-title">Processes</div>
                        <div class="card bg-shralightgreen row justify-content-between text-white">
                           <span class="text-center">Currently there are no active processes</span>
                        </div>                              
                     </div>
                  </div> -->
               </div>
               <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" color="#fff" type="ball-spin-clockwise">
                  <p style="color: white;">Please wait.</p>
               </ngx-spinner>             
            </div>
         </div>
      </div> 
      <ng-template #content let-modal>
         <div class="modal-header bg-shradarkgreen">
            <div class="page-subtitle1 text-white">
               Update {{contentTitle | titlecase}}
            </div>
           <button hidden="true" type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
             <span aria-hidden="true">&times;</span>
           </button>
         </div>
         <div class="modal-body">
            <update-contact-info [updateParam]="updateParam"></update-contact-info>
         </div>
      </ng-template>            

   </div> 
 </body>