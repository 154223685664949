import { Component, EventEmitter, Output} from "@angular/core";
import { Parameters } from '../../models/parameters';
import { Router } from '@angular/router';
import { Applicant } from '../../models/applicant';
import { User } from '../../models/user';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../services/auth.service';
import { WaitlistPortalService } from '../../services/waitlist-portal.service';
import { PortalUtilitiesService } from "../../services/portal-utilities.service";
import { ConfirmationMessageService } from "src/app/services/confirm-message.service";


@Component({
    selector : 'registration',
    templateUrl:'./registration.component.html',
    styleUrls:['./registration.component.scss']
})

export class RegistrationComponent {
    @Output() showContent:EventEmitter<string> = new EventEmitter(); 
    applicant:Applicant = new Applicant();
    params: Parameters = new Parameters();
    config:any;
    title:string = "Registration";
    user:User = new User();
    existingUser:User = new User();
    existingRegisteredUser:User = new User();
    userstatus:string = "";
    formInvalid:boolean = false;
    maxDate:Date = new Date();
    showPassword:boolean = false;   
    showConfirmPassword:boolean = false;  
    existingEmail: string = ""; 
    resetEmail:string = "";
   

    constructor (
        private spinnerService: NgxSpinnerService,
        private authService:AuthService,
        private waitlistPortalService:WaitlistPortalService,
        private portalUtilityService: PortalUtilitiesService,
        private router:Router,
        private confirmationService: ConfirmationMessageService
    ){
        this.config = {
            monthFormat: 'MMM, YYYY',
            showNearMonthDays: true,
            enableMonthSelector: true,
            format: "MM/DD/YYYY",
            hideOnOutsideClick:true
          };
    }

    ngOnInit(){
        this.maxDate = this.portalUtilityService.dateAdd(this.maxDate, "Y", -14);
        localStorage.setItem('userstatus', JSON.stringify(""));
        this.resetEmail = JSON.parse(localStorage.getItem('resetEmail')) ?? "";
    }

    setContent(content:string){
        this.showContent.emit(content);
    }

    toggleShowPassword(){
        this.showPassword = !this.showPassword
    }

    toggleShowConfirmPassword(){
        this.showConfirmPassword = !this.showConfirmPassword
    }

    register(f:any){

        if (f.invalid)
            this.formInvalid = true;
        else
        {
            this.spinnerService.show();
            this.params.email = this.user.email;
            this.user.hcvTcode  = "";
            this.user.cnvTcode  = "";
            this.userstatus = "";

            //check if yardi user exists and register
            this.waitlistPortalService.getHCVYardiTCode(this.params)
            .then(h=> { 
                this.user.hcvTcode  = h? h : ""; 
                this.waitlistPortalService.getCNVYardiTCode(this.params)
                .then(c=> { 
                    this.user.cnvTcode  = c? c : ""; 
                    this.waitlistPortalService.getAffYardiPCode(this.params)
                    .then( a => { 
                        this.user.affPcode  = a? a : ""; 
                        if (this.user.hcvTcode.length  || this.user.cnvTcode.length || this.user.affPcode.length)
                        {
                            //check if user has already signed up
                            this.authService.getUser(this.user)
                            .then(s=> {
                                if (s && s.email)
                                {
                                    this.user.id = s.id;
                                    this.existingUser = s;

                                    if (s.role.toUpperCase() == 'RegisteredUser') //if the existing user is an account with the current email with no tcodes
                                    {
                                        this.existingRegisteredUser = s;
                                        //get the user with tcodes
                                        this.existingUser.email = "xxxdummyxxxyzzz@shra.org" //set to some dummy email

                                    }
                                    
                                    //check if reset email
                                    if (this.resetEmail == "resetEmail")
                                    {
                                        this.resetAndRegister();
                                        localStorage.setItem('resetEmail', JSON.stringify(""));
                                    }
                                    else
                                    {
                                        this.userstatus = s.email != this.user.email ? "already registered with another email" : 
                                                s.emailConfirmed == true ? "already registered" : "registration not confirmed";
                                    }
                                }
                                else
                                {
                                    this.registerNewUser();                             
                                }
                            });
                        }
                        else
                        {
                            this.userstatus = "not found";
                            localStorage.setItem('userstatus', JSON.stringify("not found"));
                        }
                    });
                });
            });
            
            this.spinnerService.hide();
        }
    }

    registerNewUser(){
        this.spinnerService.show();
        this.user.role = "Applicant";
        this.authService.register(this.user).then(s=> {
            if (s)
            {
                this.userstatus = "success";
                
                //update Yardi email
                if (s.cnvTcode.length)
                {
                    this.params.applicantCode = s.cnvTcode;
                    this.waitlistPortalService.updateCNVYardiEmail(this.params).then(s =>{});
                }
                if (s.hcvTcode.length)
                {
                    this.params.applicantCode = s.hcvTcode;
                    this.waitlistPortalService.updateHCVYardiEmail(this.params).then(s =>{});
                }
                //update Yardi email
                if (s.affPcode.length)
                {
                    this.params.applicantCode = s.affPcode;
                    this.waitlistPortalService.updateAffYardiEmail(this.params).then(s =>{});
                }
            }
            else
                this.userstatus = "failure";
        });   
        this.spinnerService.hide();                             
    }


    deleteLogin(){
        this.authService.deleteUser(this.existingUser).then(s=> {
            if (s && s.confirmPassword.toUpperCase() == "FAILED")
                this.userstatus = "delete failure";
        });                                
    }

    resetAndRegister(){

        this.confirmationService.confirm('Confirm email reset','This action cannot be undone. Are you sure you want to reset the email?', 'Reset Email')
        .then(result => {
            if (result) {
                // User clicked 'Yes'
                this.spinnerService.show();
                this.deleteLogin();
        
                // if (this.userstatus.toUpperCase() != "DELETE FAILURE")
                    this.registerNewUser();
                this.spinnerService.hide();
            } 
        });
    }

    resendConfirmationLink(){
        this.spinnerService.show();
        this.authService.resendConfirmationLink(this.user)
        .then(s=> {
            if (s)
                this.userstatus = s.email == "not found"? "resent error" : "resent";
            else
                this.userstatus = "resent error";
        });
        this.spinnerService.hide();
    }

}