import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MaxNumber } from './max-number.validator';


@Directive({
    selector: '[maxNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MaxNumberDirective, multi: true }]
})
export class MaxNumberDirective implements Validator {
    @Input('maxNumber') maxNumber: number;

    validate(control: UntypedFormControl): ValidationErrors {
        return MaxNumber(this.maxNumber)(control);
    }
}