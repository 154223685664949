import {Component, OnInit} from "@angular/core";
import { ApplicantService } from '../services/applicant.service';
import { Applicant } from '../models/applicant';
import { GeneralInfo } from '../models/generalInfo';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { PortalParameters } from '../models/portalParameters';
import { Parameters } from '../models/parameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { User } from "../models/user";
import { WaitlistPortalService } from "../services/waitlist-portal.service";
import { UserService } from "../services/user.service";
import { NavigationParameters } from "../models/navigationParameters";

@Component ({
    selector : 'admin-functions',
    templateUrl : './admin-functions.component.html',
    styleUrls: ['./admin-functions.component.scss']
})

export class AdminFunctionsComponent implements OnInit{
    applicant: Applicant = new Applicant();
    generalInfo:GeneralInfo = new GeneralInfo();
    portalParams:PortalParameters = new PortalParameters;
    tcode:string;
    params: Parameters = new Parameters();
    formInvalid:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();

    constructor (
        private applicantService:ApplicantService, 
        private spinnerService: NgxSpinnerService,
        private waitlistPortalService:WaitlistPortalService,
        private userService:UserService,
        private msg:MessageService,
        private router:Router
    ){

    }

    ngOnInit(){


    }


    ConvertTwinRiversToMirasol(){
        
        //get twin rivers applicants

        //foreach convert to Mirasol Applicant
    }

}