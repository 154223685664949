 <body>
  <form name="form" #f="ngForm" novalidate>
      <div class="form d-flex justify-content-center align-items-center" *ngIf="veteranStatus">
        <div class="mt-2 mb-5">
          <div class="text-center mb-5">
              <hr><h2>Veteran Status</h2><hr>
          </div>

          <div class="row mb-5 ml-2 mr-2">
              <div class="formgroup col-lg-12 justify-content-between">
                  <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-5' : 'mb-5'">
                      <label *ngIf="!isMobile" class="control-label mr-5">Are you or any member in your household serving, or have you served,<br/>
                        in the active military, reserves or National Guard because <br/>
                         you were called to active duty by a federal order of the United States at any time?</label>
                      <label *ngIf="isMobile" class="control-label">Are you or any member in your household serving, or have you served,
                          in the active military, reserves or National Guard because 
                           you were called to active duty by a federal order of the United States at any time?</label>
                      <div class="form-group ml-3">
                        <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='veteranStatus.veteran' (click)="veteranStatusCheck()" id="veteran" name="veteran" #veteran="ngModel"  [value]=1 required /><span class="option-label mr-2">Yes</span></span>
                        <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='veteranStatus.veteran' (click)="veteranStatusCheck()" id="veteran" name="veteran" #veteran="ngModel"  [value]=0 [disabled]="readonly" required /><span class="option-label">No</span></span>
                      </div>
                  </div>

                  <div *ngIf="veteranStatus.veteran==1">
                    <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-5' : 'mb-5'">
                        <label class="control-label mr-5">Was this person dishonorably discharged or dishonorably released? </label>
                        <div class="form-group ml-3">
                          <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='veteranStatus.honorableDischarge' id="honorableDischarge" name="honorableDischarge" #honorableDischarge="ngModel"  [value]=1 required /><span class="option-label mr-2">Yes</span></span>
                          <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='veteranStatus.honorableDischarge' id="honorableDischarge" name="honorableDischarge" #honorableDischarge="ngModel"  [value]=0 [disabled]="veteranStatus.veteran==0 || readonly" required /><span class="option-label">No</span></span>
                        </div>
                    </div>
                  </div>
                <div [hidden]="!f.form.invalid" class="errormsg mb-5">Please select yes or no for all options</div>
                  
                <div class="mb-3">
                  <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
                </div>

                <div class="mb-5">
                  <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
                </div>
              </div>
          </div>
        </div>
        <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
          <p style="color: white;">Please wait.</p>
        </ngx-spinner>
    </div>
  </form>
</body>
