import { animate, state, style, transition, trigger } from '@angular/animations';
import {Component, Input, OnInit} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'popup-message',
    templateUrl: './popup-message.component.html' ,
    styleUrls: ['./popup-message.component.scss'],
    animations: [
        trigger('expand', [
            state('open', style({
                height: 'auto',
                opacity:1
            })),
            state('close', style({
                height: '0px',
                opacity:0
            })),
            transition('open=>close', animate('0.2s ease-out')),
            transition('close=>open', animate('0.2s ease-in'))
        ])
    ]
})

export class PopupMessageComponent implements OnInit{
    @Input() title:string;
    @Input() content:string;

    constructor (
        private modalService: NgbModal
        ){}


    ngOnInit()
    {
  
    }
   
    closeClick(){
        this.modalService.dismissAll();
    }

}