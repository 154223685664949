import { EventEmitter, Injectable, Output } from '@angular/core';
import { Applicant } from '../models/applicant';
import { FamilyMember } from '../models/familyMember';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalLookups } from '../models/portalLookups';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { User } from '../models/user';
import { ApplicantService } from './applicant.service';
import { MessageService } from './message.service';
import { PortalUtilitiesService } from './portal-utilities.service';
import { UserService } from './user.service';
import { WaitlistService } from './waitlist.service';

@Injectable()
export class AdminFunctionsService{
    applicant:Applicant = new Applicant();

    constructor(
        private applicantService:ApplicantService,
        private waitlistService:WaitlistService,
        private portalUtilitiesService:PortalUtilitiesService
        ) {}


    getCNVWaitlistApplicants(WLName:string, WLStatus:string){
        
    }
   
    updateAllHCVRecords(applicant:Applicant):Promise<Applicant[]>{

        return new Promise((resolve, reject) => {
    
            //get all applicants in HCV before update
            //store all applicants before update. First item is applicant with updated data.
            let applicants:Applicant[] = [];
            let curApplicant:Applicant = new Applicant;
            let params:Parameters = new Parameters;

            params.applicantCode = applicant.generalInfo.pCode;
            this.applicantService.getAllApplicantsHCV(params)
            .subscribe( h => {
                applicants = h;
                for (var index in applicants){
                    curApplicant = applicants[index];
                    applicant.generalInfo.hmy = curApplicant.generalInfo.hmy;
                    applicant.generalInfo.pCode = curApplicant.generalInfo.pCode;
                    this.applicantService.upateApplicantHCV(applicant).subscribe(()=>{
                        params.hmy = curApplicant.generalInfo.hmy;
                        this.applicantService.deleteFamilyMembersHCV(params).subscribe(
                            data => {
                            applicant.familyMembers.forEach(fH=>{
                                fH.hMy = curApplicant.generalInfo.hmy;
                                this.applicantService.insertFamilyMemberHCV(fH).subscribe(()=>{});
                            });},
                            err => {
                            },
                            () => {
                                params.applicantCode = curApplicant.generalInfo.pCode;
                                this.applicantService.finalUploadProcessesByHMyHCV(params).subscribe(()=>{});
                            }
                        );
                        //FileNet chrono should have the pCode that the file should be searched for. 
                        //the tcode of the record that is shown on the portal is used for filenet upload
                        params.applicantCode = applicant.generalInfo.pCode;
                        this.applicantService.updateChronoForFileNetUploadHCV(params).subscribe(()=>{});

                    });                
                }
                resolve(applicants);
            })
        });
    }


    applyToWaitlist(pCode:string, wlName:string, db:string){

        let applicantParams:Parameters = new Parameters;
        applicantParams.waitList =  wlName; 

        if (db == "HCV")
        {
            applicantParams.applicantCode = pCode;
            if (applicantParams.applicantCode && applicantParams.applicantCode.length > 0)
                     this.applicantService.addApplicantToWaitlistHCV(applicantParams).subscribe();
        }

        if (db == "CNV")
        {            
            applicantParams.applicantCode = pCode;
            if (applicantParams.applicantCode && applicantParams.applicantCode.length > 0)
                this.applicantService.addApplicantToWaitlistCNV(applicantParams).subscribe();
        }

    }

}