<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="form d-flex justify-content-center align-items-center" *ngIf="raceEthnicity">
            <div class="mt-2 mb-5">
                <div class="text-center mb-5">
                    <hr><h2>Demographics</h2><hr>
                </div>

                <div class="row mb-5 ml-2 mr-2">
                    <div class="formgroup col-lg-12 justify-content-between">      
                        <div class="formgroup">
                            <label class="control-label">Gender</label>
                            <div class="input-group mb-3 ml-3">
                                <select [disabled]='readonly' [(ngModel)]="raceEthnicity.gender" class="form-control input-small" name="gender" id="gender" #gender='ngModel' required>
                                    <option class="option-label" value="Male">Male</option>
                                    <option class="option-label" value="Female">Female</option>
                                </select>
                                <div *ngIf="gender.invalid && (gender.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label mr-5">Race</label>
                            <div *ngIf="!isMobile" class="form-group justify-content-between ml-3">    
                                <span class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="raceEthnicity.raceWhite" #raceWhite="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceWhite">White</label>
                                </span>
                                <span class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="raceEthnicity.raceBlack" #raceBlack="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceBlack">Black</label>
                                </span>
                                <span class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="raceEthnicity.raceAmericanIndian" #raceAmericanIndian="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceAmericanIndian">American Indian</label>
                                </span>
                                <span class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="raceEthnicity.raceAsian" #raceAsian="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceAsian">Asian</label>
                                </span>
                                <span class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="raceEthnicity.racePacificIslander" #racePacificIslander="ngModel">
                                    <label class="checkbox-label ml-1" for="racePacificIslander">Pacific Islander</label>
                                </span>
                            </div>
                            <div *ngIf="isMobile" class="form-group justify-content-between ml-3">    
                                <div class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceWhite" id="raceWhite" [(ngModel)]="raceEthnicity.raceWhite" #raceWhite="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceWhite">White</label>
                                </div>
                                <div class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceBlack" id="raceBlack" [(ngModel)]="raceEthnicity.raceBlack" #raceBlack="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceBlack">Black</label>
                                </div>
                                <div class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceAmericanIndian" id="raceAmericanIndian" [(ngModel)]="raceEthnicity.raceAmericanIndian" #raceAmericanIndian="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceAmericanIndian">American Indian</label>
                                </div>
                                <div class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="raceAsian" id="raceAsian" [(ngModel)]="raceEthnicity.raceAsian" #raceAsian="ngModel">
                                    <label class="checkbox-label ml-1 mr-5" for="raceAsian">Asian</label>
                                </div>
                                <div class="">
                                    <input [disabled]='readonly' class="checkbox" type="checkbox" name="racePacificIslander" id="racePacificIslander" [(ngModel)]="raceEthnicity.racePacificIslander" #racePacificIslander="ngModel">
                                    <label class="checkbox-label ml-1" for="racePacificIslander">Pacific Islander</label>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label">Ethnicity</label>
                            <div class="input-group mb-3 ml-3">
                                <select [disabled]='readonly' [(ngModel)]="raceEthnicity.ethnicity" class="form-control input-small" name="ethnicity" id="ethnicity" #ethnicity='ngModel' required>
                                    <option class="option-label" value="Hispanic or Latino">Hispanic or Latino</option>
                                    <option class="option-label" value="Not Hispanic or Latino">Not Hispanic or Latino</option>
                                </select>
                                <div *ngIf="ethnicity.invalid && (ethnicity.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label">English Understanding</label>
                            <div [ngClass]="raceEthnicity.englishUnderstanding == 0? 'input-group mb-3 ml-3' : 'input-group mb-5 ml-3'">
                                <select [disabled]='readonly' [(ngModel)]="raceEthnicity.englishUnderstanding" class="form-control input-small" name="englishUnderstanding" id="englishUnderstanding" #englishUnderstanding='ngModel' required>
                                    <option class="option-label" value=1>Yes</option>
                                    <option class="option-label" value=0>No</option>
                                </select>
                                <div *ngIf="englishUnderstanding.invalid && (englishUnderstanding.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                            </div>
                        </div>

                        <div *ngIf="raceEthnicity.englishUnderstanding==0" class="form-group">
                            <label class="control-label">Primary Language</label>
                            <div class="input-group mb-5 ml-3">
                                <select [disabled]='readonly' class="col form-control" [(ngModel)]="raceEthnicity.primaryLanguage" name="primaryLanguage" #primaryLanguage="ngModel" [required]="raceEthnicity.englishUnderstanding==0">
                                    <option class="option-label" *ngFor="let l of LEPLanguages" [value]="l.lkuStrItem">{{l.lkuStrItem}}</option>
                                </select>
                                <div *ngIf="primaryLanguage.invalid && (primaryLanguage.dirty || formInvalid)" class="errormsg ml-2">Required</div>
                            </div>
                        </div>

                                
                        <div class="mb-3">
                            <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
                        </div>

                        <div class="mb-5">
                            <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
                        </div>
                    </div>
                </div>
                <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                    <p style="color: white;">Please wait.</p>
                </ngx-spinner>
            </div>    
        </div>
    </form>
</body>
