import { ViewportScroller } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { Applicant } from '../models/applicant';
import { NavigationParameters } from '../models/navigationParameters';
import { Parameters } from '../models/parameters';
import { PortalLookups } from '../models/portalLookups';
import { PortalParameters } from '../models/portalParameters';
import { Preferences } from '../models/preferences';
import { WaitlistPrograms } from '../models/waitlistPrograms';
import { Waitlists } from '../models/waitlists';
import { WaitlistsExtended } from '../models/waitlistsExtended';
import { WaitlistTypes } from '../models/waitlistTypes';
import { AuthService } from '../services/auth.service';
import { FAQPanelService } from '../services/faq-panel.service';
import { MessageService } from '../services/message.service';
import { PopupMessageService } from '../services/popup.message.service';
// import { PortalUtilitiesService } from '../services/portal-utilities.service';
import { WaitlistPortalService } from '../services/waitlist-portal.service';
import { WaitlistService } from '../services/waitlist.service';


@Component({
    selector: 'waiting-list',
    templateUrl: './waiting-list.component.html' ,
    styleUrls: ['./waiting-list.component.scss']
})

export class WaitingListComponent implements OnInit{
    wltypes:WaitlistTypes[] = [];
    wlprograms:WaitlistPrograms[] = [];
    waitlistSelected:Waitlists = new Waitlists();
    waitlists: Waitlists[] = [];
    waitlistTypeSelected: string;
    waitlistProgramSelected: string = "";
    property:string = "";
    params:PortalParameters = new PortalParameters();
    title:string = "";
    wlPreferences:Preferences[] = [];
    applicantParams:Parameters = new Parameters();
    waitlistsExtended: WaitlistsExtended[] = [];
    waitlistsExtendedFilter: WaitlistsExtended[] = [];
    item:string;
    itemType:string;
    itemTitle:string = "";
    portalParams:PortalParameters = new PortalParameters;

    role:string = "";
    loggedIn:boolean = false;
    navigationParams:NavigationParameters = new NavigationParameters();

    incomeLimitCodeList: PortalLookups[] = [];
    waitlistFilterList: PortalLookups[] = [];
    waitlistStatusFilterList: PortalLookups[] = [];
    selectedWLFilter: PortalLookups[] = [];
    preferenceFilterList: PortalLookups[] = [];
    yardiIncomeLimitsCode: string = "";
    yardiIncomeLimitsDB: string = "";

    selectedFilter: string = "Open";
    imageUrl:string = "./assets/images/sac-sunrise.jpg";
    wlStatusFilter:string = "Open";
    wlProgramFilter:string[] = [];
    preferenceFilter:string[] = [];
    ddlWLProgramFilter = [];
    ddlWLStatusFilter = [];
    ddlPreferenceFilter = [];
    ddlWLStatusFilterSettings:IDropdownSettings={};
    ddlWLProgramFilterSettings:IDropdownSettings={};
    ddlPreferenceFilterSettings:IDropdownSettings={};
    isMobile:boolean = false;
    mapurl:string = "https://experience.arcgis.com/experience/affcd93a489e477d9ba92484b642292b/page/Wait-List-Map/?disable_window_focus=true";
    acopLink:string = "";
    imageScaleNormal:boolean = true;
    langCode: string = "en";
    imgFolder: string = environment.imgFolder;
    @ViewChild("brTextHCV",{static:true}) brTextHCV:ElementRef;
    @ViewChild("brTextCNV",{static:true}) brTextCNV:ElementRef;
    @ViewChild("ilHCV",{static:true}) ilHCV:ElementRef;
    @ViewChild("ilCNV",{static:true}) ilCNV:ElementRef;
    @ViewChild("prTypes",{static:true}) prTypes:ElementRef;    

    constructor(
        private spinnerService: NgxSpinnerService,
        private waitlistService:WaitlistService,
        private authService: AuthService,
        private waitlistPortalService:WaitlistPortalService,
        // private portalUtilitiesService:PortalUtilitiesService,
        private msg:MessageService,
        private router:Router,
        private viewportScroller: ViewportScroller,
        private faqPanelService: FAQPanelService,
        private modalService: NgbModal,
        private popupMessageService:PopupMessageService

    ) {
        this.isMobile = window.innerWidth < 768;
        waitlistPortalService.getselectedLanguage.subscribe(l => this.langCode = l);
    }

    
    ngOnInit(){

        this.spinnerService.show();
        this.navigationParams =  JSON.parse(localStorage.getItem('navigationParams'));
        if (!this.navigationParams) this.navigationParams = new NavigationParameters();
        this.navigationParams.resultMessage = "";
        this.navigationParams.returnLocation = "";
        this.navigationParams.waitlist = "";
        this.navigationParams.updateAction = "";
        this.navigationParams.formMode = "";

        if (!this.langCode || this.langCode == '' || this.langCode == 'en')
            this.langCode = JSON.parse(localStorage.getItem('langCode'));

        this.ddlWLStatusFilterSettings = {
            idField: 'description',
            textField: 'description',
            enableCheckAll: false,
            singleSelection:true,
        };

        this.ddlWLProgramFilterSettings = {
            idField: 'description',
            textField: 'description',
            enableCheckAll: false,
        };

        this.ddlPreferenceFilterSettings = {
            idField: 'description',
            textField: 'description',
            enableCheckAll: false,
        };

        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
        this.role = this.navigationParams.userRole;
        this.loggedIn =  this.navigationParams.loggedIn = this.authService.isUserAuthenticated();
        this.title = 'SHRA Waiting Lists';

        this.yardiIncomeLimitsCode = "vli";
        this.yardiIncomeLimitsDB = "HCV";

        this.portalParams.category = "WaitlistStatusFilter";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
           this.ddlWLStatusFilter = this.waitlistStatusFilterList = data;
           this.selectedWLFilter = this.ddlWLStatusFilter.filter(wlf => wlf.description == this.wlStatusFilter);
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "WaitlistProgramFilter";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((data) => {
           this.ddlWLProgramFilter = this.waitlistFilterList = data;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "PreferencesFilter";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((p) => {
            this.ddlPreferenceFilter = this.preferenceFilterList = p;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "IncomeLimitCode";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((i) => {
           this.incomeLimitCodeList = i;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });

        this.portalParams.category = "AcopCityLink";
        this.waitlistService.getPortalLookupsByCategory(this.portalParams).subscribe((l) => {
           this.acopLink = l[0].description;
        }, (error) => {
            this.spinnerService.hide();
            this.msg.showError(error, "Loading Error");
        });


        this.waitlistService.getWaitlistPrograms()
        .subscribe(p  => { 
            this.wlprograms = p;

            this.waitlistService.getWaitlistTypes()
                .subscribe(data  => { 
                    this.wltypes = data;
                    //exclude referrals
                    let wlp = this.wlprograms.map(x=>x.waitlistProgramID);
                    this.wltypes = this.wltypes.filter(t => t.waitlistTypeCategory.toUpperCase().includes('WAITING LISTS') && wlp.includes(t.waitlistProgramID)); //filter waiting lists types
                    this.wlprograms = this.wlprograms.filter(p => this.wltypes.find(wlt => wlt.waitlistProgramID == p.waitlistProgramID)); //filter programs for waiting lists
                    this.params.category = "Waiting Lists";
                    this.waitlistService.getWaitlistsByWLTypeCategory(this.params)
                    .subscribe(d=>{
                        this.waitlists = d;
                        this.waitlists = this.waitlists.filter(w => this.wltypes.find(wlt => wlt.waitlistTypeID == w.waitlistTypeID)); //filter waiting lists
                        this.setupWaitlists(this.waitlists);

                        // this.waitlistsExtendedFilter = this.waitlistsExtended;
                    }, (err) => {
                        this.spinnerService.hide();            
                        this.msg.showError(err, "Loading Error");
                    })

                }, (error) => {
                    this.spinnerService.hide();            
                    this.msg.showError(error, "Loading Error");
                });
            }, (error) => {
                this.spinnerService.hide();            
                this.msg.showError(error, "Loading Error");
            });

        this.spinnerService.hide();            

    }


    getWaitlistsExtendedFilterByProgram(programName: string):WaitlistsExtended[]{
        return this.waitlistsExtendedFilter.filter(w=>w.waitlistProgram === programName);
    }

    getYardiIncomeLimitsCode(programName: string):string{
        return  programName == "Housing Choice Voucher" || this.waitlistProgramSelected == "Housing Choice Voucher" ? "vli" : "li";
    }

    getYardiIncomeLimitsDB(programName: string):string{
        return  programName == "Housing Choice Voucher" || this.waitlistProgramSelected == "Housing Choice Voucher" ? "HCV" : "CNV";
    }

    getWaitlistsCount(p:WaitlistPrograms):number{
        let wltIDs = this.wltypes.map(t => t.waitlistTypeID);
        return this.waitlistsExtendedFilter.filter(w => wltIDs.includes(w.waitlist.waitlistTypeID) && w.waitlistProgram == p.name).length;
    }


    expand(e:Event){
        var elem = e.target as HTMLElement;

        if (this.imageScaleNormal)
        {
            elem.style.transform = "scale(1.3)";
            elem.style.backgroundColor = "white";
            elem.classList.remove('img-expand');
            elem.classList.add('img-shrink');
        }
        else
        {
            elem.style.transform = "scale(1)";
            elem.style.backgroundColor = "transparent";
            elem.classList.remove('img-shrink');            
            elem.classList.add('img-expand');
        }

        elem.style.transition = "transform 0.25s ease";
        this.imageScaleNormal = !this.imageScaleNormal;
    }


    infoClick(infoText:string, content:string){
        if (content == 'faq') this.faqPanelService.show(infoText, "lg");

        if (content == 'il') 
        {
            this.waitlistProgramSelected == infoText;
            if (infoText == "Housing Choice Voucher")
                this.modalService.open(this.ilHCV, { size: 'xl', centered: true});
            else
                this.modalService.open(this.ilCNV, { size: 'lg', centered: true});
        }

        if (content == 'brText') 
        {
            this.waitlistProgramSelected == infoText;
            if (infoText == "Housing Choice Voucher")
                this.modalService.open(this.brTextHCV, { size: 'md', centered: true});
            else
                this.modalService.open(this.brTextCNV, { size: 'md', centered: true});
        }

        if (content == 'prTypes') 
        {
            this.waitlistProgramSelected == infoText;
            this.modalService.open(this.prTypes, { size: 'xl', centered: true});
        }

    }

    
    popupClick(infoText:string){
        this.popupMessageService.show(environment.title211Message, environment.content211Message, true, "sm");
    }


    getImagePath(imgName: string): string {

        let imgPath: string = this.imgFolder + "en/" + imgName;

        //check if file exists for translated image in the folder
        // if (this.langCode != "en")
        // {
        //     const fs = require("fs");
        //     let fileExists = fs.existsSync(imgPath);

        //     if (!fileExists) {
        //         imgPath = this.imgFolder + "en/" + imgName;
        //     }
        //     return imgPath;
        // }

        if (this.langCode == "es" || this.langCode == "hmn" || this.langCode == "ru" || this.langCode == "vi" || this.langCode == "zh-CN" || this.langCode == "zh-TW")
            imgPath = this.imgFolder + this.langCode + "/" + imgName;

        return imgPath;
    }


    scrollToAnchor(anchor:string){
        //setup scrolling
        this.viewportScroller.scrollToAnchor(anchor);
    }

    // updateWLFilter(filter:any){
    //     this.wlFilter = filter;
    //     this.filterWaitlists();
    // }

    updateWLStatusFilter(event:any){
        //this.wlStatusFilter = event.target.checked ? "Open" : "All";    //for checkbox
        this.wlStatusFilter = event.target.value;    //for option button
        this.filterWaitlists();
    }
    

    // updateWLFilterSelect(filter:any, item:string){

    //     if (item == "wlstatus") this.wlStatusFilter = filter.description;
    //     if (item == "wlprogram")  this.wlProgramFilter.push(filter.description);
    //     if (item == "preference")  this.preferenceFilter.push(filter.description);

    //     this.filterWaitlists();
    // }
    
    // updateWLFilterDeselect(filter:any, item:string){

    //     if (item == "wlstatus") this.wlStatusFilter = 'All';

    //     if (item == "wlprogram")
    //     {
    //         var i = this.wlProgramFilter.indexOf(filter.description);
    //         this.wlProgramFilter.splice(i,1);
    //     }

    //     if (item == "preference")
    //     {
    //         var j = this.preferenceFilter.indexOf(filter.description);
    //         this.preferenceFilter.splice(j,1);
    //     }
        
    //     this.filterWaitlists();
    // }

    // updatePreferenceFilterSelect(filter:any){
    //     this.preferenceFilter.push(filter.description);
    //     this.filterWaitlists();
    // }

    
    // updatePreferenceFilterDeselect(filter:any){
    //     var index = this.preferenceFilter.indexOf(filter.description);
    //     this.preferenceFilter.splice(index,1);
    //     this.filterWaitlists();
    // }


    // updatePreferenceFilter(filter:any, event){

    //     if (event.target.checked) 
    //         this.preferenceFilter.push(filter);
    //     else {
    //           var index = this.preferenceFilter.indexOf(filter);
    //           this.preferenceFilter.splice(index,1);
    //     }

    //     this.filterWaitlists();
    // }

    filterWaitlists(){
        if (this.wlStatusFilter.toUpperCase() == "ALL")
            this.waitlistsExtendedFilter = this.waitlistsExtended;
        else
            this.waitlistsExtendedFilter = this.waitlistsExtended.filter(w => this.wlStatusFilter.toUpperCase().includes(w.wlStatus.toUpperCase()));

        //filter for programs 
        if (this.wlProgramFilter && this.wlProgramFilter.length > 0)
        {
            let waitlistsExtendedFilterNew: WaitlistsExtended[] = [];
            this.wlProgramFilter.forEach(x => {
                if (this.waitlistsExtendedFilter.filter(w1 => w1.waitlistProgram == x || w1.waitlistType == x ).length > 0)
                    this.waitlistsExtendedFilter.filter(w1 => w1.waitlistProgram == x || w1.waitlistType == x ).forEach(w2 => {
                        //filter for preferences
                        if (this.preferenceFilter && this.preferenceFilter.length > 0)
                        {
                            this.preferenceFilter.forEach(p => {
                                if (w2.preferences.includes(p))
                                   waitlistsExtendedFilterNew.push(w2)
                            });                                        
                        }
                        else
                            waitlistsExtendedFilterNew.push(w2)
                    }
                )
            });                                        

            this.waitlistsExtendedFilter = waitlistsExtendedFilterNew;
        }

    }


    goToPropertyProfile(w:WaitlistsExtended){
        this.navigationParams.waitlistTypesModel = this.wltypes.find(t =>t.waitlistTypeID == w.waitlist.waitlistTypeID);
        this.navigationParams.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == w.waitlist.waitlistTypeID).waitlistProgramID).name;
        localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));

        localStorage.setItem('waitlistExtended', JSON.stringify(w));
        this.router.navigate(['/property']);
    }

    setupWaitlists(wls:Waitlists[]){

        if (this.loggedIn && this.role.toUpperCase() == 'REGISTEREDUSER')
            this.setupWaitlistsRegisteredUser(wls);
        else 
        {
            if (this.loggedIn && this.role.toUpperCase() == "APPLICANT")
                this.setupWaitlistsApplicant(wls);
            else
                this.setupWaitlistsGeneral(wls);
        }
    }

    applyToWaitlist(wlt:WaitlistsExtended){

        if (!this.loggedIn)
            this.router.navigate(['/loginMain']);

        if (this.loggedIn && this.role.toUpperCase() == 'REGISTEREDUSER')
            this.applyToWaitlistRegisteredUser(wlt);

        if (this.loggedIn && this.role.toUpperCase() == "APPLICANT")
                this.applyToWaitlistApplicant(wlt);
    }

   
    setupPreferences(prefs:Preferences[]):string{

        let preferences = "";
 
        prefs.forEach(p => {
            if (p && p.name.length && p.name.length > 0)
                preferences += p.name + ", ";
        });
 
        //remove last ,
        preferences = preferences.substring(0, preferences.length - 2);
        return preferences;
    }

  
    //General User (All public users)
    setupWaitlistsGeneral(wls:Waitlists[]){

        let wlef:WaitlistsExtended[] = [];
        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name;
            wle.imageUrl = './assets/images/waiting lists/' + wl.name.replace('/', '').replace('.', '') + '.jpg';
            wle.waitlistType = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).name;
            wle.waitlistTypeCategory = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistTypeCategory;
            wle.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistProgramID).name;
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID
            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;

                if (wle.wlStatus.toUpperCase() == "OPEN") wle.action = "apply";
                wle.applicantAgeEligibility = true;
                wle.applicantBREligibility = true;

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                    wle.preferenceList = p;
                    wle.preferences = this.setupPreferences(p);
                    if (this.wltypes[0].showSHRARequirements == 1)
                    {
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                this.waitlistsExtended.push(wle);                    
                        });
                    }
                    else
                        this.waitlistsExtended.push(wle);   
                    
                    if (this.wlStatusFilter.toUpperCase().includes(wle.wlStatus.toUpperCase()))
                        wlef.push(wle);
                });
            });                
        }); 

        this.waitlistsExtendedFilter = wlef;

        // this.waitlistsExtendedFilter = this.waitlistsExtended
    }


    //Registered User
    setupWaitlistsRegisteredUser(wls:Waitlists[]){

        let wlef:WaitlistsExtended[] = [];
        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name;
            wle.imageUrl = './assets/images/waiting lists/' + wl.name.replace('/', '').replace('.', '') + '.jpg';
            wle.waitlistType = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).name;
            wle.waitlistTypeCategory = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistTypeCategory;
            wle.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistProgramID).name;
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID
            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;

                if (wle.wlStatus.toUpperCase() == "OPEN") wle.action = "apply";
                wle.applicantAgeEligibility = true;
                wle.applicantBREligibility = true;

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                    wle.preferenceList = p;
                    wle.preferences = this.setupPreferences(p);
                    if (this.wltypes[0].showSHRARequirements == 1)
                    {
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                this.waitlistsExtended.push(wle);                    
                        });
                    }
                    else
                        this.waitlistsExtended.push(wle);                    

                    if (this.wlStatusFilter.toUpperCase().includes(wle.wlStatus.toUpperCase()))
                        wlef.push(wle);

                });
            });                
        });
    
        this.waitlistsExtendedFilter = wlef;   //for filter
        // this.waitlistsExtendedFilter = this.waitlistsExtended
    }


    //Registered User
    applyToWaitlistRegisteredUser(wlt:WaitlistsExtended){
    
        let wl = wlt.waitlist;
        //check if waitlist needs an access code and supplied access code is valid
        if (wl.accessCodeRequired == 1 && wl.accessCode != wlt.accessCode)
            this.waitlistsExtended.find(f=>f.waitlist.name==wl.name).accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = wlt.waitlist.name;
            this.navigationParams.waitlistTypesModel = this.wltypes.find(t =>t.waitlistTypeID == wlt.waitlist.waitlistTypeID);
            this.navigationParams.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wlt.waitlist.waitlistTypeID).waitlistProgramID).name;
            this.navigationParams.returnLocation = "waitingList";
            this.navigationParams.availableBR = wlt.waitlist.bedroomsAvailable.toString();
            this.navigationParams.updateAction = "apply";

            if (wl.name == "Housing Choice Voucher")
                this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
            else
               this.navigationParams.resultMessage = "Hello. You have been added to " + wl.name + " waiting list with your application update.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/basicInfo']);
       }
    }


    //Applicant
    setupWaitlistsApplicant(wls:Waitlists[]){

        let wlef:WaitlistsExtended[] = [];
        wls.forEach ((wl) =>
        {
            let wle: WaitlistsExtended = new WaitlistsExtended;
            wle.waitlistExtendedName = wl.name;
            wle.imageUrl = './assets/images/waiting lists/' + wl.name.replace('/', '').replace('.', '') + '.jpg';
            wle.waitlistType = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).name;
            wle.waitlistTypeCategory = this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistTypeCategory;
            wle.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID).waitlistProgramID).name;
            wle.waitlist = wl;
            this.params.waitlist = wl.name;
            wle.accessCode = "";
            wle.accessCodeValid = true;
            //get waitlist status
            this.params.lookupID = wl.statusID

            this.waitlistPortalService.getPortalLookupsByID(this.params).then( l=> {
                wle.wlStatus = l.description;
                wle.action = "update";

                //setup preferences
                this.params.waitlist = wl.name;
                this.params.itemType = "Preference";
                this.waitlistPortalService.getWaitlistPreferences(this.params).then( p=> {
                    wle.preferenceList = p;
                    wle.preferences = this.setupPreferences(p);
                    this.waitlistPortalService.getApplicantPreferences().then (pr => {
                        if (pr)
                        {
                            let index:number = -1;
                            index = pr.findIndex(p => p.name.toLowerCase().includes('elderly') || p.name.toLowerCase().includes('neareld'));
                            wle.applicantAgeEligibility = index != -1? true : false;
                            if (wle.waitlist.name != 'Elderly Only' && wle.waitlist.name != 'Project-Based Elderly') wle.applicantAgeEligibility = true;
                        }

                        //SHRA requirements
                        this.params.waitlist = wl.name;
                        this.params.itemType = "SHRA Requirements";
                        this.waitlistPortalService.getWaitlistPreferences(this.params).then( r=> {
                                wle.shraRequirements = this.setupPreferences(r);
                                if (this.wltypes.find(x => x.waitlistTypeID == wl.waitlistTypeID && x.waitlistTypeCategory.toUpperCase().includes('WAITING LIST')))
                                {
                                    //get applicant's status for this waitlist if exists
                                    this.params.waitlist = wl.name;
                                    this.params.waitlistProgram = wl.yardiDB.toUpperCase() == "AFF" ? "Affordable Housing" : wl.yardiDB.toUpperCase() == "CNV" ? "Public Housing" : "Housing Choice Voucher";
                                    this.waitlistPortalService.getApplicantWaitlistStatus(this.params).then(h =>{
                                        this.applicantParams = h;
                                        wle.applicantStatus = h.waitlistStatus;
                                        wle.applicantBedroom = h.bedroomSize;

                                        let availableBR:string = wl.bedroomsAvailable + ",";
                                        if (availableBR == "0," || wle.applicantBedroom == 999 || availableBR.includes(wle.applicantBedroom + ","))
                                            wle.applicantBREligibility = true;

                                        if (availableBR.includes('SRO') && wle.applicantBedroom < 2)
                                            wle.applicantBREligibility = true;

                                        if (wle.wlStatus.toUpperCase() == "OPEN")
                                            if (wle.applicantStatus.toUpperCase() == "REMOVED" || wle.applicantStatus.toUpperCase() == "LEASED" || wle.applicantStatus.toUpperCase() == "NOT ON LIST")
                                                    wle.action = "apply";
                                        this.waitlistsExtended.push(wle);                                                
                                    });
                                }
                                else
                                    this.waitlistsExtended.push(wle); 
                                    
                                if (this.wlStatusFilter.toUpperCase().includes(wle.wlStatus.toUpperCase()))
                                    wlef.push(wle);
            
                        });
                    });
                });                
            }); 
        });
        
        this.waitlistsExtendedFilter = wlef;   // for filter

        // this.waitlistsExtendedFilter = this.waitlistsExtended
    }


    //Applicant
    applyToWaitlistApplicant(wlt:WaitlistsExtended){
    
        let wl = wlt.waitlist;
        //check if waitlist needs an access code and supplied access code is valid
        if (wl.accessCodeRequired == 1 && wl.accessCode != wlt.accessCode)
            this.waitlistsExtended.find(f=>f.waitlist.name==wl.name).accessCodeValid = false;
        else
        {
            this.navigationParams.waitlist = wlt.waitlist.name;
            this.navigationParams.waitlistTypesModel = this.wltypes.find(t =>t.waitlistTypeID == wlt.waitlist.waitlistTypeID);
            this.navigationParams.waitlistProgram = this.wlprograms.find(p => p.waitlistProgramID == this.wltypes.find(x => x.waitlistTypeID == wlt.waitlist.waitlistTypeID).waitlistProgramID).name;
            this.navigationParams.returnLocation = "waitingList";
            this.navigationParams.availableBR = wlt.waitlist.bedroomsAvailable.toString();

            let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));

            if (this.navigationParams.waitlistTypesModel.yardiDB != 'AFF' && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
            {
                if (wl.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. '
                        + 'Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>.';
                else
                    this.navigationParams.resultMessage = 'Hello! You have been added to ' + wl.name + ' waiting list. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';

                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                // this.waitlistPortalService.applyToWaitlist();    
                this.applyWithoutUpdate(applicant);
                this.router.navigate(['/result']);
            }
            else
            {
                this.navigationParams.updateAction = "apply";
                if (wl.name == "Housing Choice Voucher")
                    this.navigationParams.resultMessage = 'Thank you for submitting the pre-application for the Housing Choice Voucher waiting list. A total of 5,000 applicants will be placed on the Tenant-Based Housing Choice Voucher waiting list through a computer random selection after the waiting list has closed. Please do not submit multiple applications with duplicate information as they will be merged into one application.  Please return to this website after March 01, 2022 to see if you were selected or not selected for the waiting list. ';
                else
                    this.navigationParams.resultMessage = "Hello! You have been added to " + wl.name + " waiting list with your application update.";
                localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
                this.router.navigate(['/generalInfo']);
            }
        }
    }

    applyWithoutUpdate(applicant:Applicant){
        let resultMsg = "";        
        if (this.navigationParams.waitlist.toLowerCase().includes('elderly'))
        {
            var ageEligible = this.waitlistPortalService.checkForElderlyMember(applicant);
            if (!ageEligible) resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the age requirement for this Elderly Only property.";
        }

        //available BR size for the selected waitlist
        let availableBR:string = this.navigationParams.availableBR + ",";
        let roomCount:number;
        if (this.navigationParams.waitlistProgram == 'Housing Choice Voucher')
            roomCount = this.waitlistPortalService.calculateBRSizeHCV(applicant);
        
        if (this.navigationParams.waitlistProgram == 'Public Housing' || this.navigationParams.waitlistProgram == 'Affordable Housing')                        
            roomCount = this.waitlistPortalService.calculateBRSizeCNV(applicant);

        let brEligible:boolean = false;
        if (availableBR == "0," || availableBR.includes(roomCount.toString() + ","))                        
                brEligible = true;

        if (availableBR.includes('SRO') && roomCount < 2)
                brEligible = true;

        if (!brEligible) 
            resultMsg = "Hello. You have NOT been added to " + this.navigationParams.waitlist + " waiting list. Your household does not meet the bedroom size requirement for available bedrooms.";                                        
        
        if (resultMsg == "") 
            this.waitlistPortalService.applyToWaitlist(true);
        else
        {
            this.navigationParams.resultMessage = resultMsg;
            localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));
            this.waitlistPortalService.applyToWaitlist(false);                    
        }

        localStorage.setItem("navigationParams", JSON.stringify(this.navigationParams));          
    }

    //Applicant
    updateApplication(){
        let applicant:Applicant = JSON.parse(localStorage.getItem('applicant'));
        // this.navigationParams.waitlistTypesModel = this.wltypes[0];
        this.navigationParams.returnLocation = "waitingList";

        if ((!this.navigationParams.affPCode || this.navigationParams.affPCode?.length == 0) && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
        {
            //let msg:string = "Hello.  Your update cannot be completed online because you’re a participant in current status or in a move process. Please submit a written request to your caseworker to update your household information.";
            this.navigationParams.resultMessage = 'Hello!  Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/result']);
        }
        else
        {
            if (this.navigationParams.affPCode?.length > 0 && (applicant.generalInfo.tenantStatus.toUpperCase() == 'CURRENT' || applicant.generalInfo.tenantStatus.toUpperCase() == 'NOTICE'))
                this.navigationParams.resultMessage = 'Hello! Your Affordable Housing application has been updated. Your Housing Choice Voucher or Public Housing application update cannot be completed in this Waitlist Portal because you are a participant in current status or in a move process.  Please submit your Change of Household (COH) or Change of Income (COI) on the <a href="https://portal.shra.us/residentportal/#/login" target="blank">Resident Portal</a>';
            else
                this.navigationParams.resultMessage = "Hello!  Your application has been updated.";

            localStorage.setItem('navigationParams', JSON.stringify(this.navigationParams));
            this.router.navigate(['/generalInfo']);
        }
    }

    paperApplicationRequest(){
        this.router.navigate(['/paperApplicationFaq']);
    }

    
}