<body>
  <form name="form" #f="ngForm" novalidate>
    <div class="form d-flex justify-content-center align-items-center " *ngIf="displacementStatus">
      <div class="mt-2 mb-5">
        <hr>
        <div class="text-center">
          <h2>Displacement Status</h2>
        </div>
        <hr>
        <div class="row mt-5 mb-5 ml-2 mr-2">
          <div class="formgroup col-lg-12 justify-content-between">
            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Were you displaced by government action due to the Owner of the property prepaying the mortgage?</label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.mortPrePayment' id="mortPrePayment" name="mortPrePayment" #mortPrePayment="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.mortPrePayment' id="mortPrePayment" name="mortPrePayment" #mortPrePayment="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Were you displaced by the Owner of the property voluntarily terminating a mortgage insurance contract?</label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.mortInsContractVolTerm' id="mortInsContractVolTerm" name="mortInsContractVolTerm" #mortInsContractVolTerm="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.mortInsContractVolTerm' id="mortInsContractVolTerm" name="mortInsContractVolTerm" #mortInsContractVolTerm="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Were you displaced by government action due to a rental assistance housing program losing funding?</label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.lackFundHousingTerm' id="lackFundHousingTerm" name="lackFundHousingTerm" #lackFundHousingTerm="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.lackFundHousingTerm' id="lackFundHousingTerm" name="lackFundHousingTerm" #lackFundHousingTerm="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
              <label class="control-label mr-5">Were you displaced due to demolition or disposition of a public or Indian housing project?</label>
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.phProjectDemolition' id="phProjectDemolition" name="phProjectDemolition" #phProjectDemolition="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.phProjectDemolition' id="phProjectDemolition" name="phProjectDemolition" #phProjectDemolition="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-5' : 'mb-5'">
              <label *ngIf="!isMobile" class="control-label mr-5">Was your housing unit extensively damaged or destroyed as a result of a disaster declared<br/> 
                or otherwise formally recognized as a Federal disaster (24 CFR 5.403)?</label>
              <label *ngIf="isMobile" class="control-label mr-5">Was your housing unit extensively damaged or destroyed as a result of a disaster declared
                  or otherwise formally recognized as a Federal disaster (24 CFR 5.403)?</label>
  
              <div class="form-group ml-3">
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.disasterDamage' id="disasterDamage" name="disasterDamage" #disasterDamage="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='displacementStatus.disasterDamage' id="disasterDamage" name="disasterDamage" #disasterDamage="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
              </div>
            </div>

            <div [hidden]="!f.form.invalid" class="errormsg mb-5">Please select yes or no for all options</div>

            <div class="mb-3">
              <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
            </div>
            <div class="mb-5" *ngIf="yardiDB == 'AFF' || homelessStatus || projectBasedVoucher">
              <button type="button" (click)="submit(f.form)" class="btn btn-dark btn-sm float-left">Submit</button>
            </div>
            <div class="mb-5" *ngIf="yardiDB != 'AFF' && !homelessStatus && !projectBasedVoucher">
              <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
            </div>
          </div>
        </div>
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>
    </div>
  </form>
</body>
