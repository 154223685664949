// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  dropDownListDefaultValue:'*** Choose ***',
  imgFolder:"./assets/images/",
  pdfFileFolder:"./assets/pdf files/",
  flyerFolder:"./assets/flyers/",
  title211Message:"211 Contact",
  content211Message:"Please call (916) 498-1000 or (844) 546-1464. Call 7-1-1 if hard of hearing.",
  //baseAPIUrl:'http://localhost:55546/'
  // baseAPIUrl:'https://portal.shra.org/APRouterTest/'   //test
   baseAPIUrl:'https://portal.shra.org/APRouter/'  //live  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
