import {Component, Input, OnChanges} from '@angular/core';
import { IncomeLimits } from '../models/incomeLimits';
import { Parameters } from '../models/parameters';
import { ApplicantService } from '../services/applicant.service';
import { MessageService } from '../services/message.service';

@Component({
    selector: 'waitlist-income-limit',
    templateUrl: './waitlist-income-limit.component.html' ,
    styleUrls: ['./waitlist-income-limit.component.scss']
})

export class WaitlistIncomeLimitComponent implements OnChanges{
    @Input() item:string;
    @Input() itemType:string;
    @Input() showSmall?:boolean;
    incomeLimit: IncomeLimits;
    params:Parameters = new Parameters();
    isMobile:boolean = false;

    constructor (
        private applicantService:ApplicantService, 
        private msg:MessageService
        ){
            this.isMobile = window.innerWidth < 768;
        }


    ngOnChanges()
    {
        this.setIncomeLimits();
    }

    setIncomeLimits(){
        this.params.category = this.item;

        if (this.itemType.toUpperCase() == "HCV" || this.itemType.toUpperCase() == "HOUSING CHOICE VOUCHER")
        {
            this.applicantService.getIncomeLimitsByCodeHCV(this.params)
            .subscribe(d => {
                this.incomeLimit = d;
            }, (err) => {
                this.msg.showError(err, "waitlists error");
            });  
        }
        else
        {
            this.applicantService.getIncomeLimitsByCodeCNV(this.params)
            .subscribe(d => {
                this.incomeLimit = d;
            }, (err) => {
                this.msg.showError(err, "waitlists error");
            });  
        }
    }

    selectIncomeLimitCode(e:any){

        let code = e.target.value;
        this.item = code.toUpperCase() == "HCV" ? "vli" : code.toUpperCase() == "CNV" ? "li" : code.toUpperCase() == "AFF" ? "59vli" : ""; 
        this.itemType = code.toUpperCase();
    }
}