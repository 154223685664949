export class ResourceLinks {
    resourceID: number;
    resourceCategoryID: number;
    waitListID: number;
    resourceName: string;
    description: string;
    linkAddress: string;
    newWindow: number;
    active: number;
    displayOrder: number;
}