<body>
  <form name="form" #f="ngForm" novalidate>
    <div class=" form d-flex justify-content-center align-items-center" *ngIf="leaseInPlaceStatus">
      <div class="mt-2 mb-5">
        <div class="text-center">
          <hr><h2>Lease-In-Place Status</h2><hr>
        </div>

        <div class="row mt-5 mb-5 ml-2 mr-2">
            <div class="formgroup col-lg-12 justify-content-between">
              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label">Have you been living in your current residence for at least 3 months?</label>
                <div class="form-group ml-3 mb-5">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.curRes3MonthORMore' id="curRes3MonthORMore" name="curRes3MonthORMore" #curRes3MonthORMore="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.curRes3MonthORMore' id="curRes3MonthORMore" name="curRes3MonthORMore" #curRes3MonthORMore="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
                </div>
              </div>

              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label">Has the owner of your rental agreed to accept the Housing Choice Voucher <br/> if you were selected for this program?</label>
                <div class="form-group ml-3 mb-5">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.landlordAcceptVoucher' id="landlordAcceptVoucher" name="landlordAcceptVoucher" #landlordAcceptVoucher="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.landlordAcceptVoucher' id="landlordAcceptVoucher" name="landlordAcceptVoucher" #landlordAcceptVoucher="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
                </div>
              </div>

              <div [ngClass]="!isMobile ? 'd-flex justify-content-between mb-3' : 'mb-3'">
                <label class="control-label">Would your current landlord agree to sign a new one year lease <br/> upon approval of the Lease In Place program? </label>
                <div class="form-group ml-3 mb-5">
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.landlordSignANew1Year' id="landlordSignANew1Year" name="landlordSignANew1Year" #landlordSignANew1Year="ngModel" [value]=1 required/><span class="option-label mr-2">Yes</span></span>
                  <span class="whitespacenowrap"><input class="radio-inline mr-2" type="radio" [(ngModel)]='leaseInPlaceStatus.landlordSignANew1Year' id="landlordSignANew1Year" name="landlordSignANew1Year" #landlordSignANew1Year="ngModel" [value]=0 [disabled]="readonly" required/><span class="option-label">No</span></span>
                </div>
              </div>

              <div [hidden]="!f.form.invalid" class="errormsg mb-5">Please select yes or no for all options</div>
          
              <div class="mb-3">
                <button type="button" (click)="back(f.form)" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
              </div>
              <div class="mb-5" *ngIf="!readonly || navigationParams.updateAction == 'apply'">
                <button type="button" (click)="submit(f.form)" class="btn btn-dark btn-sm float-left">Submit</button>
              </div>
              <div class="mb-5" *ngIf="readonly && navigationParams.updateAction != 'apply'">
                <div [hidden]="navigationParams.returnLocation=='waitlistType'"><button type="button" (click)="goToDashboard()" class="btn btn-general btn-sm float-left">Go To Dashboard</button></div>
                <div [hidden]="navigationParams.returnLocation!='waitlistType'"><button type="button" (click)="goToWaitlists()" class="btn btn-general btn-sm float-left">Go back to Waitlists</button></div>
              </div>
                  
            </div>
          </div>
      </div>
      <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
        <p style="color: white;">Please wait.</p>
      </ngx-spinner>
    </div>
  </form>
</body>
