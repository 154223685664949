import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Form } from '../models/form';
import { ApplicantForm } from '../models/applicantForm';
import { Section } from '../models/section';
import { SectionComponent } from '../section/section.component';
import { FormService } from '../services/form.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApplicantProcessesService } from '../services/applicant-processes.service';
import { PortalParameters } from '../models/portalParameters';
import { FieldResponse } from '../models/fieldResponse';
import { ProcessLookup } from '../models/processLookup';
import { Field } from '../models/field';
import { Question } from '../models/question';
import { FieldGroup } from '../models/fieldGroup';
import { Rule } from '../models/rule';


@Component({
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
  providers: [FormService]
})
export class FormComponent implements OnInit {
  @ViewChild('activeSection') activeSectionRef: SectionComponent;
  myForm: Form;
  mySectionTabs: Section[];
  myQuestions: Question[];
  myFieldGroups: FieldGroup[];
  myFields: Field[];
  myFieldLookups: ProcessLookup[];
  myFieldResponses: FieldResponse[];
  myRules: Rule[];
  staffMode = false;
  activeMenu: string;
  currentSection: Section;
  user: string;
  formID: string;
  applicantFormID: string;
  processID: string;
  errorMessage: string;
  applicantForm: ApplicantForm;
  applicantCode: string;
  isapplicantFormLocked = false;
  portalParams:PortalParameters = new PortalParameters();
  @HostListener('window:beforeunload')
  canDeactivate() {
      console.log(this.routingAway());
      return this.routingAway();
  }

  routingAway():boolean {
    return this.activeSectionRef.fieldResponsesSaveList && this.activeSectionRef.fieldResponsesSaveList.length === 0;
  }


  constructor(
    private applicantProcessService: ApplicantProcessesService,
    private formService: FormService,
    private router: Router,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {
  }

  
  ngOnInit() {

    this.spinner.show();

    if (!JSON.parse(localStorage.getItem('FormID')) || JSON.parse(localStorage.getItem('FormID')) == null)
    {
      console.log(JSON.parse(localStorage.getItem('FormID')));
      this.spinner.hide();
      this.router.navigate(['/applicantDashboard']);
    }
    
    this.formID = JSON.parse(localStorage.getItem('FormID'));
    this.applicantFormID = JSON.parse(localStorage.getItem('applicantFormID')).toString();
    this.processID = JSON.parse(localStorage.getItem('ProcessID')).toString();
    this.isapplicantFormLocked = JSON.parse(localStorage.getItem('isapplicantFormLocked'));
    this.applicantCode =  JSON.parse(localStorage.getItem('tcode'));
        
    this.myForm = new Form();
    this.myForm.formTitle = JSON.parse(localStorage.getItem('FormTitle'));
    this.getSectionTabs();
    this.spinner.hide();
    //find if staff or applicant and set the mode
  }

  handleNavigation(direction) {
    const currentSectionIndex = this.mySectionTabs.findIndex(item => item.sectionID === Number(this.activeMenu));
    let sectionToNavigateTo: Section = null;
    if (direction === 'next') {
      sectionToNavigateTo = this.mySectionTabs[currentSectionIndex + 1];
    }
    else if (direction === 'none') {
      sectionToNavigateTo = this.mySectionTabs[currentSectionIndex];
    }
    else {
      sectionToNavigateTo = this.mySectionTabs[currentSectionIndex - 1];
    }
    const visited = sectionToNavigateTo.visited;
    this.setActiveTab(sectionToNavigateTo.sectionID, sectionToNavigateTo.sectionName, visited);
    window.scroll(0, 0);
  }


  getSectionTabs() {
    this.spinner.show();

    this.portalParams.formID = +this.formID;
    this.portalParams.applicantFormID = +this.applicantFormID;
   
    //build sections with questions, fiedlgroups, fields, lookups, rules & responses
    return this.formService.getSectionsByForm(this.portalParams).then(secs => { 

      this.mySectionTabs = secs;
      if (this.mySectionTabs && this.mySectionTabs.length > 0) {

        this.formService.getQuestionsByForm(this.portalParams).then(q => {

          this.myQuestions = q;
          this.formService.getFieldGroupsByForm(this.portalParams).then(fg => {

            this.myFieldGroups = fg;
            this.formService.getFieldsByForm(this.portalParams).then(f => {

              this.myFields = f;
              this.formService.getFieldLookupsByForm(this.portalParams).then(l =>{

                this.myFieldLookups = l;
                this.formService.getRulesByForm(this.portalParams).then(r =>{

                  this.myRules = r;
                  this.formService.getFieldResponsesByForm(this.portalParams).then(res =>{

                    this.myFieldResponses = res;

                    this.mySectionTabs.forEach(s1 => {

                      this.myQuestions.filter(qs => qs.sectionID == s1.sectionID).forEach(q1 => {

                        this.myFieldGroups.filter(fgs => fgs.questionID == q1.questionID && fgs.sectionID == s1.sectionID).forEach(fg1 =>{

                          this.myFields.filter(fs =>fs.fieldGroupID == fg1.fieldGroupID && fs.questionID == q1.questionID && fs.sectionID == s1.sectionID).forEach (f1 => {

                            if (f1.lookupCategoryID && f1.lookupCategoryID > 0) 
                            { 
                              l.filter(l2 => l2.fieldID == f1.fieldID).forEach(l1 => { if (!f1.lookups.find(fl => fl == l1)) f1.lookups.push(l1); })
                            }
                            else if (f1.lookupResponseForFormQuestionID && f1.lookupResponseForFormQuestionID > 0)
                            {
                              this.convertFieldResponsesToProcessLookups(res.filter(rs => rs.formQuestionsID == f1.lookupResponseForFormQuestionID), f1).forEach(lr => { f1.lookups.push (lr); })
                            }
                            this.myRules.filter(r2 => r2.sourceFormQuestionID == f1.formQuestionID && r2.sourceObjectType == "Field").forEach(r3 => { f1.rules.push(r3); })
                            this.myFieldResponses.filter(rs => rs.formQuestionsID == f1.formQuestionID).forEach(res => 
                            { 
                              if (res) { f1.fieldResponses.push(res) ; } 
                            });
                            if (!f1.fieldResponses || f1.fieldResponses.length == 0) { f1.fieldResponses.push(this.getNewFieldResponse(f1)) ; }
                            fg1.fields.push(f1);
                          })

                          this.myRules.filter(r3 => r3.sourceFormQuestionID == fg1.formQuestionID && r3.sourceObjectType == "FieldGroup").forEach( r4 => { fg1.rules.push(r4); });
                          q1.fieldGroups.push(fg1);
                        })

                        this.myRules.filter(r4 => r4.sourceFormQuestionID == q1.formQuestionID && r4.sourceObjectType == "Question").forEach(r5 => { q1.rules.push(r5); });
                        s1.questions.push(q1);
                      })
                      //this.myRules.filter(r5 => r5.sourceFormQuestionID == s1.formQuestionID && r5.sourceObjectType == "Section").forEach(r6 => {s1.rules.push(r6); });
                    })

                  })

                })

              })

              //find the last section from appliant form and show it first
              //to code
              const sectionID = this.activeMenu != null ? +this.activeMenu : this.mySectionTabs[0].sectionID ;
              this.mySectionTabs.find(s => s.sectionID === sectionID).visited = true;
              this.currentSection = this.mySectionTabs.find(s => s.sectionID === sectionID); 
              this.activeMenu = this.currentSection.sectionID.toString();
              this.spinner.hide();

            })

          })

        })

      }

    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error);

    });

  }

  backToDashboard() {
    this.router.navigate(['/applicantDashboard']);
  }

  setActiveTab(menuItem: number, activeTabName: string, visited: boolean) {
    this.activeMenu = menuItem.toString();
    this.currentSection = this.mySectionTabs.find(s => s.sectionName == activeTabName);
    this.currentSection.visited = true;
  }


  convertFieldResponsesToProcessLookups(responses:FieldResponse[], curField: Field) : ProcessLookup[] {

    let processLookups: ProcessLookup[] = [];

    responses.forEach(rs => {
      let processLookup:ProcessLookup = {
        category : "ResponseLookup",
        categoryID : 0,
        displayOrder : rs.repeatNumber,
        fieldID : curField.fieldID,
        isSingleUse : true,
        lookupID : rs.formQuestionsID,
        name : rs.response,
        repeatNumber : rs.repeatNumber,
        responseID : rs.responseID,
        sectionID : curField.sectionID,
        subCategory : "",
        value : rs.response        
      }
      processLookups.push(processLookup);
    })

    return processLookups;
  }

  getNewFieldResponse(curField: Field) : FieldResponse{

    let newFieldResponse:FieldResponse = {
      applicantFormID: +this.applicantFormID,
      createdBy: this.applicantCode,
      createdDate: Date.now().toString(),
      fieldID: curField.fieldID,
      fileLookupResponseID:0,
      formQuestionsID: curField.formQuestionID,
      isFieldHidden: false,
      isHidden: false,
      isNewDelete: false,
      repeatNumber: 1,
      response: "",
      responseID: 0,
      updatedBy: this.applicantCode,
      updatedDate: Date.now().toString()
    }
    return newFieldResponse;
  }

}
