<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="form d-flex justify-content-center align-items-center">
            <div class="mt-2 mb-5">
                <div class="text-center mb-5">
                    <hr><h2>{{title}}</h2><hr>
                </div>
                <div class="row mb-5 ml-2 mr-2">
                    <div class="formgroup justify-content-between">      
                        <div class="form-group">
                            <label class="control-label">First Name</label>         
                            <small *ngIf="requestorFirstName.invalid && (requestorFirstName.dirty || formInvalid)" class="errormsg ml-2">Required</small>
                            <input type="text" [(ngModel)]="contactForm.requestorFirstName" class="form-control"  name="requestorFirstName" #requestorFirstName="ngModel" required/>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Last Name</label>         
                            <small *ngIf="requestorLastName.invalid && (requestorLastName.dirty || formInvalid)" class="errormsg ml-2">Required</small>
                            <input type="text" [(ngModel)]="contactForm.requestorLastName" class="form-control"  name="requestorLastName" #requestorLastName="ngModel" required/>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Phone Number (no dashes, include area code)</label>         
                            <small *ngIf="requestorPhone.invalid && requestorPhone.errors.required && (requestorPhone.dirty || formInvalid)" class="errormsg ml-2">Required</small>
                            <span *ngIf="requestorPhone.invalid && requestorPhone.errors.pattern && (requestorPhone.dirty || formInvalid)" class="errormsg ml-2">Invalid</span>                                                
                            <input type="text" [(ngModel)]="contactForm.requestorPhone" class="form-control"  name="requestorPhone" #requestorPhone="ngModel" pattern="\d{10}" required/>
                        </div>
                        <div class="form-group">
                            <label class="control-label">Please provide your email address if you have one</label>
                            <span *ngIf="requestorEmail.invalid && (requestorEmail.dirty || formInvalid) && requestorEmail.errors.pattern" class="errormsg ml-2">Invalid</span> 
                            <input type="text" [(ngModel)]="contactForm.requestorEmail" class="form-control" name="requestorEmail" #requestorEmail="ngModel" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"/>
                        </div>

                        <div class="form-group">
                            <label class="control-label">Please select the waiting list/s that you want to apply</label>  
                            <div class="ml-5 mt-2" *ngFor="let p of wlPrograms">
                                <div *ngFor="let t of wlTypes" class="mt-3">
                                    <div *ngIf='t.waitlistProgramID == p.waitlistProgramID && t.waitlistTypeCategory == "Waiting Lists"'>
                                        <label class="control-label page-subtitle1">{{p.name}} - {{t.name}}</label>
                                        <div class="ml-5" *ngIf='t.descriptionText == "closed"'>
                                            <label class="control-label page-text">No open waiting lists</label>
                                        </div>
                                        <div *ngFor="let w of waitlists">
                                            <div class="ml-5" *ngIf='w.waitlistTypeID == t.waitlistTypeID && !w.name.includes("Referral")'>
                                                <span class="whitespacenowrap">
                                                    <input class="checkbox mr-5" type="checkbox" name="accessCodeRequired" id="accessCodeRequired" [(ngModel)]="w.accessCodeRequired" #accessCodeRequired="ngModel">
                                                    <label class="control-label page-subtitle2 mr-2">{{w.name}} </label>
                                                </span>
                                                <span *ngIf="!t.name.includes('Tenant-Based') && !isMobile" class="text-lgreen">has {{w.bedroomsAvailable}} bedrooms available</span>
                                                <div *ngIf="!t.name.includes('Tenant-Based') && isMobile" class="text-lgreen text-sm ml-5 mb-3">{{w.bedroomsAvailable}} bedrooms available</div>
                                            </div>
                                        </div>       
                                    </div>
                                </div>       
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label">Message (500 characters limit)</label>        
                            <textarea [(ngModel)]="contactForm.requestorMessage" class="form-control"  name="requestorMessage" #requestorMessage="ngModel" rows="0" maxlength="500"></textarea>
                        </div>
                        <div [hidden]="!wlNotSelected" class="errormsgBig mt-5 mb-2">Please select a waiting list</div>

                        <div class="mb-3">
                            <button type="button" (click)="cancel()" class="btn btn-primary btn-xs float-left mr-3">Cancel</button>
                        </div>

                        <div class="mb-5">
                            <button type="button" (click)="sendEmail(f.form)" class="btn btn-success btn-xs float-left">Submit</button>
                        </div>
                    </div>
                </div>  
            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>
    </form>
</body>
