export class PortalParameters{
    category: string;
    categoryID: number;
    lookupID: number;
    waitlistProgram: string;
    waitlistType: string;
    waitlistTypeCategory:string;
    waitlist: string;
    itemType: string;
    item: string;
    faqFormID: number;
    accessCode:string;
    applicantCode: string;
    applicantProcessID: number;
    applicantStepID: number;
    applicantFormID: number;
    processID: number;
    stepID: number;
    formID: number;
    sectionID: number;
    questionID: number;
    fieldGroupID: number;
    fieldID: number;
    formQuestionID: number;
    createdBy:string;
    updatedBy:string;

}