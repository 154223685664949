<body>
    <form name="form" #f="ngForm" novalidate>
        <div class="form d-flex justify-content-center align-items-center" *ngIf="generalInfo">
            <div class="mt-2 mb-5 ml-2 mr-2">
                <div class="text-center mb-5">
                    <hr><h2>General Info</h2><hr>
                </div>
                <div class="form-group">
                    <label class="control-label">Mailing Address <small><b>(If homeless, please provide contact information for a friend, relative or service provider)</b></small>  </label>         
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.mailingAddress" class="form-control"  name="mailingAddress" #mailingAddress="ngModel" required/>
                        <small *ngIf="mailingAddress.invalid && (mailingAddress.dirty || formInvalid)" class="errormsg ml-2">Required</small>                        
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Apt. </label>
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.apt" class="form-control" name="apt" #apt="ngModel">
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">City</label>
                    <div class="input-group mb-3 ml-3">
                        <select [disabled]='readonly' class="form-control" [(ngModel)]="generalInfo.city" name="city" #city="ngModel" required>
                            <option class="option-label" *ngFor="let city of cityList" [value]="city.description">{{city.description}}</option>
                        </select>
                        <div *ngIf="city.invalid && (city.dirty || formInvalid)" class="errormsg ml-2">Required</div> 
                    </div>
                </div>

                <div class="form-group">    
                    <div *ngIf="generalInfo.city=='Other'" class="col form-group">
                        <label class="control-label">Other City</label>
                        <div class="input-group mb-3 ml-3">
                            <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.otherCity" class="form-control" name="otherCity" #otherCity="ngModel" placeholder="" required/>
                            <div *ngIf="otherCity.invalid && (otherCity.dirty || formInvalid)" class="errormsg ml-2">Required</div>                        
                        </div>
                    </div>
                </div>  

                <div class="form-group">    
                    <label class="control-label">State</label>
                    <div class="input-group mb-3 ml-3">
                        <select [disabled]='readonly' [(ngModel)]="generalInfo.state" class="form-control input-small" name="state" #state="ngModel" required>
                            <option class="option-label" *ngFor="let state of stateList" [value]="state.description">{{state.description}}</option>
                        </select>
                        <div *ngIf="state.invalid && (state.dirty || formInvalid)" class="errormsg ml-2">Required</div>                        
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Zip Code</label>
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.zipCode" class="form-control" name="zipCode" #zipCode="ngModel" placeholder="" pattern="^[0-9]{5}(?:-[0-9]{4})?$" required/>
                        <div *ngIf="zipCode.invalid && (zipCode.dirty || formInvalid) && zipCode.errors.required" class="errormsg ml-2">Required</div>
                        <div *ngIf="zipCode.invalid && (zipCode.dirty || formInvalid) && zipCode.errors.pattern" class="errormsg ml-2">Invalid</div> 
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Email Address</label>
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='true' type="text" [(ngModel)]="generalInfo.email" class="form-control" name="email" #email="ngModel" required pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"/>
                        <div *ngIf="email.invalid && email.errors.required && (email.dirty || formInvalid) && email.errors.required" class="errormsg ml-2">Required</div> 
                        <div *ngIf="email.invalid && (email.dirty || formInvalid) && email.errors.pattern" class="errormsg ml-2">Invalid</div> 
                    </div>
                </div>

                <div class="form-group">
                    <label class="control-label">Phone (no dashes, include area code)</label>
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='readonly' type="text" [(ngModel)]="generalInfo.cellTel" class="form-control" name="cellTel" #cellTel="ngModel" placeholder="" pattern="\d{10}">
                        <div *ngIf="cellTel.invalid && cellTel.errors.pattern && (cellTel.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>                        
                    </div>
                </div>

                <div class="form-group">    
                    <label class="control-label">Alternate Phone (no dashes, include area code)</label>
                    <div class="input-group mb-3 ml-3">
                        <input [disabled]='readonly' type="text"  [(ngModel)]="generalInfo.homeTel" class="form-control" name="homeTel" #homeTel="ngModel" placeholder="" pattern="\d{10}"/>
                        <div *ngIf="homeTel.invalid && homeTel.errors.pattern && (homeTel.dirty || formInvalid)" class="errormsg ml-2">Invalid</div>                                                                        
                    </div>
                </div>


                <div class="form-group">    
                    <label class="control-label">Are you a resident of Sacramento County, or employed in Sacramento County, or hired to work in Sacramento County?</label>
                    <div class="input-group mb-3 ml-3">
                        <select [disabled]='readonly' [(ngModel)]="generalInfo.locatedSacramento" class="form-control input-small" name="locatedSacramento" #locatedSacramento="ngModel" required>
                            <option class="option-label" value=1>Yes</option>
                            <option class="option-label" value=0>No</option>
                        </select>
                        <div *ngIf="locatedSacramento.invalid && (locatedSacramento.dirty || formInvalid)" class="errormsg ml-2">Required</div>                                                
                    </div>
                </div>

                <div class="form-group">    
                    <label class="control-label">Do you have a permanent disability? </label>
                    <div class="input-group mb-5 ml-3">
                        <select [disabled]='readonly' [(ngModel)]="generalInfo.disability" class="form-control input-small" name="disability" #disability="ngModel" required>
                            <option class="option-label" value=1>Yes</option>
                            <option class="option-label" value=0>No</option>
                        </select>
                        <div *ngIf="disability.invalid && disability.dirty"  class="errormsg ml-2">Required</div>                        
                        <div *ngIf="disability.invalid && formInvalid"  class="errormsg ml-2">Required</div>                        
                    </div>
                </div>

                <div class="mb-3">
                    <button type="button" (click)="back()" class="btn btn-secondary btn-sm float-left mr-3">Back</button>
                </div>

                <div class="mb-5">
                    <button type="button" (click)="next(f.form)" class="btn btn-general btn-sm float-left">Next</button>
                </div>

            </div>
            <ngx-spinner bdcolor="rgba(51,51,51,0.8)" size="default" type="ball-spin-clockwise">
                <p style="color: white;">Please wait.</p>
            </ngx-spinner>
        </div>    
    </form>
</body>
