import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, ValidationErrors, UntypedFormControl } from '@angular/forms';
import { MinNumber } from './min-number.validator';


@Directive({
    selector: '[minNumber]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MinNumberDirective, multi: true }]
})
export class MinNumberDirective implements Validator {
    @Input('minNumber') minNumber: number;

    validate(control: UntypedFormControl): ValidationErrors {
        return MinNumber(this.minNumber)(control);
    }
}