<div class="card">
    <div class="card-header text-center text-white mb-3">
        <div class="container page-subtitle1 text-white align-items-center">MY APPLICATIONS</div>
     </div>
    <div class="card-body">
        <div *ngIf="applicantProcesses.length > 0">
            <div *ngFor="let p of applicantProcesses">
                <!-- <div class="profile-body-title">My {{p.processDisplayName}}</div> -->
                <div *ngFor="let s of p.applicantSteps">
                    <div *ngFor="let f of s.applicantForms">
                        <div class="card row justify-content-between align-items-center page-subtitle2">
                            <!-- <div class="text-left ml-5"><img src="{{imgFolder}}house icon.png" width="35px" height="25px"/></div> -->
                            <div class="text-left ml-5">{{f.displayName}}</div>
                            <div class="text-right mr-5" *ngIf="f.formStatus=='Not Started'"><button type="button" (click)="goToForm(p.processID, f.formID, f.applicantFormID, f.displayName, p.pCode)" class="btn btn-general btn-sm">Start</button></div>
                            <div class="text-right mr-5" *ngIf="f.formStatus=='In Progress'"><button type="button" (click)="goToForm(p.processID, f.formID, f.applicantFormID, f.displayName, p.pCode)" class="btn btn-general btn-sm">Continue</button></div>
                            <div class="text-right mr-5" *ngIf="f.formStatus=='Completed'"><button type="button" class="btn btn-sm btn-general" disabled="true">Completed</button></div>
                            <div class="text-right mr-5" *ngIf="f.formStatus=='Cancelled'"><button type="button" class="btn btn-sm btn-general" disabled="true">Cancelled</button></div>
                        </div>                              
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="applicantProcesses?.length == 0">
            <div class="justify-content-between">
                <div class="container mt-5 page-subtitle1 align-items-center">No active processes</div>
            </div>                              
        </div>
    </div>
</div>
